import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Guid } from "guid-typescript";

@Injectable()
export class Service {
	endpoint: string;
	http: HttpClient;

	constructor(@Inject(String) endpoint: string, injector: Injector) {
		this.http = injector.get(HttpClient);
		this.endpoint = endpoint;
	}

	createSingle(data: any) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}`, data);
	}

	deleteSingleById(id: number) {
		return this.http.delete(`${environment.server.apiURL}/${this.endpoint}/${id}`);
	}

	getAll() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}`);
	}

	getSingleById(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/${id}`);
	}

	getSingleByLookupId(id: Guid) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/LookUp/${id}`);
	}

	updateSingleById(id: number, data: any) {
		return this.http.patch(`${environment.server.apiURL}/${this.endpoint}/${id}`, data);
	}
}