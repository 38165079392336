<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" [colCount]="2">
		<dxi-item itemType="group" caption="Details" [colSpan]="2" [colCount]="6">
			<dxi-item dataField="name" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="serialNumber" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="make" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="model" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="purchaseDate" [colSpan]="2" [editorOptions]="{ displayFormat: 'dd/MM/yyyy' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="purchaseAmount" [colSpan]="2" [label]="{ text: 'Purchase Price' }" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2} }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="warranty" [colSpan]="2" [label]="{ text: 'Warranty (Months)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="Events" [colSpan]="2" [colCount]="1">
			<dx-data-grid class="data-grid" [dataSource]="toolEvents" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="toolsEvents"></dxo-state-storing>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxi-column type="buttons">
					<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editToolEventPopup"></dxi-button>
					<dxi-button hint="Delete" icon="fal fa-trash" [onClick]="deleteToolEvent"></dxi-button>
				</dxi-column>
				<dxi-column dataField="eventType" dataType="number" alignment="left" caption="Type">
					<dxo-lookup [dataSource]="toolEventTypes" displayExpr="name" valueExpr="id"></dxo-lookup>
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="reporterId" dataType="number" alignment="left" caption="Reporter">
					<dxo-lookup [dataSource]="people" displayExpr="fullName" valueExpr="id"></dxo-lookup>
				</dxi-column>
				<dxi-column dataField="eventDate" dataType="datetime" format="dd/MM/yyyy" alignment="left" caption="Date"></dxi-column>
				<dxi-column dataField="eventDate" dataType="datetime" format="HH:mm:ss" alignment="left" caption="Time"></dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div>
				<dx-form class="m-3" [(formData)]="popupToolEvent" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="eventType" editorType="dxSelectBox" [editorOptions]="{ items: toolEventTypes, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Event Type' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
					<dxi-item dataField="eventDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy HH:mm:ss', type: 'datetime' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				</dx-form>
			</div>
		</div>
	</dx-popup>
</div>