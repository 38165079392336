<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="ordersMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button *ngIf="editEnabled" hint="Edit" icon="fal fa-pencil" [onClick]="editRow"></dxi-button>
		<dxi-button *ngIf="deleteEnabled" icon="fal fa-trash" [onClick]="deleteRow"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" alignment="left" sortOrder="desc"></dxi-column>
	<dxi-column dataField="clientId" caption="Client">
		<dxo-lookup [dataSource]="clients" displayExpr="name" valueExpr="id"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="projectManagerId" caption="PM">
		<dxo-lookup [dataSource]="staff" [displayExpr]="getDisplayExpr" valueExpr="id"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="poNumber" caption="PO Number"></dxi-column>
	<dxi-column dataField="quantity" alignment="right"></dxi-column>
	<dxi-column dataField="mrdDate" dataType="date" format="dd/MM/yyyy" caption="MRD Date"></dxi-column>
	<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy" caption="Created Date"></dxi-column>
	<dxi-column dataField="totalValue" caption="Total Value" alignment="right" [format]="{ type: 'currency', currency: 'GBP', precision: 4 }"></dxi-column>
	<dxi-column dataField="vatValue" caption="VAT Value" alignment="right" [format]="{ type: 'currency', currency: 'GBP', precision: 4 }"></dxi-column>
	<dxi-column dataField="lineItems" dataType="string" [visible]="false"></dxi-column>
	<dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
	<div *dxTemplate="let sale of 'detail'">
	  <div class="master-detail-caption">{{
		"Line Items for Sales Order #" + sale.data.id
	  }}</div>
	  <salesOrderGridDetails [lineItemData]="sale.data.lineItems"></salesOrderGridDetails>
	</div>
</dx-data-grid>