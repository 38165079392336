import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { CreateSalesOrderFromPart } from "@interfaces/createSalesOrderFromPart.interface";
import { Service } from "@services/service";

@Injectable()
export class SalesOrderService extends Service {
	constructor(injector: Injector) {
		super("SalesOrder", injector);
	}

	// API
	createFromPart(data: CreateSalesOrderFromPart){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateFromPart`, data);
	}

	createRFQs(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateRFQsForSalesOrder/${id}`, {});
	}

	getGrid() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetGrid`);
	}

	merge(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/MergeByPo/${id}`, {});
	}
}