import { Injectable, Injector } from "@angular/core";
import { PurchaseType } from "@enums/purchaseType.enum";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class SupplierPurchaseTypeService extends Service {
	constructor(injector: Injector) {
		super("SupplierPurchaseType", injector);
	}

	// API
	getPurchaseTypesForSupplierId(supplierId: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForSupplier/${supplierId}`);
	}
	
	getSuppliersForPurchaseType(purchaseType: PurchaseType) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForPurchaseType/${purchaseType}`);
	}
	
	togglePurchaseType(supplierId: number, purchaseType: PurchaseType) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/TogglePurchaseType/${supplierId}/${purchaseType}`, {});
	}
}