import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { AuthService } from "@services/auth.service";
import { ExportLogService } from "@services/exportLog.service";
import { ProcessService } from "@services/processService";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "processes",
	styleUrls: ["processes.css"],
	templateUrl: "processes.html"
})
export class ProcessesAdminComponent {
	deleteEnabled = false;
	editEnabled = true;
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	itemType = "Process"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	statuses: any;
	title: string;
	
	constructor(private authService: AuthService, private router: Router, private exportLogService: ExportLogService, private itemService: ProcessService) {
		this.headerPrimaryButtons.push({ icon: "fal fa-plus", method: "addNewItem", text: `New ${this.itemType}` });
		this.title = `${this.itemType}s`;
		this.viewRow = this.viewRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.getItems();
		this.getSuperAdminStatus();
	}

	addNewItem() {
		this.router.navigate([`${this.itemUrl}/create`]);
	}

	deleteRow(e: any) {
		if (this.deleteEnabled === false) {
			return;
		}
		const result = confirm("Are you sure you want to delete this process? This will also remove any stages using this process from part routers and active work orders. This may take some time if the process is widely used.");
		if (result === true)
		{
			this.gridLoading = true;
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify(this.itemType + " deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}_edit`]);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getAll()
			.subscribe(
				(res: any) => {
					this.items = res.response.items;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	getSuperAdminStatus() {
		this.authService.getCurrentUserIsSuperAdmin()
			.subscribe(
				(res: any) => {
					this.deleteEnabled = res.response;
				}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Processes";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}`]);
	}
}