export enum ProcessTemplate {	
	None = 0,
	TemplateOne = 10,
	TemplateTwo = 20,
	TemplateThree = 30,
	TemplateFour = 40,
	TemplateFive = 50,
	TemplateSix = 60,
	TemplateSeven = 70,
	TemplateEight = 80,
	TemplateNine = 90,
	TemplateTen = 100,
	TemplateEleven = 110
}