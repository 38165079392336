import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

@Injectable()
export class AzureBlobStorageService {
	constructor(private http: HttpClient) {}

	uploadFile(container: string, fileName: string, fileContents: any) {
		const headers = {"x-ms-blob-type": "BlockBlob"};
		return this.http.put(`${environment.server.storageUrl}/${container}/${fileName}?${environment.server.storageSAS}`, fileContents, {headers});
	}
}