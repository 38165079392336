export interface ChangeWorkOrderQuantity {
	quantity: number;
	workOrderId: number;
}

export class ChangeWorkOrderQuantity implements ChangeWorkOrderQuantity {	
	constructor(data?: Partial<ChangeWorkOrderQuantity>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}