import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Guid } from "guid-typescript";
import { Service } from "./service";

@Injectable()
export class FileService extends Service {
	constructor(injector: Injector) {
		super("Files", injector);
	}

	// API
	downloadFile(lookupId: Guid) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/Download/${lookupId}`, { responseType: "blob" as "json"} );
	}
	
	uploadFile(data: any) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Upload`, data);
	}
}