<admin-header [primaryButtons]="headerPrimaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="group" caption="Details" [colCount]="6">
			<dxi-item dataField="item.name" [colSpan]="3"></dxi-item>
			<dxi-item dataField="location" [colSpan]="3"></dxi-item>
			<dxi-item dataField="gauge" [colSpan]="2"></dxi-item>
			<dxi-item dataField="width" [colSpan]="2"></dxi-item>
			<dxi-item dataField="length" [colSpan]="2"></dxi-item>
			<dxi-item dataField="quantity" [colSpan]="3"></dxi-item>
			<dxi-item dataField="alertQuantity" [colSpan]="3"></dxi-item>
			<dxi-item dataField="purchaseLog.supplier.name" [colSpan]="3"  [label]="{text: 'Supplier'}"></dxi-item>
			<dxi-item dataField="createdAt" [colSpan]="3"  [label]="{text: 'Date Recieved'}"></dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="History">
			<dx-data-grid class="data-grid" [dataSource]="history" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="storeHistory"></dxo-state-storing>
				<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="true"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="true"></dxo-export>
				<dxi-column dataField="changeAmount" dataType="string" alignment="left"></dxi-column>
				<dxi-column dataField="previousLocation" dataType="string" alignment="left"></dxi-column>
				<dxi-column dataField="currentLocation" dataType="string" alignment="left"></dxi-column>
				<dxi-column dataField="user.email" dataType="string" alignment="left" caption="User"></dxi-column>
				<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="When?"></dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
</div>