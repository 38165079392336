import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class ClientLocationService extends Service {
	constructor(injector: Injector) {
		super("ClientLocation", injector);
	}

	// API
	getLocationsByClientId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetLocationsByClientId/${id}`);
	}

	getPrimaryBillingForClientId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetPrimaryBillingForClientId/${id}`);
	}

	getPrimaryDeliveryForClientId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetPrimaryDeliveryForClientId/${id}`);
	}

	setPrimaryBilling(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/SetPrimaryBilling/${id}`, {});
	}

	setPrimaryDelivery(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/SetPrimaryDelivery/${id}`, {});
	}
}