export enum NonConformanceReportStatus {
	Open = 10,
	Closed = 20,
	Contained = 30,
	Monitor = 40,
	D1 = 50,
	D2 = 60,
	D3 = 70,
	D4 = 80,
	D5 = 90,
	D6 = 100,
	D7 = 110,
	D8 = 120,
}