import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { Currency } from "@enums/currency.enum";
import { Incoterms } from "@enums/incoterms.enum";
import { PackagingTerms } from "@enums/packagingTerms.enum";
import { PurchaseType } from "@enums/purchaseType.enum";
import { ToolingFixtureDescription } from "@enums/toolingFixtureDescription.enum";
import { environment } from "@environments/environment";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { BoughtOutMaterialsLineData } from "@interfaces/boughtOutMaterialsLineData.interface";
import { Client } from "@interfaces/client.interface";
import { ClientContact } from "@interfaces/clientContact.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { Machine } from "@interfaces/machine.interface";
import { Packaging } from "@interfaces/packaging.interface";
import { PressProcessLineData } from "@interfaces/pressProcessLineData.interface";
import { Process } from "@interfaces/process.interface";
import { Project } from "@interfaces/project.interface";
import { PurchasableItem } from "@interfaces/purchasableItem.interface";
import { QuotationAnalysisForm } from "@interfaces/quotationAnalysisForm.interface";
import { RawMaterialLineData } from "@interfaces/rawMaterialLineData.interface";
import { Supplier } from "@interfaces/supplier.interface";
import { ToolingFixtureLineData } from "@interfaces/toolingFixtureLineData.interface";
import { WeldAndAssemblyLineData } from "@interfaces/weldAndAssemblyLineData.interface";
import { ClientService } from "@services/client.service";
import { ClientContactService } from "@services/clientContact.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { FileService } from "@services/file.service";
import { MachineService } from "@services/machine.service";
import { PackagingService } from "@services/packaging.service";
import { ProcessService } from "@services/processService";
import { ProjectService } from "@services/project.service";
import { PurchasableItemService } from "@services/purchasableItem.service";
import { QuotationAnalysisFormService } from "@services/quotationAnalysisForm.service";
import { SupplierService } from "@services/supplier.service";
import { DxDataGridComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "quotationAnalysisForms-id",
	styleUrls: ["quotationAnalysisForms-id.css"],
	templateUrl: "quotationAnalysisForms-id.html"
})
export class QuotationAnalysisFormsIdAdminComponent {
	@ViewChild("boughtOutMaterialsGridContainer", { static: false }) boughtOutMaterialsGrid!: DxDataGridComponent;
	@ViewChild("pressProcessesGridContainer", { static: false }) pressProcessesGrid!: DxDataGridComponent;
	@ViewChild("rawMaterialsGridContainer", { static: false }) rawMaterialsGrid!: DxDataGridComponent;
	@ViewChild("toolingFixturesGridContainer", { static: false }) toolingFixturesGrid!: DxDataGridComponent;
	@ViewChild("weldAndAssemblyGridContainer", { static: false }) weldAndAssemblyGrid!: DxDataGridComponent;
	boughtOutMaterials: BoughtOutMaterialsLineData[] = [];
	changes: any = [];
	clientContacts: ClientContact[] = [];
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	currencies: any;
	currency = "GBP";
	editRowKey: any = null;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	imageStyle = "";
	incoterms: any;
	item: QuotationAnalysisForm = new QuotationAnalysisForm();
	itemId = 0;
	itemType = "QAF";
	machines: Machine[] = [];
	materialPurchasableItems: PurchasableItem[] = [];
	mode = "";
	packaging: Packaging[] = [];
	packagingTerms: any;
	pressProcesses: PressProcessLineData[] = [];
	processes: Process[] = [];
	projects: Project[] = [];
	purchasableItems: PurchasableItem[] = [];
	purchaseTypes: any;
	rawMaterials: RawMaterialLineData[] = [];
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	suppliers: Supplier[] = [];
	thicknesses: number[] = [0.2, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.5, 1.6, 1.8, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 10, 12, 15, 20, 25];
	title: string = "View " + this.itemType;
	toolingFixtureDescriptions: any;
	toolingFixtures: ToolingFixtureLineData[] = [];
	weldAndAssembly: WeldAndAssemblyLineData[] = [];

	constructor(private router:Router, private route: ActivatedRoute, private clientService: ClientService, private clientContactService: ClientContactService, private clientLocationService: ClientLocationService, private fileService: FileService, private machineService: MachineService, private packagingService: PackagingService, private processService: ProcessService, private projectService: ProjectService, private purchasableItemService: PurchasableItemService, private qafService: QuotationAnalysisFormService, private supplierService: SupplierService) {
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {
						this.getItem(params.id);
						this.buildButtons("view");
					}
				} else {
					this.create();
				}
			});
			
		this.currencies = GetPropertiesFromEnum(Currency, true);
		this.incoterms = GetPropertiesFromEnum(Incoterms, true);
		this.packagingTerms = GetPropertiesFromEnum(PackagingTerms);
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);
		this.toolingFixtureDescriptions = GetPropertiesFromEnum(ToolingFixtureDescription);

		this.getClients();
		this.getMachines();
		this.getPackaging();
		this.getProcesses();
		this.getPurchasableItems();
		this.getSuppliers();

		this.uploadImage = this.uploadImage.bind(this);
	}

	buildButtons(mode: string) {
		this.mode = mode;
		if (mode == "edit") {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create") {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
		}
	}

	calculateBoughtOutMaterialsLineData() {
		this.item.boughtOutMaterialsSubtotal = 0;

		this.boughtOutMaterials.forEach((line) => {
			line.total = line.quantity * line.costPerPart;
			this.item.boughtOutMaterialsSubtotal += line.total;
		});
	}

	calculatePressProcessLineData() {
		this.item.pressProcessSubtotal = 0;

		this.pressProcesses.forEach((line) => {
			const process = this.processes.filter(x => x.id == line.processId)[0];	
			const machine = this.machines.filter(x => x.id == line.machineId)[0];	
			line.tonnage = machine.tonnage;
			if (line.hourlyRunningCostLabour === undefined || line.hourlyRunningCostLabour <= 0) {
				line.hourlyRunningCostLabour = process.hourlyLabourRate;
			}
			if (line.hourlyRunningCostMachine === undefined || line.hourlyRunningCostMachine <= 0) {
				line.hourlyRunningCostMachine = process.hourlyMachineRate;
			}
			line.hourlyRunningCostTotal = line.hourlyRunningCostLabour + line.hourlyRunningCostMachine;
			if (line.settingCost === undefined || line.settingCost <= 0) {
				line.settingCost = machine.setUpCost;
			}
			line.processCost = (line.hourlyRunningCostTotal / (line.partsPerHour * line.partsPerBlow)) + line.settingCost;
			this.item.pressProcessSubtotal += line.processCost;
		});

		this.calculateProfit();
	}

	calculateProfit() {
		this.item.profit = this.item.pressProcessSubtotal === undefined ? 0 : (this.item.pressProcessSubtotal) * 0.08;
	}

	calculateRawMaterialLineData() {
		this.item.rawMaterialSubtotal = 0;

		this.rawMaterials.forEach((line) => {
			const weightConstant = this.materialPurchasableItems.filter(x => x.id == line.purchasableItemId)[0].weightConstant;	
			line.grossWeight = weightConstant * line.gauge * line.width * line.pitch;
			line.scrapWeight = line.grossWeight - line.netWeight;
			line.scrapValue = line.scrapWeight * line.scrapCostPerKg;
			line.grossMaterialCost = line.rawMaterialPricePerKg * line.grossWeight;
			line.netMaterialCost = line.grossMaterialCost - line.scrapValue;
			this.item.rawMaterialSubtotal += line.netMaterialCost;
		});
	}

	calculateToolingFixturesLineData() {
		this.item.toolingFixtureSubtotal = 0;
		this.item.toolingFixtureTotalChanges = 0;

		this.toolingFixtures.forEach((line) => {
			if (line.total !== undefined && line.total > 0) {
				this.item.toolingFixtureSubtotal += line.total;
			}
			if (line.change !== undefined && line.change > 0) {
				this.item.toolingFixtureTotalChanges += line.change;
			}
		});
	}

	calculateWeldAndAssemblyLineData() {
		this.item.weldAndAssemblySubtotal = 0;

		this.weldAndAssembly.forEach((line) => {
			line.hourlyRunningCostTotal = line.hourlyRunningCostLabour + line.hourlyRunningCostMachine;
			line.processCost = line.hourlyRunningCostTotal / line.partsPerHour;
			this.item.weldAndAssemblySubtotal += line.processCost;
		});

		this.calculateProfit();
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.prepareItemForSaving();
		this.qafService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new QuotationAnalysisForm();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	displayMachineInDropdown(e: any) { 
		return e === null || e === undefined ? "" : (e.cellNumber === null || e.cellNumber === undefined ? "???" : e.cellNumber) + " - " + e.name;
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	getClientContacts()	{
		this.clientContactService.getContactByParentId(this.item.clientId)
			.subscribe(
				(res: any) => {
					this.clientContacts = res.response;
					this.clientContacts.sort((a,b) => a.displayName.localeCompare(b.displayName));
				},
				(err) => console.log(err)
			);
	}

	getClientLocations()	{
		this.clientLocationService.getLocationsByClientId(this.item.clientId)
			.subscribe(
				(res: any) => {
					this.clientLocations = res.response;
					this.clientLocations.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clients = res.response.items;
					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getItem(itemId: number) {
		this.qafService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.boughtOutMaterials = JSON.parse(this.item.boughtOutMaterialsLineData);
					this.pressProcesses = JSON.parse(this.item.pressProcessLineData);
					this.rawMaterials = JSON.parse(this.item.rawMaterialLineData);
					this.toolingFixtures = JSON.parse(this.item.toolingFixtureLineData);
					this.weldAndAssembly = JSON.parse(this.item.weldAndAssemblyLineData);
					this.setImageStyle(this.item.partImageUrl);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getMachines() {
		this.machineService.getAll()
			.subscribe(
				(res: any) =>  {
					this.machines = res.response.items;
					this.machines.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getPackaging() {
		this.packagingService.getAll()
			.subscribe(
				(res: any) =>  {
					this.packaging = res.response.items;
					this.packaging.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getProcesses() {
		this.processService.getAll()
			.subscribe(
				(res: any) =>  {
					this.processes = res.response.items;
					this.processes.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getProjects() {
		this.projectService.GetProjectsByClient(this.item.clientId)
			.subscribe(
				(res: any) =>  {
					this.projects = res.response;
					this.projects.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getPurchasableItems() {
		this.purchasableItemService.getAll()
			.subscribe(
				(res: any) => {
					this.purchasableItems = res.response.items;
					this.purchasableItems.sort((a,b) => a.name.localeCompare(b.name));

					this.materialPurchasableItems = this.purchasableItems.filter((pi) => pi.purchaseType === PurchaseType.Material);
				},
				(err) => console.log(err)
			);
	}
	
	getSuppliers() {
		this.supplierService.getAll()
			.subscribe(
				(res: any) =>  {
					this.suppliers = res.response.items;
					this.suppliers.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	mainFormDataChanged(args: any) {
		switch (args.dataField) {
			case "clientId":
				{
					if (args.value === undefined || args.value === null) {
						this.item.manufacturingVendorCode = "";
						this.item.shippingGSDBCode = "";

					} else {
						const client = this.clients.filter((c: Client) => c.id == args.value);
						this.item.manufacturingVendorCode = client[0].supplierCode;
						this.item.shippingGSDBCode = client[0].supplierCode;

						this.getClientContacts();
						this.getClientLocations();
						this.getProjects();
					}
				}
				break;
			case "currency":
				if (args.value === Currency.EUR) {
					this.currency = "EUR";
				} else {
					this.currency = "GBP";
				}
				break;
			case "materialOverhead":
			case "generalOverhead":
			case "profit":
				this.item.additionalCostsSubtotal = 0;
				if (this.item.materialOverhead !== undefined && this.item.materialOverhead > 0) {
					this.item.additionalCostsSubtotal += this.item.materialOverhead;
				}
				if (this.item.generalOverhead !== undefined && this.item.generalOverhead > 0) {
					this.item.additionalCostsSubtotal += this.item.generalOverhead;
				}
				if (this.item.profit !== undefined && this.item.profit > 0) {
					this.item.additionalCostsSubtotal += this.item.profit;
				}
				break;
			case "additionalCostsSubtotal":
			case "boughtOutMaterialsSubtotal":
			case "rawMaterialSubtotal":
			case "toolingFixtureSubtotal":
			case "toolingFixtureTotalChanges":
				// case "pressProcessSubtotal": // changes to this also change the profit line, which changes additional costs, so don't need to re-trigger
				// case "weldAndAssemblySubTotal": // changes to this also change the profit line, which changes additional costs, so don't need to re-trigger
				this.item.totalPartCost = 0;
				if (this.item.additionalCostsSubtotal !== undefined && this.item.additionalCostsSubtotal > 0) {
					this.item.totalPartCost += this.item.additionalCostsSubtotal;
				}
				if (this.item.boughtOutMaterialsSubtotal !== undefined && this.item.boughtOutMaterialsSubtotal > 0) {
					this.item.totalPartCost += this.item.boughtOutMaterialsSubtotal;
				}
				if (this.item.pressProcessSubtotal !== undefined && this.item.pressProcessSubtotal > 0) {
					this.item.totalPartCost += this.item.pressProcessSubtotal;
				}
				if (this.item.rawMaterialSubtotal !== undefined && this.item.rawMaterialSubtotal > 0) {
					this.item.totalPartCost += this.item.rawMaterialSubtotal;
				}
				if (this.item.toolingFixtureSubtotal !== undefined && this.item.toolingFixtureSubtotal > 0) {
					this.item.totalPartCost += this.item.toolingFixtureSubtotal;
				}
				if (this.item.toolingFixtureTotalChanges !== undefined && this.item.toolingFixtureTotalChanges > 0) {
					this.item.totalPartCost += this.item.toolingFixtureTotalChanges;
				}
				break;
			case "totalPartCost":
			case "packagingAndLogistics":
			case "containerCost":
				this.item.grandTotal = 0;
				if (this.item.totalPartCost !== undefined && this.item.totalPartCost > 0) {
					this.item.grandTotal += this.item.totalPartCost;
				}
				if (this.item.packagingAndLogistics !== undefined && this.item.packagingAndLogistics > 0) {
					this.item.grandTotal += this.item.packagingAndLogistics;
				}
				if (this.item.containerCost !== undefined && this.item.containerCost > 0) {
					this.item.grandTotal += this.item.containerCost;
				}
				break;
		}
	}
	
	onInitNewPressProcessRow(e: any) {
		e.data.partsPerBlow = 1;
	}
	
	onInitNewToolingFixtureRow(e: any) {
		e.data.superseded = false;
	}

	prepareItemForSaving() {
		this.item.boughtOutMaterialsLineData = JSON.stringify(this.boughtOutMaterials);
		this.item.pressProcessLineData = JSON.stringify(this.pressProcesses);
		this.item.rawMaterialLineData = JSON.stringify(this.rawMaterials);
		this.item.toolingFixtureLineData = JSON.stringify(this.toolingFixtures);
		this.item.weldAndAssemblyLineData = JSON.stringify(this.weldAndAssembly);
	}

	setImageStyle(imageUrl: string) {
		this.imageStyle = `background-image: url("${imageUrl}")`;
	}

	updateItem() {
		this.prepareItemForSaving();
		this.qafService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {notify("Successfully Updated", "success", 5000);},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadImage(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		uploadData.append("mimeTypeKey", "ImageMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.item.partImageUrl = `${environment.server.storageUrl}/${containerName}/${res.response[0].location}`;
					this.setImageStyle(this.item.partImageUrl);
					notify("Image uploaded - Click Save to confirm change", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Image Upload Failed - Please ensure you are uploading a valid image file", "error", 5000);
				}
			);
	}
}