import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class WorkOrderStageInspectionService extends Service {
	constructor(injector: Injector) {
		super("WorkOrderStageInspection", injector);
	}

	// API
	getForWorkOrderStage(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForWorkOrderStage/${id}`);
	}
}