import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class ClientService extends Service {
	constructor(injector: Injector) {
		super("Client", injector);
	}

	getEdiClients() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/EdiClients`);
	}
}