export interface ChangeWorkOrderStagePriority {
	priority: number;
	workOrderStageId: number;
}

export class ChangeWorkOrderStagePriority implements ChangeWorkOrderStagePriority {
	constructor(data?: Partial<ChangeWorkOrderStagePriority>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}