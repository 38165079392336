import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";

@Component({
	moduleId: module.id,
	selector: "admin-header",
	styleUrls: ["admin-header.css"],
	templateUrl: "admin-header.html"
})
export class AdminHeaderComponent {
	@Output() eventFired = new EventEmitter<string>();
	@Input() primaryButtons!: AdminHeaderButton[];
	@Input() secondaryButtons: AdminHeaderButton[] = [];
	@Input() tertiaryButtons: AdminHeaderButton[] = [];
	@Input() title!: string;

	constructor(private router: Router) {}

	buttonClick(method: string) {
		this.eventFired.emit(method);
	}

	navigate(route: string) {
		this.router.navigate([route]);
	}
}