<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="row">
	<div class="col-9 p-3">
		<div id="floorplan"></div>
	</div>
	<div class="col-3" style="height: 93vh; overflow-x: auto;">
		<button *ngFor="let machine of machines" class="btn btn-primary machine-button" (click)="editMachineDimensionsPopup(machine)">{{machine.cellNumber}}<br />{{machine.name}}</button>
	</div>
</div>
<dx-popup [width]="750" [height]="450" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *ngIf="popupTitle == 'Machine Dimensions'">
		<dx-form class="m-3" [(formData)]="popupMachine" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
			<dxi-item dataField="cellNumber"></dxi-item>
			<dxi-item dataField="name"></dxi-item>
			<dxi-item dataField="xPosition"></dxi-item>
			<dxi-item dataField="yPosition"></dxi-item>
			<dxi-item dataField="depth"></dxi-item>
			<dxi-item dataField="width"></dxi-item>
			<dxi-item dataField="rotationAmount"></dxi-item>
			<dxi-item dataField="productionStatus" editorType="dxSelectBox" [editorOptions]="{ items: productionStatuses, displayExpr:'name', valueExpr:'id', searchEnabled: true }"></dxi-item>
		</dx-form>
	</div>
</dx-popup>