<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid class="data-grid" [dataSource]="features" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
	<dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="featuresMain"></dxo-state-storing>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column dataField="name" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="active" dataType="boolean" alignment="left"></dxi-column>
</dx-data-grid>