export interface FobScanned {
	fobId: string;
	siteId: number;
}

export class FobScanned implements FobScanned {
	constructor(data?: Partial<FobScanned>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}