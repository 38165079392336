import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class StockStoreHistoryService extends Service {
	constructor(injector: Injector) {
		super("StockStoreHistory", injector);
	}

	// API
	getByItemId(id:number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByItemId/${id}`);
	}
}