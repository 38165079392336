<admin-header [primaryButtons]="headerPrimaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="group" caption="Details" [colCount]="6">
			<dxi-item dataField="messageType" [colSpan]="3" editorType="dxSelectBox" [editorOptions]="{ items: messageTypes, displayExpr:'name', valueExpr:'id' }"></dxi-item>
			<dxi-item dataField="messageDirection" [colSpan]="3" editorType="dxSelectBox" [editorOptions]="{ items: messageDirections, displayExpr:'name', valueExpr:'id' }"></dxi-item>
			<dxi-item dataField="status" [colSpan]="3" editorType="dxSelectBox" [editorOptions]="{ items: statuses, displayExpr:'name', valueExpr:'id' }"></dxi-item>
			<dxi-item dataField="messageReference" [colSpan]="3"></dxi-item>
			<dxi-item dataField="senderId" [colSpan]="3"></dxi-item>
			<dxi-item dataField="recipientId" [colSpan]="3"></dxi-item>
			<dxi-item dataField="parsedEdiMessageContents" [colSpan]="6"></dxi-item>
			<dxi-item dataField="ediMessageText" [colSpan]="6"></dxi-item>
			<dxi-item dataField="failureMessage" [colSpan]="6"></dxi-item>
		</dxi-item>
	</dx-form>
</div>