import { Model } from "@interfaces/model.interface";

export interface SystemParamValue extends Model {
	primary: string;
	systemParamId: number;
	value: Record<string, unknown>;
}

export class SystemParamValue implements SystemParamValue {
	primary = "";
	systemParamId = 0;
	value: Record<string, unknown> = {};

	constructor(data?: Partial<SystemParamValue>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}