export interface NonConformanceReportUserProfileLineData {
	userProfileId: number;
}

export class NonConformanceReportUserProfileLineData implements NonConformanceReportUserProfileLineData {
	userProfileId = 0;

	constructor(data?: Partial<NonConformanceReportUserProfileLineData>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}