import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MaterialShape } from "@enums/materialShape.enum";
import { MaterialType } from "@enums/materialType.enum";
import { PartRelationshipType } from "@enums/partRelationshipType.enum";
import { PurchaseType } from "@enums/purchaseType.enum";
import { QualityControlCheckType } from "@enums/qualityControlCheckType.enum";
import { environment } from "@environments/environment";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { ChangeWorkOrderQuantity } from "@interfaces/changeWorkOrderQuantity.interface";
import { Client } from "@interfaces/client.interface";
import { ClientContact } from "@interfaces/clientContact.interface";
import { CreateSalesOrderFromPart } from "@interfaces/createSalesOrderFromPart.interface";
import { Machine } from "@interfaces/machine.interface";
import { OutsourcedSpecification } from "@interfaces/outsourcedSpecification.interface";
import { Packaging } from "@interfaces/packaging.interface";
import { Part } from "@interfaces/part.interface";
import { PartFileLink } from "@interfaces/partFileLink.interface";
import { PartOutsourcedSpecification } from "@interfaces/partOutsourcedSpecification.interface";
import { PartProcessRouter } from "@interfaces/partProcessRouter.interface";
import { PartPurchasableItemRequired } from "@interfaces/partPurchasableItemRequired.interface";
import { PartRelationship } from "@interfaces/partRelationship.interface";
import { Process } from "@interfaces/process.interface";
import { Project } from "@interfaces/project.interface";
import { PurchasableItem } from "@interfaces/purchasableItem.interface";
import { Supplier } from "@interfaces/supplier.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { WorkOrder } from "@interfaces/workOrder.interface";
import { AuthService } from "@services/auth.service";
import { ClientService } from "@services/client.service";
import { ClientContactService } from "@services/clientContact.service";
import { FileService } from "@services/file.service";
import { MachineService } from "@services/machine.service";
import { OpsActionAuditService } from "@services/opsActionAudit.service";
import { OutsourcedSpecificationService } from "@services/outsourcedSpecification.service";
import { PackagingService } from "@services/packaging.service";
import { PartService } from "@services/part.service";
import { PartRelationshipService } from "@services/partRelationship.service";
import { ProcessMachineService } from "@services/processMachine.service";
import { ProcessService } from "@services/processService";
import { ProjectService } from "@services/project.service";
import { PurchasableItemService } from "@services/purchasableItem.service";
import { SalesOrderService } from "@services/salesOrder.service";
import { SupplierService } from "@services/supplier.service";
import { UserProfileService } from "@services/userProfile.service";
import { WorkOrderService } from "@services/workOrder.service";
import { DxDataGridComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";


@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "parts-id",
	styleUrls: ["parts-id.css"],
	templateUrl: "parts-id.html"
})
export class PartsIdAdminComponent {
	@ViewChild("consumablesGridContainer", { static: false }) consumablesGrid!: DxDataGridComponent;
	@ViewChild("fixingsGridContainer", { static: false }) fixingsGrid!: DxDataGridComponent;
	@ViewChild("routingGridContainer", { static: false }) routingGrid!: DxDataGridComponent;
	allowRouterChanges = false;
	cadFiles: PartFileLink[] = [];
	changes: any = [];
	clients: Client[] = [];
	consumablePurchasableItems: PurchasableItem[] = [];
	consumablesRequired: PartPurchasableItemRequired[] = [];
	contact: ClientContact = new ClientContact();
	contacts: ClientContact[] = [];
	createEnabled = true;
	dropDownMachines: Machine[] = [];
	editEnabled = true;
	editRowKey: any = null;
	filteredPurchasableItems: PurchasableItem[] = [];
	fixingPurchasableItems: PurchasableItem[] = [];
	fixingsRequired: PartPurchasableItemRequired[] = [];
	hasCheckedSuperAdminStatus = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	isSuperAdmin = false;
	item: Part = new Part();
	itemId = 0;
	itemType = "Part";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	machines: Machine[] = [];
	masterPartId = 0;
	masterParts: PartRelationship[] = [];
	materialPurchasableItems: PurchasableItem[] = [];
	materialShapes: any;
	materialTypes: any;
	opsActionAudits: any;
	outsourcedSpecifications: OutsourcedSpecification[] = [];
	packaging: Packaging[] = [];
	packagingImageStyle = "";
	packagingImageStyleExternal = "";
	partOutsourcedSpecifications: PartOutsourcedSpecification[] = [];
	partRelationships: any;
	parts: Part[] = [];
	people: UserProfile[] = [];
	popupAddButtonOptions: any;
	popupChangeWorkOrderQuantity: ChangeWorkOrderQuantity = new ChangeWorkOrderQuantity();
	popupClient: Client = new Client();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupContact: ClientContact = new ClientContact();
	popupCreateSalesOrderFromPart: CreateSalesOrderFromPart = new CreateSalesOrderFromPart();
	popupPartToCopyRouterFrom: Part = new Part();
	popupProject: Project = new Project();
	popupPurchasableItem: PurchasableItem = new PurchasableItem();
	popupRelationship: PartRelationship = new PartRelationship();
	popupSupplier: Supplier = new Supplier();
	popupSupportingDocument: PartFileLink = new PartFileLink();
	popupTitle = "";
	popupVisible = false;
	processRouters: PartProcessRouter[] = [];
	processes: Process[] = [];
	projects: Project[] = [];
	purchasableItems: PurchasableItem[] = [];
	purchaseTypes: any;
	qualityControlCheckTypes: any;
	quantityRequired = 1;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "";
	subParts: PartRelationship[] = [];
	suppliers: Supplier[] = [];
	supportingDocumentButtonOptions = {
		onClick: () => this.addNewSupportingDocumentPopup(),
		text: "Add Document",
		type: "default",
		useSubmitBehavior: false
	}
	supportingDocuments: PartFileLink[] = [];
	technicalDrawings: PartFileLink[] = [];
	thicknesses: number[] = [0, 0.2, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.5, 1.6, 1.8, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 10, 12, 15, 20, 25];
	tileImageStyle = "";
	title: string = "View " + this.itemType;
	workOrderStageSummary: any;
	workOrders: WorkOrder[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private clientContactService: ClientContactService, private clientService: ClientService, private fileService: FileService, private outsourcedSpecificationService: OutsourcedSpecificationService, private machineService: MachineService, private opsActionAuditService: OpsActionAuditService, private packagingService: PackagingService, private projectService: ProjectService, private purchasableItemService: PurchasableItemService, private itemService: PartService, private partRelationshipService: PartRelationshipService, private processService: ProcessService, private processMachineService: ProcessMachineService, private salesOrderService: SalesOrderService, private supplierService: SupplierService, private userProfileService: UserProfileService, private workOrderService: WorkOrderService) {
		this.getClients();
		this.getMachines();
		this.getPackaging();
		this.getParts();
		this.getPeople();
		this.getProcesses();
		this.getProjects();
		this.getPurchasableItems();
		this.getSuppliers();

		this.materialShapes = GetPropertiesFromEnum(MaterialShape);
		this.materialTypes = GetPropertiesFromEnum(MaterialType);
		this.qualityControlCheckTypes = GetPropertiesFromEnum(QualityControlCheckType);
		this.partRelationships = GetPropertiesFromEnum(PartRelationshipType);
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);

		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.itemId = params.id.split("_")[0];
					this.buildButtons("edit");
				} else {
					this.itemId = params.id;
					if (!document.referrer.includes("edi-orders")){
						this.buildButtons("view");
					} else {
						this.getItem(this.itemId);
						this.readOnly = true;
					}
				}
			} else {
				this.create();
			}
		});

		this.changeWorkOrderQuantityPopup = this.changeWorkOrderQuantityPopup.bind(this);
		this.deleteCadFileLink = this.deleteCadFileLink.bind(this);
		this.deletePartRelationship = this.deletePartRelationship.bind(this);
		this.deleteSupportingDocumentLink = this.deleteSupportingDocumentLink.bind(this);
		this.deleteTechnicalDrawingLink = this.deleteTechnicalDrawingLink.bind(this);
		this.downloadFileLink = this.downloadFileLink.bind(this);
		this.editWorkOrder = this.editWorkOrder.bind(this);
		this.generateAndDownloadWorkOrderMasterLabelPDF = this.generateAndDownloadWorkOrderMasterLabelPDF.bind(this);
		this.updateRouterOrder = this.updateRouterOrder.bind(this);
		this.updateRouterNumbers = this.updateRouterNumbers.bind(this);
		this.uploadPackingImage = this.uploadPackingImage.bind(this);
		this.uploadPackingImageExternal = this.uploadPackingImageExternal.bind(this);
		this.uploadCadFile = this.uploadCadFile.bind(this);
		this.uploadSupportingDocument = this.uploadSupportingDocument.bind(this);
		this.uploadTechnicalDrawing = this.uploadTechnicalDrawing.bind(this);
		this.uploadTileImage = this.uploadTileImage.bind(this);
		this.viewMasterPart = this.viewMasterPart.bind(this);
		this.viewSubPart = this.viewSubPart.bind(this);
		this.viewWorkOrder = this.viewWorkOrder.bind(this);
	}

	addNewClient() {
		this.clientService.createSingle(this.popupClient)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupClient = new Client();
					this.getClients(true);
				},
				(err: any) => console.log(err)
			);
	}

	addNewClientPopup() {
		this.popupTitle = "Add New Client";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewClient(), text: "Add Client" };
	}

	addNewContact() {
		this.clientContactService.createSingle(this.popupContact)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getContacts(this.item.clientId, true);
				},
				(err: any) => {
					notify("Could not add client contact. Please check the details are valid and this contact does not already exist.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewContactPopup() {
		this.popupContact = new ClientContact();
		this.popupContact.clientId = this.item.clientId;
		this.popupTitle = "Add New Contact";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewContact(), text: "Add Contact" };
	}

	addNewProject() {
		this.projectService.createSingle(this.popupProject)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getProjects(true);
				},
				(err: any) => {
					notify("Could not add project. Please check the details are valid and this project does not already exist.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewProjectPopup() {
		this.popupProject = new Project();
		this.popupProject.clientId = this.item.clientId;
		this.popupTitle = "Add New Project";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewProject(), text: "Add Project" };
	}

	addNewPurchasableItem() {
		this.purchasableItemService.createSingle(this.popupPurchasableItem)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupPurchasableItem = new PurchasableItem();
					this.getPurchasableItems();
				},
				(err: any) => console.log(err)
			);
	}

	addNewPurchasableItemPopup() {
		this.popupTitle = "Add New Purchasable Item";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewPurchasableItem(), text: "Add Item" };
	}

	addNewRelationshipChild() {
		this.popupRelationship.masterPartId = this.item.id;
		
		this.partRelationshipService.createSingle(this.popupRelationship)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupRelationship = new PartRelationship();
					this.getMasterParts(this.item.id);
					this.getSubParts(this.item.id);
				},
				(err: any) => console.log(err)
			);

		this.closePopup();
	}

	addNewRelationshipChildPopup() {
		this.popupRelationship = new PartRelationship();
		this.popupTitle = "Add New Child Relationship";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewRelationshipChild(), text: "Add Relationship" };
	}

	addNewRelationshipMaster() {
		this.popupRelationship.childPartId = this.item.id;
		
		this.partRelationshipService.createSingle(this.popupRelationship)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupRelationship = new PartRelationship();
					this.getMasterParts(this.item.id);
					this.getSubParts(this.item.id);
				},
				(err: any) => console.log(err)
			);

		this.closePopup();
	}

	addNewRelationshipMasterPopup() {
		this.popupRelationship = new PartRelationship();
		this.popupTitle = "Add New Master Relationship";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewRelationshipMaster(), text: "Add Relationship" };
	}

	addNewSupplier() {
		this.supplierService.createSingle(this.popupSupplier)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getSuppliers();
				},
				(err: any) => {
					notify("Could not add supplier. Please check the details are valid and this supplier does not already exist.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewSupplierPopup() {
		this.popupSupplier = new Supplier();
		this.popupTitle = "Add New Supplier";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewSupplier(), text: "Add Supplier" };
	}

	addNewSupportingDocument() {
		if (this.popupSupportingDocument === null || this.popupSupportingDocument === undefined || this.popupSupportingDocument.fileName === "") {
			alert("Please upload a document");
			return;
		}

		if (this.supportingDocuments.length > 0) {
			const previousVersion = Math.max(...this.supportingDocuments.map(o => o.version));
			this.popupSupportingDocument.version = previousVersion + 1;
		} else {
			this.popupSupportingDocument.version = 1;
		}

		this.supportingDocuments.push(this.popupSupportingDocument);
		this.popupSupportingDocument = new PartFileLink();

		if (this.title === "Create New " + this.itemType) {
			this.createItem();
		} else {
			this.updateItem();
		}
		this.closePopup();
	}

	addNewSupportingDocumentPopup() {
		this.popupSupportingDocument = new PartFileLink();
		this.popupTitle = "Add New Supporting Document";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewSupportingDocument(), text: "Add Document" };
	}

	buildButtons(mode: string) {
		this.screenMode = mode;
		this.getItem(this.itemId);
		this.headerPrimaryButtons = [];
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		if (mode == "edit" && this.editEnabled) {
			this.readOnly = false;
			this.title = "Edit " + this.itemType;
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [
				{ method: "copyPartRouterPopup", text: "Copy Router" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewPurchasableItemPopup", text: "Add New Item" },
				{ method: "addNewClientPopup", text: "Add New Client" },
				{ method: "addNewContactPopup", text: "Add New Contact" },
				{ method: "addNewProjectPopup", text: "Add New Project" },
				{ method: "addNewSupplierPopup", text: "Add New Supplier" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.readOnly = false;
			this.allowRouterChanges = true;
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [
				{ method: "copyPartRouterPopup", text: "Copy Router" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewPurchasableItemPopup", text: "Add New Item" },
				{ method: "addNewClientPopup", text: "Add New Client" },
				{ method: "addNewContactPopup", text: "Add New Contact" },
				{ method: "addNewProjectPopup", text: "Add New Project" },
				{ method: "addNewSupplierPopup", text: "Add New Supplier" }
			];
		} else if (mode == "view") {
			this.readOnly = true;
			this.allowRouterChanges = false;
			this.title = "View " + this.itemType;
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			if (this.hasCheckedSuperAdminStatus === false) {				
				this.authService.getCurrentUserIsSuperAdmin().subscribe(
					(res: any) => {
						this.isSuperAdmin = res.response;
						this.hasCheckedSuperAdminStatus = true;
						if (this.isSuperAdmin) {
							this.headerTertiaryButtons.push({ method: "createSalesOrderPopup", text: "Create Order" });
						}
					}
				);
			} else if (this.isSuperAdmin) {				
				this.headerTertiaryButtons.push({ method: "createSalesOrderPopup", text: "Create Order" });
			}
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.router.navigate([`/${this.returnUrl}/${this.itemId}`])
			.then(() => {
				window.location.reload();
			});
	}

	changeWorkOrderQuantity() {								
		this.workOrderService.changeQuantity(this.popupChangeWorkOrderQuantity.workOrderId, this.popupChangeWorkOrderQuantity.quantity).subscribe(
			() => {
				notify("Successfully Updated Work Order", "success", 5000);
				this.getWorkOrders(this.item.id);
			},
			(err) => {
				console.log(err);
				notify("Something went wrong!", "error", 5000);
			}
		);
		this.closePopup();
	}

	changeWorkOrderQuantityPopup(e: any) {
		this.popupChangeWorkOrderQuantity = new ChangeWorkOrderQuantity();
		this.popupChangeWorkOrderQuantity.workOrderId = e.row.data.id;
		this.popupChangeWorkOrderQuantity.quantity = e.row.data.quantity;
		this.popupTitle = "Change Work Order Quantity";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.changeWorkOrderQuantity(), text: "Change Quantity" };
	}

	close() {
		if (this.screenMode === "view" || confirm("Are you sure you wish to leave the page? Unsaved changes will be discarded.")) {
			this.router.navigate([`/${this.returnUrl}/`]);
		}
	}

	closePopup() {
		this.popupVisible = false;
		this.popupClient = new Client();
	}

	copyPartRouter() {
		const partToCopyFrom = this.parts.filter((p) => p.id === this.popupPartToCopyRouterFrom.id)[0];
		this.processRouters = JSON.parse(partToCopyFrom.router);
		this.closePopup();
	}

	copyPartRouterPopup() {
		this.popupPartToCopyRouterFrom = new Part();
		this.popupTitle = "Copy Router From Part";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.copyPartRouter(), text: "Copy Router" };
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.prepareItemForSaving();

		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new Part();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	createSalesOrder() {
		if (confirm("Are you sure you wish to create a Sales Order with these details?") === false) {
			return;
		}
		this.salesOrderService.createFromPart(this.popupCreateSalesOrderFromPart)
			.subscribe(
				(res: any) => {
					this.popupVisible = false;
					this.popupCreateSalesOrderFromPart = new CreateSalesOrderFromPart();

					const closingResults = res.response;
					this.router.navigate([`/admin/salesOrders/${closingResults.lookupId}_edit`]);
				},
				(err: any) => {
					notify("Could not create sales order. Please double check the details.", "Error", 5000);
					console.log(err);
				}
			);
	}

	createSalesOrderPopup() {
		this.popupCreateSalesOrderFromPart = new CreateSalesOrderFromPart();
		this.popupCreateSalesOrderFromPart.clientId = this.item.clientId;
		this.popupCreateSalesOrderFromPart.clientContactId = this.item.clientContactId !== undefined ? this.item.clientContactId : 0;
		this.popupCreateSalesOrderFromPart.partId = this.item.id;
		this.popupCreateSalesOrderFromPart.projectId = this.item.projectId;
		this.popupCreateSalesOrderFromPart.quantity = 1;
		this.popupTitle = "Create Sales Order";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.createSalesOrder(), text: "Create Sales Order" };
	}

	deleteCadFileLink(e: any) {
		const index = this.cadFiles.findIndex(d => d.version === e.row.data.version);
		this.cadFiles.splice(index, 1);

		return false;
	}

	deletePartRelationship(e: any) {
		if (confirm("Are you sure you want to delete this relationship? This will not affect existing work orders.") == false) {
			return;
		}

		this.partRelationshipService.deleteSingleById(e.row.data.id)
			.subscribe(
				() => {
					notify("Relationship deleted!", "success", 5000);
					this.getMasterParts(this.item.id);
					this.getSubParts(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
		return false;
	}

	deleteSupportingDocumentLink(e: any) {
		const index = this.supportingDocuments.findIndex(d => d.version === e.row.data.version);
		this.supportingDocuments.splice(index, 1);

		return false;
	}

	deleteTechnicalDrawingLink(e: any) {
		const index = this.technicalDrawings.findIndex(d => d.version === e.row.data.version);
		this.technicalDrawings.splice(index, 1);

		return false;
	}

	displayMachineInDropdown(e: any) { 
		return e === null || e === undefined ? "" : (e.cellNumber === null || e.cellNumber === undefined ? "???" : e.cellNumber) + " - " + e.name;
	}

	downloadFileLink(e: any) {
		const lookupId = Guid.parse(e.row.data.lookupId);
		this.fileService.downloadFile(lookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
		return false;
	}

	edit() {
		this.router.navigate([`/${this.returnUrl}/${this.itemId}_edit`])
			.then(() => {
				window.location.reload();
			});
	}

	editWorkOrder(e: any) {
		this.router.navigate([`admin/workOrders/${e.row.data.id}_edit`]);
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "clientId":
				this.getContacts(args.value);
				this.getOutsourcedSpecifications(args.value);
				break;
			case "clientContactId":
				this.getContact(args.value);
				break;
		}
	}

	generateAndDownloadWorkOrderMasterLabelPDF(e: any) {
		this.workOrderService.generateAndDownloadMasterLabelPdf(e.row.data.id).subscribe(
			(response: any) => {
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Work_Order_Master_Label_" + this.item.id + ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			},
			(err) => {
				console.log(err);
				notify("Something went wrong!", "error", 5000);
			},
		);
	}

	getClients(setNewestClient = false) {
		this.clientService.getAll()
			.subscribe(
				(res: any) => {
					this.clients = res.response.items;

					if (setNewestClient) {
						this.item.clientId = this.clients[this.clients.length - 1].id;
					}

					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getContact(contactId: number) {
		this.clientContactService.getSingleById(contactId)
			.subscribe(
				(res: any) => {
					this.contact = res.response;
					this.item.clientContactId = contactId;
				},
				(err) => console.log(err)
			);
	}

	getContacts(clientId: number, setNewestContact = false)	{
		this.clientContactService.getContactByParentId(clientId)
			.subscribe(
				(res: any) => {
					this.contacts = res.response;

					if (setNewestContact) {
						this.getContact(this.contacts[this.contacts.length - 1].id);
					} else if (this.contacts.some(c => c.id === this.item.clientContactId)) {
						// do nothing, the contact is present
					}
					else
					{
						this.getContact(this.contacts[0].id);
					}

					this.contacts.sort((a,b) => a.contactName.localeCompare(b.contactName));
				},
				(err) => console.log(err)
			);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.cadFiles = JSON.parse(this.item.cadFiles);
					this.consumablesRequired = JSON.parse(this.item.consumablesRequired);
					if (this.consumablesRequired === null || this.consumablesRequired === undefined) {
						this.consumablesRequired = [];
					}
					this.fixingsRequired = JSON.parse(this.item.fixingsRequired);
					if (this.fixingsRequired === null || this.fixingsRequired === undefined) {
						this.fixingsRequired = [];
					}
					this.partOutsourcedSpecifications = JSON.parse(this.item.outsourcedSpecifications);
					this.supportingDocuments = JSON.parse(this.item.supportingDocuments);
					this.technicalDrawings = JSON.parse(this.item.technicalDrawings);
					this.processRouters = JSON.parse(this.item.router);
					this.getContacts(this.item.clientId);
					this.getOpsActionAudits(itemId);
					this.getOutsourcedSpecifications(this.item.clientId);
					this.getPurchasableItems();
					this.setPackingImageStyle(this.item.packagingExampleUrl, false);
					this.setPackingImageStyle(this.item.packagingExampleUrlExternal, true);
					this.setTileImageStyle(this.item.tileImageUrl);
					this.getSubParts(itemId);
					this.getMasterParts(itemId);
					this.getWorkOrderStageSummary(itemId);
					this.getWorkOrders(itemId);

					if (this.screenMode === "edit" ) {
						if (this.item.linkedPartGroupId !== null && this.item.linkedPartGroupId !== undefined && this.item.linkedPartGroupId > 0) {
							this.allowRouterChanges = false;
						} else {
							this.allowRouterChanges = true;
						}
					}
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getMachines() {
		this.machineService.getAll()
			.subscribe(
				(res: any) => {
					this.machines = res.response.items;
				},
				(err) => console.log(err)
			);
	}

	getMasterParts(id: number) {
		this.partRelationshipService.getMasterByChildPart(id)
			.subscribe(
				(res: any) => this.masterParts = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getOpsActionAudits(partId: number)	{
		this.opsActionAuditService.getByPartId(partId)
			.subscribe(
				(res: any) => {
					this.opsActionAudits = res.response;
				},
				(err) => console.log(err)
			);
	}

	getOutsourcedSpecifications(clientId: number)	{
		this.outsourcedSpecificationService.getByClientId(clientId)
			.subscribe(
				(res: any) => {
					this.outsourcedSpecifications = res.response;

					this.outsourcedSpecifications.sort((a,b) => a.specification.localeCompare(b.specification));
				},
				(err) => console.log(err)
			);
	}

	getPackaging() {
		this.packagingService.getAll()
			.subscribe(
				(res: any) => {
					this.packaging = res.response.items;

					this.packaging.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getParts() {
		this.itemService.getAll()
			.subscribe(
				(res: any) => this.parts = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	getProcessMachines(processId: number) {
		this.processMachineService.forProcess(processId)
			.subscribe(
				(res: any) => {
					const results = res.response;
					console.log(results);
					this.dropDownMachines = [];

					results.forEach((result: any) => {
						this.dropDownMachines.push(result.machine);
					});
				},
				(err) => console.log(err)
			);
	}

	getProcesses() {
		this.processService.getAll()
			.subscribe(
				(res: any) => {
					this.processes = res.response.items;

					this.processes.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getProjects(setNewestProject = false) {
		this.projectService.getAll()
			.subscribe(
				(res: any) => {
					this.projects = res.response.items;
					if (setNewestProject) {
						this.item.projectId = this.projects[this.projects.length - 1].id;
					}

					this.projects.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getPurchasableItems() {
		this.purchasableItemService.getAll()
			.subscribe(
				(res: any) => {
					this.purchasableItems = res.response.items;
					this.purchasableItems.sort((a,b) => a.name.localeCompare(b.name));

					this.consumablePurchasableItems = this.purchasableItems.filter((pi) => pi.purchaseType === PurchaseType.Consumables);
					this.fixingPurchasableItems = this.purchasableItems.filter((pi) => pi.purchaseType === PurchaseType.Fixings);
					this.materialPurchasableItems = this.purchasableItems.filter((pi) => pi.purchaseType === PurchaseType.Material);
				},
				(err) => console.log(err)
			);
	}

	getSubParts(id: number) {
		this.partRelationshipService.getChildByMasterParts(id)
			.subscribe(
				(res: any) => {
					this.subParts = res.response;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getSuppliers() {
		this.supplierService.getAll()
			.subscribe(
				(res: any) => {
					this.suppliers = res.response.items;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getWorkOrderStageSummary(itemId: number) {
		this.workOrderService.getStageSummaryForPart(itemId)
			.subscribe(
				(res: any) => {
					this.workOrderStageSummary = res.response;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getWorkOrders(itemId: number) {
		this.workOrderService.getForPart(itemId)
			.subscribe(
				(res: any) => {
					this.workOrders = res.response;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}
	
	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	popupPurchasableItemFormDataChanged(args: any) {
		switch (args.dataField) {
			case "purchaseType":
				if (this.popupPurchasableItem.purchaseType !== PurchaseType.Material && this.popupPurchasableItem.purchaseType !== PurchaseType.ToolingBillet) {
					this.popupPurchasableItem.materialType = MaterialType.NotApplicable;
				}
				break;
		}
	}

	prepareItemForSaving() {
		const newSpecs: PartOutsourcedSpecification[] =[];
		if (this.partOutsourcedSpecifications !== null) {
			this.partOutsourcedSpecifications.forEach(spec => {
				const theSpec = this.outsourcedSpecifications.find(x=>x.id === spec.outsourcedSpecificationId);
				spec.description = theSpec === undefined || theSpec === null ? "" : theSpec!.specification;
				newSpecs.push(spec);
			});
		}
		if (this.item.clientContactId === 0) { this.item.clientContactId = undefined; }
		if (this.item.engineeringContactId === 0) { this.item.engineeringContactId = undefined; }
		if (this.item.packagingExternalId === 0) { this.item.packagingExternalId = undefined; }
		if (this.item.materialId === 0) { this.item.materialId = undefined; }
		if (this.item.packagingId === 0) { this.item.packagingId = undefined; }
		if (this.item.projectManagerId === 0) { this.item.projectManagerId = undefined; }

		this.item.cadFiles = JSON.stringify(this.cadFiles);
		this.item.consumablesRequired = JSON.stringify(this.consumablesRequired);
		this.item.fixingsRequired = JSON.stringify(this.fixingsRequired);
		this.item.outsourcedSpecifications = JSON.stringify(newSpecs);
		this.item.router = JSON.stringify(this.processRouters);
		this.item.supportingDocuments = JSON.stringify(this.supportingDocuments);
		this.item.technicalDrawings = JSON.stringify(this.technicalDrawings);
	}

	routerProcessDataChanged(args: any) {
		console.log(args);
		switch (args.dataField) {
			case "processId":
				this.getProcessMachines(args.value);
				break;
		}
	}

	setPackingImageStyle(imageUrl: string, isExternal: boolean) {
		if (isExternal) {
			this.packagingImageStyleExternal = `background-image: url("${imageUrl}")`;
		} else {
			this.packagingImageStyle = `background-image: url("${imageUrl}")`;
		}		
	}

	setTileImageStyle(imageUrl: string) {
		this.tileImageStyle = `background-image: url("${imageUrl}")`;
	}

	updateItem() {
		console.log("hit");
		this.prepareItemForSaving();

		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateRouterNumbers() {
		for (let i = 0; i < this.processRouters.length; i++) {
			this.processRouters[i].stage = i + 1;
		}
	}

	updateRouterOrder(e: any) {
		const visibleRows = e.component.getVisibleRows();
		const toIndex = this.processRouters.indexOf(visibleRows[e.toIndex].data);
		const fromIndex = this.processRouters.indexOf(e.itemData);
	
		this.processRouters.splice(fromIndex, 1);
		this.processRouters.splice(toIndex, 0, e.itemData);

		this.updateRouterNumbers();
	}

	uploadCadFile(file: File) {
		const containerName = "partfiles";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					const newCadFile = new PartFileLink();
					newCadFile.lookupId = res.response[0].lookupId;
					newCadFile.fileName = res.response[0].name;
					if (this.cadFiles.length > 0) {
						const previousVersion = Math.max(...this.cadFiles.map(o => o.version));
						newCadFile.version = previousVersion + 1;
					} else {
						newCadFile.version = 1;
					}

					this.cadFiles.push(newCadFile);
				},
				(err) => {
					console.log(err);
					notify("CAD File Upload Failed - Please ensure you are uploading a valid CAD file", "error", 5000);
				}
			);
	}

	uploadPackingImage(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		uploadData.append("mimeTypeKey", "ImageMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.item.packagingExampleUrl = `${environment.server.storageUrl}/${containerName}/${res.response[0].location}`;
					this.setPackingImageStyle(this.item.packagingExampleUrl, false);
					notify("Image uploaded - Click Save to confirm change", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Image Upload Failed - Please ensure you are uploading a valid image file", "error", 5000);
				}
			);
	}

	uploadPackingImageExternal(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		uploadData.append("mimeTypeKey", "ImageMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.item.packagingExampleUrlExternal = `${environment.server.storageUrl}/${containerName}/${res.response[0].location}`;
					this.setPackingImageStyle(this.item.packagingExampleUrlExternal, true);
					notify("Image uploaded - Click Save to confirm change", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Image Upload Failed - Please ensure you are uploading a valid image file", "error", 5000);
				}
			);
	}

	uploadSupportingDocument(file: File) {
		const containerName = "partfiles";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupSupportingDocument.lookupId = res.response[0].lookupId;
					this.popupSupportingDocument.fileName = res.response[0].name;
				},
				(err) => {
					console.log(err);
					notify("Supporting Document Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	uploadTechnicalDrawing(file: File) {
		const containerName = "partfiles";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					const newTechnicalDrawing = new PartFileLink();
					newTechnicalDrawing.lookupId = res.response[0].lookupId;
					newTechnicalDrawing.fileName = res.response[0].name;
					if (this.technicalDrawings.length > 0) {
						const previousVersion = Math.max(...this.cadFiles.map(o => o.version));
						newTechnicalDrawing.version = previousVersion + 1;
					} else {
						newTechnicalDrawing.version = 1;
					}

					this.technicalDrawings.push(newTechnicalDrawing);
				},
				(err) => {
					console.log(err);
					notify("Technical Drawing Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	uploadTileImage(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		uploadData.append("mimeTypeKey", "ImageMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.item.tileImageUrl = `${environment.server.storageUrl}/${containerName}/${res.response[0].location}`;
					this.setTileImageStyle(this.item.tileImageUrl);
					notify("Image uploaded - Click Save to confirm change", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Image Upload Failed - Please ensure you are uploading a valid image file", "error", 5000);
				}
			);
	}

	viewMasterPart(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.masterPart.id}`]);
	}

	viewSubPart(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.childPart.id}`]);
	}

	viewWorkOrder(e: any) {
		this.router.navigate([`admin/workOrders/${e.row.data.id}`]);
	}
}