import { HolidayRequestStatus } from "@enums/holdayRequestStatus.enum";
import { HolidayTime } from "@enums/holidayTime.enum";
import { Model } from "@interfaces/model.interface";
import { UserProfile } from "./userProfile.interface";

export interface HolidayRequest extends Model {
	endDate: Date;
	endTime: HolidayTime;
	holidayLength: number;
	requestStatus: HolidayRequestStatus;
	requestedBy: UserProfile;
	requestedById: number;
	reviewdById?: number;
	startDate: Date;
	startTime: HolidayTime;
	year: number;
}

export class HolidayRequest implements HolidayRequest {	
	constructor(data?: Partial<HolidayRequest>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}