import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Project } from "@interfaces/project.interface";
import { ProjectService } from "@services/project.service";
import { ClientService } from "@services/client.service";
import { Client } from "@interfaces/client.interface";
import { PurchaseLogService } from "@services/purchaseLog.service";
import { SalesEnquiryService } from "@services/salesEnquiry.service";
import { UserProfileService } from "@services/userProfile.service";
import { SalesEnquiry } from "@interfaces/salesEnquiry.interface";

@Component({
	moduleId: module.id,
	selector: "projects-id",
	styleUrls: ["projects-id.css"],
	templateUrl: "projects-id.html"
})
export class ProjectIdAdminComponent {
	activeProcessIds: number[] = [];
	clients: Client[] = [];
	createEnabled = true;
	editEnabled = true;
	enquiries: SalesEnquiry[]=[];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	item: Project = new Project();
	itemId = "";
	itemType = "Project";
	purchases: any[] = [];
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	statuses: any;
	title: string = "View " + this.itemType;
	users: any[]=[];

	constructor(private router:Router, private route: ActivatedRoute, private itemService: ProjectService, private clientService: ClientService, private purchaseOrderService: PurchaseLogService, private salesEnquiriesService: SalesEnquiryService, private usersService: UserProfileService) {
		this.buildButtons("view");
		this.getClients();
		this.getUsers();
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {
						this.getItem(params.id);
					}
				} else {
					this.create();
				}
			});

		this.viewEnquiry = this.viewEnquiry.bind(this);
		this.viewPo = this.viewPo.bind(this);
	}

	boolYesNo(cellInfo: any){		
		if(cellInfo.value === true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	} 

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];	
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new Project();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}



	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => this.clients = res.response.items,
				(err) => console.log(err)
			);
	}

	getEnquiries(itemId: number) {
		this.salesEnquiriesService.getByProjectId(itemId)
			.subscribe(
				(res: any) => {this.enquiries = res.response;
					this.item.potentialTotalEnq = this.enquiries.length;
					this.getPotential(itemId);
				},
				(err) => console.log(err)
			);
	}
	
	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getEnquiries(itemId);
					this.getPurchaseOrders(itemId);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getPotential(itemId: number)
	{
		this.salesEnquiriesService.getProjectPotentialQuantity(itemId)
			.subscribe(
				(res: any) => this.item.potentialQty = res.response,
				(err) => console.log(err)
			);
		this.salesEnquiriesService.getProjectPotentialValue(itemId)
			.subscribe(
				(res: any) => this.item.potentialTotalVal = res.response,
				(err) => console.log(err)
			);
		this.purchaseOrderService.getTotalSpend(itemId)
			.subscribe(
				(res: any) => this.item.totalSpend = res.response,
				(err) => console.log(err)
			);
	}

	getPurchaseOrders(itemId: number) {
		this.purchaseOrderService.getByProjectId(itemId)
			.subscribe(
				(res: any) => this.purchases = res.response,
				(err) => console.log(err)
			);
	}

	getUsers() {
		this.usersService.getAll()
			.subscribe(
				(res: any) => {
					this.users = res.response.items;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id,this.item)
			.subscribe(
				() => {
					() => notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	viewEnquiry(e: any)
	{
		this.router.navigate([`/admin/salesEnquiries/${e.row.data.id}`]);
	}

	viewPo(e: any)
	{
		this.router.navigate([`/admin/purchases/${e.row.data.id}`]);
	}
}