import { OpsPlanningWorkOrderSummaryPdfRowStatus } from "@enums/opsPlanningWorkOrderSummaryPdfRowStatus.enum";

export interface GenerateOpsPlanningWorkOrdersPdf {
	clientId?: number;
	dateRequiredFrom?: Date;
	dateRequiredTo?: Date;
	status?: OpsPlanningWorkOrderSummaryPdfRowStatus;
}

export class GenerateOpsPlanningWorkOrdersPdf implements GenerateOpsPlanningWorkOrdersPdf {
	constructor(data?: Partial<GenerateOpsPlanningWorkOrdersPdf>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}