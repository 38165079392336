import { Model } from "@interfaces/model.interface";

export interface UpdateProfile extends Model {
	address1: string;
	address2: string;
	defaultHolidayAllowance: number;
	email: string;
	firstName: string; 
	jobTitle: string;
	lastName: string;
	postcode: string;
	telephoneNumber: string;
	title: string;
	town: string;
	userId: number;
}

export class UpdateProfile implements UpdateProfile {
	address1 = "";
	address2 = "";
	email = "";
	firstName = "";
	jobTitle = "";
	lastName = "";
	postcode = "";
	telephoneNumber = "";
	title = "";
	town = "";

	constructor(data?: Partial<UpdateProfile>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}