import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { ChangeQuantity } from "@interfaces/changeQuantity.interface";
import { Service } from "@services/service";

@Injectable()
export class EdiShippingNoteItemService extends Service {
	constructor(injector: Injector) {
		super("EdiShippingNoteItem", injector);
	}

	// API
	changeQuantity(id: number, quantity: ChangeQuantity) {
		return this.http.patch(`${environment.server.apiURL}/${this.endpoint}/ChangeQuantity/${id}`, quantity);
	}
	
	getItemsForShippingNote(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetForShippingNote/${id}`);
	}
}