import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { environment } from "@environments/environment";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Packaging } from "@interfaces/packaging.interface";
import { Supplier } from "@interfaces/supplier.interface";
import { FileService } from "@services/file.service";
import { PackagingService } from "@services/packaging.service";
import { SupplierService } from "@services/supplier.service";
import { PackagingLabelSize } from "@enums/packagingLabelSize.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";

@Component({
	moduleId: module.id,
	selector: "packaging-id",
	styleUrls: ["packaging-id.css"],
	templateUrl: "packaging-id.html"
})
export class PackagingIdAdminComponent {
	activeProcessIds: number[] = [];
	createEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	imageStyle = "";
	item: Packaging = new Packaging();
	itemId = "";
	itemType = "Packaging";
	labelSizes: any;
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupSupplier: Supplier = new Supplier();
	popupTitle = "";
	popupVisible = false;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	statuses: any;
	suppliers: Supplier[] = [];
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private itemService: PackagingService, private fileService: FileService, private supplierService: SupplierService) {
		this.buildButtons("view");
		this.getSuppliers();

		this.labelSizes = GetPropertiesFromEnum(PackagingLabelSize);

		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {
						this.getItem(params.id);
					}
				} else {
					this.create();
				}
			});

		this.uploadImage = this.uploadImage.bind(this);
	}

	addNewSupplier() {
		this.supplierService.createSingle(this.popupSupplier)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getSuppliers();
				},
				(err: any) => {
					notify("Could not add supplier. Please check the details are valid and this supplier does not already exist.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewSupplierPopup() {
		this.popupSupplier = new Supplier();
		this.popupTitle = "Add New Supplier";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewSupplier(), text: "Add Supplier" };
	}

	boolYesNo(cellInfo: any) {		
		if (cellInfo.value === true) {
			return "YES";
		} else {
			return "NO";
		}
	} 

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [
				{ method: "addNewSupplierPopup", text: "Add New Supplier" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [
				{ method: "addNewSupplierPopup", text: "Add New Supplier" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];	
		}
	}

	calculateMaxLoad() {
		this.item.maxLoad = this.item.grossWeightLimit - this.item.weight;
		if (this.item.maxLoad < 0) {
			this.item.maxLoad = 0;
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new Packaging();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "grossWeightLimit":
			case "weight":
				this.calculateMaxLoad();
				break;
		}
	}
	
	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.setImageStyle(this.item.imageUrl);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getSuppliers() {
		this.supplierService.getAll()
			.subscribe(
				(res: any) => {
					this.suppliers = res.response.items;
					this.suppliers.sort((a,b) => a.name.localeCompare(b.name));
					if (this.popupSupplier.name !== "") this.item.supplierId = this.suppliers.find((supplier) => supplier.name === this.popupSupplier.name)?.id || -1;
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	setImageStyle(imageUrl: string) {
		this.imageStyle = `background-image: url("${imageUrl}")`;
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => notify("Successfully Updated", "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadImage(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		uploadData.append("mimeTypeKey", "ImageMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.item.imageUrl = `${environment.server.storageUrl}/${containerName}/${res.response[0].location}`;
					this.setImageStyle(this.item.imageUrl);
					notify("Image uploaded - Click Save to confirm change", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Image Upload Failed - Please ensure you are uploading a valid image file", "error", 5000);
				}
			);
	}
}