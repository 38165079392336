import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class ClientDocumentService extends Service {
	constructor(injector: Injector) {
		super("ClientDocument", injector);
	}

	// API
	getDocumentsForClientId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/DocumentsFor/${id}`);
	}
}