import { Model } from "@interfaces/model.interface";
import { Guid } from "guid-typescript";

export interface SupplierDocument extends Model {
	description: string;
	fileId: number;
	fileLookupId: Guid;
	fileName: string;
	supplierId: number;
}

export class SupplierDocument implements SupplierDocument {
	description = "";
	fileId = 0;
	fileName = "";
	supplierId = 0;

	constructor(data?: Partial<SupplierDocument>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}