import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class ProductionRequestService extends Service {
	constructor(injector: Injector) {
		super("ProductionRequest", injector);
	}

	// API	
	approve(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Approve/${id}`, {});
	}
	
	createProductionRequestsFromEdi() {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateProductionRequestsFromEdi`, {});
	}
	
	decline(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Decline/${id}`, {});
	}
	
	getAllDetails() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetAllDetails`);
	}
}