import { AfterViewInit, Component, Input } from "@angular/core";
import { SalesLineItem } from "@interfaces/salesLineItem.interface";

@Component({
	moduleId: module.id,
	selector: "salesEnquiryGridDetails",
	styleUrls: ["salesEnquiryGridDetails.css"],
	templateUrl: "salesEnquiryGridDetails.html"
})
export class SalesEnquiryGridDetailsComponent implements AfterViewInit {
	@Input() lineItemData = "";
	lineItems: SalesLineItem[] = [];
  
	ngAfterViewInit() {
		this.lineItems = JSON.parse(this.lineItemData);
	}
}