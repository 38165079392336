export interface GenerateStaffAttendanceReportCsv {
	dateFrom?: Date;
	dateTo?: Date;
	siteId?: number;
}

export class GenerateStaffAttendanceReportCsv implements GenerateStaffAttendanceReportCsv {
	constructor(data?: Partial<GenerateStaffAttendanceReportCsv>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}