import { ToolEventType } from "@enums/toolEventType.enum";
import { Model } from "@interfaces/model.interface";

export interface ToolEvent extends Model {
	eventDate: Date;
	eventType: ToolEventType;
	reporterId: number;
	toolId: number;
}

export class ToolEvent implements ToolEvent {
	eventDate = new Date();

	constructor(data?: Partial<ToolEvent>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}