import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserProfile } from "@interfaces/userProfile.interface";
import { WorkOrder } from "@interfaces/workOrder.interface";
import { WorkOrderService } from "@services/workOrder.service";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "workOrderView",
	styleUrls: ["workOrderView.css"],
	templateUrl: "workOrderView.html"
})
export class WorkOrderViewComponent {
	isLoading = true;
	showPopup = false;
	workOrder: WorkOrder = new WorkOrder();
	workOrderId: Guid = Guid.createEmpty();

	constructor(private route: ActivatedRoute, private woService: WorkOrderService) {
		this.route.params
			.subscribe((params) => {
				this.workOrderId = Guid.parse(params.id);
				this.isLoading = true;
				this.showPopup = true;
				this.getWo();
				setTimeout(() => {
					this.isLoading = false;
					this.showPopup = false;
				}, 2000);
			});
	}

	formatDate(dateComplete: Date) {
		const date = new Date(dateComplete);
		const dateFormat = `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
		return dateFormat;
	}

	formatUser(user: UserProfile){
		const firstInitial = user.firstName.substring(0,1);
		const secondInitial = user.lastName.substring(0,1);
		const initials = firstInitial + secondInitial;
		console.log(initials);
		return initials;
	}

	getWo() {
		this.woService.appGetAllDetails(this.workOrderId)
			.subscribe(
				(res: any) => {
					this.workOrder = res.response;
					console.log(res.response);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}
}