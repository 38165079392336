import { IncidentType } from "@enums/incidentType.enum";
import { PersonType } from "@enums/personType.enum";
import { Status } from "@enums/status.enum";
import { Model } from "@interfaces/model.interface";

export interface Accident extends Model {
	emergencyServicesRequired: boolean;
	evidence: string;
	firstAidRequired: boolean;
	followUpAction: string;
	incidentDateTime: Date;
	incidentDescription: string;
	incidentType : IncidentType;
	injuryDescription: string;
	otherComments: string;
	personAddress1: string;
	personAddress2: string;
	personContactEmail: string;
	personContactNumber: string;
	personCountry: string;
	personCounty: string;
	personDob: Date;
	personName: string;
	personPostcode: string;
	personTitle: string;
	personTown: string;
	personType: PersonType;
	reportedById: number;
	siteId: number;
	specificArea: string;
	status: Status;
}

export class Accident implements Accident {
	emergencyServicesRequired = false;
	evidence = "";
	firstAidRequired = false;
	followUpAction = "";
	incidentDateTime = new Date();
	incidentDescription = "";
	incidentType = IncidentType.SlipsTripFalls;
	injuryDescription = "";
	otherComments = "";
	personAddress1 = "";
	personAddress2 = "";
	personContactEmail = "";
	personContactNumber = "";
	personCountry = "";
	personCounty = "";
	personDob = new Date();
	personName = "";
	personPostcode = "";
	personTitle = "";
	personTown = "";
	personType = PersonType.Public;
	reportedById = 0;
	siteId = 0;
	specificArea = "";
	status = Status.Active;

	constructor(data?: Partial<Accident>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}