<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div id="forecastTableContainer">
	<table id="forecastTable">
		<thead>
			<tr>
				<th scope="row" colspan="5">Week Number</th>
				<th *ngFor="let header of forecastTable.headers" colspan="8">{{header.weekNumber}}</th>
			</tr>
			<tr>
				<th scope="col">Client</th>
				<th scope="col">Part</th>
				<th scope="col">Description</th>
				<th scope="col">Image</th>
				<th scope="col">Location</th>
				<ng-template ngFor let-header [ngForOf]="forecastTable.headers">
					<th scope="col">{{header.monday | date: 'd-MMM'}}</th>
					<th scope="col">{{header.tuesday | date: 'd-MMM'}}</th>
					<th scope="col">{{header.wednesday | date: 'd-MMM'}}</th>
					<th scope="col">{{header.thursday | date: 'd-MMM'}}</th>
					<th scope="col">{{header.friday | date: 'd-MMM'}}</th>
					<th scope="col" class="weekend">{{header.saturday | date: 'd-MMM'}}</th>
					<th scope="col" class="weekend">{{header.sunday | date: 'd-MMM'}}</th>
					<th scope="col" class="weekTotal">Total</th>
				</ng-template>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of forecastTable.rows">
				<th scope="row" style="background: {{row.rowBackgroundColour}}">{{row.clientName}}</th>
				<th scope="row" style="background: {{row.rowBackgroundColour}}">{{row.partNumber}}</th>
				<th scope="row" style="background: {{row.rowBackgroundColour}}">{{row.partDescription}}</th>
				<th scope="row" style="background: {{row.rowBackgroundColour}}"><img *ngIf="row.partImageUrl != ''" src="{{row.partImageUrl}}"/></th>
				<th scope="row" style="background: {{row.rowBackgroundColour}}">{{row.clientLocationName}}</th>
				<ng-template ngFor let-quantity [ngForOf]="row.quantities">
					<td>{{quantity.monday > 0 ? quantity.monday : ''}}</td>
					<td>{{quantity.tuesday > 0 ? quantity.tuesday : ''}}</td>
					<td>{{quantity.wednesday > 0 ? quantity.wednesday : ''}}</td>
					<td>{{quantity.thursday > 0 ? quantity.thursday : ''}}</td>
					<td>{{quantity.friday > 0 ? quantity.friday : ''}}</td>
					<td class="weekend">{{quantity.saturday > 0 ? quantity.saturday : ''}}</td>
					<td class="weekend">{{quantity.sunday > 0 ? quantity.sunday : ''}}</td>
					<td class="weekTotal">{{quantity.total}}</td>
				</ng-template>
			</tr>
		</tbody>
	</table>
</div>