import { Component } from "@angular/core";
import { LogExport } from "@interfaces/logExport.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { ExportLogService } from "@services/exportLog.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "exportLogs",
	styleUrls: ["exportLogs.css"],
	templateUrl: "exportLogs.html"
})
export class ExportLogsAdminComponent {
	itemType = "Export Log"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	people: UserProfile[] = [];
	title: string;
	
	constructor(private itemService: ExportLogService, private userProfileService: UserProfileService) {
		this.title = `${this.itemType}s`;
		this.getItems();
		this.getPeople();
	}

	getItems() {
		this.itemService.getAll()
			.subscribe(
				(res: any) => this.items = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Export Logs";
		this.itemService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}
}