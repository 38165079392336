<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="6">
				<dxi-item [colSpan]="3" dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>	
				<dxi-item [colSpan]="3" dataField="clientContactId" editorType="dxSelectBox" [editorOptions]="{ items: contacts, searchEnabled: true, displayExpr: 'displayName', valueExpr:'id' }" [label]="{ text: 'Contact' }"></dxi-item>	
				<dxi-item [colSpan]="3" dataField="poNumber" [label]="{ text: 'PO Number' }"></dxi-item>
				<dxi-item [colSpan]="3" dataField="projectId" editorType="dxSelectBox" [editorOptions]="{ items: projects, searchEnabled: true, displayExpr:'displayName', valueExpr:'id' }" [label]="{ text: 'Project' }"></dxi-item>	
				<dxi-item [colSpan]="3" dataField="projectManagerId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Project Manager' }"></dxi-item>	
				<dxi-item [colSpan]="3" dataField="mrdDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'MRD Date' }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="totalValue" [label]="{ text: 'Net Value (Excl VAT)' }" editorType="dxNumberBox" [editorOptions]="{ readOnly: true, format: { type:'currency', currency:'GBP', precision: 2 } }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item [colSpan]="2" dataField="vatValue" editorType="dxNumberBox" [editorOptions]="{ readOnly: true, format: { type:'currency', currency:'GBP', precision: 2 } }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item [colSpan]="2" dataField="quantity" [editorOptions]="{ readOnly: true }"></dxi-item>
			</dxi-tab>
			<dxi-tab title="PO Document">
				<dxi-item itemType="group" caption="Current Document" [colCount]="6" [visible]="poDocument !== null && poDocument.id > 0">
					<dxi-item [colSpan]="5">{{(poDocument !== null ? poDocument.name : "")}}</dxi-item>
					<dxi-item itemType="group">
						<dxi-item itemType="button" [buttonOptions]="removePoDocumentButton"></dxi-item>
						<dxi-item itemType="button" [buttonOptions]="downloadPoDocumentButton"></dxi-item>
					</dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="Upload New PO Document">
					<dx-file-uploader class="mb-3 mx-2" [maxFileSize]="10485760" [uploadFile]="uploadPoDocument" [showFileList]="false"></dx-file-uploader>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Parts Ordered">
				<dx-data-grid class="data-grid" [dataSource]="salesOrderParts" [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="ordersParts"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="part.partNumber" dataType="text" alignment="left" caption="Part Number"></dxi-column>
					<dxi-column dataField="unitCost" dataType="text" alignment="left" caption="Price" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 3 }"></dxi-column>
					<dxi-column dataField="quantityOrdered" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="quantityInWorkOrders" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="quantityInDeliveryNotes" dataType="number" alignment="left" caption="Quantity Delivered"></dxi-column>
					<dxi-column dataField="subTotalValue" dataType="number" alignment="left" caption="Sub Total" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 3 }"></dxi-column>
					<dxi-column dataField="part.material.name" dataType="text" alignment="left" caption="Material"></dxi-column>
					<dxi-column dataField="sheetLength" dataType="number" alignment="left" caption="Sheet Length (mm)"></dxi-column>
					<dxi-column dataField="sheetWidth" dataType="number" alignment="left" caption="Sheet Width (mm)"></dxi-column>
					<dxi-column type="buttons">
						<dxi-button hint="Edit Details" icon="fal fa-pencil" [onClick]="editSalesOrderPartPopup"></dxi-button>
						<dxi-button hint="View Part" icon="fal fa-eye" [onClick]="viewPart"></dxi-button>
						<dxi-button hint="Create Work Order" icon="fal fa-user-hard-hat" [onClick]="addNewWorkOrderPopup" [visible]="isAddWorkOrderVisible"></dxi-button>
						<dxi-button hint="Remove this Line" icon="fal fa-trash" [onClick]="deleteSalesOrderPart" [visible]="isDeleteSalesOrderPartVisible"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Work Orders">
				<dx-data-grid class="data-grid" [dataSource]="workOrders" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="ordersWorkOrders"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewWorkOrder"></dxi-button>
						<dxi-button hint="Master Label PDF" icon="fal fa-download" [onClick]="generateAndDownloadWorkOrderMasterLabelPDF"></dxi-button>
						<dxi-button hint="Delete" icon="fal fa-trash" [onClick]="deleteWorkOrder"></dxi-button>
					</dxi-column>
					<dxi-column dataField="id" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="isQLoop" caption="Q-Loop"></dxi-column>
					<dxi-column dataField="part.partNumber" dataType="text" alignment="left" caption="Part Number"></dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="dateRequired" dataType="date" format="dd/MM/yyyy" alignment="left"></dxi-column>
					<dxi-column dataField="workOrderComplete" caption="Completed"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupSaveButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Add New Client'">
				<dx-form class="m-3" [(formData)]="popupClient" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="name" [colSpan]="2" [label]="{ text: 'Client Name' }">
						<dxi-validation-rule type="required" message="Client Name is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="accountingNumber"></dxi-item>
					<dxi-item dataField="telephoneNumber" [label]="{ text: 'Contact Phone Number' }"></dxi-item>
					<dxi-item dataField="address1" [label]="{ text: 'Address Line 1' }"></dxi-item>
					<dxi-item dataField="address2" [label]="{ text: 'Address Line 2' }"></dxi-item>
					<dxi-item dataField="town"></dxi-item>
					<dxi-item dataField="postcode"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Contact'">
				<dx-form class="m-3" [(formData)]="popupContact" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="contactName">
						<dxi-validation-rule type="required" message="Contact Name is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item dataField="contactEmailAddress">
						<dxi-validation-rule type="required" message="Contact Email is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="jobTitle"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Project'">
				<dx-form class="m-3" [(formData)]="popupProject" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>	
					<dxi-item dataField="name">
						<dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item dataField="description"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Work Order'">
				<dx-form class="m-3" [(formData)]="popupWorkOrder" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="dateRequired" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }">
						<dxi-validation-rule type="required" message="Date is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item dataField="quantity">
						<dxi-validation-rule type="required" message="Quantity is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="maximumQuantity" [editorOptions]="{ readOnly: true }"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Edit Part Details'">
				<dx-form class="m-3" [(formData)]="popupSalesOrderPart" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item itemType="group" caption="Details" [colCount]="2">
						<dxi-item dataField="sheetLength" editorType="dxNumberBox" [label]="{ text: 'Sheet Length (mm)' }"></dxi-item>
						<dxi-item dataField="sheetWidth" editorType="dxNumberBox" [label]="{ text: 'Sheet Width (mm)' }"></dxi-item>
						<dxi-item dataField="quantityOrdered" editorType="dxNumberBox" [label]="{ text: 'Quantity Ordered' }"></dxi-item>
					</dxi-item>
					<dxi-item itemType="group" caption="Purchase Orders" [colCount]="2">
						<dxi-item dataField="poNumber" [label]="{ text: 'Purchase Order Number - Parts' }"></dxi-item>
						<dxi-item dataField="poNumberAssemblyFixture" [label]="{ text: 'Purchase Order Number - Assembly Fixture' }"></dxi-item>
						<dxi-item dataField="poNumberInspectionFixture" [label]="{ text: 'Purchase Order Number - Inspection Fixture' }"></dxi-item>
						<dxi-item dataField="poNumberLaserFixture" [label]="{ text: 'Purchase Order Number - Laser Fixture' }"></dxi-item>
						<dxi-item dataField="poNumberTooling" [label]="{ text: 'Purchase Order Number - Tooling' }"></dxi-item>
						<dxi-item dataField="poNumberWeldFixture" [label]="{ text: 'Purchase Order Number - Weld Fixture' }"></dxi-item>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Create Delivery Note'">
				<dx-form class="m-3" [(formData)]="popupDeliveryNote" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="clientLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, searchEnabled: true, displayExpr: 'name', valueExpr:'id' }" [label]="{ text: 'Client Location' }"></dxi-item>	
				</dx-form>
				<dxi-item itemType="group" caption="Parts to Deliver">
					<dx-data-grid class="data-grid" [dataSource]="salesOrderPartsForDeliveryNote" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="salesOrderPartsForDeliveryNote"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-editing [allowUpdating]="true" mode="cell" [allowDeleting]="false" [confirmDelete]="false"></dxo-editing>
						<dxi-column [allowEditing]="false" dataField="partNumber" dataType="text" alignment="left" caption="Part Number"></dxi-column>
						<dxi-column [allowEditing]="false" dataField="quantityOrdered" dataType="number" alignment="left" caption="Quantity Ordered"></dxi-column>
						<dxi-column [allowEditing]="false" dataField="quantityInDeliveryNotes" dataType="number" alignment="left" caption="Already Delivered"></dxi-column>
						<dxi-column dataField="quantity" dataType="number" alignment="left" caption="Quantity for Note"></dxi-column>
					</dx-data-grid>
				</dxi-item>
			</div>
			<div *ngIf="popupTitle == 'Create Invoice'">
				<dx-form class="m-3" [(formData)]="popupInvoice" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="clientBillingLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, searchEnabled: true, displayExpr: 'name', valueExpr:'id' }" [label]="{ text: 'Billing Location' }"></dxi-item>	
					<dxi-item dataField="clientDeliveryLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, searchEnabled: true, displayExpr: 'name', valueExpr:'id' }" [label]="{ text: 'Delivery Location' }"></dxi-item>	
					<dxi-item dataField="includeDeliveryCost" editorType="dxCheckBox" [label]="{ text: 'Include Delivery Costs' }"></dxi-item>
					<dxi-item dataField="shipVia" editorType="dxTextBox" [label]="{ text: 'Ship Via' }"></dxi-item>
					<dxi-item dataField="alsoCreateDeliveryNote" editorType="dxCheckBox" [label]="{ text: 'Create New Delivery Note' }"></dxi-item>
					<dxi-item dataField="deliveryNoteId" editorType="dxSelectBox" [editorOptions]="{ items: deliveryNotesForInvoice, searchEnabled: true, displayExpr: 'id', valueExpr:'id', readOnly: popupInvoice.alsoCreateDeliveryNote }" [label]="{ text: 'Use Existing Delivery Note (optional)' }"></dxi-item>	
				</dx-form>
				<dxi-item itemType="group" caption="Parts to Deliver">
					<dx-data-grid class="data-grid" height="25vh" [dataSource]="salesOrderPartsForInvoice" [remoteOperations]="false" [allowColumnReordering]="false" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onEditorPreparing)="invoiceEditorPreparing($event)">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="salesOrderPartsForInvoice"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-editing [allowUpdating]="true" mode="cell" [allowDeleting]="false" [confirmDelete]="false"></dxo-editing>
						<dxi-column [allowEditing]="false" dataField="partNumber" dataType="text" alignment="left" caption="Part Number" [width]="250"></dxi-column>
						<dxi-column dataField="quantity" dataType="number" alignment="left" caption="Quantity for Invoice"></dxi-column>
						<dxi-column [allowEditing]="false" dataField="quantityOrdered" dataType="number" alignment="left" caption="Ordered"></dxi-column>
						<dxi-column [allowEditing]="false" dataField="quantityInvoiced" dataType="number" alignment="left" caption="Invoiced"></dxi-column>
						<dxi-column dataField="includeAssemblyFixtureValue" dataType="boolean" caption="Assembly"></dxi-column>
						<dxi-column dataField="includeInspectionFixtureValue" dataType="boolean" caption="Inspection"></dxi-column>
						<dxi-column dataField="includeLaserFixtureValue" dataType="boolean" caption="Laser"></dxi-column>
						<dxi-column dataField="includeToolingValue" dataType="boolean" caption="Tooling"></dxi-column>
						<dxi-column dataField="includeWeldFixtureValue" dataType="boolean" caption="Weld"></dxi-column>
					</dx-data-grid>
				</dxi-item>
			</div>
		</div>
	</dx-popup>
</div>