import { MaterialShape } from "@enums/materialShape.enum";

export interface LineItem {
	cost: number;
	height: number;
	length: number;
	lineCost: number;
	lineDescription: string;
	lineNumber: number;
	materialShape: MaterialShape;
	partId?: number;
	quantity: number;
	thickness: number;
	weight: number;
	width: number;
}

export class LineItem implements LineItem {
	cost = 0;
	length = 0;
	lineCost = 0;
	lineDescription = "";
	lineNumber = 0;
	materialShape = MaterialShape.Sheet;
	quantity = 0;
	thickness = 0;
	width = 0;

	constructor(data?: Partial<LineItem>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}