import { Component, ViewEncapsulation } from "@angular/core";
import { BarcodeFormat } from "@zxing/library";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "workOrderScan",
	styleUrls: ["workOrderScan.css"],
	templateUrl: "workOrderScan.html"
})
export class WorkOrderScanComponent {
	allowedFormats = BarcodeFormat.QR_CODE;
	availableDevices: MediaDeviceInfo[] = [];
	hasCameras = false;
	hasPermission = false;
	result = "";
	scanner: ZXingScannerComponent = new ZXingScannerComponent();
	scannerEnabled = true;
	selectedDevice: any;

	constructor() {
		this.getCameras();
	}

	cameraFound(e: any) {
		// Log to see if the camera was found
		console.log(e);
		this.availableDevices = e;
		this.selectedDevice = e[0];
		this.hasCameras = true;
		this.hasPermission = true;
		console.log("Devices: ", this.availableDevices);

		// selects the devices's back camera by default'
		for (const device of e) {
			if (/back|rear|environment/gi.test(device.label)) {
				new this.scanner.deviceChange(true);
				this.selectedDevice = device;
				break;
			} else if (e.length == 1) {
				new this.scanner.deviceChange(true);
				this.selectedDevice = device;
				break;
			}
		}
	}

	camerasNotFound() {
		// Display an alert modal here
	}

	getCameras() {
		console.log("Hit!");
	}

	onDeviceSelectChange() {
		console.log("Selection changed: ", this.selectedDevice.label);
		// this.selectedDevice = this.scanner.device!;
	}

	onScanSuccess(result: string) {
		console.log(result);
		alert(result);
	}
}