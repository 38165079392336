export enum ToolingFixtureDescription {
	ManualForm = 0,
	BlankingTool = 10,
	ProgressionTool = 20,
	SoftTool = 30,
	CheckingFixture = 40,
	WeldFixture = 50,
	LaserFixture = 60,
	InspectionFixture = 70,
	PokeYokeGauge = 80,
	GoNoGoGauge = 90,
}