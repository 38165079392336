import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { UploadCSV } from "@interfaces/uploadCSV.interface";
import { Service } from "@services/service";

@Injectable()
export class PurchasableItemService extends Service {
	constructor(injector: Injector) {
		super("PurchasableItem", injector);
	}

	// API
	getForSupplier(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForSupplier/${id}`);
	}
	
	importCSV(data: UploadCSV) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ImportCSV`, data);
	}
}