import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";
import { NonConformanceReportConcernOrigin } from "@enums/nonConformanceReportConcernOrigin.enum";
import { NonConformanceReportPriority } from "@enums/nonConformanceReportPriority.enum";
import { NonConformanceReportStatus } from "@enums/nonConformanceReportStatus.enum";
import { environment } from "@environments/environment";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { Machine } from "@interfaces/machine.interface";
import { NonConformanceReport } from "@interfaces/nonConformanceReport.interface";
import { NonConformanceReportFileLineData } from "@interfaces/nonConformanceReportFileLineData.interface";
import { NonConformanceReportUserProfileLineData } from "@interfaces/nonConformanceReportUserProfileLineData.interface";
import { Part } from "@interfaces/part.interface";
import { Project } from "@interfaces/project.interface";
import { Supplier } from "@interfaces/supplier.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { ClientService } from "@services/client.service";
import { FileService } from "@services/file.service";
import { MachineService } from "@services/machine.service";
import { NonConformanceReportService } from "@services/nonConformanceReport.service";
import { PartService } from "@services/part.service";
import { ProjectService } from "@services/project.service";
import { SupplierService } from "@services/supplier.service";
import { UserProfileService } from "@services/userProfile.service";
import { NonConformanceReportTimesheetLineData } from "@interfaces/nonConformanceReportTimesheetLineData.interface";
import { PartProcessRouter } from "@interfaces/partProcessRouter.interface";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "nonConformanceReports-id",
	styleUrls: ["nonConformanceReports-id.css"],
	templateUrl: "nonConformanceReports-id.html"
})
export class NonConformanceReportsIdAdminComponent {
	areasOfIssue: string[] = [
		"CAD Issue",
		"Health and Safety",
		"Issue Found at Customer",
		"Maintenance Issue",
		"Person / Training Issue",
		"Process wrong",
		"Process - Assembly",
		"Process - 5 Axis Issue",
		"Process - Flat Laser issue",
		"Process - Goods-In",
		"Process - Press Issue",
		"Process - Press Brake issue",
		"Process - Weld Issue",
		"Process - Spot Weld Issue",
		"Process - Kingsland Issue",
		"Process - Fab/Deburr Issue",
		"Process - Firewall Issue",
		"Process - Logistics issue",
		"Process - Quality Issue",
		"Process - Toolroom",
		"Supplier - Material Issue",
		"Supplier - Outsource Issue",
	];
	changes: any = [];
	clients: Client[] = [];
	concernOrigins: any;
	createEnabled = true;
	currentUserProfile: UserProfile = new UserProfile;
	dropDownProcessRouters: any[] = [];
	editEnabled = true;
	editRowKey: any = null;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	imageButtonOptions = {
		onClick: () => this.addNewImagePopup(),
		text: "Add Image",
		type: "default",
		useSubmitBehavior: false
	}
	images: NonConformanceReportFileLineData[] = [];
	item: NonConformanceReport = new NonConformanceReport();
	itemId = 0;
	itemType = "Non Conformance Report";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	machines: Machine[] = [];
	parts: Part[] = [];
	people: UserProfile[] = [];
	peopleResponsibleForClosure: NonConformanceReportUserProfileLineData[] = [];
	peopleResponsibleForClosureButtonOptions = {
		onClick: () => this.addNewUserProfileLinkPopup(),
		text: "Add Employee",
		type: "default",
		useSubmitBehavior: false
	}
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupImage: NonConformanceReportFileLineData = new NonConformanceReportFileLineData();
	popupSupportingDocument: NonConformanceReportFileLineData = new NonConformanceReportFileLineData();
	popupTitle = "";
	popupUserProfile: NonConformanceReportUserProfileLineData = new NonConformanceReportUserProfileLineData();
	popupVisible = false;
	priorities: any;
	processRouters: PartProcessRouter[] = [];
	projects: Project[] = [];
	qualityCostPerHour = 45;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "";
	statuses: any;
	suppliers: Supplier[] = [];
	supportingDocumentButtonOptions = {
		onClick: () => this.addNewSupportingDocumentPopup(),
		text: "Add Document",
		type: "default",
		useSubmitBehavior: false
	}
	supportingDocuments: NonConformanceReportFileLineData[] = [];
	timesheet: NonConformanceReportTimesheetLineData[] = [];
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private clientService: ClientService, private fileService: FileService, private machineService: MachineService, private itemService: NonConformanceReportService, private partService: PartService, private projectService: ProjectService, private supplierService: SupplierService, private userProfileService: UserProfileService) {
		this.getClients();
		this.getCurrentUserProfile();
		this.getParts();
		this.getPeople();
		this.getProjects();
		this.getQualityCostPerHour();
		this.getSuppliers();
		this.getMachines();

		this.concernOrigins = GetPropertiesFromEnum(NonConformanceReportConcernOrigin);
		this.priorities = GetPropertiesFromEnum(NonConformanceReportPriority);
		this.statuses = GetPropertiesFromEnum(NonConformanceReportStatus);

		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.getItem(params.id.split("_")[0], "edit");
					this.edit();
				} else {
					this.getItem(params.id, "view");
				}
			} else {
				this.create();
			}
		});

		this.calculateTimesheetCostCellValue = this.calculateTimesheetCostCellValue.bind(this);
		this.deleteImageLink = this.deleteImageLink.bind(this);
		this.deleteSupportingDocumentLink = this.deleteSupportingDocumentLink.bind(this);
		this.deleteUserProfileLink = this.deleteUserProfileLink.bind(this);
		this.downloadFileLink = this.downloadFileLink.bind(this);
		this.onInitNewTimesheetRow = this.onInitNewTimesheetRow.bind(this);
		this.uploadImage = this.uploadImage.bind(this);
		this.uploadSupportingDocument = this.uploadSupportingDocument.bind(this);
		this.viewImage = this.viewImage.bind(this);
	}

	addNewImage() {		
		this.images.push(this.popupImage);
		this.popupImage = new NonConformanceReportFileLineData();
		this.closePopup();
	}

	addNewImagePopup() {
		this.popupImage = new NonConformanceReportFileLineData();
		this.popupTitle = "Add New Image";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewImage(), text: "Add Image" };
	}

	addNewSupportingDocument() {
		if (this.popupSupportingDocument.description === "") {
			alert("Please enter a description");
			return;
		}
		this.supportingDocuments.push(this.popupSupportingDocument);
		this.popupSupportingDocument = new NonConformanceReportFileLineData();
		this.closePopup();
	}

	addNewSupportingDocumentPopup() {
		this.popupSupportingDocument = new NonConformanceReportFileLineData();
		this.popupTitle = "Add New Supporting Document";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewSupportingDocument(), text: "Add Document" };
	}

	addNewUserProfileLink() {		
		this.peopleResponsibleForClosure.push(this.popupUserProfile);
		this.popupUserProfile = new NonConformanceReportUserProfileLineData();
		this.closePopup();
	}

	addNewUserProfileLinkPopup() {
		this.popupUserProfile = new NonConformanceReportUserProfileLineData();
		this.popupTitle = "Add New Responsible Employee";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewUserProfileLink(), text: "Add Employee" };
	}

	buildButtons(mode: string) {
		this.screenMode = mode;
		this.headerPrimaryButtons = [];
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "Cancel" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			if (this.item.status !== NonConformanceReportStatus.Closed) {
				this.headerPrimaryButtons.push({ method: "edit", text: "Edit" });
				this.headerSecondaryButtons = [{ method: "viewTimesheetPopup", text: "Timesheet" }];
				this.headerTertiaryButtons = [{ method: "notifyStaff", text: "Notify Staff" }, { icon: "fal fa-file-times", method: "closeNonConformanceReport", style:"background-color: #f00; color: #fff;", text: "Close NCR" }];
			}

			this.headerPrimaryButtons.push({ method: "close", text: "Close" });
			this.headerSecondaryButtons.push({ icon: "fal fa-download", method: "generateAndDownloadSummaryPDF", text: "Summary PDF" });
			if (this.item.carePoint === true) {
				this.headerSecondaryButtons.push({ icon: "fal fa-download", method: "generateAndDownloadCarepointPDF", text: "Carepoint PDF" });
			}
		}
	}

	calculateTimesheetCostCellValue(data: any) {
		const timeSheetLineItemCost =  (data.time * this.qualityCostPerHour);
		return timeSheetLineItemCost;
	}

	cancelEditing() {
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		if (this.screenMode === "view" || confirm("Are you sure you wish to leave the page? Unsaved changes will be discarded.")) {
			this.router.navigate([`/${this.returnUrl}/`]);
		}
	}

	closeNonConformanceReport() {
		if (this.item.status === NonConformanceReportStatus.Closed || this.screenMode !== "view") {
			return;
		}

		if (confirm("Are you sure you want to close this NCR? This can't be undone.") == false) {
			return;
		}

		this.itemService.closeNonConformanceReport(this.item)
			.subscribe(
				() => {
					notify("Successfully Closed " + this.itemType, "success", 5000);
					this.getItem(this.item.lookupId, "view");
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	closePopup() {
		this.popupVisible = false;
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.prepareItemForSaving();

		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Added New " + this.itemType, "success", 5000);
					this.item = new NonConformanceReport();
					this.screenMode = "view";
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteImageLink(e: any) {
		const index = this.images.findIndex(d => d.lookupId === e.row.data.lookupId);
		this.images.splice(index, 1);

		return false;
	}

	deleteSupportingDocumentLink(e: any) {
		const index = this.supportingDocuments.findIndex(d => d.lookupId === e.row.data.lookupId);
		this.supportingDocuments.splice(index, 1);

		return false;
	}

	deleteUserProfileLink(e: any) {
		const index = this.peopleResponsibleForClosure.findIndex(d => d.userProfileId === e.row.data.userProfileId);
		this.peopleResponsibleForClosure.splice(index, 1);

		return false;
	}

	downloadFileLink(e: any) {
		const lookupId = Guid.parse(e.row.data.lookupId);
		this.fileService.downloadFile(lookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
		return false;
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "partId":
				this.getProcessRouters();
				break;
		}
	}

	generateAndDownloadCarepointPDF() {
		this.itemService.generateAndDownloadCarepointPdf(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "NCR_" + this.item.id + "_Carepoint.pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	generateAndDownloadSummaryPDF() {
		this.itemService.generateAndDownloadSummaryPdf(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "NCR_" + this.item.id + "_Summary.pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => {
					this.clients = res.response.items;
					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getCurrentUserProfile() {
		this.userProfileService.getCurrentUserProfile()
			.subscribe(
				(res: any) =>  {
					this.currentUserProfile = res.response;
				},
				(err) => console.log(err)
			);
	}

	getItem(lookupId: Guid, mode: string) {
		this.itemService.getSingleByLookupId(lookupId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.images = JSON.parse(this.item.images);
					this.supportingDocuments = JSON.parse(this.item.supportingDocuments);
					this.peopleResponsibleForClosure = JSON.parse(this.item.peopleResponsibleForClosure);
					this.timesheet = JSON.parse(this.item.timesheet);
					
					this.buildButtons(mode);

					if (this.parts.length > 0) {
						this.getProcessRouters();
					}
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}
	
	getMachines() {
		this.machineService.getAll()
			.subscribe(
				(res: any) =>  {
					this.machines = res.response.items;
					this.machines.sort((a,b) => a.cellNumber.localeCompare(b.cellNumber));
				},
				(err) => console.log(err)
			);
	}
	
	getParts() {
		this.partService.getAll()
			.subscribe(
				(res: any) =>  {
					this.parts = res.response.items;
					this.parts.sort((a,b) => a.partNumber.localeCompare(b.partNumber));

					if (this.item.id > 0) {
						this.getProcessRouters();
					}
				},
				(err) => console.log(err)
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	getProcessRouters() {
		this.dropDownProcessRouters = [];

		if (this.item.partId === undefined || this.item.partId === null || this.parts.length == 0) {
			this.processRouters = [];
			return;
		}

		const part = this.parts.filter(p => p.id === this.item.partId)[0];

		this.processRouters = JSON.parse(part.router);

		this.processRouters.forEach(et => {
			this.dropDownProcessRouters.push({ id: et.stage, name: "OP" + et.stage.toString() + "0" });
		});
	}
	
	getProjects() {
		this.projectService.getAll()
			.subscribe(
				(res: any) =>  {
					this.projects = res.response.items;
					this.projects.sort((a,b) => a.displayName.localeCompare(b.displayName));
				},
				(err) => console.log(err)
			);
	}

	getQualityCostPerHour() {
		this.itemService.getNCRQualityCostPerHour()
			.subscribe(
				(res: any) => {
					this.qualityCostPerHour = res.response;
				},
				(err) => console.log(err)
			);
	}

	getSuppliers() {
		this.supplierService.getAll()
			.subscribe(
				(res: any) => {
					this.suppliers = res.response.items;
					this.suppliers.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	notifyStaff() {
		if (this.item.status === NonConformanceReportStatus.Closed || this.screenMode !== "view") {
			return;
		}

		if (confirm("This will generate a PDF summary of the NCR and send it to the staff mailbox. Proceed?") == false) {
			return;
		}

		this.itemService.notifyStaff(this.item.id)
			.subscribe(
				() => {
					notify("Successfully Notified Staff", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
 
	onInitNewTimesheetRow(row: any) {
		row.data.userId = this.currentUserProfile.id;
		row.data.date = new Date;
	}

	prepareItemForSaving() {
		if (this.item.partId === 0) { this.item.partId = undefined; }
		if (this.item.machineId === 0) { this.item.machineId = undefined; }

		this.item.images = JSON.stringify(this.images);
		this.item.peopleResponsibleForClosure = JSON.stringify(this.peopleResponsibleForClosure);
		this.item.supportingDocuments = JSON.stringify(this.supportingDocuments);
		this.item.timesheet = JSON.stringify(this.timesheet);
		
		let totalTime = 0;
		this.timesheet.forEach(lineItem => {
			totalTime += lineItem.time;
		});
		this.item.totalTime = totalTime;
		this.item.totalCost = totalTime * this.qualityCostPerHour;
	}

	updateItem() {
		if (this.item.status === NonConformanceReportStatus.Closed) {
			return;
		}

		this.closePopup();
		this.prepareItemForSaving();

		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadImage(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		uploadData.append("mimeTypeKey", "ImageMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupImage.lookupId = res.response[0].lookupId;
					this.popupImage.fileName = res.response[0].name;
					this.popupImage.fileUrl = `${environment.server.storageUrl}/${containerName}/${res.response[0].location}`;
				},
				(err) => {
					console.log(err);
					notify("Image Upload Failed - Please ensure you are uploading a valid image file", "error", 5000);
				}
			);
	}

	uploadSupportingDocument(file: File) {
		const containerName = "nonconformancereportfiles";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupSupportingDocument.lookupId = res.response[0].lookupId;
					this.popupSupportingDocument.fileName = res.response[0].name;
				},
				(err) => {
					console.log(err);
					notify("Supporting Document Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	viewImage(e: any) {
		const imageUrl = e.row.data.fileUrl;
		const viewLink = document.createElement("a");
		viewLink.href = imageUrl;
		document.body.appendChild(viewLink);
		viewLink.click();
		return false;
	}

	viewTimesheetPopup() {
		this.popupTitle = "Timesheet";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.updateItem(), text: "Save Changes" };
	}
}