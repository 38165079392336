import { Model } from "@interfaces/model.interface";

export interface QmsDocumentVersion extends Model {
	approverId?: number;
	changeDescription: string;
	fileId: number;
	isApproved: boolean;
	qmsDocumentId: number;
	revisionNumber: number;
	uploaderId: number;
}

export class QmsDocumentVersion implements QmsDocumentVersion {
	constructor(data?: Partial<QmsDocumentVersion>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}