import { Model } from "@interfaces/model.interface";

export interface HolidayAllowanceData extends Model {
	approvedHolidays: number;
	firstName: string;
	lastName: string;
	pendingHolidays: number;
	remainingAllowance: number;
	totalAllowance: number;
	userProfileId: number;
	year: number;
}

export class HolidayAllowanceData implements HolidayAllowanceData {	
	constructor(data?: Partial<HolidayAllowanceData>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}