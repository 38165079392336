import { EdiMessageDirection } from "@enums/ediMessageDirection.enum";
import { EdiMessageProcessingStatus } from "@enums/ediMessageProcessingStatus.enum";
import { EdiMessageType } from "@enums/ediMessageType.enum";
import { Model } from "@interfaces/model.interface";

export interface EdiMessage extends Model {
	ediMessageText: string;
	failureMessage: string;
	messageDirection: EdiMessageDirection;
	messageReference: string;
	messageType: EdiMessageType;
	parsedEdiMessageContents: string;
	recipientId: string;
	senderId: string;
	status: EdiMessageProcessingStatus;
}

export class EdiMessage implements EdiMessage {
	constructor(data?: Partial<EdiMessage>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}