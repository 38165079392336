<div class="row" style="width:100vw; background-color: #346982; position:fixed">
	<h2 style="color:white; padding-top: 15px; padding-left:15px; padding-bottom: 15px;">// WO VIEW INTERFACE // {{this.workOrder.id}}</h2>
</div>
<div class="operator-bg" style="overflow-x:auto; padding-top:60px">
	<!-- Loading -->
	<div id="loading" class="popup" *ngIf="isLoading">
		<h2 style="text-align: center; vertical-align: middle; margin-top:12.5vh; color:white;">Loading</h2>
		<img src="../../../../assets/loading.gif" class="loading-image">
	</div>
	<div class="popup-blackout" *ngIf="showPopup"></div>
	
	<div class="row">
		<div class="col" style="padding-left:1rem; padding-top:1rem; color:white;">
			<!-- Left hand Details -->
			 <h2>Part Number: {{this.workOrder.part.partNumber}}</h2>
			 <h2>Client: {{this.workOrder.salesOrder.client.name}}</h2>
			 <h2>Order Qty: {{this.workOrder.quantity}}</h2>
			 <h2>Date Required: {{this.workOrder.dateRequired.toString().split('T')[0]}}</h2>
		</div>
		<div class="col">
			<!-- Part Image -->
			<img style="width:50vw; max-height: 25vh; margin-top:25px; object-fit: scale-down;" [src]="this.workOrder.part.tileImageUrl">
		</div>
		<div class="row" style="margin-top:20px; color:white; font-weight: bold; border-bottom: white solid 5px; ">  
			<div class="col-1"><h2>OP</h2></div>
			<div class="col-7"><h2>Name</h2></div>
			<div class="col-1"><h2>Req</h2></div>
			<div class="col-1"><h2>Done</h2></div>
			<div class="col-1"><h2>By</h2></div>
			<div class="col-1"><h2>Date</h2></div>
		</div>  
		<div class="row" *ngFor="let k of workOrder.workOrderStages" style="color:white; border-bottom: grey dashed 5px">  
			<div class="col-1"><h2>OP{{k.stageNumber}}0</h2></div>
			<div class="col-7"><h2>{{k.process.name}}</h2></div>
			<div class="col-1"><h2>{{k.quantityRequired}}</h2></div>
			<div class="col-1"><h2>{{k.quantityCompleted}}</h2></div>
			<div class="col-1"><h2 *ngIf="k.assignedUserProfile">{{formatUser(k.assignedUserProfile)}}</h2></div>
			<div class="col-1"><h2 *ngIf="k.dateCompleted">{{formatDate(k.dateCompleted)}}</h2></div>
		</div>  
	</div>
</div>