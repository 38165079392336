import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class MenuItemService extends Service {
	constructor(injector: Injector) {
		super("MenuItem", injector);
	}

	// API
	getMenuItemsByMenu(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetMenuItemsByMenu/${id}`);
	}

	getUserMenuItems(slug: string) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetUserMenuItems/${slug}`);
	}
}