import { Guid } from "guid-typescript";

export interface UploadCSV {
	fileLookupId: Guid
}

export class UploadCSV implements UploadCSV {
	constructor(data?: Partial<UploadCSV>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}