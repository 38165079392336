import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Log } from "@interfaces/log.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AuthService } from "@services/auth.service";
import { LogService } from "@services/logs.service";

@Component({
	moduleId: module.id,
	selector: "logs-id",
	styleUrls: ["logs-id.css"],
	templateUrl: "logs-id.html"
})
export class LogIdAdminComponent {
	headerPrimaryButtons: AdminHeaderButton[] = [
		{ method: "closeLog", text: "Close" }
	];
	itemId = 0;
	log: Log = new Log();
	title = "View Log";
	titles: string[] = [];
	user: UserProfile = new UserProfile();
	userId = 0;

	constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService, private logService: LogService) {
		this.route.params
			.subscribe((params) => {
				this.itemId = params.id;
				this.getAudit(this.itemId);
				this.getTitles();
				this.getUser(this.userId);
			});
	}

	closeLog() {
		// TODO: Delete log
		this.router.navigate(["admin/logs"]);
	}

	getAudit(logId: number) {
		this.logService.getSingleById(logId)
			.subscribe(
				(res: any) => {
					this.log = res.response;
					this.userId = this.log.userId;
					this.getUser(this.log.userId);
				},
				(err) => console.log(err)
			);
	}

	getTitles() {
		this.authService.getTitles()
			.subscribe(
				(res: any) => this.titles = res.response,
				(err) => console.log(err)
			);
	}

	getUser(logId: number) {
		this.authService.getUser(logId)
			.subscribe(
				(res: any) => this.user = res.response,
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}
}