import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { PFMEA } from "@interfaces/pfmea.interface";
import { ExportLogService } from "@services/exportLog.service";
import { MachineService } from "@services/machine.service";
import { PFMEAService } from "@services/pfmea.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "machines-id-pfmeas",
	styleUrls: ["machines-id-pfmeas.css"],
	templateUrl: "machines-id-pfmeas.html"
})
export class MachinesIdPFMEAsAdminComponent {
	headerPrimaryButtons: AdminHeaderButton[] = [
		{ method: "createPFMEA", text: "Create PFMEA" },
		{ method: "viewMachine", text: "Go to Machine" }
	];
	itemId = 0;
	items: PFMEA[] = [];
	title = "";

	constructor(private router: Router, private route: ActivatedRoute, private exportLogService: ExportLogService, private pfmeaService: PFMEAService, private machineService: MachineService) {
		this.route.params.subscribe((params) => {
			this.itemId = params.id;
			this.title = `PFMEAs for Machine #${params.id}`;
			this.getItems(params.id);
			this.getMachine(params.id);
		});
		
		this.deleteRow = this.deleteRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.viewRow = this.viewRow.bind(this);
	}

	createPFMEA() {
		this.router.navigate([`admin/pfmeas/${this.itemId}/create`]);
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this item?");
		if(result)
		{
			this.pfmeaService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify("PFMEA deleted!", "success", 5000);
						this.getItems(this.itemId);
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`admin/pfmeas/${this.itemId}/${e.row.data.id}_edit`]);
	}

	getItems(id: number) {
		this.pfmeaService.getByMachineId(id)
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getMachine(id: number) {
		this.machineService.getSingleById(id)
			.subscribe(
				(res: any) => this.title = `PFMEAs for ${res.response.name}`,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "PFMEAS for Machine " + this.itemId;
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewMachine() {		
		this.router.navigate([`admin/machines/${this.itemId}`]);
	}

	viewRow(e: any) {
		this.router.navigate([`admin/pfmeas/${this.itemId}/${e.row.data.id}`]);
	}
}