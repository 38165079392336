export enum IncidentType {
	ActsOfViolence = 10,
	NearMiss = 20,
	SlipsTripFalls = 30,
	Handling = 40,
	FallFromHeight = 50,
	TrappedByObject = 60,
	StruckByMovingObject = 70,
	StruckByMovingVehicle = 80,
	Other = 90,
}