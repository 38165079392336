<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="2">
				<dxi-item dataField="supplierId" editorType="dxSelectBox" [editorOptions]="{ readOnly: ((item.salesOrderId != undefined && item.salesOrderId > 0) || lineItems.length > 0), items: suppliers, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Supplier' }"></dxi-item>	
				<dxi-item dataField="projectManagerId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Project Manager' }"></dxi-item>	
				<dxi-item dataField="won" editorType="dxCheckBox" [label]="{ text: 'RFQ Won' }"></dxi-item>	
				<dxi-item dataField="dateSent" editorType="dxDateBox" [label]="{ text: 'Date Sent' }" [editorOptions]="{ readOnly: true, displayFormat: 'dd/MM/yyyy', type: 'date' }"></dxi-item>	
			</dxi-tab>
			<dxi-tab title="Line Items">
				<dx-data-grid #gridContainer class="data-grid" [dataSource]="lineItems" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="rfqLines"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="true" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
						<dxo-popup title="Line Item Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
						<dxo-form [readOnly]="readOnly" [colCount]="1">
							<dxi-item itemType="group" caption="Item Info" [colCount]="2">
								<dxi-item dataField="purchasableItemId" editorType="dxSelectBox" [editorOptions]="{ dataSource: groupedPurchasableItems, searchEnabled: true, displayExpr:'name', valueExpr:'id', readOnly: readOnly, grouped: true }" [label]="{text: 'Item'}"></dxi-item>	
								<dxi-item dataField="quantity" [editorOptions]="{ format : '#0', readOnly: readOnly }"></dxi-item>
							</dxi-item>
							<dxi-item itemType="group" caption="Material Spec" [colCount]="3">
								<dxi-item dataField="gauge" editorType="dxSelectBox" [editorOptions]="{ readOnly: readOnly, items: thicknesses, searchEnabled: false }" [label]="{ text: 'Gauge' }"></dxi-item>	
								<dxi-item dataField="sheetLength" [editorOptions]="{ format : '#0', readOnly: readOnly }" [label]="{text: 'Sheet Width (mm)'}"></dxi-item>
								<dxi-item dataField="sheetWidth" [editorOptions]="{ format : '#0', readOnly: readOnly }" [label]="{text: 'Sheet Length (mm)'}"></dxi-item>
							</dxi-item>
						</dxo-form>
					</dxo-editing>
					<dxi-column dataField="purchasableItemId" dataType="number" alignment="left" caption="Item">
						<dxo-lookup [dataSource]="purchasableItems" displayExpr="name" valueExpr="id"></dxo-lookup>
					</dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="right"></dxi-column>
					<dxi-column dataField="gauge" dataType="number" alignment="right" caption="Gauge (mm)"></dxi-column>
					<dxi-column dataField="sheetLength" dataType="number" alignment="right" caption="Sheet Length (mm)"></dxi-column>
					<dxi-column dataField="sheetWidth" dataType="number" alignment="right" caption="Sheet Width (mm)"></dxi-column>
					<dxi-column type="buttons">
						<dxi-button name="edit"></dxi-button>
						<dxi-button name="delete"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Outsourced Line Items">
				<dx-data-grid #gridContainer class="data-grid" [dataSource]="outsourcedLineItems" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="rfqOutsourcedLines"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="true" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
						<dxo-popup title="Outsourced Line Item Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
						<dxo-form [readOnly]="readOnly">
							<dxi-item itemType="group" caption="Item Info">
								<dxi-item dataField="specificationId" editorType="dxSelectBox" [editorOptions]="{ dataSource: outsourcedSpecifications, searchEnabled: true, displayExpr:'specification', valueExpr:'id', readOnly: readOnly }" [label]="{text: 'Outsourced Specification'}"></dxi-item>	
								<dxi-item dataField="quantity" [editorOptions]="{ format : '#0', readOnly: readOnly }"></dxi-item>
							</dxi-item>
						</dxo-form>
					</dxo-editing>
					<dxi-column dataField="specificationId" dataType="number" alignment="left" caption="Item">
						<dxo-lookup [dataSource]="outsourcedSpecifications" displayExpr="specification" valueExpr="id"></dxo-lookup>
					</dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="right"></dxi-column>
					<dxi-column type="buttons">
						<dxi-button name="edit"></dxi-button>
						<dxi-button name="delete"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>