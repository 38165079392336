export interface GenerateEdiShippingNote {
	clientLocationId: number;
	clientLocationName: string;
	dock: string;
	endDate: Date;
	startDate: Date;
}

export class GenerateEdiShippingNote implements GenerateEdiShippingNote {
	clientLocationId = 0;
	clientLocationName = "";
	dock = "";
	endDate = new Date;
	startDate = new Date;

	constructor(data?: Partial<GenerateEdiShippingNote>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}