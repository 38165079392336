import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	moduleId: module.id,
	selector: "main",
	styleUrls: ["main.css"],
	templateUrl: "main.html"
})
export class MainComponent {

	constructor(private router: Router) {
	}
}