import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { FobScanned } from "@interfaces/fobScanned.interface";
import { GenerateStaffAttendanceReportCsv } from "@interfaces/generateStaffAttendanceReportCsv.interface";
import { TimeReportForUser } from "@interfaces/timeReportForUser.interface";
import { Service } from "@services/service";

@Injectable()
export class StaffAttendanceService extends Service {
	constructor(injector: Injector) {
		super("StaffAttendance", injector);
	}

	// API
	app_currentStatusForSite(siteId: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_CurrentStatusForSite/${siteId}`);
	}
	fobScanned(fobScanned: FobScanned) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/FobScanned/`,fobScanned);
	}

	forSite(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForSite/${id}`);
	}

	forUserProfile(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForUserProfile/${id}`);
	}

	generateReport(parameters: GenerateStaffAttendanceReportCsv) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/GenerateReport`, parameters, { responseType: "blob" as "json"} );
	}

	openSessionForUserProfile(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/OpenSessionForUserProfile/${id}`);
	}

	timeReportForUser(parameters: TimeReportForUser) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/TimeReportForUser`, parameters );
	}
}