<admin-header [primaryButtons]="headerPrimaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="group" caption="Details" [colCount]="6">
			<dxi-item dataField="name" [colSpan]="6" [label]="{ text: 'Name / Part Number' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="description" [colSpan]="6"></dxi-item>
			<dxi-item dataField="purchaseType" editorType="dxSelectBox" [editorOptions]="{ items: purchaseTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true }" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="materialType" editorType="dxSelectBox" [editorOptions]="{ items: materialTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true, readOnly: (item.purchaseType != 30 && item.purchaseType != 70) }" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="minimumBuyableQuantity" [colSpan]="2" editorType="dxNumberBox" [label]="{ text: 'MBQ' }"></dxi-item>
			<dxi-item dataField="cost" [colSpan]="2" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency:'GBP', precision: 4}, showClearButton:true }"></dxi-item>
			<dxi-item dataField="weightConstant" [colSpan]="2" editorType="dxNumberBox" [editorOptions]="{format: {type: 'fixedPoint', precision: 8}, readOnly: (item.purchaseType != 30 && item.purchaseType != 70) }" [label]="{ text: 'Density' }"></dxi-item>
		</dxi-item>
	</dx-form>
</div>