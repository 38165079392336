import { Component, ViewEncapsulation } from "@angular/core";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { HolidayTime } from "@enums/holidayTime.enum";
import { HolidayRequestStatus } from "@enums/holdayRequestStatus.enum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { CompanyHoliday } from "@interfaces/companyHoliday.interface";
import { HolidayAllowanceData } from "@interfaces/holidayAllowanceData.interface";
import { HolidayRequest } from "@interfaces/holidayRequest.interface";
import { HolidaySettings } from "@interfaces/holidaySettings.interface";
import { CompanyHolidayService } from "@services/companyHoliday.service";
import { HolidayAllowanceService } from "@services/holidayAllowance.service";
import { HolidayRequestService } from "@services/holidayRequest.service";
import { HolidaySettingsService } from "@services/holidaySettings.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "userHolidays",
	styleUrls: ["userHolidays.css"],
	templateUrl: "userHolidays.html"
})
export class UserHolidaysAdminComponent {
	companyHolidays: CompanyHoliday[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [{ icon: "fal fa-calendar-plus", method: "addNewHolidayRequestPopup", text: "Request Holiday" }];
	holidayAllowanceData: HolidayAllowanceData = new HolidayAllowanceData();
	holidayRequestStatuses: any;
	holidayRequests: HolidayRequest[] = [];
	holidaySettings: HolidaySettings = new HolidaySettings();
	holidayTimes: any;
	holidayTimesEnd: any;
	holidayTimesStart: any;
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupHolidayRequest: HolidayRequest = new HolidayRequest();
	popupTitle = "";
	popupVisible = false;
	remainingAllowance = 0;
	title = "User Holidays";
	year = 2022;
	years: number[] = [];

	constructor(private companyHolidayService: CompanyHolidayService, private holidayAllowanceService: HolidayAllowanceService, private holidayRequestService: HolidayRequestService, private holidaySettingsService: HolidaySettingsService) {
		this.holidayRequestStatuses = GetPropertiesFromEnum(HolidayRequestStatus);
		this.holidayTimes = GetPropertiesFromEnum(HolidayTime);
		this.holidayTimesEnd = GetPropertiesFromEnum(HolidayTime);
		this.holidayTimesStart = GetPropertiesFromEnum(HolidayTime);
		
		// When requesting a holiday, the End time cannot be 'Start of Day' so remove it from the array. Same with Start time and 'End of Day'.
		this.holidayTimesEnd.splice(0, 1);
		this.holidayTimesStart.splice(2, 1);

		const today = new Date();
		const year = today.getFullYear();
		this.years = [];
		for (let y = -1; y <= 5; y ++) {
			this.years.push(year + y);
		}
		this.setYear(year);
		this.getHolidaySettings();
	}

	addNewHolidayRequest() {
		this.holidayRequestService.createSingle(this.popupHolidayRequest)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getHolidayAllowanceData();
					this.getHolidayRequests();
				},
				(err: any) => {
					notify("Could not add holiday request. Please double check the details you have entered and ensure all rules have been followed.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewHolidayRequestPopup() {
		const today = new Date();
		today.setDate(today.getDate() + this.holidaySettings.shortHolidayNoticeDays);
		this.popupHolidayRequest = new HolidayRequest();
		this.popupHolidayRequest.startDate = today;
		this.popupHolidayRequest.startTime = HolidayTime.StartOfDay;
		this.popupHolidayRequest.endDate = today;
		this.popupHolidayRequest.endTime = HolidayTime.EndOfDay;
		this.popupTitle = "Add New Holiday Request";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewHolidayRequest(), text: "Add Holiday Request" };
	}

	changeYear(args: any) {
		if (args) {
			this.setYear(args.value);
		}
	}

	closePopup() {
		this.popupVisible = false;
		this.popupHolidayRequest = new HolidayRequest();
	}

	getCompanyHolidays() {
		this.companyHolidayService.forYear(this.year)
			.subscribe(
				(res: any) => {
					this.companyHolidays = res.response;
				},
				(err) => console.log(err)
			);
	}

	getHolidayAllowanceData() {
		this.holidayAllowanceService.myAllowanceForYear(this.year)
			.subscribe(
				(res: any) => {
					this.holidayAllowanceData = res.response;
				},
				(err) => console.log(err)
			);
	}

	getHolidayRequests() {
		this.holidayRequestService.myRequestsForYear(this.year)
			.subscribe(
				(res: any) => {
					this.holidayRequests = res.response;
				},
				(err) => console.log(err)
			);
	}

	getHolidaySettings() {
		this.holidaySettingsService.getSettings()
			.subscribe(
				(res: any) => {
					this.holidaySettings = res.response;
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	setYear(year: number) {
		this.year = year;
		this.title = `User Holidays - ${year}`;
		this.getCompanyHolidays();
		this.getHolidayAllowanceData();
		this.getHolidayRequests();
	}
}