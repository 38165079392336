import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { Department } from "@enums/department";
import { ProfileAccountStatus } from "@enums/profileAccountStatus.enum";
import { Shift } from "@enums/shift.enum";
import { TrainingStatus } from "@enums/trainingStatus.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Group } from "@interfaces/group.interface";
import { Machine } from "@interfaces/machine.interface";
import { Site } from "@interfaces/site.interface";
import { StaffAttendance } from "@interfaces/staffAttendance.interface";
import { TimeReportForUser } from "@interfaces/timeReportForUser.interface";
import { Training } from "@interfaces/training.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AuthService } from "@services/auth.service";
import { FileService } from "@services/file.service";
import { GroupService } from "@services/group.service";
import { HolidaySettingsService } from "@services/holidaySettings.service";
import { MachineService } from "@services/machine.service";
import { MachineQuestionnaireService } from "@services/machineQuestionnaire.service";
import { OpsActionAuditService } from "@services/opsActionAudit.service";
import { SiteService } from "@services/site.service";
import { StaffAttendanceService } from "@services/staffAttendance.service";
import { TrainingService } from "@services/trainingService.service";
import { UserService } from "@services/user.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "userProfiles-id",
	styleUrls: ["userProfiles-id.css"],
	templateUrl: "userProfiles-id.html"
})
export class UserProfilesIdAdminComponent {
	attendance: StaffAttendance[] = [];
	createEnabled = true;
	currentlyAtSite = "";
	departments: any;
	disableEmailAddress = true;
	editEnabled = true;
	fullName = "";
	groups: Group[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	holidayAllowanceReadOnly = true;
	isSuperAdmin = false;
	item: UserProfile = new UserProfile();
	itemType = "User";
	machineQuestionnaires: any;
	machines: Machine[] = [];
	mode = "";
	newTraining: Training = new Training();
	opsActionAuditsOperator: any;
	opsActionAuditsUser: any;
	popupAddButtonOptions: any = { onClick: () => this.createTraining(), text: "Add Training" };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupTitle = "Add New Training";
	popupVisible = false;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	shifts: any;
	sites: Site[] = [];
	statuses: any;
	supervisors: any;
	timeReport: any;
	timeReportParameters: TimeReportForUser = new TimeReportForUser();
	title: string = "View " + this.itemType;
	titles: string[] = [];
	training: Training[] = [];
	trainingStatuses: any;
	userGroups: Group[] = [];

	constructor(private router:Router, private route: ActivatedRoute, private authService: AuthService, private fileService: FileService, private groupService: GroupService, private holidaySettingsService: HolidaySettingsService, private machineService: MachineService, private machineQuestionnaireService: MachineQuestionnaireService, private opsActionAuditService: OpsActionAuditService, private siteService: SiteService, private staffAttendanceService: StaffAttendanceService, private trainingService: TrainingService, private userService: UserService, private itemService: UserProfileService) {
		this.buildButtons("view");
		this.departments = GetPropertiesFromEnum(Department);
		this.shifts = GetPropertiesFromEnum(Shift);
		this.statuses = GetPropertiesFromEnum(ProfileAccountStatus);
		this.trainingStatuses = GetPropertiesFromEnum(TrainingStatus);
		this.getDefaultHolidayEditingPermission();
		this.getSupervisors();
		this.getSuperAdminStatus();
		this.getTitles();
		this.getMachines();
		this.getSites();
		this.deleteTraining = this.deleteTraining.bind(this);
		this.downloadCertificate = this.downloadCertificate.bind(this);
		this.toggleUserGroup = this.toggleUserGroup.bind(this);
		this.uploadTrainingCertificate = this.uploadTrainingCertificate.bind(this);
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {
						this.getItem(params.id);
					}
				} else {
					this.create();
				}
			});
	}

	addTrainingPopup() {
		this.newTraining = new Training();
		this.popupAddButtonOptions = { onClick: () => this.createTraining(), text: "Add Training" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupTitle = "Add New Training";
		this.popupVisible = true;
	}

	boolYesNo(cellInfo: any) {		
		if(cellInfo.value === true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	}

	buildButtons(mode: string) {
		this.mode = mode;
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [			
				{ method: "addTrainingPopup", text: "Add Training" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "addTrainingPopup", text: "Add Training" },
				{ method: "updateTimeReportPopup", text: "Update Time Report" }
			];
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}
	
	closePopup() {
		this.popupVisible = false;
	}

	create() {
		this.holidaySettingsService.getSettings()
			.subscribe(
				(res: any) => {
					const holidaySettings = res.response;
					this.item.defaultHolidayAllowance = holidaySettings.defaultHolidayAllowance;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
		this.disableEmailAddress = false;
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.adminCreate(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new UserProfile();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	createTraining() {
		this.newTraining.userProfileId = this.item.id;
		this.newTraining.trainingStatus = TrainingStatus.Trained;
		this.trainingService.createSingle(this.newTraining)
			.subscribe(
				() => {
					notify("Successfully Added New Training", "success", 5000);
					this.getTraining(this.item.id);
					this.closePopup();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteTraining(e: any) {
		if (confirm("Are you sure you with to delete this training?") == false) {
			return;
		}
		this.trainingService.deleteSingleById(e.row.data.id)
			.subscribe(
				() => {
					notify("Successfully Removed Training", "success", 5000);
					this.getTraining(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	displayMachineInDropdown(e: any) { 
		return e === null || e === undefined ? "" : (e.cellNumber === null || e.cellNumber === undefined ? "???" : e.cellNumber) + " - " + e.name;
	}

	downloadCertificate(e: any) {
		if (e.row.data.certificateName === "") {
			alert("No Certificate Uploaded to this Training");
			return;
		}
		this.fileService.downloadFile(e.row.data.certificateLookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.certificateFileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	edit() {
		this.disableEmailAddress = true;
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	getAttendance(id: number) {
		this.staffAttendanceService.forUserProfile(id)
			.subscribe(
				(res: any) => this.attendance = res.response,
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getDefaultHolidayEditingPermission() {		
		this.holidaySettingsService.canEditDefaultAllowance()
			.subscribe(
				(res: any) => {
					const canEdit = res.response;
					this.holidayAllowanceReadOnly = canEdit === false;
				},
				(err) => console.log(err)
			);
	}

	getGroups() {		
		this.groupService.getAll()
			.subscribe(
				(res: any) => {
					this.groups = res.response.items;
					this.groups.forEach((group) => { group.isSelected = false; });
					this.getUserGroups();
				},
				(err) => console.log(err)
			);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.fullName = res.response.fullName;
					this.getAttendance(this.item.id);
					this.getOpenSession(this.item.id);
					this.getTraining(this.item.id);
					this.getGroups();
					this.getOpsActionAuditsOperator(itemId);
					this.getOpsActionAuditsUser(itemId);
					this.getQuestionnaires();

					this.timeReportParameters.userProfileId = this.item.id;
					this.timeReportParameters.endDate = new Date;
					this.timeReportParameters.startDate = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
					this.getTimeReport();
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getMachines() {		
		this.machineService.getAll()
			.subscribe(
				(res: any) => this.machines = res.response.items,
				(err) => console.log(err)
			);
	}

	getOpenSession(id: number) {
		this.staffAttendanceService.openSessionForUserProfile(id)
			.subscribe(
				(res: any) => {
					if (res.response !== null) {
						this.currentlyAtSite = res.response.site.name;
					}
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getOpsActionAuditsOperator(operatorId: number)	{
		this.opsActionAuditService.getByOperatorId(operatorId)
			.subscribe(
				(res: any) => {
					this.opsActionAuditsOperator = res.response;
				},
				(err) => console.log(err)
			);
	}

	getOpsActionAuditsUser(userProfileId: number)	{
		this.opsActionAuditService.getByUserProfileId(userProfileId)
			.subscribe(
				(res: any) => {
					this.opsActionAuditsUser = res.response;
				},
				(err) => console.log(err)
			);
	}

	getQuestionnaires() {
		this.machineQuestionnaireService.getByUserProfileId(this.item.id)
			.subscribe(
				(res: any) => this.machineQuestionnaires = res.response,
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getSites() {		
		this.siteService.getAll()
			.subscribe(
				(res: any) => this.sites = res.response.items,
				(err) => console.log(err)
			);
	}
	
	getSuperAdminStatus() {
		this.authService.getCurrentUserIsSuperAdmin()
			.subscribe(
				(res: any) => {
					this.isSuperAdmin = res.response;
				}
			);
	}

	getSupervisors() {		
		this.itemService.getSupervisors()
			.subscribe(
				(res: any) => this.supervisors = res.response,
				(err) => console.log(err)
			);
	}

	getTimeReport() {
		this.staffAttendanceService.timeReportForUser(this.timeReportParameters)
			.subscribe(
				(res: any) => this.timeReport = res.response,
				(err) => console.log(err)
			);
	}

	getTitles() {
		this.authService.getTitles()
			.subscribe(
				(res: any) => this.titles = res.response,
				(err) => console.log(err)
			);
	}

	getTraining(id: number) {
		this.trainingService.getTrainingForUserProfileId(id)
			.subscribe(
				(res: any) => this.training = res.response,
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getUserGroups() {		
		this.userService.groupsForUser(this.item.userId)
			.subscribe(
				(res: any) => {
					this.userGroups = res.response;
					this.userGroups.forEach((userGroup) => {
						const index = this.groups.findIndex((g => g.id === userGroup.id));
						this.groups[index].isSelected = true;
					});
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	isToggleGroupOffVisible(e: any) {
		return e.row.data.isSelected === true;
	}

	isToggleGroupOnVisible(e: any) {
		return e.row.data.isSelected === false;
	}

	toggleUserGroup(e: any) {
		if (this.isSuperAdmin === false) {
			return;
		}

		this.userService.toggleGroup(this.item.userId, e.row.data.id)
			.subscribe(
				() => {
					const index = this.groups.findIndex((g => g.id === e.row.data.id));
					this.groups[index].isSelected = !this.groups[index].isSelected;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => notify("Successfully Updated " + this.itemType, "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateTimeReport() {
		this.getTimeReport();
		this.closePopup();
	}

	updateTimeReportPopup() {
		this.popupAddButtonOptions = { onClick: () => this.updateTimeReport(), text: "Fetch Report" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupTitle = "Time Report Parameters";
		this.popupVisible = true;
	}

	uploadTrainingCertificate(file: File) {
		const uploadData = new FormData();
		uploadData.append("files", file);
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.newTraining.certificateLookupId = res.response[0].lookupId;
					this.newTraining.certificateFileName = res.response[0].name;										
					this.fileService.getSingleByLookupId(res.response[0].lookupId)
						.subscribe(
							(res: any) => {
								this.newTraining.certificateId = res.response.id;
							},
							(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
						);
				},
				(err) => console.log(err)
			);
	}
}