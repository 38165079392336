import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Visitor } from "@interfaces/visitor.interface";
import { Service } from "@services/service";

@Injectable()
export class VisitorService extends Service {
	constructor(injector: Injector) {
		super("Visitor", injector);
	}

	// API
	byAccessCode(accessCode: string) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ByAccessCode/${accessCode}`);
	}

	createAsGuest(data: Visitor) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateAsGuest`, {data});
	}

	sendEmailNotification(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/SendEmailNotification/${id}`, {});
	}

	sendSMSNotification(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/SendSMSNotification/${id}`, {});
	}

	updateAsGuest(accessCode: string, data: Visitor) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/UpdateAsGuest/${accessCode}`, data);
	}
}