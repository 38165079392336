import { Model } from "@interfaces/model.interface";

export interface ChangePassword extends Model {
	confirmPassword: string;
	oldPassword: string;
	password: string;
}

export class ChangePassword implements ChangePassword {
	confirmPassword = "";
	oldPassword = "";
	password = "";

	constructor(data?: Partial<ChangePassword>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}