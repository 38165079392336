import { VisitorType } from "@enums/visitorType.enum";
import { Model } from "@interfaces/model.interface";

export interface Visitor extends Model {
	accessCode: string;
	companyName: string;
	emailAddress: string;
	name: string;
	photoUrl: string;
	telephoneNumber: string;	
	userProfileId?: number;
	vehicleRegistration: string;
	visitorType: VisitorType;
}

export class Visitor implements Visitor {
	constructor(data?: Partial<Visitor>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}