import { Injectable, Injector } from "@angular/core";
import { PromiseDepartment } from "@enums/promiseDepartment.enum";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class PromiseService extends Service {
	constructor(injector: Injector) {
		super("Promise", injector);
	}

	// API
	getPromiseBoard() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetPromiseBoard`);
	}

	getPromiseBoardForDepartment(department: PromiseDepartment) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetPromiseBoardForDepartment/${department}`);
	}
}