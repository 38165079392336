import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class PartRelationshipService extends Service {
	constructor(injector: Injector) {
		super("PartRelationship", injector);
	}

	// API
	getChildByMasterParts(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByMasterPart/${id}`);
	}
	getChildParts(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetChildParts/${id}`);
	}
	getMasterByChildPart(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByChildPart/${id}`);
	}
	getMasterParts(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetMasterParts/${id}`);
	}
}