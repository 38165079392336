import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Machine } from "@interfaces/machine.interface";
import { Service } from "@services/service";
import { Guid } from "guid-typescript";

@Injectable()
export class ProcessMachineService extends Service {
	constructor(injector: Injector) {
		super("ProcessMachine", injector);
	}

	// API
	forMachine(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForMachine/${id}`);
	}

	forProcess(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForProcess/${id}`);
	}

	updateMachinesForProcess(lookupId: Guid, data: Machine[]) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/UpdateMachinesForProcess/${lookupId}`, data);
	}
}