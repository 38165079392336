import { ProductionStatus } from "@enums/productionStatus.enum";

export function GetProductionStatusColor(status: ProductionStatus) {
	switch (status) {
		case ProductionStatus.Active:
			return "#1DFF00";
		case ProductionStatus.Changeover:
		case ProductionStatus.ToiletBreak:
		case ProductionStatus.Break:
		case ProductionStatus.QLoop:
		case ProductionStatus.QualityCheck:
		case ProductionStatus.AssistanceRequired:
			return "#FFA500";
		case ProductionStatus.Idle:
		case ProductionStatus.Inactive:
		case ProductionStatus.InitialCheckFailed:
		case ProductionStatus.Breakdown:
		case ProductionStatus.Maintenance:
			return "red";
		default:
			return "blue";
	}
}