import { Model } from "@interfaces/model.interface";

export interface PFMEA extends Model {
	actionsTaken: string;
	charge: string;
	completionDate?: Date;
	cpcDetails: string;
	detection: number;
	machineId: number;
	occurance: number;
	potentialCause: string;
	potentialEffect: string;
	potentialFailure: string;
	recommendedAction: string;
	rpn: number;
	severity: number;
}

export class PFMEA implements PFMEA {
	detection = 0;
	occurance = 0;
	severity = 0;

	constructor(data?: Partial<PFMEA>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}