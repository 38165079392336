<span *ngIf="isDeleted == false && (quantities.quantity > 0 || quantities.ediOrderId !== null)" class="orderStatus_{{quantities.status}}{{quantities.highlighted?' highlighted':''}}">{{quantities.quantity}}
	<a *ngIf="quantities.quantity > 0 && quantities.isTotals == false && quantities.status == 0" href="#" (click)="createShippingNotePopup()" class="createShippingNote"><i class="fal fa-file-plus"></i></a>
	<a *ngIf="quantities.isTotals == false && quantities.status == 0 && isSuperAdmin" href="#" (click)="deleteEdiOrder()" class="deleteOrder"><i class="fal fa-file-minus"></i></a>
	<a *ngIf="quantities.quantity > 0 && quantities.isTotals == false && quantities.status == 10 && quantities.isLocked == false" href="#" (click)="editShippingNote()"><i class="fal fa-pencil"></i></a>
	<a *ngIf="quantities.quantity > 0 && quantities.isTotals == false && (quantities.status == 25 || quantities.status == 30 || (quantities.status == 10 && quantities.isLocked == true))" href="#" (click)="viewShippingNote()"><i class="fal fa-eye"></i></a>
</span>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *dxTemplate="let data of 'content'">
		<div *ngIf="popupTitle == 'Create Shipping Note'">
			<dx-form class="m-3" [(formData)]="popupGenerateShippingNote" [showColonAfterLabel]="true" labelLocation="top">
				<dxi-item dataField="clientLocationName" [editorOptions]="{ readOnly: 'true' }"></dxi-item>
				<dxi-item dataField="dock" [editorOptions]="{ readOnly: 'true' }"></dxi-item>
				<dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"></dxi-item>
				<dxi-item dataField="endDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"></dxi-item>
			</dx-form>
		</div>
	</div>
</dx-popup>