export interface HolidaySettings {
	defaultHolidayAllowance: number;
	longHolidayNoticeDays: number;
	shortHolidayMaximumDays: number;
	shortHolidayNoticeDays: number;
}

export class HolidaySettings implements HolidaySettings {
	constructor(data?: Partial<HolidaySettings>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}