import { Model } from "@interfaces/model.interface";
import { Part } from "./part.interface";

export interface SalesOrderPart extends Model {
	assemblyFixtureInvoiced: boolean;
	assemblyFixtureValue: number;
	deliveryCost: number;
	deliveryInvoiced: boolean;
	inspectionFixtureInvoiced: boolean;
	inspectionFixtureValue: number;
	laserFixtureInvoiced: boolean;
	laserFixtureValue: number;
	part: Part;
	partId: number;
	poNumber: string;
	poNumberAssemblyFixture: string;
	poNumberInspectionFixture: string;
	poNumberLaserFixture: string;
	poNumberTooling: string;
	poNumberWeldFixture: string;
	quantityInDeliveryNotes: number;
	quantityInWorkOrders: number;
	quantityInvoiced: number;
	quantityOrdered: number;
	salesOrderId: number;
	sheetLength?: number;
	sheetWidth?: number;
	subTotalValue: number;
	toolingInvoiced: boolean;
	toolingValue: number;
	unitCost: number;
	weldFixtureInvoiced: boolean;
	weldFixtureValue: number;
}

export class SalesOrderPart implements SalesOrderPart {
	constructor(data?: Partial<SalesOrderPart>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}