export interface TimeReportForUser {
	endDate: Date;
	startDate: Date;
	userProfileId: number;
}

export class TimeReportForUser implements TimeReportForUser {

	constructor(data?: Partial<TimeReportForUser>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}