<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="group" caption="Details" [colCount]="2">
			<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>	
			<dxi-item dataField="clientBillingLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Billing Location' }"></dxi-item>	
			<dxi-item dataField="clientDeliveryLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Delivery Location' }"></dxi-item>	
			<dxi-item dataField="dateSent" editorType="dxDateBox" [label]="{ text: 'Sent to Customer On' }" [editorOptions]="{ readOnly: true, displayFormat: 'dd/MM/yyyy', type: 'date' }"></dxi-item>
			<dxi-item dataField="includeDeliveryCost" editorType="dxCheckBox" [label]="{ text: 'Include Delivery Costs' }"></dxi-item>
			<dxi-item dataField="shipVia" editorType="dxTextBox" [label]="{ text: 'Ship Via' }"></dxi-item>
			<dxi-item dataField="internalNotes" editorType="dxTextArea" [colSpan]="2" [label]="{ text: 'Internal Notes' }" [editorOptions]="{ height: 150 }"></dxi-item>
			<dxi-item dataField="completed" editorType="dxCheckBox" [label]="{ text: 'Completed' }"></dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="Line Items">
			<dx-data-grid #gridContainer class="data-grid" [dataSource]="lineItems" [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onRowUpdated)="updateLineItems()">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxo-editing mode="popup" [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
					<dxo-popup title="Self Billing" [showTitle]="true" [width]="'55%'" [height]="'auto'"></dxo-popup>
					<dxo-form [colCount]="2">
						<dxi-item dataField="selfBilling" editorType="dxCheckBox" [label]="{ text: 'Is Self Billed' }"></dxi-item>
						<dxi-item dataField="selfBillingReference" [label]="{ text: 'Reference' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					</dxo-form>
				</dxo-editing>
				<dxi-column type="buttons">
					<dxi-button name="edit"></dxi-button>
				</dxi-column>
				<dxi-column dataField="partNumber" dataType="text" alignment="left"></dxi-column>
				<dxi-column dataField="partDescription" dataType="text" alignment="right" caption="Description"></dxi-column>
				<dxi-column dataField="partRevisionNumber" dataType="number" alignment="right" caption="Revision"></dxi-column>
				<dxi-column dataField="poNumber" dataType="text" alignment="left" caption="PO Number"></dxi-column>
				<dxi-column dataField="quantity" dataType="number" alignment="right" caption="Quantity"></dxi-column>
				<dxi-column dataField="unitPrice" dataType="number" alignment="right" caption="Piece Price" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
				<dxi-column dataField="totalPrice" dataType="number" alignment="right" caption="Subtotal" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
				<dxi-column dataField="selfBilling" dataType="boolean" caption="SB"></dxi-column>
				<dxi-column dataField="selfBillingReference" dataType="text" caption="SB Ref"></dxi-column>
				<dxi-column dataField="includeAssemblyFixtureValue" dataType="boolean" caption="Assembly"></dxi-column>
				<dxi-column dataField="assemblyFixtureValue" dataType="number" alignment="right" caption="Value" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
				<dxi-column dataField="includeInspectionFixtureValue" dataType="boolean" caption="Inspection"></dxi-column>
				<dxi-column dataField="inspectionFixtureValue" dataType="number" alignment="right" caption="Value" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
				<dxi-column dataField="includeLaserFixtureValue" dataType="boolean" caption="Laser"></dxi-column>
				<dxi-column dataField="laserFixtureValue" dataType="number" alignment="right" caption="Value" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
				<dxi-column dataField="includeToolingValue" dataType="boolean" caption="Tooling"></dxi-column>
				<dxi-column dataField="toolingValue" dataType="number" alignment="right" caption="Value" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
				<dxi-column dataField="includeWeldFixtureValue" dataType="boolean" caption="Weld"></dxi-column>
				<dxi-column dataField="weldFixtureValue" dataType="number" alignment="right" caption="Value" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
				<dxi-column dataField="deliveryValue" dataType="number" alignment="right" caption="Delivery" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
				<dxi-column dataField="totalExcludingVAT" dataType="number" alignment="right" caption="Total (Exc VAT)" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
				<dxi-column dataField="totalIncludingVAT" dataType="number" alignment="right" caption="Total (Inc VAT)" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
</div>

<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *dxTemplate="let data of 'content'">
		<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="internalNotes" editorType="dxTextArea" [label]="{ text: 'Internal Notes' }" [editorOptions]="{ height: 150 }"></dxi-item>
		</dx-form>
	</div>
</dx-popup>