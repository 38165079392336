import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	moduleId: module.id,
	selector: "homeRoute",
	styleUrls: ["homeRoute.css"],
	templateUrl: "homeRoute.html"
})
export class HomeRouteComponent {
	constructor(private router: Router){
		this.router.navigate(["admin/"]);
	}
}