<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="group" caption="Details">
			<dxi-item dataField="name"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="addressLine1"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="addressLine2"></dxi-item>
			<dxi-item dataField="town"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="county"></dxi-item>
			<dxi-item dataField="postcode"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="colour" editorType="dxColorBox" [label]="{ text: 'Colour (HTML #rrggbb)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="floorPlanWidth" editorType="dxNumberBox" [editorOptions]="{ format : '#0'}"></dxi-item>
			<dxi-item dataField="floorPlanHeight" editorType="dxNumberBox" [editorOptions]="{ format : '#0'}"></dxi-item>
		</dxi-item>
	</dx-form>
</div>