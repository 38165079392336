import { Guid } from "guid-typescript";
import { Invoice } from "./invoice.interface";

export interface InvoiceGridRow extends Invoice {
	hasBeenSent: boolean;
	invoiceTotalExcludingVat: number;
	invoiceTotalIncludingVat: number;
	poNumber: string;
	salesOrderLookupId: Guid;
	selfBilled: boolean;
	toolingInvoiced: boolean;
}

export class InvoiceGridRow implements InvoiceGridRow {	
	constructor(data?: Partial<InvoiceGridRow>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}