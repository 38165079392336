export interface PartFileLink {
	description: string;
	fileName: string;
	lookupId: string;
	version: number;
}

export class PartFileLink implements PartFileLink {
	description = "";
	fileName = "";
	lookupId = "";
	version = 0;

	constructor(data?: Partial<PartFileLink>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}