import { Guid } from "guid-typescript";

export interface ImportEdiOrdersCSV {
	clientId: number;
	fileLookupId: Guid;
	orderReference: string;
}

export class ImportEdiOrdersCSV implements ImportEdiOrdersCSV {	
	constructor(data?: Partial<ImportEdiOrdersCSV>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}