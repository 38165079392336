import { HolidayRequestStatus } from "@enums/holdayRequestStatus.enum";

export interface ChangeHolidayRequestStatus {
	holidayRequestId: number;
	holidayRequestStatus: HolidayRequestStatus;
	reason: string;
}

export class ChangeHolidayRequestStatus implements ChangeHolidayRequestStatus {	
	constructor(data?: Partial<ChangeHolidayRequestStatus>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}