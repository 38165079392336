import { Department } from "@enums/department";

export interface ChangeUserProfileDepartment {
	department: Department;
	userProfileId: number;
}

export class ChangeUserProfileDepartment implements ChangeUserProfileDepartment {
	constructor(data?: Partial<ChangeUserProfileDepartment>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}