import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { ExportLogService } from "@services/exportLog.service";
import { SalesOrderPartService } from "@services/salesOrderPart.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "outstandingOrders",
	styleUrls: ["outstandingOrders.css"],
	templateUrl: "outstandingOrders.html"
})
export class OutstandingOrdersAdminComponent {
	deleteEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	itemType = "Outstanding Order"
	itemUrl = "admin/salesOrders"
	items: any[] = [];
	statuses: any;
	title: string;
	
	constructor(private router: Router, private exportLogService: ExportLogService, private itemService: SalesOrderPartService) {
		this.title = `${this.itemType}s`;
		this.viewRow = this.viewRow.bind(this);
		this.getItems();
	}

	getItems() {
		this.itemService.outstanding()
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Outstanding Orders";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}`]);
	}
}