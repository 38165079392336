import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MaterialShape } from "@enums/materialShape.enum";
import { MaterialType } from "@enums/materialType.enum";
import { PurchaseType } from "@enums/purchaseType.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { LineItem } from "@interfaces/lineItem.interface";
import { OutsourcedSpecification } from "@interfaces/outsourcedSpecification.interface";
import { Part } from "@interfaces/part.interface";
import { PartOutsourcedSpecification } from "@interfaces/partOutsourcedSpecification.interface";
import { Project } from "@interfaces/project.interface";
import { PurchasableItem } from "@interfaces/purchasableItem.interface";
import { PurchaseLog } from "@interfaces/purchaseLog.interface";
import { PurchaseLogFileLink } from "@interfaces/purchaseLogFileLink.interface";
import { Site } from "@interfaces/site.interface";
import { Supplier } from "@interfaces/supplier.interface";
import { SupplierContact } from "@interfaces/supplierContact.interface";
import { AuthService } from "@services/auth.service";
import { ClientService } from "@services/client.service";
import { FileService } from "@services/file.service";
import { OutsourcedSpecificationService } from "@services/outsourcedSpecification.service";
import { PartService } from "@services/part.service";
import { ProjectService } from "@services/project.service";
import { PurchasableItemService } from "@services/purchasableItem.service";
import { PurchaseLogService } from "@services/purchaseLog.service";
import { SiteService } from "@services/site.service";
import { SupplierService } from "@services/supplier.service";
import { SupplierContactService } from "@services/supplierContact.service";
import { DxDataGridComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "purchaseLogs-id",
	styleUrls: ["purchaseLogs-id.css"],
	templateUrl: "purchaseLogs-id.html"
})
export class PurchaseLogsIdAdminComponent {
	@ViewChild("gridContainer", { static: false }) dataGrid!: DxDataGridComponent;
	changes: any = [];
	clients: Client[] = [];
	contact: SupplierContact = new SupplierContact();
	contacts: SupplierContact[] = [];
	createEnabled = true;
	editEnabled = true;
	editRowKey: any = null;
	filteredParts: Part[] = [];
	filteredPurchasableItems: PurchasableItem[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: PurchaseLog = new PurchaseLog();
	itemType = "Purchase Log";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	lineItems: LineItem[] = [];
	materialShapes: any;
	materialTypes: any;
	outsourcedSpecifications: OutsourcedSpecification[] = [];
	partSelected: Part = new Part;
	parts: Part[] = [];
	partsSpec: Part[] = [];
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupContact: SupplierContact = new SupplierContact();
	popupProject: Project = new Project();
	popupPurchasableItem: PurchasableItem = new PurchasableItem();
	popupSupplier: Supplier = new Supplier();
	popupSupportingDocument: PurchaseLogFileLink = new PurchaseLogFileLink();
	popupTitle = "";
	popupVisible = false;
	projects: Project[]=[];
	purchasableItems: PurchasableItem[] = [];
	purchasableSpecs: string[] = [];
	purchaseTypes: any;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "";
	sites: Site[] = [];
	suppliers: Supplier[] = [];
	supportingDocumentButtonOptions = {
		onClick: () => this.addNewSupportingDocumentPopup(),
		text: "Add Document",
		type: "default",
		useSubmitBehavior: false
	}
	supportingDocuments: PurchaseLogFileLink[] = [];
	thicknesses: number[] = [0, 0.2, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.5, 1.6, 1.8, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 10, 12, 15, 20, 25];
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private clientService: ClientService, private fileService: FileService,  private outsourcedSpecificationService: OutsourcedSpecificationService, private partService: PartService, private projectService: ProjectService, private purchasableItemService: PurchasableItemService, private itemService: PurchaseLogService, private siteService: SiteService, private supplierContactService: SupplierContactService, private supplierService: SupplierService) {
		this.buildButtons("view");
		this.getClients();
		this.getProjects();
		this.getSites();
		this.getSuppliers();

		this.materialShapes = GetPropertiesFromEnum(MaterialShape);
		this.materialTypes = GetPropertiesFromEnum(MaterialType);
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);
		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.getItem(params.id.split("_")[0]);
					this.edit();
				} else {
					this.getItem(params.id);
				}
			} else {
				this.create();
			}
		});

		this.downloadPrice = this.downloadPrice.bind(this);
		this.cloneIconClick = this.cloneIconClick.bind(this);
		this.deleteSupportingDocumentLink = this.deleteSupportingDocumentLink.bind(this);
		this.downloadFileLink = this.downloadFileLink.bind(this);
		this.updateLineNumbers = this.updateLineNumbers.bind(this);
		this.uploadSupportingDocument = this.uploadSupportingDocument.bind(this);
		this.saveLineItemChanges = this.saveLineItemChanges.bind(this);

		this.onInitNewLineItemRow = this.onInitNewLineItemRow.bind(this);
		this.onSetLineItemDescriptionCellValue = this.onSetLineItemDescriptionCellValue.bind(this);
	}

	addNewContact() {
		this.supplierContactService.createSingle(this.popupContact)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getContacts(this.item.supplierId, true);
				},
				(err: any) => console.log(err)
			);
	}

	addNewContactPopup() {
		if (this.item.supplierId === undefined || this.item.supplierId === null || this.item.supplierId < 1) {
			alert("No Supplier Selected - Please select a supplier before adding a contact.");
			return;
		}
		this.popupContact = new SupplierContact();
		this.popupContact.supplierId = this.item.supplierId;
		this.popupTitle = "Add New Contact";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewContact(), text: "Add Contact" };
	}

	addNewProject() {
		this.projectService.createSingle(this.popupProject)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getProjects(true);
				},
				(err: any) => {
					notify("Could not add client project. Please check the details are valid and this project does not already exist.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewProjectPopup() {
		this.popupProject = new Project();
		this.popupTitle = "Add New Project";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewProject(), text: "Add Project" };
	}

	addNewPurchasableItem() {
		this.purchasableItemService.createSingle(this.popupPurchasableItem)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupPurchasableItem = new PurchasableItem();
					this.getPurchasableItems();
				},
				(err: any) => console.log(err)
			);
	}

	addNewPurchasableItemPopup() {
		this.popupTitle = "Add New Purchasable Item";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewPurchasableItem(), text: "Add Item" };
	}

	addNewSupplier() {
		this.supplierService.createSingle(this.popupSupplier)
			.subscribe(
				() => {
					this.getSuppliers(true);

					this.popupVisible = false;
					this.popupSupplier = new Supplier();
				},
				(err: any) => console.log(err)
			);
	}

	addNewSupplierPopup() {
		this.popupTitle = "Add New Supplier";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewSupplier(), text: "Add Supplier" };
	}

	addNewSupportingDocument() {
		if (this.popupSupportingDocument === null || this.popupSupportingDocument === undefined || this.popupSupportingDocument.fileName === "") {
			alert("Please upload a document");
			return;
		}
		this.supportingDocuments.push(this.popupSupportingDocument);
		this.popupSupportingDocument = new PurchaseLogFileLink();

		if (this.title === "Create New " + this.itemType) {
			this.createItem();
		} else {
			this.updateItem();
		}
		this.closePopup();
	}

	addNewSupportingDocumentPopup() {
		this.popupSupportingDocument = new PurchaseLogFileLink();
		this.popupTitle = "Add New Supporting Document";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewSupportingDocument(), text: "Add Document" };
	}

	buildButtons(mode: string) {
		this.screenMode = mode;

		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [];
			this.headerTertiaryButtons = [
				{ method: "addNewPurchasableItemPopup", text: "Add New Item" },
				{ method: "addNewContactPopup", text: "Add New Contact" },
				{ method: "addNewProjectPopup", text: "Add New Project" },
				{ method: "addNewSupplierPopup", text: "Add New Supplier" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [];
			this.headerTertiaryButtons = [
				{ method: "addNewPurchasableItemPopup", text: "Add New Item" },
				{ method: "addNewContactPopup", text: "Add New Contact" },
				{ method: "addNewProjectPopup", text: "Add New Project" },
				{ method: "addNewSupplierPopup", text: "Add New Supplier" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [
				{ method: "generatePDF", text: "Send PO" },
				{ method: "generatePreviewPDF", text: "Download PDF" },
				{ icon: "fal fa-copy", method: "duplicate", text: "Duplicate" },
			];
			this.headerTertiaryButtons = [];
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	cloneIconClick(line: any) {
		const data = line.row.data;
		const newLine: LineItem = new LineItem;
		newLine.lineDescription = data.lineDescription;
		newLine.width = data.width;
		newLine.length = data.length;
		newLine.thickness = data.thickness;
		newLine.quantity = data.quantity;
		newLine.cost = data.cost;
		newLine.lineCost = newLine.quantity * newLine.cost;
		newLine.lineNumber = this.lineItems.length + 1;
		this.lineItems.push(newLine);
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	create() {
		this.getEmail();
		this.getParts();
		this.getPurchasableItems();
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.updateLineNumbers();
		this.item.lineItems = JSON.stringify(this.lineItems);
		this.item.supportingDocuments = JSON.stringify(this.supportingDocuments);

		this.itemService.createSingle(this.item)
			.subscribe(
				(res: any) => {
					notify("Successfully Created " + this.itemType, "success", 5000);
					this.router.navigate([`/${this.itemUrl}/${res.response.lookupId}_edit`]);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteSupportingDocumentLink(e: any) {
		const index = this.supportingDocuments.findIndex(d => d.lookupId === e.row.data.lookupId);
		this.supportingDocuments.splice(index, 1);

		return false;
	}

	downloadFileLink(e: any) {
		const lookupId = Guid.parse(e.row.data.lookupId);
		this.fileService.downloadFile(lookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
		return false;
	}

	downloadPrice(line: any) {
		const data = line.row.data;
		// Required to calculate the line cost, set the material id and remove the auto generated __KEY__ property
		this.lineItems.find((item: any) => {
			if (item.__KEY__ === data.__KEY__) {
				if(this.item.purchaseType == 50){
					const partNo = item.lineDescription.split(">");
					const thisPart : Part = this.partsSpec.filter(x=>x.partNumber == partNo[0].trim())[0];
					const thisPartSpecs: PartOutsourcedSpecification[] = JSON.parse(thisPart.outsourcedSpecifications);
					const thisSpec : PartOutsourcedSpecification = thisPartSpecs.filter(x=>x.description == partNo[1].trim())[0];
					item.cost = thisSpec.defaultPrice;
				}
				item.lineCost = item.cost * item.quantity;
				item.__KEY__ = undefined;

				if(item.lineNumber == null){
					item.lineNumber = data.lineNumber;
				}
			}
		});

		this.item.totalCost = 0;
		this.lineItems.forEach(obj => {
			this.item.totalCost += obj.lineCost;
		});
	}

	duplicate() {
		if (confirm("Are you sure you wish to duplicate this purhcase log? This cannot be undone.") === false) {
			return;
		}
		this.itemService.duplicatePurchaseLog(this.item.id)
			.subscribe(
				(res: any) => {
					const duplicateItem = res.response;
					this.router.navigate([`${this.itemUrl}/${duplicateItem.lookupId}`]);
				},
				(err: any) => {
					notify("Could not duplicate purchase log", "Error", 5000);
					console.log(err);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	filterParts(projectId: number) {
		if (projectId !== undefined && projectId > 0) {
			this.filteredParts = this.parts.filter((p) => p.projectId === projectId);
		}
		else
		{
			this.filteredParts = this.parts;
		}
	}

	filterPurchasableItems(purchaseType: PurchaseType) {
		if (this.purchasableItems.length > 0) {
			if(purchaseType != PurchaseType.Outsourced){
				this.filteredPurchasableItems = this.purchasableItems.filter((pi) => pi.purchaseType === purchaseType);
			} else {
				this.filteredPurchasableItems = [];
				const i = 1;
				let tempSpecs:PartOutsourcedSpecification[] = [];
				tempSpecs = JSON.parse(this.partSelected.outsourcedSpecifications);
				tempSpecs.forEach((x) =>{
					if(x.supplierId === this.item.supplierId){
						const tempItem = new PurchasableItem();
						tempItem.id = i;
						tempItem.description = x.description + " - " + this.outsourcedSpecifications.find(os=>os.id === x.outsourcedSpecificationId)?.specification;
						tempItem.minimumBuyableQuantity = 0;
						tempItem.purchaseType = PurchaseType.Outsourced;

						this.filteredPurchasableItems.push(tempItem);
					}
				});
			}
		}
	}

	// P8B214A004AA

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "purchaseType":
				this.filterPurchasableItems(args.value);
				break;
			case "projectId":
				this.filterParts(args.value);
				break;
			case "supplierId":
				this.getContacts(args.value);
				this.filterPurchasableItems(this.item.purchaseType);
				this.getPurchasableSpecs(args.value);
				break;
			case "supplierContactId":
				this.getContact(args.value);
				break;
			case "partId":
				this.getPart(args.value);
				this.filterPurchasableItems(this.item.purchaseType);
				break;
		}
	}

	generatePDF() {
		this.itemService.generateAndEmailPdf(this.item.id)
			.subscribe(
				() => {
					notify("PDF has been generated and emailed.", "Success", 5000);
				},
				(err: any) => console.log(err)
			);
	}

	generatePreviewPDF() {
		this.itemService.generateAndPreviewPdf(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Purchase_Order_" + this.item.id + ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => {
					this.clients = res.response.items;
					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getContact(contactId: number) {
		this.supplierContactService.getSingleById(contactId)
			.subscribe(
				(res: any) => {
					this.contact = res.response;
					this.item.supplierContactId = contactId;
					this.item.contactEmail = res.response.contactEmailAddress;
					this.item.contactName = res.response.contactName;
				},
				(err) => console.log(err)
			);
	}

	getContacts(supplierId: number, setNewestContact = false) {
		this.supplierContactService.getContactsBySupplierId(supplierId)
			.subscribe(
				(res: any) => {
					this.contacts = res.response;

					if (setNewestContact) {
						this.getContact(this.contacts[this.contacts.length - 1].id);
					} else if (this.contacts.some(c => c.id === this.item.supplierContactId)) {
						// do nothing, the contact is present
					}
					else
					{
						this.getContact(this.contacts[0].id);
					}

					this.contacts.sort((a,b) => a.contactName.localeCompare(b.contactName));
				},
				(err) => console.log(err)
			);
	}

	getEmail() {
		this.authService.getCurrentUser()
			.subscribe(
				(res: any) => {this.item.email = res.response.user.email;},
				(err) => console.log(err)
			);
	}

	getItem(lookupId: Guid) {
		this.itemService.getSingleByLookupId(lookupId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.lineItems = JSON.parse(this.item.lineItems);
					this.supportingDocuments = JSON.parse(this.item.supportingDocuments);
					this.getContacts(this.item.supplierId);
					this.getParts();
					this.getPurchasableItems();
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getOutsourcedSpecifications(clientId: number)	{
		this.outsourcedSpecificationService.getByClientId(clientId)
			.subscribe(
				(res: any) => {
					this.outsourcedSpecifications = res.response;

					this.outsourcedSpecifications.sort((a,b) => a.specification.localeCompare(b.specification));
				},
				(err) => console.log(err)
			);
	}

	getPart(id:number){
		this.partService.getSingleById(id)
			.subscribe(
				(res: any) => {
					this.parts = res.response;
					this.getOutsourcedSpecifications(res.response.id);
				},
				(err) => console.log(err)
			);
	}

	getParts() {
		this.partService.getAll()
			.subscribe(
				(res: any) => {
					this.parts = res.response.items;
					this.parts.sort((a,b) => a.partNumber.localeCompare(b.partNumber));

					if (this.item != null) {
						this.filterParts(this.item.projectId);
					}
				},
				(err) => console.log(err)
			);
	}

	getProjects(setNewestProject = false) {
		this.projectService.getAll()
			.subscribe(
				(res: any) => {
					this.projects = res.response.items;
					if (setNewestProject) {
						this.item.projectId = this.projects[this.projects.length - 1].id;
					}

					this.projects.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getPurchasableItems() {
		this.purchasableItemService.getAll()
			.subscribe(
				(res: any) => {
					this.purchasableItems = res.response.items;
					this.purchasableItems.sort((a,b) => a.name.localeCompare(b.name));

					if (this.item != null) {
						this.filterPurchasableItems(this.item.purchaseType);
					}
				},
				(err) => console.log(err)
			);
	}

	getPurchasableSpecs(id: number) {
		this.partService.purchaseSpecifications(id)
			.subscribe(
				(res: any) => {
					this.purchasableSpecs = res.response;
					this.purchasableSpecs.sort((a,b) => a.localeCompare(b));
				},
				(err) => console.log(err)
			);

		this.partService.getBySpecificationSupplier(id)
			.subscribe(
				(res: any) => {
					this.partsSpec = res.response;
				},
				(err) => console.log(err)
			);
	}

	getSites() {
		this.siteService.getAll()
			.subscribe(
				(res: any) => {
					this.sites = res.response.items;
					this.sites.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getSuppliers(setNewestSupplier = false) {
		this.supplierService.getAll()
			.subscribe(
				(res: any) => {
					this.suppliers = res.response.items;

					if (setNewestSupplier) {
						this.item.supplierId = this.suppliers[this.suppliers.length - 1].id;
					}

					this.suppliers.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	onInitNewLineItemRow(row: any) {
		if (this.item.purchaseType === PurchaseType.BoughtOutParts) {
			row.data.lineDescription = ""; // todo: replace this with whatever BPL eventually decide on
		}
	}

	onSetLineItemDescriptionCellValue(newRowData: LineItem, value: string) {
		newRowData.lineDescription = value;
		
		// Attempt to load the purchasable item to fetch its defined cost
		const purchasableItems : PurchasableItem[] = this.filteredPurchasableItems.length > 0 ? this.filteredPurchasableItems.filter(x => x.name == value) : this.purchasableItems.filter(x => x.name == value);
		if (purchasableItems.length > 0) {
			const purchasableItem = purchasableItems[0];

			if (purchasableItem.cost !== undefined && purchasableItem.cost !== null) {
				newRowData.cost = purchasableItem.cost;
			}
		}
	}

	popupPurchasableItemFormDataChanged(args: any) {
		switch (args.dataField) {
			case "purchaseType":
				if (this.popupPurchasableItem.purchaseType !== PurchaseType.Material && this.popupPurchasableItem.purchaseType !== PurchaseType.ToolingBillet) {
					this.popupPurchasableItem.materialType = MaterialType.NotApplicable;
				}
				break;
		}
	}

	saveLineItemChanges(line: any) {
		const data = line.row.data;

		if (data.lineNumber === undefined) {
			data.lineNumber = this.lineItems.length + 1;
		} else {
			this.updateLineNumbers();
		}
		this.dataGrid.instance.saveEditData();
		// Required to calculate the line cost, set the material id and remove the auto generated __KEY__ property
		this.lineItems.find((item: any) => {
			if (item.__KEY__ === data.__KEY__) {
				item.lineCost = item.cost * item.quantity;
				item.__KEY__ = undefined;

				if(item.lineNumber == null){
					item.lineNumber = data.lineNumber;
				}
			}
		});

		this.item.totalCost = 0;
		this.lineItems.forEach(obj => {
			this.item.totalCost += obj.lineCost;
		});
	}

	updateItem() {
		this.updateLineNumbers();
		this.item.lineItems = JSON.stringify(this.lineItems);
		this.item.supportingDocuments = JSON.stringify(this.supportingDocuments);

		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateLineNumbers() {
		for (let i = 0; i < this.lineItems.length; i++) {
			this.lineItems[i].lineNumber = i + 1;
		}
	}

	uploadSupportingDocument(file: File) {
		const containerName = "purchaselogfiles";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupSupportingDocument.lookupId = res.response[0].lookupId;
					this.popupSupportingDocument.fileName = res.response[0].name;
				},
				(err) => {
					console.log(err);
					notify("Supporting Document Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}
}