export interface CreateSalesOrderFromPart {
	assemblyFixtureValue: number;
	clientContactId: number;
	clientId: number;
	deliveryCost: number;
	inspectionFixtureValue: number;
	laserFixtureValue: number;
	partId: number;
	projectId: number;
	quantity: number;
	toolingValue: number;
	unitCost: number;
	weldFixtureValue: number;
}

export class CreateSalesOrderFromPart implements CreateSalesOrderFromPart {
	constructor(data?: Partial<CreateSalesOrderFromPart>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}