import { TrainingStatus } from "@enums/trainingStatus.enum";
import { Model } from "@interfaces/model.interface";
import { Guid } from "guid-typescript";

export interface Training extends Model {
	certificateFileName: string;
	certificateId: number;
	certificateLookupId: Guid;
	expiryDate: Date;
	machineId: number;
	machineName: string;
	memberId: number;
	name: string;
	trainingDate: Date;
	trainingStatus: TrainingStatus;
	userProfileId: number;
	userProfileName: string;
}

export class Training implements Training {
	expiryDate = new Date();
	trainingDate = new Date();

	constructor(data?: Partial<Training>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}