import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class UserService extends Service {
	constructor(injector: Injector) {
		super("Users", injector);
	}

	// API
	groupsForUser(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/${id}/Groups`);
	}

	toggleGroup(userId: number, groupId: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ToggleGroup/${userId}/${groupId}`, {});
	}
}