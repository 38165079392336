import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { IncidentType } from "@enums/incidentType.enum";
import { PersonType } from "@enums/personType.enum";
import { Status } from "@enums/status.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { Accident } from "@interfaces/accident.interface";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Site } from "@interfaces/site.interface";
import { AccidentService } from "@services/accident.service";
import { AuthService } from "@services/auth.service";
import { SiteService } from "@services/site.service";
import { FileService } from "@services/file.service";
import { AccidentEvidence } from "@interfaces/accidentEvidence.interface";
import { Guid } from "guid-typescript";

@Component({
	moduleId: module.id,
	selector: "accidents-id",
	styleUrls: ["accidents-id.css"],
	templateUrl: "accidents-id.html"
})
export class AccidentIdAdminComponent {
	evidence: AccidentEvidence[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	incidentTypes: any;
	item: Accident = new Accident();
	itemId = "";
	itemType = "Accident";
	personTypes: any;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "";
	sites: Site[] = [];
	statuses: any;
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private itemService: AccidentService, private fileService: FileService, private siteService: SiteService, private authService: AuthService) {
		this.statuses = GetPropertiesFromEnum(Status);
		this.incidentTypes = GetPropertiesFromEnum(IncidentType);
		this.personTypes = GetPropertiesFromEnum(PersonType);
		this.getSites();
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0], "edit");
						this.edit();
					} else {
						this.getItem(params.id, "view");
					}
				} else {
					this.create();
				}
			});
		this.downloadFileLink = this.downloadFileLink.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
	}

	buildButtons(mode: string) {
		this.screenMode = mode;
		this.headerPrimaryButtons = [];
		this.headerSecondaryButtons = [];

		if (mode == "edit") {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "Cancel" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create") {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons.push({ method: "close", text: "Close" });
			this.headerSecondaryButtons.push({ icon: "fal fa-download", method: "generatePDF", text: "Download PDF" });
		}
	}

	cancelEditing() {
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;		
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new Accident();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteFile(e: any) {
		const index = this.evidence.findIndex(d => d.lookupId === e.row.data.lookupId);
		this.evidence.splice(index, 1);

		return false;
	}

	downloadFileLink(e: any) {
		const lookupId = Guid.parse(e.row.data.lookupId);
		this.fileService.downloadFile(lookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
		return false;
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	generatePDF() {
		this.itemService.generatePdf(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Accident_Report_" + this.item.id + ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	getItem(itemId: number, mode: string) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.evidence = JSON.parse(this.item.evidence);
					this.buildButtons(mode);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getSites() {
		this.siteService.getAll()
			.subscribe(
				(res: any) => this.sites = res.response.items,
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateItem() {
		this.item.evidence = JSON.stringify(this.evidence);
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => notify("Successfully Updated " + this.itemType, "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadFile(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					const newEvidenceFile = new AccidentEvidence();
					newEvidenceFile.lookupId = res.response[0].lookupId;
					newEvidenceFile.fileName = res.response[0].name;
					if (this.evidence === null || this.evidence === undefined) {
						this.evidence = [];
					}
					this.evidence.push(newEvidenceFile);
				},
				(err) => {
					console.log(err);
					notify("Evidence Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}
}