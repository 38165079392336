export interface ChangeWorkOrderStageQuantity {
	quantity: number;
	workOrderStageId: number;
}

export class ChangeWorkOrderStageQuantity implements ChangeWorkOrderStageQuantity {	
	constructor(data?: Partial<ChangeWorkOrderStageQuantity>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}