<div class="admin-header">
	<div class="mx-3">
		<h4 class="my-0">{{title}}</h4>
	</div>
	<div class="mx-3">
		<dx-button-group *ngIf="tertiaryButtons" class="me-3" [items]="tertiaryButtons" keyExpr="method" stylingMode="outlined" [activeStateEnabled]="true" buttonTemplate="template">
			<div *dxTemplate="let buttonData of 'template'" class="button-group-button" (click)="buttonClick(buttonData.method)" style="{{buttonData.style}}">
				<i *ngIf="buttonData.icon" class="{{buttonData.icon}}" [class.me-2]="buttonData.text"></i>
				<span>{{buttonData.text}}</span>
			</div>
    	</dx-button-group>
		<dx-button-group *ngIf="secondaryButtons" class="me-3" [items]="secondaryButtons" keyExpr="method" stylingMode="outlined" [activeStateEnabled]="true" buttonTemplate="template">
			<div *dxTemplate="let buttonData of 'template'" class="button-group-button" (click)="buttonClick(buttonData.method)" style="{{buttonData.style}}">
				<i *ngIf="buttonData.icon" class="{{buttonData.icon}}" [class.me-2]="buttonData.text"></i>
				<span>{{buttonData.text}}</span>
			</div>
    	</dx-button-group>
		<dx-button-group [items]="primaryButtons" keyExpr="method" stylingMode="outlined" [activeStateEnabled]="true" buttonTemplate="template">
			<div *dxTemplate="let buttonData of 'template'" class="button-group-button" (click)="buttonClick(buttonData.method)" style="{{buttonData.style}}">
				<i *ngIf="buttonData.icon" class="{{buttonData.icon}}" [class.me-2]="buttonData.text"></i>
				<span>{{buttonData.text}}</span>
			</div>
    	</dx-button-group>
	</div>
</div>