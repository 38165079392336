<div class="operator-bg">
	<!-- Loading -->
	<div id="loading" class="popup" *ngIf="isLoading">
		<h2 style="margin-left:370px; margin-top:180px; color:white;">Loading</h2>
		<img src="../../../../assets/loading.gif" style="margin-left:340px;">
	</div>

	<!-- Supervisor Login -->
	<div id="complete" class="popup" style="height:474px; margin-top:0px;" *ngIf="!isLoading && showSuperLogin">
		<div style="height:40px;"></div>
		<span style="font-size:40px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">
			Please enter your supervisor code.</span>
		<div class="row">
			<input class="input-override" type="password" name="quantity" (keyup.enter)="submitSupervisorLogin()"
				[(ngModel)]="supervisorCode" placeholder="Enter Login Code" autocomplete="off"
				style="margin-left:42px; width: 762px;margin-top:50px;border-radius:0px; height:100px; font-size: 40px;">
		</div>
		<div class="row">
			<div (click)="submitSupervisorLogin()"
				style="padding-top:20px; margin-left:42px; margin-top:40px; width:365px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 60px; color:white">SUBMIT</span>
			</div>
			<div (click)="toggleSupervisor(null)"
				style="padding-top:20px; margin-left:32px; margin-top:40px; width:365px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
				<span style="font-size: 50px; color:white">CANCEL</span>
			</div>
		</div>
	</div>
	<!-- Qty Adjustment Enter -->
	<!-- <div id="complete" class="popup" style="height:474px; margin-top:0px;" *ngIf="!isLoading && currentMachine.runningStages > 1000">
		<div style="height:40px;"></div>
		<span style="font-size:30px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">
			Please enter your new required amount.</span>
		<div class="row">
			<input class="input-override" type="password" name="quantity" (keyup.enter)="submitRequiredQty()"
				[(ngModel)]="requiredAmount" placeholder="Enter Login Code" autocomplete="off"
				style="margin-left:42px; width: 762px;margin-top:50px;border-radius:0px; height:100px; font-size: 40px;">
		</div>
		<div class="row">
			<div (click)="submitSupervisorLogin()"
				style="padding-top:20px; margin-left:42px; margin-top:40px; width:365px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 60px; color:white">SUBMIT</span>
			</div>
			<div (click)="toggleSupervisor(null)"
				style="padding-top:20px; margin-left:32px; margin-top:40px; width:365px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
				<span style="font-size: 50px; color:white">CANCEL</span>
			</div>
		</div>
	</div> -->
	<!-- Machine Info Popup -->
	<div id="machine" class="popup" style="height:729px; margin-left:54px; width:972px; margin-top:40px;"
		*ngIf="!isLoading && showInfo">
		<div class="row" style="background-color: #346982; width:972px; margin-left:0px">
			<div class="col-10">
				<h2 style="color:white; margin-top: 15px; margin-left:15px">// {{siteName.toUpperCase()}} //
					{{currentMachine.cellNumber}}</h2>
			</div>
			<div class="col-2" style="padding-right:0px">
				<div (click)="toggleInfo(null)"
					style="padding-top:8px;float:right;margin-right:5px; margin-top:5px; margin-bottom:5px; width:50px; height:50px; border:2px solid #ffa7a7; background-color: #eb0000;  text-align: center;">
					<i class="fal fa-times fs-30" style="color:#ffffff"></i>
				</div>
			</div>
		</div>
		<div id="statusBar"
			style="background-color:{{this.renderStatusColor(this.currentMachine.productionStatus)}}; height:30px; color:BLACK; padding:10px">
			{{renderStatusName(this.currentMachine.productionStatus)}}
		</div>
		<div class="row align-items-start" style="width:968px; margin-left:15px;">
			<div class="col-7" style="padding-right:20px; margin-top:20px; min-height: 350px;">
				<div class="row" style="margin-left:-25px;">
					<h2 style="color:white; font-weight: bold; text-decoration: underline;">{{renderUserAssigned()}}
					</h2>
				</div>
				<div class="row">
					<table style="color:white !important">
						<tbody>
							<tr>
								<th class="running" colspan="7"
									style="font-size: 20pt; color:white; font-weight: bold;">
									CURRENT WORK ORDER</th>
							</tr>
							<tr>
								<th scope="col">W/O</th>
								<th scope="col">Part Number</th>
								<th scope="col">Progress</th>
							</tr>
							<!-- <tr *ngIf="currentMachine.runningStages.length == 0">
								<td colspan="3">Nothiing Currently Running.</td>
							</tr> -->
							<tr *ngFor="let workOrderStage of currentMachine.runningStages">
								<th scope="row">{{workOrderStage.workOrderId}}</th>
								<td id="runningPartNumber{{workOrderStage.id}}">{{workOrderStage.partNumber}}</td>
								<dx-tooltip target="#runningPartNumber{{workOrderStage.id}}" showEvent="mouseenter"
									hideEvent="mouseleave" position="right">
									<div *dxTemplate="let data = data; of: 'content'">
										<img alt="{{workOrderStage.partNumber}}" width="300"
											src="{{workOrderStage.partImage}}" />
									</div>
								</dx-tooltip>
								<td>{{workOrderStage.quantityCompleted}} of {{workOrderStage.quantityRequired}}</td>
							</tr>
							<tr style="height:20px"></tr>
							<tr>
								<th class="available" colspan="7"
									style="font-size: 20pt; color:white; font-weight: bold;">
									MACHINE QUEUE</th>
							</tr>
							<tr>
								<th scope="col" style="width:50px">W/O</th>
								<th scope="col" style="width:450px">Part</th>
								<th scope="col" style="width:100px">Qty</th>
							</tr>
							<tr *ngFor="let workOrderStage of currentMachine.availableStages.slice(0,3)">
								<th scope="row">{{workOrderStage.workOrderId}}</th>
								<td id="availablePartNumber{{workOrderStage.id}}">{{workOrderStage.partNumber}}</td>
								<dx-tooltip target="#availablePartNumber{{workOrderStage.id}}" showEvent="mouseenter"
									hideEvent="mouseleave" position="right">
									<div *dxTemplate="let data = data; of: 'content'">
										<img alt="{{workOrderStage.partNumber}}" width="300" style="max-height:275px"
											src="{{workOrderStage.partImage}}" />
									</div>
								</dx-tooltip>
								<td>{{workOrderStage.quantityRequired}}</td>

							</tr>

						</tbody>
					</table>
					<span style="font-weight: bold; color:white;">{{renderWorkOrderHidden()}}</span>
				</div>
			</div>
			<div class="col-5" style="padding-left:20px; margin-top:20px">
				<div class="row">
					<h2 *ngIf="currentMachine.runningStages.length == 0" style="color:white; font-weight: bold;">NO PART RUNNING</h2>
					<h2 *ngIf="currentMachine.runningStages.length > 0" style="color:white; font-weight: bold;">CURRENT PART</h2>
					<h4 *ngIf="currentMachine.runningStages.length > 0" style="color:white">{{currentMachine.runningStages[0].partNumber}}</h4>
					<div class="row" *ngIf="currentMachine.runningStages.length > 0">
						<div class="col-12" style="padding:5px; color:#ffffff;">
							<div style="width:100%; height:25px; background-color: #2B2B38; border-radius:25px">
								<div style="width:{{currentMachine.runningStages[0].quantityCompleted/currentMachine.runningStages[0].quantityRequired * 100}}%; height:25px; background-color:#33aa00; padding-left:20px; padding-top:3px; border-radius:25px">{{(currentMachine.runningStages[0].quantityCompleted/currentMachine.runningStages[0].quantityRequired * 100).toFixed(0)}}%</div>
							  </div>
							
						</div>
					</div>
					<img *ngIf="currentMachine.runningStages.length > 0" src="{{currentMachine.runningStages[0].partImage}}" style="padding:20px; max-height:300px;">
				</div>
				<!-- {{runningStages[0].assignedUserProfile}} -->
			</div>
		</div>
		<div class="row" style="width:972px; margin-left:0px; bottom:0 !important">
			<div class="row">
				<div *ngIf="currentMachine.productionStatus == 100" (click)="submitProductionMode(10)"
					style="padding-top:20px; margin-left:32px; margin-top:20px; width:300px; height:75px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
					<span style="font-size: 20px; color:white;">RESET SUPERVISOR CALL</span>
				</div>
				<div *ngIf="currentMachine.productionStatus == 10" (click)="submitProductionMode(10)"
				style="padding-top:20px; margin-left:32px; margin-top:20px; width:300px; height:75px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 20px; color:white;">ADJUST QUANTITY</span>
			</div>
			<div *ngIf="currentMachine.productionStatus != 10 && currentMachine.productionStatus != 100" (click)="submitProductionMode(10)"
				style="padding-top:20px; margin-left:32px; margin-top:20px; width:300px; height:75px; border:5px solid #7f7f7f; background-color: #484848;  text-align: center;">
				<span style="font-size: 20px; color:white;">ADJUST QUANTITY</span>
			</div>
				<div (click)="toggleMode()"
					style="padding-top:20px; margin-left:32px; margin-top:20px; width:250px; height:75px; border:5px solid #c3a7ff; background-color: #5b00b5;  text-align: center;">
					<span style="font-size: 20px; color:white;">SET MODE</span>
				</div>
				<div *ngIf="currentMachine.runningStages.length == 0"
					style="padding-top:20px; margin-left:32px; margin-top:20px; width:250px; height:75px; border:5px solid #7f7f7f; background-color: #484848;  text-align: center;">
					<span style="font-size: 20px; color:white;">CHANGE OPERATOR</span>
				</div>
				<div (click)="toggleOperatorChange()" *ngIf="currentMachine.runningStages.length > 0"
					style="padding-top:20px; margin-left:32px; margin-top:20px; width:250px; height:75px; border:5px solid #ffe0a7; background-color: #b58500;  text-align: center;">
					<span style="font-size: 20px; color:white;">CHANGE OPERATOR</span>
				</div>
				<div class="row">
					<div *ngIf="currentMachine.runningStages.length == 0"
						style="padding-top:20px; margin-left:32px; margin-top:20px; width:300px; height:75px; border:5px solid #7f7f7f; background-color: #484848;  text-align: center;">
						<span style="font-size: 20px; color:white;">SPLIT WORK ORDER</span>
					</div>
					<div (click)="splitWorkOrder();" *ngIf="currentMachine.runningStages.length > 0"
						style="padding-top:20px; margin-left:32px; margin-top:20px; width:300px; height:75px; border:5px solid #ffa7e3; background-color: #b50085;  text-align: center;">
						<span style="font-size: 20px; color:white;">SPLIT WORK ORDER</span>
					</div>

					<div (click)="reloadPage()"
						style="padding-top:20px; margin-left:32px; margin-top:20px; width:250px; height:75px; border:5px solid #a7d0ff; background-color: #0070b5;  text-align: center;">
						<span style="font-size: 20px; color:white;">REFRESH DATA</span>
					</div>
					<div (click)="toggleMimic()"
						style="padding-top:20px; margin-left:32px; margin-top:20px; width:250px; height:75px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
						<span style="font-size: 20px; color:white;">REMOTE ACCESS</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Machine Info Popup  END -->
	<!-- Change Mode -->
	<div id="mode" class="popup" *ngIf="showMode">

		<div class="row" style="padding-left:10px;">
			<div (click)="changeMode(10)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 20px; color:white;">PRODUCTION</span>
			</div>
			<div (click)="changeMode(50)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">CHANGE OVER</span>
			</div>
			<div (click)="changeMode(60)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">TOILET BREAK</span>
			</div>
		</div>
		<div class="row" style="padding-left:10px;">
			<div (click)="changeMode(70)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">GENERAL BREAK</span>
			</div>
			<div (click)="changeMode(80)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">Q LOOP</span>
			</div>
			<div (click)="changeMode(90)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">QUALITY CHECK</span>
			</div>
		</div>
		<div class="row" style="padding-left:10px;">
			<div (click)="changeMode(30)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFA7A7; background-color: #EB0000;  text-align: center;">
				<span style="font-size: 20px; color:white;">MAINTENANCE</span>
			</div>
			<div (click)="changeMode(120)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFA7A7; background-color: #EB0000;  text-align: center;">
				<span style="font-size: 20px; color:white;">BREAKDOWN</span>
			</div>
			<div (click)="changeMode(20)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFA7A7; background-color: #EB0000;  text-align: center;">
				<span style="font-size: 20px; color:white;">NOT RUNNING</span>
			</div>
		</div>
		<div (click)="toggleMode()"
			style=" padding-top:20px;margin-left:32px; margin-top:40px; width:750px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
			<span style="font-size: 50px; color:white;">CANCEL</span>
		</div>
	</div>
	<!-- Mode Change END -->
	<!--  User Status View -->
	<div id="userStatus" class="popup" *ngIf="showUserStatus">
		<div class="row" style="background-color: #346982; width:818px; margin-left:0px">
			<div class="col-10">
				<h2 style="color:white; margin-top: 15px; margin-left:15px">// {{siteName.toUpperCase()}} // ON SITE
				</h2>
			</div>
			<div class="col-2" style="padding-right:0px">
				<div (click)="toggleUserStatus()"
					style="padding-top:8px;float:right;margin-right:5px; margin-top:5px; margin-bottom:5px; width:50px; height:50px; border:2px solid #ffa7a7; background-color: #eb0000;  text-align: center;">
					<i class="fal fa-times fs-30" style="color:#ffffff"></i>
				</div>
			</div>
		</div>
		<div style="padding:10px">
			<div class="row" style=" width:818px;color:white; padding:10px; border-bottom:white solid 5px">
				<div class="col-4">
					<h2>User</h2>
				</div>
				<div class="col-4">
					<h2>Session Start</h2>
				</div>
				<div class="col-4">
					<h2>Session End</h2>
				</div>

			</div>
			<div *ngFor="let k of staffAttendance">
				<div *ngIf="formatDate(k.firstScan) != ''" class="row"
					style="padding:10px; border-bottom:grey dashed 5px">
					<div class="col-4" style="color:{{k.onSite? 'limegreen' : 'red'}}">
						<h2>{{k.fullName}}</h2>
					</div>
					<div class="col-4" style="color:{{k.onSite? 'limegreen' : 'red'}}">
						<h2>{{formatDate(k.firstScan)}}</h2>
					</div>
					<div class="col-4" style="color:{{k.onSite? 'limegreen' : 'red'}}">
						<h2>{{formatDate(k.lastScan)}}</h2>
					</div>

				</div>
			</div>
		</div>
	</div>
	<!-- User Status View END -->
	<!-- Change Operator -->
	<div id="operatorChange" class="popup" style="height:474px; margin-top:0px;"
		*ngIf="!isLoading && showOperatorChange">
		<div style="height:40px;"></div>
		<span style="font-size:40px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">
			Please select a new operator.</span>
		<div class="row">
			<select id="Id" #Id="ngModel" class="hideLabel form-control" [(ngModel)]="selectedUserProfileId" name="Id"
				aria-placeholder="Select Operator"
				style="margin-left:42px; width: 762px;margin-top:50px;border-radius:0px; height:100px; font-size: 40px;"
				(change)="selectName();">
				<option [ngValue]="k.id" *ngFor="let k of userProfiles">
					{{k.fullName}}
				</option>
			</select>
		</div>
		<div class="row">
			<div (click)="changeWorkOrderStageOperator()"
				style="padding-top:20px; margin-left:42px; margin-top:40px; width:365px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 60px; color:white">SUBMIT</span>
			</div>
			<div (click)="toggleOperatorChange()"
				style="padding-top:20px; margin-left:32px; margin-top:40px; width:365px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
				<span style="font-size: 50px; color:white">CANCEL</span>
			</div>
		</div>
	</div>
	<!-- MIMIC -->
	<div id="mimic" class="popup" style="height:729px; margin-left:54px; width:972px; margin-top:40px;"
		*ngIf="!isLoading && showMimic">
		<div class="row" style="background-color: #346982; width:972px; margin-left:0px">
			<div class="col-10">
				<h2 style="color:white; margin-top: 15px; margin-left:15px">// {{siteName.toUpperCase()}} //
					{{currentMachine.cellNumber}} // REMOTE ACCESS</h2>
			</div>
			<div class="col-2" style="padding-right:0px">
				<div (click)="toggleMimic()"
					style="padding-top:8px;float:right;margin-right:5px; margin-top:5px; margin-bottom:5px; width:50px; height:50px; border:2px solid #ffa7a7; background-color: #eb0000;  text-align: center;">
					<i class="fal fa-times fs-30" style="color:#ffffff"></i>
				</div>
			</div>
		</div>
		<div class="row" style="margin-left:120px !important; margin-top:50px">
			<iframe [src]="mimicUrl()" class="entry-content"
				style="height:540px; width:720px; border:solid black 5px !important;"></iframe>
		</div>
	</div>
	<!-- MIMIC END -->
	<div class="popup-blackout" *ngIf="showPopup"></div>
	<div class="row">
		<div class="col-10">
			<H2 style="padding-left:20px; padding-top:20px; color:white;">// SUPERVISOR INTERFACE //
				{{siteName.toUpperCase()}}</H2>
		</div>
		<div class="col-2"><dx-drop-down-button *ngIf="departments !== undefined && departments.length > 1"
				style="float: right !important; margin: 20px 20px 0 0 !important; color:black; background-color: #0076EB; width:175px;"
				[text]="this.departmentName" [dropDownOptions]="{ width: 240 }" [items]="departments"
				(onItemClick)="selectSiteForScreen($event)"></dx-drop-down-button></div>
	</div>
	<div class="row" style="padding-left:25px; padding-right:25px; padding-top:25px; height:550px; z-index: -9999; overflow-y: auto;">
		<div *ngFor="let tile of machines" class="col-12 col-sm-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-4 col-xxxl-2">
			<div class="admin-tile" (click)="toggleSupervisor(tile);">
				<div class="tile position-relative pointer"
					style="text-align: center; background-color: {{renderStatusColor(tile.productionStatus)}};">
					<i class="fal fa-conveyor-belt-alt fs-30" style="color:#344281"></i>
					<div *ngIf="tile.runningStages.length > 0">
						<span class="text-center"
							style="margin-bottom:5px; font-weight: bold;">{{tile.cellNumber}}</span><br />
						<span class="text-center" style="text-decoration: underline;">
							{{tile.runningStages[0].partNumber.substring(0,30)}}</span>
						<span
							style="top:5px; right:10px; text-align: right; position: absolute">WO{{tile.runningStages[0].workOrderId}}
							//</span>
						<span
							style="top:25px; right:10px; text-align: right; position: absolute">{{tile.runningStages[0].operationNumber}}
							//</span>
					</div>
					<div *ngIf="tile.runningStages.length == 0">
						<span class="text-center" style="font-weight: bold;">{{tile.cellNumber}}</span> <br />
						<span class="text-center" style="text-decoration: underline; ">NO WORK ORDER ASSIGNED</span>
					</div>
					<div class="row" *ngIf="tile.runningStages.length > 0">
						<div class="col-12" style="padding:5px; color:#ffffff;">
							<div style="width:80%; height:25px; background-color: #2B2B38; border-radius:25px; margin-left:10%">
								<div style="width:{{tile.runningStages[0].quantityCompleted/tile.runningStages[0].quantityRequired * 100}}%; height:25px; background-color:#33aa00; padding-left:20px; padding-top:3px; border-radius:25px">{{(tile.runningStages[0].quantityCompleted/tile.runningStages[0].quantityRequired * 100).toFixed(0)}}%</div>
							  </div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row" style="padding:20px; background-color: #253061; margin-left:0; margin-right:0; margin-bottom:-50px !important; z-index: -99;">
		<div class="col-6">
			<div (click)="toggleUserStatus()"
				style="margin-top:20px; height:130px; border:5px solid #d9a7ff; background-color: #6a00eb; text-align: center;">
				<i class="fa fa-users" style="font-size: 80px; color:white; margin-top:20px;"></i>
			</div>
		</div>
		<div class="col-6">
			<div (click)="reloadPage()"
				style="margin-top:20px; height:130px; border:5px solid #A7DDFF; background-color: #0076EB; text-align: center;">
				<i class="fa fa-redo-alt" style="font-size: 80px; color:white; margin-top:20px;"></i>
			</div>
		</div>
	</div>
	<!-- <div id="statusBar" style="background-color:{{this.currentStatusColor}}; margin-top:25px;"></div> -->

	<!-- <img src="../../../../assets/Logo.png" width="200" style="margin-top:25px; margin-left:140px; margin-bottom:25px;">
<input class="input-override" type="password" name="password" (keyup.enter)="submitData()"
	[(ngModel)]="fobScannedEntity.fobId" placeholder="Fob ID" autocomplete="off"
	style="margin-left:25px; margin-right:25px;">
<button class="btn-override" (click)="submitData()" style="margin-left:25px; margin-top:25px;">
	Submit Details
</button> -->

</div>