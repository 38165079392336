import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

@Injectable()
export class BannerService {
	endpoint = "Banner";

	constructor(private http: HttpClient) {}

	// API
	getBanner() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}`);
	}
}