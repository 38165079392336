import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { RequestForQuote } from "@interfaces/requestForQuote.interface";
import { SalesOrder } from "@interfaces/salesOrder.interface";
import { ExportLogService } from "@services/exportLog.service";
import { RequestForQuoteService } from "@services/requestForQuote.service";
import { SalesOrderService } from "@services/salesOrder.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "salesOrders-id-rfqs",
	styleUrls: ["salesOrders-id-rfqs.css"],
	templateUrl: "salesOrders-id-rfqs.html"
})
export class SalesOrdersIdRFQsAdminComponent {
	deleteEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [
		{ method: "viewSalesOrder", text: "Go to Sales Order" }
	];
	headerSecondaryButtons: AdminHeaderButton[] = [
		{ icon: "fal fa-mailbox", method: "sendEmails", text: "Send All" }
	];
	itemId = 0;
	items: RequestForQuote[] = [];
	salesOrder: SalesOrder = new SalesOrder();
	title = "";

	constructor(private router: Router, private route: ActivatedRoute, private exportLogService: ExportLogService, private requestForQuoteService: RequestForQuoteService, private salesOrderService: SalesOrderService) {
		this.route.params.subscribe((params) => {
			this.itemId = params.id;
			this.getItems(params.id);
			this.getSalesOrder();
			this.title = `RFQs for Sales Order #${params.id}`;
		});

		this.deleteRow = this.deleteRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.viewRow = this.viewRow.bind(this);
	}

	boolYesNo(cellInfo: any){		
		if (cellInfo.value === true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this RFQ?");
		if (result) {
			this.requestForQuoteService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify("RFQ deleted!", "success", 5000);
						this.getItems(this.itemId);
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`admin/requestForQuotes/${e.row.data.lookupId}_edit`]);
	}

	getItems(id: number) {
		this.requestForQuoteService.getForSalesOrder(id)
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getSalesOrder() {
		this.salesOrderService.getSingleById(this.itemId)
			.subscribe(
				(res: any) => this.salesOrder = res.response,
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "RFQs for Sales Order " + this.itemId;
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	sendEmails() {
		if (confirm("Are you sure you wish to send all these RFQs? Please ensure the supplier RFQ contacts and the details on each RFQ are correct.") == false) {
			return;
		}

		this.requestForQuoteService.sendRFQsForSalesOrder(this.itemId)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					this.getItems(this.itemId);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	viewRow(e: any) {
		this.router.navigate([`admin/requestForQuotes/${e.row.data.lookupId}`]);
	}

	viewSalesOrder() {		
		this.router.navigate([`admin/salesOrders/${this.salesOrder.lookupId}`]);
	}
}