import { Component } from "@angular/core";
import { ModalService } from "@services/modal.service";

@Component({
	moduleId: module.id,
	selector: "modal",
	styleUrls: ["modal.css"],
	templateUrl: "modal.html"
})
export class ModalComponent {
	data?: any;
	showModal = false;
	template = "";

	constructor(private modalService: ModalService) {
		this.modalService.status.subscribe(
			(value: string) => {
				this.handleTemplate(value);
				if (this.modalService.data) this.data = this.modalService.data;
			}
		);
	}

	close() {
		document.body.style.overflow = "auto";
		document.body.style.height = "auto";

		this.showModal = false;
	}

	handleTemplate(template: string) {
		this.template = template;

		if (template === "") {
			this.close();
		} else {
			document.body.style.overflow = "hidden";
			document.body.style.height = "100%";

			this.showModal = true;
		}
	}
}