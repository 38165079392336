import { Model } from "@interfaces/model.interface";

export interface DeliveryNote extends Model {
	clientId: number;
	clientLocationId: number;
	dateSent?: Date;
	generatedById: number;
	lineItems: string;
	salesOrderId: number;
}

export class DeliveryNote implements DeliveryNote {	
	constructor(data?: Partial<DeliveryNote>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}