import { Model } from "@interfaces/model.interface";

export interface Project extends Model {
	clientId: number;
	description: string;
	displayName: string;
	name: string;
	potentialQty:number;
	potentialTotalEnq:number;
	potentialTotalVal:number;
	totalSpend:number;
}

export class Project implements Project {	
	description = "";
	name = "";

	constructor(data?: Partial<Project>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}