import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { environment } from "@environments/environment";
import notify from "devextreme/ui/notify";
import { CompleteAccount } from "@interfaces/completeAccount.interface";
import { Guid } from "guid-typescript";
import { UserProfileService } from "@services/userProfile.service";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "complete-account-id",
	styleUrls: ["complete-account-id.css"],
	templateUrl: "complete-account-id.html"
})
export class CompleteAccountIdAdminComponent {
	companyName = "";
	completeAccountDetails: CompleteAccount = new CompleteAccount();
	completeAccountToken: Guid = Guid.parse(Guid.EMPTY);
	copyright = "";
	passwordComplexityRules = "";
	validationErrors = {confirmPassword: "", password: ""};	
	
	constructor(private route: ActivatedRoute, private authService: AuthService, private userProfileService: UserProfileService) {
		this.companyName = this.getEnvironmentProperty("companyLongName");
		this.copyright = "© Copyright " + this.companyName + " " + new Date().getFullYear();
		this.getPasswordComplexityRules();
		this.route.params
			.subscribe((params) => {
				this.getIncompleteAccount(params.id);
			});
	}

	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment["build"][property];
	}

	getIncompleteAccount(id: Guid) {
		this.userProfileService.getIncompleteAccount(id)
			.subscribe(
				(res: any) => {
					this.completeAccountDetails = res.response;
					this.completeAccountToken = id;
				},
				() => {
					window.location.href = "/admin";
				}
			);
	}

	getPasswordComplexityRules() {
		this.authService.getPasswordComplexityRules()
			.subscribe(
				(res: any) => {
					this.passwordComplexityRules = res.response;
				},
				(err) => console.log(err)
			);
	}

	submitData() {
		this.completeAccountDetails.termsAndConditions = true;
		this.validationErrors = {confirmPassword: "", password: ""};
		let validationFail = false;

		if (this.completeAccountDetails.password === undefined || this.completeAccountDetails.password === null || this.completeAccountDetails.password === "") {
			this.validationErrors.password = "Password must not be blank.";
			validationFail = true;
		}

		if (this.completeAccountDetails.confirmPassword === undefined || this.completeAccountDetails.confirmPassword === null || this.completeAccountDetails.confirmPassword === "") {
			this.validationErrors.confirmPassword = "Password Confirmation must not be blank.";
			validationFail = true;
		} else if(this.completeAccountDetails.confirmPassword !== this.completeAccountDetails.password){
			this.validationErrors.confirmPassword = "Password Confirmation must match password.";
			validationFail = true;
		}

		if (validationFail === true) { return; }

		this.userProfileService.completeAccount(this.completeAccountToken, this.completeAccountDetails)
			.subscribe(
				() => {
					notify("Your account has been completed", "success", 5000),
					window.location.href = "/admin";
				},
				(err) => {
					this.validationErrors.password = err.password;
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	windowWidthCheck() {
		return window.innerWidth >= 768;
	}
}