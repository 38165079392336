<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<ul class="promiseBoardLegend">
	<li class="monthEnd">End of Month</li>
	<li class="internal">Internal (BPL)</li>
	<li class="external">External (Client)</li>
</ul>
<div *ngFor="let week of promiseBoard" class="promiseBoardWeek">
	<h2>{{week.header}}</h2>
	<table class="promiseBoard">
		<thead>
			<tr>
				<th *ngFor="let day of week.columns" scope="col" class="{{day.isEndOfMonth == true ? 'monthEnd' : ''}}">{{day.day}}<br >{{day.date}}</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td *ngFor="let day of week.columns">
					<a *ngFor="let promise of day.promises" class="{{promise.promiseType == 10 ? 'internal' : 'external'}}" href="#" (click)="editPromisePopup(promise)">
						{{promise.description}}
					</a>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *dxTemplate="let data of 'content'">
		<dx-form class="m-3" [(formData)]="popupPromise" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="promiseDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'Promise Date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="promiseType" editorType="dxSelectBox" [editorOptions]="{ items: promiseTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true }" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="description"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
		</dx-form>
	</div>
</dx-popup>