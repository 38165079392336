import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { SystemParamValue } from "@interfaces/systemParamValue.interface";
import { SystemParameterService } from "@services/systemParameter.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "system-param-id-values-vid",
	styleUrls: ["system-param-id-values-vid.css"],
	templateUrl: "system-param-id-values-vid.html"
})
export class SystemParamIdValueAdminComponent {
	createEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	item: SystemParamValue = new SystemParamValue();
	itemType = "System Param Value Detail";
	readOnly = true;
	returnUrl = `${location.href.split("/")[3]}/${location.href.split("/")[4]}/${location.href.split("/")[5]}`;
	systemParamId = 0;
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private itemService: SystemParameterService) {
		this.buildButtons("view");
		this.route.params
			.subscribe((params) => {
				this.systemParamId = params.id;
				if (params.vid.split("_").length > 1) {
					this.getItem(params.id, params.vid.split("_")[0]);
					this.edit();
				} else {
					this.getItem(params.id, params.vid);
				}
			});
	}

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];	
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`${this.returnUrl}`]);
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new SystemParamValue();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	getItem(id: number, vid: number) {
		this.itemService.getSystemParamValueById(id, vid)
			.subscribe(
				(res: any) => {
					this.item = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getKeys(data: Record<string, unknown>) {
		return Object.keys(data);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateItem() {
		this.itemService.updateSystemParamValueById(this.systemParamId, this.item.id, this.item)
			.subscribe(
				() => notify("Successfully Updated " + this.itemType, "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}