import { CadQuoteLevel } from "@enums/cadQuoteLevel.enum";
import { InspectionType } from "@enums/inspectionType.enum";

export function GetPropertiesFromCadQuoteLevelEnum(enumName: typeof CadQuoteLevel | typeof InspectionType) {
	const arrayObjects = [];  
	for (const [propertyKey, propertyValue] of Object.entries(enumName)) {  
		if (!Number.isNaN(Number(propertyKey))) continue;
		arrayObjects.push({ id: propertyValue, name: propertyKey.replace(/(\B[A-Z])/g, " $1") .replace("Two Dimensional", "2D").replace("Three Dimensional", "3D").replace("And", " & ")});  
	}
	return arrayObjects;
}