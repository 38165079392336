import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { SupplierContact } from "@interfaces/supplierContact.interface";
import { SupplierDocument } from "@interfaces/supplierDocument.interface";
import { StockStoreService } from "@services/stockStore.service";
import { StockStore } from "@interfaces/stockStore.interface";
import { StockStoreHistoryService } from "@services/stockStoreHistory.service";
import { StockStoreHistory } from "@interfaces/stockStoreHistory.interface";

@Component({
	moduleId: module.id,
	selector: "stores-id",
	styleUrls: ["stores-id.css"],
	templateUrl: "stores-id.html"
})
export class StoresIdAdminComponent {
	contacts: SupplierContact[] = [];
	createEnabled = true;
	documents: SupplierDocument[] = [];
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	history: StockStoreHistory[] = [];
	item: StockStore = new StockStore();
	itemId = 0;
	itemType = "Store Item";
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private itemService: StockStoreService, private historyService: StockStoreHistoryService) {
		this.buildButtons("view");

		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.itemId = params.id.split("_")[0];
					this.getItem(this.itemId);
					this.edit();
				} else {
					this.itemId = params.id;
					this.getItem(params.id);
				}
			} else {
				this.create();
			}
		});
	}

	boolYesNo(cellInfo: any){
		if(cellInfo.value === true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	}

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "viewPurchaseLog", text: "View Purchase Log" },
				{ method: "viewSupplier", text: "View Document" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "viewPurchaseLog", text: "View Purchase Log" },
				{ method: "viewSupplier", text: "View Supplier" }
			];
		}
	}

	cancelEditing() {
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new StockStore();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	getHistory(itemId: number){
		this.historyService.getByItemId(itemId)
			.subscribe(
				(res: any) => {
					this.history = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getItem(itemId: number) {
		this.itemService.getDetails(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getHistory(res.response.id);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	isTogglePurchaseTypeOffVisible(e: any) {
		return e.row.data.isSelected === true;
	}

	isTogglePurchaseTypeOnVisible(e: any) {
		return e.row.data.isSelected === false;
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => notify("Successfully Updated " + this.itemType, "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	viewPurchaseLog(){
		this.router.navigate([`admin//purchaseLogs/${this.item.purchaseLog.lookupId}`]);
	}

	viewSupplier(){
		this.router.navigate([`admin/suppliers/${this.item.purchaseLog.supplierId}`]);
	}
}