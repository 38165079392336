<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="log-details">
	<dx-form class="m-3" [(formData)]="log" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2"  [readOnly]="true">
		<dxi-item itemType="group" caption="Log Details" [colSpan]="2" [colCount]="2">
			<dxi-item dataField="id" [colSpan]="2">
				<dxo-label text="Audit ID">
				</dxo-label>
			</dxi-item>
			<dxi-item dataField="type"></dxi-item>
			<dxi-item dataField="statusCode"></dxi-item>
			<dxi-item dataField="message"></dxi-item>
			<dxi-item dataField="createdAt"></dxi-item>
		</dxi-item>

		<dxi-item itemType="group" caption="Request" [colSpan]="2" [colCount]="6">
			<dxi-item dataField="requestMethod" [colSpan]="2"></dxi-item>
			<dxi-item dataField="requestUri" [colSpan]="4"></dxi-item>
			<dxi-item dataField="requestIp" [colSpan]="6"></dxi-item>
			<dxi-item dataField="JSON.stringify(requestBody)" [colSpan]="6">
				<dxo-label text="Request Body"></dxo-label>
			</dxi-item>
		</dxi-item>

		<dxi-item itemType="group" caption="Response" [colSpan]="2" [colCount]="6">
			<dxi-item dataField="responseTime" [colSpan]="6"></dxi-item>
			<dxi-item dataField="JSON.stringify(responseBody)" [colSpan]="6">
				<dxo-label text="Response Body"></dxo-label>
			</dxi-item>
		</dxi-item>

		<dxi-item itemType="group" caption="Stack Trace" [colSpan]="2" [colCount]="6">
			<dxi-item dataField="JSON.stringify(trace)" [colSpan]="6">
				<dxo-label text="Details"></dxo-label>
			</dxi-item>
		</dxi-item>
	</dx-form>
	<dx-form *ngIf="log.userId != null" class="m-3" [(formData)]="user" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2" [readOnly]="true">
		<dxi-item itemType="group" caption="User Details" [colSpan]="2" [colCount]="6">
			<dxi-item dataField="user.email" [colSpan]="6" ><dxo-label text="Email Address"></dxo-label></dxi-item>
			<dxi-item dataField="title" editorType="dxSelectBox" [editorOptions]="{ items: titles, searchEnabled: true, value: '' }" [colSpan]="2"></dxi-item>
			<dxi-item dataField="firstName" [colSpan]="2"></dxi-item>
			<dxi-item dataField="lastName" [colSpan]="2"></dxi-item>
			
			<dxi-item dataField="dateOfBirth" [colSpan]="3"></dxi-item>
			<dxi-item dataField="gender" [colSpan]="3"></dxi-item>

			<dxi-item dataField="address1" [colSpan]="3"></dxi-item>
			<dxi-item dataField="address2" [colSpan]="3"></dxi-item>
			<dxi-item dataField="town" [colSpan]="3"></dxi-item>
			<dxi-item dataField="postcode" [colSpan]="3"></dxi-item>
			
			<dxi-item dataField="telephoneNumber" [colSpan]="3"></dxi-item>
			<dxi-item dataField="gym.name" [colSpan]="3"><dxo-label text="Home Gym"></dxo-label></dxi-item>
		</dxi-item>
	</dx-form>
</div>