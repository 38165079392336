<admin-header [primaryButtons]="headerPrimaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="2">
				<dxi-item dataField="visitorType" editorType="dxSelectBox" [editorOptions]="{ items: visitorTypes, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Visitor Type' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="name"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="emailAddress"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="telephoneNumber"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="companyName"></dxi-item>
				<dxi-item dataField="vehicleRegistration"></dxi-item>
				<dxi-item dataField="userProfileId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Assigned Staff' }"></dxi-item>	
			</dxi-tab>
			<dxi-tab title="Photo" [colCount]="2">
				<dxi-item>
					<div class="photoImage" [style]="photoStyle"></div>
				</dxi-item>
				<dxi-item>
					<dx-file-uploader [visible]="screenMode != 'view'" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadImage" accept="image/*" [allowedFileExtensions]="['.gif', '.jpg', '.jpeg', '.png']"></dx-file-uploader>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Visits">
				<dx-data-grid class="data-grid" [dataSource]="visits" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="clientContacts"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column dataField="reasonForVisit" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="userProfileId" caption="Assigned Staff">
						<dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup>
					</dxi-column>
					<dxi-column dataField="startDate" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left"></dxi-column>
					<dxi-column dataField="endDate" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>