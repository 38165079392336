import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { FirewallLog } from "@interfaces/firewallLog.interface";
import { Service } from "@services/service";

@Injectable()
export class FirewallLogService extends Service {
	constructor(injector: Injector) {
		super("FirewallLog", injector);
	}

	// API
	appCreateFirewallLog(log: FirewallLog){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_Create`, log);
	}
	createNonConformanceReport(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateNonConformanceReport/${id}`, {});
	}
}