<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="productionRequestsMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="Approve" icon="fal fa-check" [onClick]="approve" [visible]="isRowPending"></dxi-button>
        <dxi-button hint="Decline" icon="fal fa-times" [onClick]="decline" [visible]="isRowPending"></dxi-button>
        <dxi-button hint="View Sales Order" icon="fal fa-eye" [onClick]="viewSalesOrder" [visible]="isRowApproved"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" dataType="string" alignment="left"></dxi-column>
    <dxi-column dataField="status" dataType="string" alignment="left"><dxo-lookup [dataSource]="statuses" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
    <dxi-column dataField="part.partNumber" dataType="string" alignment="left" caption="Part Number"></dxi-column>
	<dxi-column dataField="part.project.name" dataType="string" alignment="left" caption="Project"></dxi-column>
	<dxi-column dataField="part.client.name" dataType="string" alignment="left" caption="Client"></dxi-column>
	<dxi-column dataField="quantity" dataType="number" alignment="left"></dxi-column>
</dx-data-grid>