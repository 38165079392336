import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Visit } from "@interfaces/visit.interface";
import { Service } from "@services/service";

@Injectable()
export class VisitService extends Service {
	constructor(injector: Injector) {
		super("Visit", injector);
	}

	// API
	endVisit(accessCode: string) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/StartVisit/${accessCode}`, {});
	}
	
	forVisitor(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForVisitor/${id}`);
	}

	startVisit(accessCode: string, data: Visit) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/StartVisit/${accessCode}`, data);
	}
}