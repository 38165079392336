import { EmailPriority } from "@enums/emailPriority.enum";
import { Model } from "@interfaces/model.interface";
import { PurchaseLog } from "./purchaseLog.interface";
import { PurchasableItem } from "./purchasableItem.interface";

export interface StockStore extends Model {
	alertQuantity: string;
	gauge: any;
	item: PurchasableItem;
	itemId:number;
	length: string;
	location: string;
	purchaseLog: PurchaseLog;
	purchaseLogId: string;
	quantity: string;
	width: EmailPriority;
}

export class StockStore implements StockStore {

	constructor(data?: Partial<StockStore>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}