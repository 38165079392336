import { SalesEnquiryStatus } from "@enums/salesEnquiryStatus.enum";

export interface CloseSalesEnquiry {
	closingComments: string;
	newStatus: SalesEnquiryStatus;
}

export class CloseSalesEnquiry implements CloseSalesEnquiry {
	closingComments = "";
	newStatus = SalesEnquiryStatus.Lost;

	constructor(data?: Partial<CloseSalesEnquiry>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}