import { Model } from "@interfaces/model.interface";

export interface Audit extends Model {
	personName: string;
}

export class Audit implements Audit {

	constructor(data?: Partial<Audit>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}