import { Model } from "@interfaces/model.interface";

export interface CompleteAccount extends Model {
	confirmPassword: string;
	email: string;
	firstName: string;
	lastName: string;
	marketingConsent: boolean;
	password: string;
	termsAndConditions: boolean;
}

export class CompleteAccount implements CompleteAccount {
	confirmPassword = "";
	email = "";
	firstName = "";
	lastName = "";
	marketingConsent = false;
	password = "";
	termsAndConditions = false;

	constructor(data?: Partial<CompleteAccount>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}