import { Model } from "@interfaces/model.interface";

export interface BankDetails extends Model {
	accountName: string;
	accountNumber: string;
	bankName: string;
	iban: string;
	sortCode: string;
	swift: string;
}

export class BankDetails implements BankDetails {
	constructor(data?: Partial<BankDetails>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}