import { Model } from "@interfaces/model.interface";

export interface OutsourcedSpecification extends Model {
	clientId: number;
	specification: string;
}

export class OutsourcedSpecification implements OutsourcedSpecification {
	specification = "";

	constructor(data?: Partial<OutsourcedSpecification>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}