<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="group" caption="Details" [colCount]="2">
			<dxi-item dataField="name" [label]="{ text: 'Name' }"></dxi-item>	
		</dxi-item>
		<dxi-item itemType="group" caption="Parts" *ngIf="mode !== 'create'">			
			<dx-data-grid class="data-grid" [dataSource]="linkedParts" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
				<dxo-paging [pageSize]="10"></dxo-paging>
				<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxi-column dataField="partNumber" dataType="text" alignment="left" caption="Part"></dxi-column>
				<dxi-column dataField="linkedPartGroupProportion" dataType="number" alignment="left" caption="Proportion"></dxi-column>
				<dxi-column type="buttons">
					<dxi-button *ngIf="mode !== 'view'" hint="Remove" icon="fal fa-trash" [onClick]="removePartFromGroup"></dxi-button>
				</dxi-column>
			</dx-data-grid>
		</dxi-item>
		<dxi-item itemType="group" caption="Routing Links" *ngIf="mode !== 'create'">			
			<dx-data-grid class="data-grid" [dataSource]="linkedRouterDetails" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="500px">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxo-editing mode="popup" [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
					<dxo-popup title="Part Details" [showTitle]="true" [width]="'55%'" [height]="'auto'"></dxo-popup>
					<dxo-form>
						<dxi-item dataField="linkGroupId" editorType="dxNumberBox" [editorOptions]="{ format: {type:'fixedPoint', precision: 0, min: 0}, min: 1 }" [label]="{ text: 'Group' }"><dxi-validation-rule type="required" message="Group is required"></dxi-validation-rule></dxi-item>	
						<dxi-item dataField="partId" editorType="dxSelectBox" [editorOptions]="{ items: linkedParts, searchEnabled: true, displayExpr:'partNumber', valueExpr:'id' }" [label]="{ text: 'Part to Link' }"><dxi-validation-rule type="required" message="Part is required"></dxi-validation-rule></dxi-item>	
						<dxi-item dataField="stageNumber" editorType="dxNumberBox" [editorOptions]="{ format: {type:'fixedPoint', precision: 0, min: 0}, min: 1 }" [label]="{ text: 'Stage Number' }"><dxi-validation-rule type="required" message="Stage is required"></dxi-validation-rule></dxi-item>	
					</dxo-form>
				</dxo-editing>
				<dxo-toolbar>
					<dxi-item name="addRowButton" showText="always"></dxi-item>
				</dxo-toolbar>
				<dxi-column type="buttons">
					<dxi-button name="edit"></dxi-button>
					<dxi-button name="delete"></dxi-button>
				</dxi-column>
				<dxi-column dataField="linkGroupId" dataType="number" alignment="left" caption="Group"></dxi-column>
				<dxi-column dataField="partId" dataType="number" alignment="left" caption="Part"><dxo-lookup [dataSource]="linkedParts" valueExpr="id" displayExpr="partNumber"></dxo-lookup></dxi-column>
				<dxi-column dataField="stageNumber" dataType="number" alignment="left" caption="Stage Number"></dxi-column>
				<dxi-column dataField="stageNumber" dataType="text" alignment="left" caption="OP Number" [customizeText]="stageToOP"></dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
</div>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *dxTemplate="let data of 'content'">
		<div *ngIf="popupTitle == 'Add Part to Linking Group'">
			<dx-form class="m-3" [(formData)]="popupChangePartLinkedGroupDetailsDto" [showColonAfterLabel]="true" labelLocation="top">
				<dxi-item dataField="partId" editorType="dxSelectBox" [editorOptions]="{ items: unlinkedParts, searchEnabled: true, displayExpr:'partNumber', valueExpr:'id' }" [label]="{ text: 'Part to Link' }"><dxi-validation-rule type="required" message="Part is required"></dxi-validation-rule></dxi-item>	
				<dxi-item dataField="linkedPartGroupProportion" editorType="dxNumberBox" [editorOptions]="{ format: {type:'fixedPoint', precision: 0, min: 0}, min: 1 }" [label]="{ text: 'Proportion' }"><dxi-validation-rule type="required" message="Proportion is required"></dxi-validation-rule></dxi-item>	
			</dx-form>
		</div>
	</div>
</dx-popup>