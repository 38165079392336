import { Component } from "@angular/core";
import { Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { HolidayRequestService } from "@services/holidayRequest.service";
import { Shift } from "@enums/shift.enum";

@Component({
	moduleId: module.id,
	selector: "holidayRequests-planner",
	styleUrls: ["holidayRequests-planner.css"],
	templateUrl: "holidayRequests-planner.html"
})
export class HolidayRequestsPlannerAdminComponent {
	currentDate: Date = new Date();
	dayShiftPeopleData: HolidayPlannerPerson[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [{ method: "viewHolidayRequests", text: "Holiday Requests" }];
	nightShiftPeopleData: HolidayPlannerPerson[] = [];
	peopleData: HolidayPlannerPerson[] = [];
	requestsData: any;
	selectedTabId = 0;
	statusesData: HolidayPlannerStatus[] = [{ color: "#1e90ff", id: 0, text: "Pending" }, { color: "#ff9747", id: 10, text: "Approved" }];
	tabs: Tab[] = [{ icon: "fal fa-sun", id: 0, text: "Day Shift"}, { icon: "fal fa-moon", id: 1, text: "Night Shift" }, { icon: "fal fa-watch", id: 2, text: "Both" }];
	title = "Holiday Planner";

	constructor(private router: Router, private itemService: HolidayRequestService) {
		this.getHolidayPlannerData(this.currentDate.getFullYear());
	}

	blockPopup(event: any) {
		event.cancel = true;
	}
	
	getHolidayPlannerData(year: number) {
		this.itemService.holidayPlanner(year)
			.subscribe(
				(res: any) => {
					this.peopleData = res.response.people;
					this.peopleData.sort((a,b) => a.text.localeCompare(b.text));

					this.dayShiftPeopleData = this.peopleData.filter((p) => p.shift === Shift.Day);
					this.nightShiftPeopleData = this.peopleData.filter((p) => p.shift === Shift.Night);

					this.requestsData = res.response.requests;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	selectTab(e: any) {
		this.selectedTabId = this.tabs[e.itemIndex].id;
	}

	viewHolidayRequests() {
		this.router.navigate(["/admin/holidayRequests"]);
	}
}
export class HolidayPlannerStatus {
	color = "";
	id = 0;
	text = "";
}
export class HolidayPlannerPerson {
	id = 0;
	shift: Shift = Shift.Day;
	text = "";
}
export class HolidayPlannerRequest {
	details = "";
	endDate: Date = new Date();
	ownerId = 0;
	requestStatus = 0;
	startDate: Date = new Date();
}
export class Tab {
	icon = "";
	id = 0;
	text = "";
}
