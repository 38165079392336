import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { InvoiceGridRow } from "@interfaces/invoiceGridRow.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { SalesOrder } from "@interfaces/salesOrder.interface";
import { ClientService } from "@services/client.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { ExportLogService } from "@services/exportLog.service";
import { InvoiceService } from "@services/invoice.service";
import { SalesOrderService } from "@services/salesOrder.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "salesOrders-id-invoices",
	styleUrls: ["salesOrders-id-invoices.css"],
	templateUrl: "salesOrders-id-invoices.html"
})
export class SalesOrdersIdInvoicesAdminComponent {
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [
		{ method: "viewSalesOrder", text: "Go to Sales Order" }
	];
	itemId = 0;
	items: InvoiceGridRow[] = [];
	salesOrder: SalesOrder = new SalesOrder();
	title = "";

	constructor(private router: Router, private route: ActivatedRoute, private clientService: ClientService, private clientLocationService: ClientLocationService, private exportLogService: ExportLogService, private invoiceService: InvoiceService, private salesOrderService: SalesOrderService) {
		this.route.params.subscribe((params) => {
			this.itemId = params.id;
			this.getItems();
			this.getSalesOrder();
			this.title = `Invoices for Sales Order #${params.id}`;
		});

		this.deleteRow = this.deleteRow.bind(this);
		this.generatePreviewPDF = this.generatePreviewPDF.bind(this);		
		this.viewRow = this.viewRow.bind(this);

		this.getClients();
		this.getClientLocations();
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this invoice?");
		if(result)
		{
			this.invoiceService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify("Invoice deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	generatePreviewPDF(e: any) {
		this.invoiceService.generateAndPreviewPdf(e.row.data.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Invoice_" + e.row.data.id + ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}
	
	getClientLocations() {
		this.clientLocationService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clientLocations = res.response.items;
				},
				(err) => console.log(err)
			);
	}
	
	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clients = res.response.items;
				},
				(err) => console.log(err)
			);
	}

	getItems() {
		this.invoiceService.getBySalesOrderId(this.itemId)
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getSalesOrder() {
		this.salesOrderService.getSingleById(this.itemId)
			.subscribe(
				(res: any) => this.salesOrder = res.response,
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Invoices for Sales Order " + this.itemId;
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`admin/invoices/${e.row.data.lookupId}`]);
	}

	viewSalesOrder() {		
		this.router.navigate([`admin/salesOrders/${this.salesOrder.lookupId}`]);
	}
}