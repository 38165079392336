export enum MaterialType {
	Aluminium = 10,
	BDMS = 15,
	Brass = 20,
	Copper = 30,
	D2 = 32,
	FCMS = 35,
	P20 = 36,
	Polyurethane = 37,
	StainlessSteel = 40,
	Steel = 50,
	Tufnol = 60,
	NotApplicable = 99,
}