import { AfterViewInit, Component, Input } from "@angular/core";
import { PurchaseLogGridLineItem } from "@interfaces/purchaseLogGridLineItem.interface";

@Component({
	moduleId: module.id,
	selector: "purchaseGridDetails",
	styleUrls: ["purchaseGridDetails.css"],
	templateUrl: "purchaseGridDetails.html"
})
export class PurchaseGridDetailsComponent implements AfterViewInit {
	@Input() lineItemData = "";
	lineItems: PurchaseLogGridLineItem[] = [];
  
	ngAfterViewInit() {
		this.lineItems = JSON.parse(this.lineItemData);
	}
}