<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="6">
				<dxi-item dataField="name" [colSpan]="3">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>
				<dxi-item dataField="description" [colSpan]="3"></dxi-item>
				<dxi-item dataField="hourlyLabourRate" [colSpan]="3" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2} }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>
				<dxi-item dataField="hourlyMachineRate" [colSpan]="3" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2} }">				
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>
				<dxi-item dataField="totalHourlyRate" [colSpan]="3" [editorOptions]="{ readOnly: true, format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
				<dxi-item dataField="isSingleQuantityStage" [colSpan]="3" editorType="dxCheckBox" [label]="{ text: 'Is Single Quantity Stage (e.g. Templates)' }"></dxi-item>
				<dxi-item dataField="isSampleBlank" [colSpan]="3" editorType="dxCheckBox" [label]="{ text: 'Produces a Sample Blank' }"></dxi-item>
				<dxi-item dataField="templateId" [colSpan]="3" editorType="dxSelectBox" [editorOptions]="{ items: processTemplates, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'PDF Template' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item dataField="department" [colSpan]="3" editorType="dxSelectBox" [editorOptions]="{ items: departments, displayExpr:'name', valueExpr:'id' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			</dxi-tab>			
			<dxi-tab title="Machines">				
				<dxi-item [template]="'machineTemplate'"></dxi-item>
				<div *dxTemplate="let data of 'machineTemplate'" class="row">
					<div *ngFor="let machine of machines" class="col-6 col-sm-4 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
						<div class="machine-tile">
							<div class="tile position-relative pointer" [class.active]="activeMachineIds.indexOf(machine.id) >= 0" (click)="toggleMachine(machine)">
								<span class="position-absolute start-50 translate-middle-x text-center">{{machine.cellNumber}} - {{machine.name}}</span>
							</div>
						</div>
					</div>
				</div>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>