import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class MachineEventService extends Service {
	constructor(injector: Injector) {
		super("MachineEvent", injector);
	}

	// API
	getByMachineId(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByMachineId/${id}`);
	}
}