import { PackagingLabelSize } from "@enums/packagingLabelSize.enum";

export interface DownloadShippingNoteLabels {
	labelSize?: PackagingLabelSize
	shippingNoteId: number
}

export class DownloadShippingNoteLabels implements DownloadShippingNoteLabels {
	constructor(data?: Partial<DownloadShippingNoteLabels>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}