import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class LoadingService {
	requestInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	emit(value: boolean): void {
		this.requestInProgress.next(value);
	}
}