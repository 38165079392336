import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { ChangePartLinkedGroupDetailsDto } from "@interfaces/changePartLinkedGroupDetailsDto.interface";
import { ReduceArrearsOnParts } from "@interfaces/reduceArrearsOnParts.interface";
import { UploadCSV } from "@interfaces/uploadCSV.interface";
import { Service } from "@services/service";

@Injectable()
export class PartService extends Service {
	constructor(injector: Injector) {
		super("Part", injector);
	}

	// API
	bulkReduceArrears(data: ReduceArrearsOnParts) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/BulkReduceArrears`, data);
	}

	changeLinkedGroupDetails(data: ChangePartLinkedGroupDetailsDto) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ChangeLinkedGroupDetails`, data);
	}

	duplicate(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Duplicate/${id}`, {});
	}

	getBySpecificationSupplier(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetBySpecificationSupplier/${id}`);
	}

	getLinkedParts(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetLinkedParts/${id}`);
	}

	getMasterParts() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/MasterParts`);
	}
	
	getSubPartsForMaster(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/SubPartsForMaster/${id}`);
	}
	
	getUnlinkedParts() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetUnlinkedParts`);
	}
	
	importCSV(data: UploadCSV) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ImportCSV`, data);
	}
	
	incrementBPLVersion(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/IncrementBPLVersion/${id}`, {});
	}

	purchaseSpecifications(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/PurchaseSpecifications/${id}`);
	}
}