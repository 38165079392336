import { Model } from "@interfaces/model.interface";

export interface SupplierContact extends Model {
	contactEmailAddress: string;
	contactName: string;
	displayName: string;
	jobTitle: string;
	supplierId: number;
}

export class SupplierContact implements SupplierContact {	
	contactEmailAddress = "";
	contactName = "";
	jobTitle = "";

	constructor(data?: Partial<SupplierContact>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}