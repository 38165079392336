import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import notify from "devextreme/ui/notify";
import { EdiForecastService } from "@services/ediForecast.service";

@Component({
	moduleId: module.id,
	selector: "ediForecasts",
	styleUrls: ["ediForecasts.css"],
	templateUrl: "ediForecasts.html"
})
export class EdiForecastsAdminComponent {
	deleteEnabled = true;
	editEnabled = true;
	forecastTable: any;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	itemType = "Part Forecast"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	title: string;
	
	constructor(private router: Router, private itemService: EdiForecastService) {
		this.title = `${this.itemType}s`;
		this.getForecastTable();
	}

	getForecastTable() {
		this.itemService.getForecastTable()
			.subscribe(
				(res: any) => {
					this.forecastTable = res.response;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}
}