import { Model } from "@interfaces/model.interface";
import { Part } from "./part.interface";
import { SalesOrder } from "./salesOrder.interface";
import { SalesOrderPart } from "./salesOrderPart.interface";
import { WorkOrderStage } from "./workOrderStage.interface";

export interface WorkOrder extends Model {
	cocNumber: string;
	currentStage: number;
	dateRequired: Date;
	isQLoop: boolean;
	linkedWorkOrderCode: string;
	maximumQuantity: number; // This isn't mapped to the object in the API, just used on the front end when creating Work Orders.
	originalWorkOrderId?: number;
	parentWorkOrderId?: number;
	part: Part;
	partId: number;
	quantity: number;
	salesOrder: SalesOrder;
	salesOrderId: number;
	salesOrderPart?: SalesOrderPart;
	salesOrderPartId?: number;
	specialInstructions: string;
	supportingDocuments: string;
	workOrderComplete: boolean;
	workOrderStages: WorkOrderStage[];
}

export class WorkOrder implements WorkOrder {
	constructor(data?: Partial<WorkOrder>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}