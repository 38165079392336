import { Model } from "@interfaces/model.interface";

export interface Site extends Model {
	addressLine1: string;
	addressLine2: string;
	colour: string;
	county: string;
	floorPlanHeight: number;
	floorPlanWidth: number;
	name: string;
	postcode: string;
	town: string;
}

export class Site implements Site {
	colour = "#ffffff";
	floorPlanHeight = 900;
	floorPlanWidth = 1000;

	constructor(data?: Partial<Site>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}