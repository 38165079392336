import { Component } from "@angular/core";
import { ChangePassword } from "@interfaces/changePassword.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { UpdateProfile } from "@interfaces/updateProfile.interface";
import { AuthService } from "@services/auth.service";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Router } from "@angular/router";

@Component({
	moduleId: module.id,
	selector: "userSettings",
	styleUrls: ["userSettings.css"],
	templateUrl: "userSettings.html",
})
export class UserSettingsAdminComponent {
	changePasswordButton = {
		text: "Change Password",
		type: "default",
		useSubmitBehavior: true
	}
	cp: ChangePassword = new ChangePassword();
	headerPrimaryButtons: AdminHeaderButton[] = [{ icon: "fal fa-calendar", method: "viewHolidays", text: "Holidays" }];
	member: UserProfile = new UserProfile();
	memberDto: UpdateProfile = new UpdateProfile();
	message = "";
	title = "User Settings";
	titles: string[] = [];
	toastVisible  = false;
	type = "info";
	updateProfileButton = {
		text: "Update Profile",
		type: "default",
		useSubmitBehavior: true
	}

	constructor(private router: Router, private authService: AuthService) {
		this.getTitles();
		this.getUser();
	}

	changePassword() {
		alert(this.cp.password);
	}

	getTitles() {
		this.authService.getTitles()
			.subscribe(
				(res: any) => this.titles = res.response,
				(err) => console.log(err)
			);
	}

	getUser() {
		this.authService.getCurrentUser()
			.subscribe(
				(res: any) => this.member = res.response,
				(err) => console.log(err)
			);
		//this.memberOriginal = JSON.parse(JSON.stringify(this.member));
	}

	handleProfileSubmit(e: any) {
		this.memberDto.address1 = this.member.address1;
		this.memberDto.address2 = this.member.address2;
		this.memberDto.postcode = this.member.postcode;
		this.memberDto.town = this.member.town;
		this.memberDto.email = this.member.email;
		this.memberDto.firstName = this.member.firstName;
		this.memberDto.lastName = this.member.lastName;
		this.memberDto.jobTitle = this.member.jobTitle;
		this.memberDto.telephoneNumber  = this.member.telephoneNumber;
		this.memberDto.title  = this.member.title;
		this.memberDto.userId  = this.member.userId;

		this.authService.updateAccount(this.memberDto)
			.subscribe(
				() => this.showToast("Profile Updated", "success"),
				() => this.showToast("Something went wrong", "error")
			);
		e.preventDefault();
	}

	handleSubmit(e: any) {
		this.authService.updatePassword(this.cp)
			.subscribe(
				() => {
					this.showToast("Password Updated", "success");
					this.cp = new ChangePassword();
				},
				() => this.showToast("Something went wrong", "error")
			);
		e.preventDefault();
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	showToast(message: string, type: string) {
		notify({
			displayTime: 5000,
			message: message,
			type: type
		});
	}

	viewHolidays() {
		this.router.navigate(["/admin/userHolidays"]);
	}
}