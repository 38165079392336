import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "@environments/environment";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";
import { ChangePassword } from "@interfaces/changePassword.interface";
import { AuthService } from "@services/auth.service";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "resetPassword",
	styleUrls: ["resetPassword.css"],
	templateUrl: "resetPassword.html"
})
export class ResetPasswordAdminComponent {
	companyName = "";
	copyright = "";
	passwordComplexityRules = "";
	resetPasswordDetails: ChangePassword = new ChangePassword();
	resetPasswordToken: Guid = Guid.parse(Guid.EMPTY);
	validationErrors = {confirmPassword: "", password: ""};	
	
	constructor(private route: ActivatedRoute, private authService: AuthService) {
		this.companyName = this.getEnvironmentProperty("companyLongName");
		this.copyright = "© Copyright " + this.companyName + " " + new Date().getFullYear();
		this.getPasswordComplexityRules();
		this.route.params
			.subscribe((params) => {
				this.validateToken(params.id);
			});
	}

	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment["build"][property];
	}

	getPasswordComplexityRules() {
		this.authService.getPasswordComplexityRules()
			.subscribe(
				(res: any) => {
					this.passwordComplexityRules = res.response;
				},
				(err) => console.log(err)
			);
	}

	submitData() {
		this.validationErrors = {confirmPassword: "", password: ""};
		let validationFail = false;

		if (this.resetPasswordDetails.password === undefined || this.resetPasswordDetails.password === null || this.resetPasswordDetails.password === "") {
			this.validationErrors.password = "Password must not be blank.";
			validationFail = true;
		}

		if (this.resetPasswordDetails.confirmPassword === undefined || this.resetPasswordDetails.confirmPassword === null || this.resetPasswordDetails.confirmPassword === "") {
			this.validationErrors.confirmPassword = "Password Confirmation must not be blank.";
			validationFail = true;
		} else if(this.resetPasswordDetails.confirmPassword !== this.resetPasswordDetails.password){
			this.validationErrors.confirmPassword = "Password Confirmation must match password.";
			validationFail = true;
		}

		if (validationFail === true) { return; }

		this.authService.resetPassword(this.resetPasswordToken, this.resetPasswordDetails)
			.subscribe(
				() => {
					notify("Your account has been completed", "success", 5000),
					window.location.href = "/admin";
				},
				(err) => {
					this.validationErrors.password = err.password;
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	validateToken(id: Guid) {
		this.authService.validateResetPaswordToken(id)
			.subscribe(
				() => {
					this.resetPasswordToken = id;
				},
				() => {
					window.location.href = "/admin";
				}
			);
	}

	windowWidthCheck() {
		return window.innerWidth >= 768;
	}
}