import { Component } from "@angular/core";
import { environment } from "@environments/environment";

@Component({
	moduleId: module.id,
	selector: "header",
	styleUrls: ["header.css"],
	templateUrl: "header.html"
})
export class HeaderComponent {
	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment[property];
	}
}