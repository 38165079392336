<dx-form class="m-3" [(formData)]="audit" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2"  [readOnly]="true">
	<dxi-item itemType="group" caption="Audit Details" [colSpan]="2" [colCount]="2">
		<dxi-item dataField="id" [colSpan]="2" [label]="{ text: 'Audit ID' }"></dxi-item>
		<dxi-item dataField="detail.Action"></dxi-item>
		<dxi-item dataField="model"></dxi-item>
		<dxi-item dataField="userId"></dxi-item>
		<dxi-item dataField="createdAt"></dxi-item>
	</dxi-item>
	<dxi-item itemType="group" caption="Original Values"></dxi-item>
	<dxi-item itemType="group" caption="New Values"></dxi-item>
	<dxi-item itemType="group" caption="Request" [colSpan]="2" [colCount]="5">
		<dxi-item [colSpan]="1" dataField="requestMethod"></dxi-item>
		<dxi-item [colSpan]="4"  dataField="requestUri"></dxi-item>
		<dxi-item [colSpan]="5" dataField="requestIp" [label]="{ text: 'Request IP' }"></dxi-item>
		<dxi-item dataField="JSON.stringify(requestBody);" [colSpan]="5" [label]="{ text: 'Request Body' }"></dxi-item>
	</dxi-item>
	
<!--	<dxi-item dataField="slug" [label]="{ text: 'SLUG' }"></dxi-item>
	<dxi-item dataField="location" [colSpan]="2"></dxi-item>
	<dxi-item dataField="longitude"></dxi-item>
	<dxi-item dataField="latitude"></dxi-item>
	<dxi-item dataField="siteIdDD" [label]="{ text: 'Direct Debit Id' }"></dxi-item>
	<dxi-item dataField="phoneNumber" [editorOptions]="{ mask: '00000 000000'}"></dxi-item> -->
</dx-form>
<!-- 
	Gym Details
	Page Content
	(Gym) Facilities
	Page SEO
 -->