import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { EdiOrderDailyQuantities } from "@interfaces/ediOrderDailyQuantities.interface";
import { GenerateEdiShippingNote } from "@interfaces/generateEdiShippingNote.interface";
import { EdiOrderService } from "@services/ediOrder.service";
import { EdiShippingNoteService } from "@services/ediShippingNote.service";

@Component({
	moduleId: module.id,
	selector: "ediOrderDayQuantities",
	styleUrls: ["ediOrderDayQuantities.css"],
	templateUrl: "ediOrderDayQuantities.html"
})
export class EdiOrderDayQuantitiesComponent {
	@Input() dock!: string;
	@Input() isSuperAdmin!: boolean;
	@Input() locationId!: number;
	@Input() locationName!: string;
	@Input() quantities!: EdiOrderDailyQuantities;
	
	isDeleted = false;
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupGenerateShippingNote: GenerateEdiShippingNote = new GenerateEdiShippingNote();
	popupTitle = "";
	popupVisible = false;

	constructor(private router: Router, private ediOrderService: EdiOrderService, private ediShippingNoteService: EdiShippingNoteService) {}

	closePopup() {
		this.popupVisible = false;
		this.popupGenerateShippingNote = new GenerateEdiShippingNote();
	}

	createShippingNote() {
		this.ediShippingNoteService.generateShippingNote(this.popupGenerateShippingNote)
			.subscribe(
				(res: any) =>  {
					const shippingNoteId = res.response.lookupId;
					this.router.navigate([`admin/edi-shipping-notes/${shippingNoteId}_edit`]);
				},
				(err) => console.log(err)
			);

		return false;
	}

	createShippingNotePopup() {
		this.popupTitle = "Create Shipping Note";
		this.popupGenerateShippingNote = new GenerateEdiShippingNote();
		this.popupGenerateShippingNote.clientLocationId = this.locationId;
		this.popupGenerateShippingNote.clientLocationName = this.locationName;
		this.popupGenerateShippingNote.dock = this.dock;
		this.popupGenerateShippingNote.startDate = this.quantities.date;
		this.popupGenerateShippingNote.endDate = this.quantities.date;
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.createShippingNote(), text: "Create Shipping Note" };

		return false;
	}

	deleteEdiOrder() {
		if (this.quantities.ediOrderId == null || this.isSuperAdmin == false) {
			return false;
		}

		if (confirm("Are you sure you wish to delete this order? This cannot be undone. Note that weekly totals will be unaffected until the page is refreshed.") == false) {
			return false;
		}
		
		this.ediOrderService.deleteSingleById(this.quantities.ediOrderId)
			.subscribe(
				() =>  {
					this.isDeleted = true;
				},
				(err) => console.log(err)
			);

		return false;
	}

	editShippingNote() {
		this.router.navigate([`admin/edi-shipping-notes/${this.quantities.shippingNoteId}_edit`]);
		return false;
	}

	viewShippingNote() {
		this.router.navigate([`admin/edi-shipping-notes/${this.quantities.shippingNoteId}`]);
		return false;
	}
}