import { Model } from "@interfaces/model.interface";

export interface HolidayAllowance extends Model {
	allowance: number;
	userProfileId: number;
	year: number;
}

export class HolidayAllowance implements HolidayAllowance {	
	constructor(data?: Partial<HolidayAllowance>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}