import { Injectable, Injector } from "@angular/core";
import { Guid } from "guid-typescript";
import { environment } from "@environments/environment";
import { Service } from "@services/service";
import { UserProfile } from "@interfaces/userProfile.interface";
import { CompleteAccount } from "@interfaces/completeAccount.interface";
import { ChangeUserProfileDepartment } from "@interfaces/changeUserProfileDepartment.interface";
import { Department } from "@enums/department";

@Injectable()
export class UserProfileService extends Service {
	constructor(injector: Injector) {
		super("UserProfile", injector);
	}

	// API
	adminCreate(data: UserProfile) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/AdminCreate`, data);
	}

	byCurrentDepartment(department: Department) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ByCurrentDepartment/${department}`);
	}

	bySite(siteId: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/BySite/${siteId}`);
	}
	
	changeCurrentDepartment(data: ChangeUserProfileDepartment) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ChangeCurrentDepartment`, data);
	}
	
	completeAccount(token: Guid, data: CompleteAccount) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CompleteUserProfile/${token}`, data);
	}

	getActiveUsers() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/AllActiveUsers`);
	}

	getCurrentUserProfile() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetCurrentUserProfile`);		
	}

	getIncompleteAccount(token: Guid) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/IncompleteProfileDetails/${token}`);
	}
	
	getSupervisors() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetSupervisors`);
	}
	
	getUserProfileAdmin(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/AdminGetProfileAccount/${id}`);
	}
	
	getWorkforce() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetWorkforce`);
	}
	
	resetCurrentDepartment(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ResetCurrentDepartment/${id}`, {});
	}
}