import { Component } from "@angular/core";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { HolidaySettings } from "@interfaces/holidaySettings.interface";
import { HolidaySettingsService } from "@services/holidaySettings.service";

@Component({
	moduleId: module.id,
	selector: "holidaySettings",
	styleUrls: ["holidaySettings.css"],
	templateUrl: "holidaySettings.html"
})
export class HolidaySettingsAdminComponent {
	createEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	item: HolidaySettings = new HolidaySettings();
	itemType = "Holiday Settings";
	title: string = "Editing " + this.itemType;

	constructor(private itemService: HolidaySettingsService) {
		this.buildButtons();
		this.getItem();
	}

	buildButtons() {
		this.headerPrimaryButtons = [
			{ method: "updateItem", text: "Save Changes" },
			{ method: "cancelEditing", text: "Cancel Changes" }
		];
	}

	cancelEditing() {
		this.getItem();
	}
	
	getItem() {
		this.itemService.getSettings()
			.subscribe(
				(res: any) => {
					this.item = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateItem() {
		this.itemService.updateSettings(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}