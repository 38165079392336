import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class SupplierContactService extends Service {
	constructor(injector: Injector) {
		super("SupplierContact", injector);
	}
	
	// API
	getContactsBySupplierId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetContactsBySupplierId/${id}`);
	}
}