<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2"  [readOnly]="readOnly">
			<dxi-item dataField="templateName"></dxi-item>
			<dxi-item dataField="senderName"></dxi-item>
			<dxi-item dataField="senderNumber"></dxi-item>
			<dxi-item dataField="JSON.stringify(design)" [colSpan]="2"></dxi-item>
			<dxi-item dataField="html" [colSpan]="2"></dxi-item>
			<dxi-item>
				<dx-html-editor [(value)]="item.html">
				  <dxo-toolbar>
					<dxi-item formatName="undo"></dxi-item>
					<dxi-item formatName="redo"></dxi-item>
					<dxi-item formatName="separator"></dxi-item>
					<dxi-item
					  formatName="size"
					  [formatValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
					></dxi-item>
					<dxi-item
					  formatName="font"
					  [formatValues]="['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']"
					></dxi-item>
					<dxi-item formatName="separator"></dxi-item>
					<dxi-item formatName="bold"></dxi-item>
					<dxi-item formatName="italic"></dxi-item>
					<dxi-item formatName="strike"></dxi-item>
					<dxi-item formatName="underline"></dxi-item>
					<dxi-item formatName="separator"></dxi-item>
					<dxi-item formatName="alignLeft"></dxi-item>
					<dxi-item formatName="alignCenter"></dxi-item>
					<dxi-item formatName="alignRight"></dxi-item>
					<dxi-item formatName="alignJustify"></dxi-item>
					<dxi-item formatName="separator"></dxi-item>
					<dxi-item
					  formatName="header"
					  [formatValues]="[false, 1, 2, 3, 4, 5]"
					></dxi-item>
					<dxi-item formatName="separator"></dxi-item>
					<dxi-item formatName="orderedList"></dxi-item>
					<dxi-item formatName="bulletList"></dxi-item>
					<dxi-item formatName="separator"></dxi-item>
					<dxi-item formatName="color"></dxi-item>
					<dxi-item formatName="background"></dxi-item>
					<dxi-item formatName="separator"></dxi-item>
					<dxi-item formatName="link"></dxi-item>
					<dxi-item formatName="image"></dxi-item>
					<dxi-item formatName="separator"></dxi-item>
					<dxi-item formatName="clear"></dxi-item>
					<dxi-item formatName="codeBlock"></dxi-item>
					<dxi-item formatName="blockquote"></dxi-item>
				  </dxo-toolbar>
				</dx-html-editor>
			  </dxi-item>

	</dx-form>
</div>