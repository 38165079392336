import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class RequestForQuoteService extends Service {
	constructor(injector: Injector) {
		super("RequestForQuote", injector);
	}

	// API
	getForSalesOrder(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetForSalesOrder/${id}`);
	}

	send(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Send/${id}`, {});
	}
	
	sendRFQsForSalesOrder(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/SendRFQsForSalesOrder/${id}`, {});
	}
}