import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { HolidayRequestStatus } from "@enums/holdayRequestStatus.enum";
import { HolidayTime } from "@enums/holidayTime.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { ChangeHolidayRequestStatus } from "@interfaces/changeHolidayRequestStatus.interface";
import { HolidayRequest } from "@interfaces/holidayRequest.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AuthService } from "@services/auth.service";
import { HolidayRequestService } from "@services/holidayRequest.service";
import { UserProfileService } from "@services/userProfile.service";

@Component({
	moduleId: module.id,
	selector: "holidayRequests-id",
	styleUrls: ["holidayRequests-id.css"],
	templateUrl: "holidayRequests-id.html"
})
export class HolidayRequestsIdAdminComponent {
	createEnabled = false;
	currentUser: UserProfile = new UserProfile();
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	holidayRequestStatuses: any;
	holidayTimes: any;
	holidayTimesEnd: any;
	holidayTimesStart: any;
	item: HolidayRequest = new HolidayRequest();
	itemId = "";
	itemType = "Holiday Request";
	overlappingRequests: HolidayRequest[] = [];
	people: UserProfile[] = [];
	popupAddButtonOptions: any;
	popupChangeHolidayRequestStatus: ChangeHolidayRequestStatus = new ChangeHolidayRequestStatus();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupHolidayRequestStatuses: any;
	popupTitle = "";
	popupVisible = false;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private itemService: HolidayRequestService, private userProfileService: UserProfileService) {
		this.holidayRequestStatuses = GetPropertiesFromEnum(HolidayRequestStatus);
		this.holidayTimes = GetPropertiesFromEnum(HolidayTime);
		this.holidayTimesEnd = GetPropertiesFromEnum(HolidayTime);
		this.holidayTimesStart = GetPropertiesFromEnum(HolidayTime);
		
		// When requesting a holiday, the End time cannot be 'Start of Day' so remove it from the array. Same with Start time and 'End of Day'.
		this.holidayTimesEnd.splice(0, 1);
		this.holidayTimesStart.splice(2, 1);

		this.getPeople();
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0], "edit");
						this.edit();
					} else {
						this.getItem(params.id, "view");
					}
				}
			});
	}

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view" && this.currentUser.id > 0) {
			this.headerPrimaryButtons = [];
			if (this.item.requestedById != this.currentUser.id) {
				this.headerPrimaryButtons.push({ icon: "fal fa-calendar-check", method: "changeHolidayRequestStatusPopup", text: "Change Status" });
			}
			this.headerPrimaryButtons.push({ method: "edit", text: "Edit" }, { method: "close", text: "Close" });
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	changeHolidayRequestStatus() {
		this.itemService.changeStatus(this.popupChangeHolidayRequestStatus)
			.subscribe(
				() => {
					this.closePopup();
					this.getItem(this.item.id, "view");
				},
				(err: any) => {
					notify("Could not change holiday request status. Please double check the details you have entered and ensure all rules have been followed.", "Error", 5000);
					console.log(err);
				}
			);
	}

	changeHolidayRequestStatusPopup() {
		this.popupTitle = "Change Request Status";
		this.popupChangeHolidayRequestStatus = new ChangeHolidayRequestStatus();
		this.popupChangeHolidayRequestStatus.holidayRequestId = this.item.id;
		this.popupHolidayRequestStatuses = GetPropertiesFromEnum(HolidayRequestStatus);
		this.popupChangeHolidayRequestStatus.holidayRequestStatus = HolidayRequestStatus.Approved;

		// Always get rid of the Pending option
		this.popupHolidayRequestStatuses.splice(0, 1);
		switch (this.item.requestStatus) {
			case HolidayRequestStatus.Approved:
				// if the holiday was already approved, it can only be revoked, so remove Approved and Declined
				this.popupHolidayRequestStatuses.splice(0, 2);
				this.popupChangeHolidayRequestStatus.holidayRequestStatus = HolidayRequestStatus.Revoked;
				break;
			case HolidayRequestStatus.Declined:
			case HolidayRequestStatus.Revoked:
				// if the holiday was already declined or Revoked, it can only be approved, so remove Declined and Revoked
				this.popupHolidayRequestStatuses.splice(1, 2);
				break;
			case HolidayRequestStatus.Pending:
				// if the holiday is pending, it can only be Approved or Declined, so remove Revoked
				this.popupHolidayRequestStatuses.splice(2, 1);
				break;
		}

		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.changeHolidayRequestStatus(), text: "Change Status" };
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
		this.popupChangeHolidayRequestStatus = new ChangeHolidayRequestStatus();
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new HolidayRequest();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	getCurrentUser(mode: string)
	{
		this.authService.getCurrentUser()
			.subscribe(
				(res: any) => {
					this.currentUser = res.response;
					this.buildButtons(mode);
				},
				(err) => console.log(err)
			);
	}
	
	getItem(itemId: number, mode: string) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getCurrentUser(mode);
					this.getOverlappingRequests(itemId);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}
	
	getOverlappingRequests(itemId: number) {
		this.itemService.overlappingHolidayRequestsFor(itemId)
			.subscribe(
				(res: any) => {
					this.overlappingRequests = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}