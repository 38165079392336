import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { PurchaseType } from "@enums/purchaseType.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Supplier } from "@interfaces/supplier.interface";
import { SupplierContact } from "@interfaces/supplierContact.interface";
import { SupplierDocument } from "@interfaces/supplierDocument.interface";
import { SupplierPurchaseType } from "@interfaces/supplierPurchaseType.interface";
import { FileService } from "@services/file.service";
import { SupplierService } from "@services/supplier.service";
import { SupplierContactService } from "@services/supplierContact.service";
import { SupplierDocumentService } from "@services/supplierDocument.service";
import { SupplierPurchaseTypeService } from "@services/supplierPurchaseType.service";
import { SelectablePurchaseType } from "@interfaces/selectablePurchaseType.interface";
import { SupplierLocationService } from "@services/supplierLocation.service";
import { SupplierLocation } from "@interfaces/supplierLocation.interface";

@Component({
	moduleId: module.id,
	selector: "suppliers-id",
	styleUrls: ["suppliers-id.css"],
	templateUrl: "suppliers-id.html"
})
export class SupplierIdAdminComponent {
	addDocumentPopupAddButtonOptions: any = { onClick: () => this.createSupplierDocument(), text: "Add Document" };
	addDocumentPopupCloseButtonOptions: any = { onClick: () => this.closeAddDocumentPopup(), text: "Close" };
	addDocumentPopupTitle = "Add Document";
	addDocumentPopupVisible = false;
	contacts: SupplierContact[] = [];
	createEnabled = true;
	documents: SupplierDocument[] = [];
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: Supplier = new Supplier();
	itemId = 0;
	itemType = "Supplier";
	locations: SupplierLocation[] = [];
	newSupplierDocument: SupplierDocument = new SupplierDocument();
	popupAddButtonOptions: any = { onClick: () => this.addNewContact(), text: "Add Contact" };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupContact: SupplierContact = new SupplierContact();
	popupLocation: SupplierLocation = new SupplierLocation();
	popupTitle = "Add New Contact";
	popupType = "";
	popupVisible = false;
	purchaseTypes: any;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	selectablePurchaseTypes: SelectablePurchaseType[] = [];
	statuses: any;
	supplierPurchaseTypes: SupplierPurchaseType[] = [];
	title: string = "View " + this.itemType;
	uploadEnabled = false;

	constructor(private router: Router, private route: ActivatedRoute, private fileService: FileService, private itemService: SupplierService, private supplierContactsService: SupplierContactService, private supplierDocumentService: SupplierDocumentService, private supplierLocationService: SupplierLocationService, private supplierPurchaseTypeService: SupplierPurchaseTypeService) {
		this.buildButtons("view");

		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.itemId = params.id.split("_")[0];
					this.getItem(this.itemId);
					this.edit();
				} else {
					this.itemId = params.id;
					this.getItem(params.id);
				}
			} else {
				this.create();
			}
		});

		this.downloadDocument = this.downloadDocument.bind(this);
		this.deleteContact = this.deleteContact.bind(this);
		this.editContactPopup = this.editContactPopup.bind(this);
		this.editLocationPopup = this.editLocationPopup.bind(this);
		this.setPrimaryBilling = this.setPrimaryBilling.bind(this);
		this.setPrimaryDelivery = this.setPrimaryDelivery.bind(this);
		this.togglePurchaseType = this.togglePurchaseType.bind(this);
		this.uploadChunk = this.uploadChunk.bind(this);
	}

	addDocumentPopup() {
		this.addDocumentPopupVisible = true;
	}

	addNewContact() {
		this.popupContact.supplierId = this.item.id;
		this.supplierContactsService.createSingle(this.popupContact)
			.subscribe(
				() => {
					this.closePopup();
					this.getContacts();
				},
				(err: any) => console.log(err)
			);
	}

	addNewContactPopup() {
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewContact(), text: "Add Contact" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupContact = new SupplierContact();
		this.popupTitle = "Add New Contact";
		this.popupType = "Contact";
	}

	addNewLocation() {
		this.popupLocation.supplierId = this.item.id;
		this.supplierLocationService.createSingle(this.popupLocation)
			.subscribe(
				() => {
					this.closePopup();
					this.getLocations();
				},
				(err: any) => console.log(err)
			);
	}

	addNewLocationPopup() {
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewLocation(), text: "Add Location" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupLocation = new SupplierLocation();
		this.popupTitle = "Add New Location";
		this.popupType = "Location";
	}

	boolYesNo(cellInfo: any){		
		if(cellInfo.value === true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	}

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewContactPopup", text: "Add New Contact" },
				{ method: "addDocumentPopup", text: "Add Document" },
				{ method: "addNewLocationPopup", text: "Add Location" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewContactPopup", text: "Add New Contact" },
				{ method: "addDocumentPopup", text: "Add Document" },
				{ method: "addNewLocationPopup", text: "Add Location" }
			];	
		}
	}

	cancelEditing() {
		this.uploadEnabled = false;
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}
	
	closeAddDocumentPopup() {
		this.addDocumentPopupVisible = false;
		this.newSupplierDocument = new SupplierDocument();
		// clear out an uploaded file?
	}
	
	closePopup() {
		this.popupVisible = false;
	}

	create() {
		this.uploadEnabled = false;
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new Supplier();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	createSupplierDocument() {
		this.newSupplierDocument.supplierId = this.itemId;
		this.supplierDocumentService.createSingle(this.newSupplierDocument)
			.subscribe(
				() => {
					notify("Successfully Added New Document " + this.newSupplierDocument.fileName, "success", 5000);
					this.newSupplierDocument = new SupplierDocument();
					this.getDocuments();
					this.addDocumentPopupVisible = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteContact(e: any) {
		if (confirm("Are you sure you wish to delete this contact? Note: Contacts attached to Purchase Logs cannot be deleted.") == false) {
			return;
		}

		this.supplierContactsService.deleteSingleById(e.row.data.id).subscribe(
			() => {
				notify("Successfully Deleted Contact", "success", 5000);
				this.getContacts();
			},
			(err) => {
				notify("Could not delete contact - " + err, "error", 5000);
			}
		);
	}

	deleteLocation(e: any) {
		if (confirm("Are you sure you wish to delete this location?") == false) {
			return;
		}

		this.supplierLocationService.deleteSingleById(e.row.data.id).subscribe(
			() => {
				notify("Successfully Deleted Location", "success", 5000);
				this.getLocations();
			},
			(err) => {
				notify("Could not delete Location - " + err, "error", 5000);
			}
		);
	}

	downloadDocument(e: any) {
		this.fileService.downloadFile(e.row.data.fileLookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	editContactPopup(e: any) {
		this.supplierContactsService.getSingleById(e.row.data.id).subscribe(
			(res: any) => {
				this.popupVisible = true;
				this.popupAddButtonOptions = { onClick: () => this.updateContact(), text: "Save Changes" };
				this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
				this.popupContact = res.response;
				this.popupTitle = "Edit Contact";
				this.popupType = "Contact";
			},
			(err) => {
				console.log(err);
				notify("Could not edit contact", "error", 5000);
			}
		);
	}

	editLocationPopup(e: any) {
		this.supplierLocationService.getSingleById(e.row.data.id).subscribe(
			(res: any) => {
				this.popupVisible = true;
				this.popupAddButtonOptions = { onClick: () => this.updateLocation(), text: "Save Changes" };
				this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
				this.popupLocation = res.response;
				this.popupTitle = "Edit Location";
				this.popupType = "Location";
			},
			(err) => {
				console.log(err);
				notify("Could not edit location", "error", 5000);
			}
		);
	}

	getContacts() {
		this.supplierContactsService.getContactsBySupplierId(this.item.id)
			.subscribe(
				(res: any) => {
					this.contacts = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getDocuments() {
		this.supplierDocumentService.getDocumentsForSupplierId(this.item.id)
			.subscribe(
				(res: any) => this.documents = res.response,
				(err) => console.log(err)
			);
	}

	getItem(itemId: number) {
		this.getSelectablePurchaseTypes();
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getContacts();
					this.getDocuments();
					this.getLocations();
					this.getSupplierPurchaseTypes();
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
		this.uploadEnabled = true;
	}

	getLocations() {
		this.supplierLocationService.getLocationsBySupplierId(this.item.id)
			.subscribe(
				(res: any) => this.locations = res.response,
				(err) => console.log(err)
			);
	}

	getSelectablePurchaseTypes() {
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);
		this.purchaseTypes.forEach((purchaseType: { id: PurchaseType; name: string; }) => {
			this.selectablePurchaseTypes.push({ isSelected: false, name: purchaseType.name, value: purchaseType.id });
		});
	}

	getSupplierPurchaseTypes() {		
		this.supplierPurchaseTypeService.getPurchaseTypesForSupplierId(this.item.id)
			.subscribe(
				(res: any) => {
					this.supplierPurchaseTypes = res.response;
					this.supplierPurchaseTypes.forEach((supplierPurchaseType) => {
						const index = this.selectablePurchaseTypes.findIndex(((p: { value: PurchaseType; }) => p.value === supplierPurchaseType.purchaseType));
						this.selectablePurchaseTypes[index].isSelected = true;
					});
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	isTogglePurchaseTypeOffVisible(e: any) {
		return e.row.data.isSelected === true;
	}

	isTogglePurchaseTypeOnVisible(e: any) {
		return e.row.data.isSelected === false;
	}

	setPrimaryBilling(e: any) {
		this.supplierLocationService.setPrimaryBilling(e.row.data.id).subscribe(
			() => {
				notify("Successfully Updated Primary Billing Location", "success", 5000);
				this.getLocations();
			},
			(err) => {
				console.log(err);
				notify("Could not edit location", "error", 5000);
			}
		);
	}

	setPrimaryDelivery(e: any) {
		this.supplierLocationService.setPrimaryDelivery(e.row.data.id).subscribe(
			() => {
				notify("Successfully Updated Primary Delivery Location", "success", 5000);
				this.getLocations();
			},
			(err) => {
				console.log(err);
				notify("Could not edit location", "error", 5000);
			}
		);
	}

	togglePurchaseType(e: any) {
		this.supplierPurchaseTypeService.togglePurchaseType(this.item.id, e.row.data.value)
			.subscribe(
				() => {
					const index = this.selectablePurchaseTypes.findIndex((p => p.value === e.row.data.value));
					this.selectablePurchaseTypes[index].isSelected = !this.selectablePurchaseTypes[index].isSelected;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateContact() {
		this.supplierContactsService.updateSingleById(this.popupContact.id, this.popupContact)
			.subscribe(
				() => {
					notify("Successfully Updated Contact", "success", 5000);					
					this.closePopup();
					this.getContacts();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => notify("Successfully Updated " + this.itemType, "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateLocation() {
		this.supplierLocationService.updateSingleById(this.popupLocation.id, this.popupLocation)
			.subscribe(
				() => {
					notify("Successfully Updated Location", "success", 5000);					
					this.closePopup();
					this.getLocations();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadChunk(file: File) {
		const uploadData = new FormData();
		uploadData.append("files", file);
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.newSupplierDocument.fileLookupId = res.response[0].lookupId;
					this.newSupplierDocument.fileName = res.response[0].name;
				},
				(err) => console.log(err)
			);
	}
}