<admin-header [title]="title"></admin-header>
<dx-data-grid class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="eventLogsMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column dataField="createdAt" dataType="date" alignment="left" caption="Export Date" format="dd/MM/yyyy HH:mm:ss"></dxi-column>
	<dxi-column dataField="gridName" dataType="string" alignment="left" caption="Grid Exported"></dxi-column>
	<dxi-column dataField="userProfileId" caption="User"><dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup></dxi-column>
</dx-data-grid>