<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="year-selector">
	Select Year: <dx-select-box [items]="years" [(value)]="year" (onValueChanged)="changeYear($event)"></dx-select-box>
</div>
<div class="form-container">
	<dx-form class="m-3" [colCount]="1" [(formData)]="holidayAllowanceData" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="true">
		<dxi-item itemType="group" caption="Your Allowance" [colSpan]="1" [colCount]="4">
			<dxi-item [colSpan]="1" dataField="totalAllowance"></dxi-item>
			<dxi-item [colSpan]="1" dataField="approvedHolidays"></dxi-item>
			<dxi-item [colSpan]="1" dataField="pendingHolidays"></dxi-item>
			<dxi-item [colSpan]="1" dataField="remainingAllowance"></dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="Company Holidays" [colSpan]="1" [colCount]="1">
			<dx-data-grid #fixingsGridContainer class="data-grid" [dataSource]="companyHolidays" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="holCompany"></dxo-state-storing>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxi-column dataField="startDate" dataType="date" alignment="left" caption="Start Date" format="dd/MM/yyyy" sortOrder="asc"></dxi-column>
				<dxi-column dataField="endDate" dataType="date" alignment="left" caption="End Date" format="dd/MM/yyyy"></dxi-column>
				<dxi-column dataField="length" dataType="number" alignment="left" caption="Length (days)"></dxi-column>
			</dx-data-grid>
		</dxi-item>
		<dxi-item itemType="group" caption="Your Holiday Requests" [colSpan]="1" [colCount]="1">
			<dx-data-grid #fixingsGridContainer class="data-grid" [dataSource]="holidayRequests" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="holUserReq"></dxo-state-storing>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxi-column dataField="startDate" dataType="date" alignment="left" caption="Start Date" format="dd/MM/yyyy" sortOrder="asc"></dxi-column>
				<dxi-column dataField="startTime" dataType="number" alignment="left" caption="Start Time"><dxo-lookup [dataSource]="holidayTimes" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
				<dxi-column dataField="endDate" dataType="date" alignment="left" caption="End Date" format="dd/MM/yyyy"></dxi-column>
				<dxi-column dataField="endTime" dataType="number" alignment="left" caption="End Time"><dxo-lookup [dataSource]="holidayTimes" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
				<dxi-column dataField="holidayLength" dataType="number" alignment="left" caption="Length (days)"></dxi-column>
				<dxi-column dataField="requestStatus" dataType="number" alignment="left" caption="Status"><dxo-lookup [dataSource]="holidayRequestStatuses" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<dx-form class="m-3" [(formData)]="popupHolidayRequest" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
				<dxi-item [colSpan]="2">
					When requesting a holiday, a minimum of <strong>{{holidaySettings.shortHolidayNoticeDays}} days notice</strong> is required.<br />
					If your holiday is longer than <strong>{{holidaySettings.shortHolidayMaximumDays}} days</strong>, this increases to <strong>{{holidaySettings.longHolidayNoticeDays}} days notice</strong>.<br />
					Your holiday may not overlap any other pending or approved holidays you have requested, or any company holidays.<br />
					The length of your holiday may not exceed your remaining allowance of <strong>{{holidayAllowanceData.remainingAllowance}} days</strong>.<br />
					If your holiday overlaps the end of the year, it will be split into two requests and allowances checked for each year's request.
				</dxi-item>
				<dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'Start Date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="startTime" editorType="dxSelectBox" [editorOptions]="{ items: holidayTimesStart, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Start Time' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item dataField="endDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'End Date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="endTime" editorType="dxSelectBox" [editorOptions]="{ items: holidayTimesEnd, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'End Time' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
			</dx-form>
		</div>
	</dx-popup>
</div>