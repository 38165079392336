import { Model } from "@interfaces/model.interface";

export interface EdiShippingNoteItem extends Model {
	containerNumbers: string;
	containerQuantity: number;
	ediOrderId: number;
	ediShippingNoteId: number;
	grossWeight: number;
	netWeight: number;
	palletDetails: string;
	palletNumber?: number;
	quantity: number;
}

export class EdiShippingNoteItem implements EdiShippingNoteItem {
	constructor(data?: Partial<EdiShippingNoteItem>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}