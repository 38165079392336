import { Model } from "@interfaces/model.interface";

export interface Supplier extends Model {
	address1: string;
	address2: string;
	excludeFromRFQs: boolean;
	name: string;
	postcode: string;
	rfqContactId?: number;
	telephoneNumber: string;
	town: string;
}

export class Supplier implements Supplier {
	address1 = "";
	address2 = "";
	name = "";
	postcode = "";
	telephoneNumber = "";
	town = "";	

	constructor(data?: Partial<Supplier>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}