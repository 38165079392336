<dx-data-grid #masterDetailsGridContainer class="data-grid" [dataSource]="lineItems" [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true">
	<dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="salesOrderDetailsLineItems"></dxo-state-storing>
	<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
	<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
	<dxo-group-panel [visible]="false"></dxo-group-panel>
	<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="false"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column dataField="partNumber" dataType="text" alignment="left"></dxi-column>
	<dxi-column dataField="price" dataType="number" alignment="right" caption="Piece Price" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
	<dxi-column dataField="quantityOrdered" dataType="number" alignment="right" caption="Quantity Ordered"></dxi-column>
	<dxi-column dataField="quantityInvoiced" dataType="number" alignment="right" caption="Quantity Invoiced"></dxi-column>
	<dxi-column dataField="quantityDelivered" dataType="number" alignment="right" caption="Quantity Delivered"></dxi-column>
	<dxi-column dataField="subtotal" dataType="number" alignment="right" caption="Subtotal" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
	<dxi-column dataField="material" dataType="text" alignment="left"></dxi-column>
	<dxi-column dataField="sheetLength" dataType="number" alignment="right" caption="Sheet Length"></dxi-column>
	<dxi-column dataField="sheetWidth" dataType="number" alignment="right" caption="Sheet Width"></dxi-column>
</dx-data-grid>