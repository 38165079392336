import { Component, ViewEncapsulation } from "@angular/core";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Machine } from "@interfaces/machine.interface";
import { Site } from "@interfaces/site.interface";
import { MachineService } from "@services/machine.service";
import { SiteService } from "@services/site.service";
import notify from "devextreme/ui/notify";
import Konva from "konva";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "floorplan",
	styleUrls: ["floorplan.css"],
	templateUrl: "floorplan.html"
})
export class FloorplanComponent {
	floorPlanHeight = 900;
	floorPlanWidth = 1000;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	machineLayer: Konva.Layer = new Konva.Layer();
	machines: Machine[] = [];
	placedMachines: number[] = [];
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupMachine: any = null;
	popupTitle = "Machine Info";
	popupVisible = false;
	productionStatuses: any;
	selectedSiteId = 1;
	sites: Site[] = [];
	stage?: Konva.Stage;
	title = "Floorplan";

	constructor(private machineService: MachineService, private siteService: SiteService) {
		this.getSites();
		this.productionStatuses = GetPropertiesFromEnum(ProductionStatus);
	}

	addMachine(machine: Machine) {
		if (machine.xPosition < 0 || machine.yPosition < 0 || machine.width <= 0 || machine.depth <= 0) {
			return;
		}
		const group = new Konva.Group({
			height: machine.depth,
			name: machine.id.toString(),
			rotation: machine.rotationAmount,
			width: machine.width,
			x: machine.xPosition !== -1 ? machine.xPosition : Math.floor(this.stage!.width() / 3),
			y: machine.yPosition !== -1 ? machine.yPosition :  Math.floor(this.stage!.height() / 3)
		});
		group.draggable(true);
		group.on("dragend", () => this.shapeDragEnd(group, machine));
		group.on("dragmove", () => this.shapeDragMove(group));
		group.on("transformend", () => {
			this.shapeDragEnd(group, machine);
		});

		const shape = new Konva.Rect({
			cornerRadius: 5,
			fill: this.getStatusColour(machine.productionStatus),
			height: machine.depth,
			shadowBlur: 5,
			width: machine.width
		});
		group.add(shape);

		const text = new Konva.Text({
			align: "center",
			fill: "black",
			fontFamily: "Calibri",
			fontSize: 18,
			height: machine.depth,
			text: machine.cellNumber + "\n" + machine.name,
			verticalAlign: "middle",
			width: machine.width
		});
		group.add(text);

		this.machineLayer.add(group);
		this.placedMachines.push(machine.id);
	}

	changeSite(siteId: number) {
		this.selectedSiteId = siteId;

		const selectedSite = this.sites.filter((d: Site) => d.id == siteId);
		this.title = "Floorplan - " + selectedSite[0].name;
		this.floorPlanHeight = selectedSite[0].floorPlanHeight;
		this.floorPlanWidth = selectedSite[0].floorPlanWidth;

		this.machineService.getBySite(siteId)
			.subscribe(
				(res: any) => {
					this.machines = res.response;
					this.renderPlacedMachines();
				},
				(err) => console.log(err)
			);
	}

	closePopup() {
		this.popupVisible = false;
	}

	editMachineDimensions() {
		this.machineService.updateSingleById(this.popupMachine.id, this.popupMachine)
			.subscribe(
				() => {
					this.popupVisible = false;
					if (this.placedMachines.indexOf(this.popupMachine.id) > -1) {
						// force a re-render - todo: see if we can just find and edit the existing group?
						this.getMachines(this.selectedSiteId);
					} else {
						this.addMachine(this.popupMachine);
					}
				},
				(err: any) => {
					notify("Could not edit machine dimensions, please check that all required fields have been filled in for this machine via the machines admin page.", "Error", 5000);
					console.log(err);
				}
			);
		this.closePopup();
	}

	editMachineDimensionsPopup(machine: Machine) {
		this.popupMachine = machine;
		this.popupTitle = "Machine Dimensions";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.editMachineDimensions(), text: "Save Changes" };
	}

	getMachines(siteId: number) {
		this.machineService.getBySite(siteId)
			.subscribe(
				(res: any) => {
					this.machines = res.response;
					this.renderPlacedMachines();
				},
				(err) => console.log(err)
			);
	}

	getSites() {
		this.siteService.getAll()
			.subscribe(
				(res: any) => {
					this.sites = res.response.items;
					if (this.sites.length > 0) {
						this.sites.sort((a,b) => a.name.localeCompare(b.name));
						this.selectedSiteId = this.sites[0].id;
						this.getMachines(this.sites[0].id);
						
						this.title = "Floorplan - " + this.sites[0].name;
						this.floorPlanHeight = this.sites[0].floorPlanHeight;
						this.floorPlanWidth = this.sites[0].floorPlanWidth;
						this.headerPrimaryButtons = [];
						this.sites.forEach((site: any) => {
							this.headerPrimaryButtons.push({ method: "changeSite-" + site.id, text: site.name });
						});
					}
				},
				(err) => console.log(err)
			);
	}

	getStatusColour(status: ProductionStatus) {
		switch(status) {
			case ProductionStatus.Active:
				return "green";
			case ProductionStatus.Idle:
				return "yellow";
			case ProductionStatus.Maintenance:
				return "red";
			default:
				return "blue";
		}
	}

	headerButtonClick(method: string) {
		if (method.indexOf("-") > 0) {
			const split = method.split("-");
			// @ts-ignore // Required to be able to call the method directly from the variable
			this[split[0]](split[1]);
		}
		else
		{
			// @ts-ignore // Required to be able to call the method directly from the variable
			if (this[method]) this[method]();
		}
	}

	initCanvas() {
		const divName = "floorplan";
		const canvas = document.getElementById(divName);
		if (canvas) {
			this.stage = new Konva.Stage({
				container: divName,
				height: this.floorPlanHeight,
				width: this.floorPlanWidth
			});
			this.stage.add(this.machineLayer);

			this.machineLayer.removeChildren();

			const transformer = new Konva.Transformer({
				nodes: [],
				resizeEnabled: false
			});
			this.machineLayer.add(transformer);

			this.stage.on("click tap", (e) => {
				if (e.target === this.stage) {
					transformer.nodes([]);
					return;
				}
				if (e.target instanceof Konva.Group) {
					transformer.nodes([e.target]);
					return;
				}
				if (e.target.parent instanceof Konva.Group) {
					transformer.nodes([e.target.parent]);
					return;
				}
			});
		}
	}

	renderPlacedMachines() {
		this.initCanvas();
		this.placedMachines = [];
		this.machines.filter((machine) => machine.xPosition > -1 && machine.yPosition > -1 && machine.width > 0 && machine.depth > 0).forEach((machine) => this.addMachine(machine));
	}

	// Function to rotate a point.
	// pt = {x,y} of point to rotate, 
	// o = {x, y} of rotation origin, 
	// a = angle of rotation in degrees.
	// returns {x, y} giving the new point.
	rotatePoint(pt: any, o: any, a: any) {
		const angle = a * (Math.PI / 180); // Convert to radians
		const rotatedX = Math.cos(angle) * (pt.x - o.x) - Math.sin(angle) * (pt.y - o.y) + o.x;
		const rotatedY = Math.sin(angle) * (pt.x - o.x) + Math.cos(angle) * (pt.y - o.y) + o.y;
		return {x: rotatedX, y: rotatedY};
	}

	shapeDragEnd(shape: Konva.Group | Konva.Shape, machine: Machine) {
		this.shapeDragMove(shape);
		machine.rotationAmount = shape.rotation();
		machine.xPosition = shape.x();
		machine.yPosition = shape.y();
		this.machineService.updateSingleById(machine.id, machine)
			.subscribe(
				(res: any) => console.log(res.response),
				(err) => console.log(err)
			);
	}

	shapeDragMove(shape: Konva.Group | Konva.Shape) {
		// Calculates the absolute positions for each vertex of rotated items
		const machineBottomLeft = this.rotatePoint({x: Math.round(shape.x() + shape.width()), y: Math.round(shape.y())}, {x: Math.round(shape.x()), y: Math.round(shape.y())}, shape.rotation());
		const machineBottomRight = this.rotatePoint({x: Math.round(shape.x() + shape.width()), y: Math.round(shape.y() + shape.height())}, {x: Math.round(shape.x()), y: Math.round(shape.y())}, shape.rotation());
		const machineTopLeft = this.rotatePoint({x: Math.round(shape.x()), y: Math.round(shape.y())}, {x: Math.round(shape.x()), y: Math.round(shape.y())}, shape.rotation());
		const machineTopRight = this.rotatePoint({x: Math.round(shape.x()), y: Math.round(shape.y() + shape.height())}, {x: Math.round(shape.x()), y: Math.round(shape.y())}, shape.rotation());

		if (this.stage === undefined) return;

		const xMaxValue = Math.max(machineBottomLeft.x, machineBottomRight.x, machineTopLeft.x, machineTopRight.x);
		if (xMaxValue > this.stage.width()) {
			const xDiff = xMaxValue - this.stage.width();
			shape.x(shape.x() - xDiff);
			machineBottomLeft.x -= xDiff;
			machineBottomRight.x -= xDiff;
			machineTopLeft.x -= xDiff;
			machineTopRight.x -= xDiff;
		}

		const xMinValue = Math.min(machineBottomLeft.x, machineBottomRight.x, machineTopLeft.x, machineTopRight.x);
		if (xMinValue < 0) {
			shape.x(shape.x() - xMinValue);
			machineBottomLeft.x -= xMinValue;
			machineBottomRight.x -= xMinValue;
			machineTopLeft.x -= xMinValue;
			machineTopRight.x -= xMinValue;
		}

		const yMaxValue = Math.max(machineBottomLeft.y, machineBottomRight.y, machineTopLeft.y, machineTopRight.y);
		if (yMaxValue > this.stage.height()) {
			const yDiff = yMaxValue - this.stage.height();
			shape.y(shape.y() - yDiff);
			machineBottomLeft.y -= yDiff;
			machineBottomRight.y -= yDiff;
			machineTopLeft.y -= yDiff;
			machineTopRight.y -= yDiff;
		}

		const yMinValue = Math.min(machineBottomLeft.y, machineBottomRight.y, machineTopLeft.y, machineTopRight.y);
		if (yMinValue < 0) {
			shape.y(shape.y() - yMinValue);
			machineBottomLeft.y -= yMinValue;
			machineBottomRight.y -= yMinValue;
			machineTopLeft.y -= yMinValue;
			machineTopRight.y -= yMinValue;
		}
	}
}