import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { SmsTemplate } from "@interfaces/smsTemplate.interface";
import { SmsTemplateService } from "@services/smsTemplate.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "smsTemplates-id",
	styleUrls: ["smsTemplates-id.css"],
	templateUrl: "smsTemplates-id.html"
})
export class SmsTemplateIdAdminComponent {
	createEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] =[];
	item: SmsTemplate = new SmsTemplate();
	itemId = 0;
	itemType = "Sms Template";
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	statuses: any;
	title: string = "View " + this.itemType;

	constructor(private router:Router, private route: ActivatedRoute, private itemService: SmsTemplateService) {
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {
						this.getItem(params.id);
					}
				} else {
					this.create();
				}
			});
	}

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];	
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new SmsTemplate();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => this.item = res.response,
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}