import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import notify from "devextreme/ui/notify";
import { PromiseService } from "@services/promise.service";
import { Promise } from "@interfaces/promise.interface";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { PromiseType } from "@enums/promiseType.enum";

@Component({
	moduleId: module.id,
	selector: "promises-board",
	styleUrls: ["promises-board.css"],
	templateUrl: "promises-board.html"
})
export class PromisesBoardAdminComponent {
	deleteEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupPromise: Promise = new Promise();
	popupTitle = "";
	popupVisible = false;
	promiseBoard: any[] = [];
	promiseTypes: any;
	title = "Promise Board";
	
	constructor(private router: Router, private promiseService: PromiseService) {
		this.promiseTypes = GetPropertiesFromEnum(PromiseType);
		this.headerPrimaryButtons.push({ icon: "fal fa-th-large", method: "viewGrid", text: "Grid" });
		this.headerPrimaryButtons.push({ icon: "fal fa-plus", method: "addNewPromisePopup", text: "New Promise" });
		this.getBoard();
	}

	addNewPromise() {		
		this.promiseService.createSingle(this.popupPromise)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupPromise = new Promise();
					this.getBoard();
					notify("Promise Created", "Success", 5000);
				},
				(err: any) => {
					notify("Something Went Wrong!", "Error", 5000);
					console.log(err);
				}
			);

		this.closePopup();
	}

	addNewPromisePopup() {
		this.popupPromise = new Promise();
		this.popupTitle = "Add New Promise";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewPromise(), text: "Add Promise" };
	}

	closePopup() {
		this.popupVisible = false;
		this.popupPromise = new Promise();
	}

	editPromise() {		
		this.promiseService.updateSingleById(this.popupPromise.id, this.popupPromise)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupPromise = new Promise();
					this.getBoard();
					notify("Promise Updated", "Success", 5000);
				},
				(err: any) => {
					notify("Something Went Wrong!", "Error", 5000);
					console.log(err);
				}
			);

		this.closePopup();
	}

	editPromisePopup(e: any) {
		this.popupPromise = new Promise(e);
		this.popupTitle = "Edit Promise";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.editPromise(), text: "Save Changes" };
		return false;
	}

	getBoard() {
		this.promiseService.getPromiseBoard()
			.subscribe(
				(res: any) => this.promiseBoard = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	viewGrid() {
		this.router.navigate(["admin/promises"]);
	}
}