export interface LogExport {
	gridName: string;
}

export class LogExport implements LogExport {
	constructor(data?: Partial<LogExport>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}