export enum PurchaseType {
	Consumables = 10,
	Fixings = 20,
	Material = 30,
	Packaging = 35,
	ItEquipment = 40,
	Outsourced = 50,
	HandTools = 60,
	Machinery = 65,
	ToolingBillet = 70,
	ToolingAccessories = 80,
	BoughtOutParts = 90,
	Maintenance = 95,
	Transport = 99,
	Other = 100
}