export interface ChangeWorkOrderStageOperator {
	userProfileId?: number;
	workOrderStageId: number;
}

export class ChangeWorkOrderStageOperator implements ChangeWorkOrderStageOperator {
	constructor(data?: Partial<ChangeWorkOrderStageOperator>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}