import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class MachineQuestionnaireService extends Service {
	constructor(injector: Injector) {
		super("MachineQuestionnaire", injector);
	}

	// API
	appCreate(data: any) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/AppCreate`, data);
	}

	appFilledInToday(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/AppFilledInToday/${id}` );
	}

	getByMachineId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByMachineId/${id}` );
	}

	getByUserProfileId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByUserProfileId/${id}` );
	}
}