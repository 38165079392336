import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { VisitorType } from "@enums/visitorType.enum";
import { environment } from "@environments/environment";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { Visit } from "@interfaces/visit.interface";
import { Visitor } from "@interfaces/visitor.interface";
import { FileService } from "@services/file.service";
import { UserProfileService } from "@services/userProfile.service";
import { VisitService } from "@services/visit.service";
import { VisitorService } from "@services/visitor.service";

@Component({
	moduleId: module.id,
	selector: "visitors-id",
	styleUrls: ["visitors-id.css"],
	templateUrl: "visitors-id.html"
})
export class VisitorsIdAdminComponent {
	createEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: Visitor = new Visitor();
	itemId = 0;
	itemType = "Visitor";
	people: UserProfile[] = [];
	photoStyle = "";
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "View";
	title: string = "View " + this.itemType;
	visitorTypes: any;
	visits: Visit[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private itemService: VisitorService, private fileService: FileService, private userProfileService: UserProfileService, private visitService: VisitService) {
		this.buildButtons("view");
		this.getPeople();
		this.visitorTypes = GetPropertiesFromEnum(VisitorType);
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.itemId = params.id.split("_")[0];
						this.getItem(this.itemId);
						this.edit();
					} else {
						this.itemId = params.id;
						this.getItem(params.id);
					}
				} else {
					this.create();					
				}
			});
		this.uploadImage = this.uploadImage.bind(this);
	}

	buildButtons(mode: string) {
		this.headerPrimaryButtons = [];
		this.headerTertiaryButtons = [];
		this.screenMode = mode;
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "sendEmailNotification", text: "Notify by Email" },
				{ method: "sendSMSNotification", text: "Notify by SMS" }
			];
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Created " + this.itemType, "success", 5000);
					this.item = new Visitor();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getVisits(this.item.id);
					this.setImageStyle(this.item.photoUrl);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	getVisits(itemId: number) {
		this.visitService.forVisitor(itemId)
			.subscribe(
				(res: any) => this.visits = res.response,
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	sendEmailNotification() {
		if (confirm("Send Access Code by email to " + this.item.emailAddress + "?") == false) {
			return;
		}

		this.itemService.sendEmailNotification(this.item.id)
			.subscribe(
				() => notify("Email Notification Created", "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	sendSMSNotification() {
		if (confirm("Send Access Code by SMS to " + this.item.telephoneNumber + "?") == false) {
			return;
		}

		this.itemService.sendSMSNotification(this.item.id)
			.subscribe(
				() => notify("SMS Notification Created", "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	setImageStyle(imageUrl: string) {
		this.photoStyle = `background-image: url("${imageUrl}")`;
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => notify("Successfully Updated " + this.itemType, "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadImage(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		uploadData.append("mimeTypeKey", "ImageMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.item.photoUrl = `${environment.server.storageUrl}/${containerName}/${res.response[0].location}`;
					this.setImageStyle(this.item.photoUrl);
					notify("Image uploaded - Click Save to confirm change", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Image Upload Failed - Please ensure you are uploading a valid image file", "error", 5000);
				}
			);
	}
}