import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { BankDetails } from "@interfaces/bankDetails.interface";
import { Client } from "@interfaces/client.interface";
import { ClientContact } from "@interfaces/clientContact.interface";
import { ClientDocument } from "@interfaces/clientDocument.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { OutsourcedSpecification } from "@interfaces/outsourcedSpecification.interface";
import { BankDetailsService } from "@services/bankDetails.service";
import { ClientService } from "@services/client.service";
import { ClientContactService } from "@services/clientContact.service";
import { ClientDocumentService } from "@services/clientDocument.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { FileService } from "@services/file.service";
import { OutsourcedSpecificationService } from "@services/outsourcedSpecification.service";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { EdiStyle } from "@enums/ediStyle.enum";

@Component({
	moduleId: module.id,
	selector: "clients-id",
	styleUrls: ["clients-id.css"],
	templateUrl: "clients-id.html"
})
export class ClientIdAdminComponent {
	addDocumentPopupAddButtonOptions: any = { onClick: () => this.createClientDocument(), text: "Add Document" };
	addDocumentPopupCloseButtonOptions: any = { onClick: () => this.closeAddDocumentPopup(), text: "Close" };
	addDocumentPopupTitle = "Add Document";
	addDocumentPopupVisible = false;
	bankDetails: BankDetails[] = [];
	contacts: ClientContact[] = [];
	createEnabled = true;
	documents: ClientDocument[] = [];
	ediStyles: any;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: Client = new Client();
	itemId = 0;
	itemType = "Client";
	locations: ClientLocation[] = [];
	newClientDocument: ClientDocument = new ClientDocument();
	outsourcedSpecifications: OutsourcedSpecification[] = [];
	popupAddButtonOptions: any = { onClick: () => this.addNewContact(), text: "Add Contact" };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupContact: ClientContact = new ClientContact();
	popupLocation: ClientLocation = new ClientLocation();
	popupSpecification: OutsourcedSpecification = new OutsourcedSpecification();
	popupTitle = "Add New Contact";
	popupType = "Contact";
	popupVisible = false;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	statuses: any;
	title: string = "View " + this.itemType;
	uploadEnabled = false;

	constructor(private router: Router, private route: ActivatedRoute, private bankDetailsService: BankDetailsService, private clientContactsService: ClientContactService, private clientDocumentService: ClientDocumentService, private clientLocationService: ClientLocationService, private fileService: FileService, private itemService: ClientService, private outsourceService: OutsourcedSpecificationService) {
		this.ediStyles = GetPropertiesFromEnum(EdiStyle);
		this.downloadDocument = this.downloadDocument.bind(this);
		this.uploadChunk = this.uploadChunk.bind(this);
		this.buildButtons("view");
		this.deleteContact = this.deleteContact.bind(this);
		this.deleteSpecification = this.deleteSpecification.bind(this);
		this.editContactPopup = this.editContactPopup.bind(this);
		this.editLocationPopup = this.editLocationPopup.bind(this);
		this.editSpecificationPopup = this.editSpecificationPopup.bind(this);
		this.setPrimaryBilling = this.setPrimaryBilling.bind(this);
		this.setPrimaryDelivery = this.setPrimaryDelivery.bind(this);
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.itemId = params.id.split("_")[0];
						this.getItem(this.itemId);
						this.edit();
					} else {
						this.itemId = params.id;
						this.getItem(params.id);
					}
				} else {
					this.create();					
				}
			});
		this.getBankDetails();
	}

	addDocumentPopup() {
		this.addDocumentPopupVisible = true;
	}

	addNewContact() {
		this.popupContact.clientId = this.item.id;
		this.clientContactsService.createSingle(this.popupContact)
			.subscribe(
				() => {
					this.closePopup();
					this.getContacts(this.item.id);
				},
				(err: any) => console.log(err)
			);
	}

	addNewContactPopup() {
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewContact(), text: "Add Contact" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupContact = new ClientContact();
		this.popupTitle = "Add New Contact";
		this.popupType = "Contact";
	}

	addNewLocation() {
		this.popupLocation.clientId = this.item.id;
		this.clientLocationService.createSingle(this.popupLocation)
			.subscribe(
				() => {
					this.closePopup();
					this.getLocations(this.item.id);
				},
				(err: any) => console.log(err)
			);
	}

	addNewLocationPopup() {
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewLocation(), text: "Add Location" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupLocation = new ClientLocation();
		this.popupTitle = "Add New Location";
		this.popupType = "Location";
	}
	
	addNewSpec() {
		this.popupSpecification.clientId = this.item.id;
		this.outsourceService.createSingle(this.popupSpecification)
			.subscribe(
				() => {
					this.closePopup();
					this.getSpecifications(this.item.id);
				},
				(err: any) => console.log(err)
			);
	}

	addNewSpecificationPopup() {
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewSpec(), text: "Add Specification" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupSpecification = new OutsourcedSpecification();
		this.popupTitle = "Add New Specification";
		this.popupType = "Specification";
	}
	
	boolYesNo(cellInfo: any){		
		if(cellInfo.value === true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	}

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewContactPopup", text: "Add Contact" },
				{ method: "addDocumentPopup", text: "Add Document" },
				{ method: "addNewLocationPopup", text: "Add Location" },
				{ method: "addNewSpecificationPopup", text: "Add Spec" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [];
		} else if (mode == "view") {
			this.headerTertiaryButtons = [
				{ method: "addNewContactPopup", text: "Add Contact" },
				{ method: "addDocumentPopup", text: "Add Document" },
				{ method: "addNewLocationPopup", text: "Add Location" },
				{ method: "addNewSpecificationPopup", text: "Add Spec" }
			];
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];	
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}
	
	closeAddDocumentPopup() {
		this.addDocumentPopupVisible = false;
		this.newClientDocument = new ClientDocument();
		// clear out an uploaded file?
	}

	closePopup() {
		this.popupVisible = false;
		this.popupContact = new ClientContact();
	}

	create() {
		this.uploadEnabled = false;
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createClientDocument() {
		this.newClientDocument.clientId = this.itemId;
		this.clientDocumentService.createSingle(this.newClientDocument)
			.subscribe(
				() => {
					notify("Successfully Added New Document " + this.newClientDocument.fileName, "success", 5000);
					this.newClientDocument = new ClientDocument();
					this.getDocuments(this.itemId);
					this.addDocumentPopupVisible = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new Client();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteContact(e: any) {
		if (confirm("Are you sure you wish to delete this contact? Note: Contacts attached to Sales Enquiries cannot be deleted.") == false) {
			return;
		}

		this.clientContactsService.deleteSingleById(e.row.data.id).subscribe(
			() => {
				notify("Successfully Deleted Contact", "success", 5000);
				this.getContacts(this.itemId);
			},
			(err) => {
				notify("Could not delete contact - " + err, "error", 5000);
			}
		);
	}

	deleteLocation(e: any) {
		if (confirm("Are you sure you wish to delete this location? Note: Locations attached to Forecasts, Orders and Shipping Notes cannot be deleted.") == false) {
			return;
		}

		this.clientLocationService.deleteSingleById(e.row.data.id).subscribe(
			() => {
				notify("Successfully Deleted Location", "success", 5000);
				this.getLocations(this.itemId);
			},
			(err) => {
				notify("Could not delete Location - " + err, "error", 5000);
			}
		);
	}

	deleteSpecification(e: any) {
		if (confirm("Are you sure you wish to delete this specification? Note: Specifications attached to parts cannot be deleted.") == false) {
			return;
		}

		this.outsourceService.deleteSingleById(e.row.data.id).subscribe(
			() => {
				notify("Successfully Deleted Specification", "success", 5000);
				this.getSpecifications(this.itemId);
			},
			(err) => {
				notify("Could not delete specification - " + err, "error", 5000);
			}
		);
	}

	downloadDocument(e: any) {
		this.fileService.downloadFile(e.row.data.fileLookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	editContactPopup(e: any) {
		this.clientContactsService.getSingleById(e.row.data.id).subscribe(
			(res: any) => {
				this.popupVisible = true;
				this.popupAddButtonOptions = { onClick: () => this.updateContact(), text: "Save Changes" };
				this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
				this.popupContact = res.response;
				this.popupTitle = "Edit Contact";
				this.popupType = "Contact";
			},
			(err) => {
				console.log(err);
				notify("Could not edit contact", "error", 5000);
			}
		);
	}

	editLocationPopup(e: any) {
		this.clientLocationService.getSingleById(e.row.data.id).subscribe(
			(res: any) => {
				this.popupVisible = true;
				this.popupAddButtonOptions = { onClick: () => this.updateLocation(), text: "Save Changes" };
				this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
				this.popupLocation = res.response;
				this.popupTitle = "Edit Location";
				this.popupType = "Location";
			},
			(err) => {
				console.log(err);
				notify("Could not edit location", "error", 5000);
			}
		);
	}

	editSpecificationPopup(e: any) {
		this.outsourceService.getSingleById(e.row.data.id).subscribe(
			(res: any) => {
				this.popupVisible = true;
				this.popupAddButtonOptions = { onClick: () => this.updateSpecification(), text: "Save Changes" };
				this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
				this.popupSpecification = res.response;
				this.popupTitle = "Edit Specification";
				this.popupType = "Specification";
			},
			(err) => {
				console.log(err);
				notify("Could not edit Specification", "error", 5000);
			}
		);
	}

	getBankDetails() {
		this.bankDetailsService.getAll()
			.subscribe(
				(res: any) => {
					this.bankDetails = res.response.items;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getContacts(id: number) {
		this.clientContactsService.getContactByParentId(id)
			.subscribe(
				(res: any) => {
					this.contacts = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getDocuments(itemId: number) {
		this.clientDocumentService.getDocumentsForClientId(itemId)
			.subscribe(
				(res: any) => this.documents = res.response,
				(err) => console.log(err)
			);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getContacts(this.item.id);
					this.getDocuments(this.item.id);
					this.getLocations(this.item.id);
					this.getSpecifications(this.item.id);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
		this.uploadEnabled = true;
	}

	getLocations(itemId: number) {
		this.clientLocationService.getLocationsByClientId(itemId)
			.subscribe(
				(res: any) => this.locations = res.response,
				(err) => console.log(err)
			);
	}

	getSpecifications(id: number) {
		this.outsourceService.getByClientId(id)
			.subscribe(
				(res: any) => {
					this.outsourcedSpecifications = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	setPrimaryBilling(e: any) {
		this.clientLocationService.setPrimaryBilling(e.row.data.id).subscribe(
			() => {
				notify("Successfully Updated Primary Billing Location", "success", 5000);
				this.getLocations(this.item.id);
			},
			(err) => {
				console.log(err);
				notify("Could not edit location", "error", 5000);
			}
		);
	}

	setPrimaryDelivery(e: any) {
		this.clientLocationService.setPrimaryDelivery(e.row.data.id).subscribe(
			() => {
				notify("Successfully Updated Primary Delivery Location", "success", 5000);
				this.getLocations(this.item.id);
			},
			(err) => {
				console.log(err);
				notify("Could not edit location", "error", 5000);
			}
		);
	}

	updateContact() {
		this.clientContactsService.updateSingleById(this.popupContact.id, this.popupContact)
			.subscribe(
				() => {
					notify("Successfully Updated Contact", "success", 5000);					
					this.closePopup();
					this.getContacts(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id,this.item)
			.subscribe(
				() => notify("Successfully Updated " + this.itemType, "success", 5000),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateLocation() {
		this.clientLocationService.updateSingleById(this.popupLocation.id, this.popupLocation)
			.subscribe(
				() => {
					notify("Successfully Updated Location", "success", 5000);					
					this.closePopup();
					this.getLocations(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateSpecification() {
		this.outsourceService.updateSingleById(this.popupSpecification.id, this.popupSpecification)
			.subscribe(
				() => {
					notify("Successfully Updated Specification", "success", 5000);					
					this.closePopup();
					this.getSpecifications(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadChunk(file: File) {
		const uploadData = new FormData();
		uploadData.append("files", file);
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.newClientDocument.fileLookupId = res.response[0].lookupId;
					this.newClientDocument.fileName = res.response[0].name;
				},
				(err) => console.log(err)
			);
	}
}