<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="2">
				<dxi-item dataField="bankName"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="sortCode"></dxi-item>
				<dxi-item dataField="accountNumber"></dxi-item>
				<dxi-item dataField="accountName"></dxi-item>
				<dxi-item dataField="iban" [label]="{ text: 'IBAN' }"></dxi-item>
				<dxi-item dataField="swift" [label]="{ text: 'SWIFT' }"></dxi-item>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>