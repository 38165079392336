import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class QmsDocumentVersionService extends Service {
	constructor(injector: Injector) {
		super("QMSDocumentVersion", injector);
	}

	// API
	approve(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Approve/${id}`, {});
	}

	getByQMSDocumentId(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByQMSDocumentId/${id}`);
	}
}