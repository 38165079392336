import { EmailPriority } from "@enums/emailPriority.enum";
import { Model } from "@interfaces/model.interface";

export interface SmsTemplate extends Model {
	css: string;
	design: any;
	emailPriority: EmailPriority;
	html: string;
	notes: string;
	senderEmailAddresses: string;
	senderName: string;
	subject: string;
	templateName: string;
}

export class SmsTemplate implements SmsTemplate {
	css = "";
	emailPriority: EmailPriority = 0;
	html = "";
	notes = "";
	senderEmailAddresses = "";
	senderName = "";
	subject = "";
	templateName = "";

	constructor(data?: Partial<SmsTemplate>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}