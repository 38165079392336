import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class EdiOrderService extends Service {
	constructor(injector: Injector) {
		super("EdiOrder", injector);
	}

	// API
	getOrderTableByClient(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/OrderTable/${id}`);
	}

	pickList() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/PickList`, { responseType: "blob" as "json"} );
	}
}