<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Person Details" [colCount]="4">
				<dxi-item dataField="personType" editorType="dxSelectBox" [colSpan]="2" [editorOptions]="{ items: personTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true }"></dxi-item>
				<dxi-item dataField="personTitle" [colSpan]="2" [label]="{ text: 'Title'}"></dxi-item>
				<dxi-item dataField="personName" [colSpan]="2" [label]="{ text: 'Name'}"></dxi-item>
				<dxi-item dataField="personDob" [colSpan]="2" [label]="{ text: 'Date of Birth'}" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"></dxi-item>
				<dxi-item dataField="personAddress1" [colSpan]="4" [label]="{ text: 'Address 1'}"></dxi-item>
				<dxi-item dataField="personAddress2" [colSpan]="4" [label]="{ text: 'Address 2'}"></dxi-item>
				<dxi-item dataField="personTown" [colSpan]="2" [label]="{ text: 'Town / City'}"></dxi-item>
				<dxi-item dataField="personCounty" [colSpan]="2" [label]="{ text: 'County'}"></dxi-item>
				<dxi-item dataField="personPostcode" [colSpan]="2" [label]="{ text: 'Postcode'}"></dxi-item>
				<dxi-item dataField="personCountry" [colSpan]="2" [label]="{ text: 'Country'}"></dxi-item>
				<dxi-item dataField="personContactNumber" [colSpan]="2" [label]="{ text: 'Contact Number'}"></dxi-item>
				<dxi-item dataField="personContactEmail" [colSpan]="2" [label]="{ text: 'Email Address'}"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Incident Details" [colCount]="4">
				<dxi-item dataField="incidentDateTime" [colSpan]="2" [label]="{ text: 'Date & Time'}" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy HH:mm', type: 'datetime' }"></dxi-item>
				<dxi-item dataField="incidentType" [colSpan]="2" editorType="dxSelectBox" [editorOptions]="{ items: incidentTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true }"></dxi-item>
				<dxi-item dataField="siteId" [colSpan]="2" editorType="dxSelectBox" [editorOptions]="{ items: sites, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Site' }"><dxi-validation-rule type="required" message="Site is required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="specificArea" [colSpan]="2"></dxi-item>
				<dxi-item dataField="incidentDescription" editorType="dxTextArea" [colSpan]="4"></dxi-item>
				<dxi-item dataField="injuryDescription" editorType="dxTextArea" [colSpan]="4"></dxi-item>
				<dxi-item dataField="otherComments" editorType="dxTextArea" [colSpan]="4"></dxi-item>
				<dxi-item dataField="firstAidRequired" editorType="dxCheckBox"  [colSpan]="2"></dxi-item>
				<dxi-item dataField="emergencyServicesRequired" editorType="dxCheckBox" [colSpan]="2"></dxi-item>
				<dxi-item dataField="followUpAction" [colSpan]="4"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Evidence">
				<dx-data-grid class="data-grid" [dataSource]="evidence" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column type="buttons">
						<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
						<dxi-button [visible]="!readOnly" hint="Delete" icon="fal fa-trash" [onClick]="deleteFile"></dxi-button>
					</dxi-column>
				</dx-data-grid>
				<dx-file-uploader [visible]="!readOnly" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadFile"></dx-file-uploader>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>