import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ModalService {
	data?: any;
	status: BehaviorSubject<string> = new BehaviorSubject("");

	hide() {
		this.status.next("");
	}

	show(template: string, data?: any) {
		if (data) this.data = data;
		this.status.next(template);
	}
}