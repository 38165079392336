<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<form (submit)="handleSubmit($event)">
		<dx-form class="m-3" [(formData)]="cp" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
			<dxi-item itemType="group" caption="Change Password" [colSpan]="2" [colCount]="2">
				<dxi-item dataField="oldPassword" [colSpan]="2"  [editorOptions]="{ mode: 'password', showClearButton:'true' }"></dxi-item>
				<dxi-item dataField="password" [colSpan]="2"  [editorOptions]="{ mode: 'password', showClearButton:'true' }" ></dxi-item>
				<dxi-item dataField="confirmPassword" [colSpan]="2" [editorOptions]="{ mode: 'password', showClearButton:'true' }"></dxi-item>
				<dxi-item itemType="button" [buttonOptions]="changePasswordButton" alignment="left" [colSpan]="2" type="default"></dxi-item>
			</dxi-item>
		</dx-form>
	</form>
	<form (submit)="handleProfileSubmit($event)">
		<dx-form class="m-3" [(formData)]="member" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
			<dxi-item itemType="group" caption="My Profile" [colSpan]="2" [colCount]="6">
				<dxi-item dataField="user.email" [colSpan]="6" [editorOptions]="{ disabled: true }"><dxo-label text="Email Address"></dxo-label></dxi-item>
				<dxi-item dataField="title" editorType="dxSelectBox" [editorOptions]="{ items: titles, searchEnabled: true, value: '' }" [colSpan]="2"></dxi-item>
				<dxi-item dataField="firstName" [colSpan]="2"></dxi-item>
				<dxi-item dataField="lastName" [colSpan]="2"></dxi-item>
				<dxi-item dataField="dateOfBirth" [editorOptions]="{ disabled: true }" [colSpan]="3"></dxi-item>
				<dxi-item dataField="gender" [editorOptions]="{ disabled: true }" [colSpan]="3"></dxi-item>
				<dxi-item dataField="address1" [colSpan]="3"></dxi-item>
				<dxi-item dataField="address2" [colSpan]="3"></dxi-item>
				<dxi-item dataField="town" [colSpan]="3"></dxi-item>
				<dxi-item dataField="postcode" [colSpan]="3"></dxi-item>
				<dxi-item dataField="telephoneNumber" [colSpan]="3"></dxi-item>
			</dxi-item>
			<dxi-item itemType="group" [colSpan]="2" [colCount]="6">
				<dxi-item itemType="button" [buttonOptions]="updateProfileButton" alignment="left" [colSpan]="6" type="default"></dxi-item>
			</dxi-item>
		</dx-form>
	</form>
	<dx-toast [(visible)]="toastVisible" [type]="type" [message]="message"></dx-toast>
</div>