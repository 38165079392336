import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { ChangePartLinkedGroupDetailsDto } from "@interfaces/changePartLinkedGroupDetailsDto.interface";
import { LinkedPartGroup } from "@interfaces/linkedPartGroup.interface";
import { LinkedPartGroupRouterGroupLineData } from "@interfaces/linkedPartGroupRouterGroupLineData.interface";
import { Part } from "@interfaces/part.interface";
import { LinkedPartGroupService } from "@services/linkedPartGroup.service";
import { PartService } from "@services/part.service";
import { DxDataGridComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "linkedPartGroups-id",
	styleUrls: ["linkedPartGroups-id.css"],
	templateUrl: "linkedPartGroups-id.html"
})
export class LinkedPartGroupsIdAdminComponent {
	@ViewChild("gridContainer", { static: false }) dataGrid!: DxDataGridComponent;
	changes: any = [];
	editRowKey: any = null;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: LinkedPartGroup = new LinkedPartGroup();
	itemId: Guid = Guid.parse(Guid.EMPTY);
	itemType = "Linked Part Group";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	linkedParts: Part[] = [];
	linkedRouterDetails: LinkedPartGroupRouterGroupLineData[] = [];
	mode = "";
	popupAddButtonOptions: any;
	popupChangePartLinkedGroupDetailsDto: ChangePartLinkedGroupDetailsDto = new ChangePartLinkedGroupDetailsDto();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupLinkedPartGroupRouterGroupLineData: LinkedPartGroupRouterGroupLineData = new LinkedPartGroupRouterGroupLineData();
	popupTitle = "";
	popupVisible = false;
	readOnly = true;
	readOnlyAlways = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	title: string = "View " + this.itemType;
	unlinkedParts: Part[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private itemService: LinkedPartGroupService, private partService: PartService) {
		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.itemId = params.id.split("_")[0];
					this.buildButtons("edit");
				} else {
					this.itemId = params.id;
					this.buildButtons("view");
				}
			} else {
				this.create();
			}
		});
		this.removePartFromGroup = this.removePartFromGroup.bind(this);
	}

	addPartToGroup() {
		this.partService.changeLinkedGroupDetails(this.popupChangePartLinkedGroupDetailsDto)
			.subscribe(
				() => {
					notify("Successfully Added Part", "success", 5000);
					this.getItem(this.itemId);
					this.closePopup();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	addPartToGroupPopup() {
		this.popupChangePartLinkedGroupDetailsDto = new ChangePartLinkedGroupDetailsDto();
		this.popupChangePartLinkedGroupDetailsDto.linkedPartGroupId = this.item.id;
		this.popupTitle = "Add Part to Linking Group";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addPartToGroup(), text: "Add Part" };
	}

	buildButtons(mode: string) {
		this.mode = mode;
		this.getItem(this.itemId);
		this.headerPrimaryButtons = [];
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		switch (mode) {
			case "create":
				this.readOnly = false;
				this.headerPrimaryButtons = [
					{ method: "createItem", text: "Save" },
					{ method: "close", text: "Close" }
				];
				break;
			case "edit":
				this.readOnly = false;
				this.title = "Edit " + this.itemType;
				this.headerPrimaryButtons = [
					{ method: "updateItem", text: "Save Changes" },
					{ method: "cancelEditing", text: "View Mode" },
					{ method: "close", text: "Close" }
				];
				this.headerSecondaryButtons = [
					{ method: "addPartToGroupPopup", text: "Add Part" }
				];
				break;
			case "view":
				this.readOnly = true;
				this.title = "View " + this.itemType;
				this.headerPrimaryButtons = [
					{ method: "edit", text: "Edit" },
					{ method: "close", text: "Close" }
				];
				break;
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.router.navigate([`/${this.returnUrl}/${this.itemId}`])
			.then(() => {
				window.location.reload();
			});
	}

	close() {
		if (this.mode === "view" || confirm("Are you sure you wish to leave the page? Unsaved changes will be discarded.")) {
			this.router.navigate([`/${this.returnUrl}/`]);
		}
	}

	closePopup() {
		this.popupVisible = false;
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.item.linkedRouterDetails = JSON.stringify(this.linkedRouterDetails);
		this.itemService.createSingle(this.item)
			.subscribe(
				(res: any) => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.itemId = res.response.lookupId;
					this.edit();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.router.navigate([`/${this.returnUrl}/${this.itemId}_edit`])
			.then(() => {
				window.location.reload();
			});
	}

	getItem(lookupId: Guid) {
		this.itemService.getSingleByLookupId(lookupId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.linkedRouterDetails = JSON.parse(this.item.linkedRouterDetails);
					this.getParts();
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
			);
	}

	getParts() {
		this.partService.getUnlinkedParts()
			.subscribe(
				(res: any) => {
					this.unlinkedParts = res.response;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
			);
		this.partService.getLinkedParts(this.item.id)
			.subscribe(
				(res: any) => {
					this.linkedParts = res.response;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}	

	removePartFromGroup(e: any) {
		const id = e.row.data.id;
		this.popupChangePartLinkedGroupDetailsDto = new ChangePartLinkedGroupDetailsDto();
		this.popupChangePartLinkedGroupDetailsDto.partId = id;
		this.partService.changeLinkedGroupDetails(this.popupChangePartLinkedGroupDetailsDto)
			.subscribe(
				() => {
					notify("Successfully Removed Part", "success", 5000);
					this.getItem(this.itemId);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	stageToOP(cellInfo: any) {
		return "OP" + cellInfo.value + "0";
	}

	updateItem() {
		this.item.linkedRouterDetails = JSON.stringify(this.linkedRouterDetails);
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					// this.cancelEditing();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}