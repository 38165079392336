import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class EdiForecastService extends Service {
	constructor(injector: Injector) {
		super("EdiForecast", injector);
	}

	// API
	getForecastTable() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForecastTable`);
	}
}