<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onRowPrepared)="onRowPrepared($event)" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="edimMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
    </dxi-column>
	<dxi-column dataField="createdAt"  dataType="date" format="dd/MM/yyy" alignment="left"></dxi-column>
	<dxi-column dataField="messageType" caption="Type"><dxo-lookup [dataSource]="messageTypes" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
	<dxi-column dataField="messageReference" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="senderId" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="recipientId" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="asnNumber" dataType="string" alignment="left" caption="ASN Number"></dxi-column>
	<dxi-column dataField="messageDirection" caption="Type"><dxo-lookup [dataSource]="messageDirections" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
	<dxi-column dataField="status" caption="Type"><dxo-lookup [dataSource]="statuses" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
</dx-data-grid>

<dx-popup [width]="750" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *ngIf="popupTitle === 'Import Edi Orders Message CSV'">
			<dx-form class="m-3" [(formData)]="popupImportDetails" [showColonAfterLabel]="true" labelLocation="top">
				<dxi-item>
					<p>This will create an EDI message and orders/forecasts from the CSV. This will cancel all existing future (Date >= Today) orders and shipping notes associated with the selected client <em>except</em> locked ones.</p>
				</dxi-item>
				<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>	
				<dxi-item dataField="orderReference" [label]="{ text: 'Order Reference' }"></dxi-item>	
				<dxi-item>
					<dx-file-uploader [maxFileSize]="2097152" [uploadFile]="uploadCSVForImport"></dx-file-uploader>
				</dxi-item>
				<dxi-item>
					<p *ngIf="validationErrors.length > 0">Please fix the below issues and re-upload:</p>
					<ul *ngIf="validationErrors.length > 0" style="overflow-y: scroll; height: 120px;">
						<li *ngFor="let validationError of validationErrors" class="text-danger">{{validationError}}</li>
					</ul>
					<p>&nbsp;</p>
				</dxi-item>
			</dx-form>
		</div>
</dx-popup>