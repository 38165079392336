import { Component, SecurityContext, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { environment } from "@environments/environment";
import { GetProductionStatusColor } from "@helpers/getProductionStatusColor";
import { WorkOrderStage } from "@interfaces/workOrderStage.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { MachineService } from "@services/machine.service";
import { WorkOrderStageService } from "@services/workOrderStage.service";
import notify from "devextreme/ui/notify";
import { AuthService } from "@services/auth.service";
import { ChangeWorkOrderStageOperator } from "@interfaces/changeWorkOrderStageOperator.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { Department } from "@enums/department";
import { UserProfileService } from "@services/userProfile.service";
import { WorkOrderService } from "@services/workOrder.service";
import { WorkOrder } from "@interfaces/workOrder.interface";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { StaffAttendanceService } from "@services/staffAttendance.service";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "supervisor",
	styleUrls: ["supervisor.css"],
	templateUrl: "supervisor.html"
})
export class SupervisorComponent {
	currentMachine: any;
	currentStatus = 10;
	departmentLookup: any[] = [];
	departmentName = "";
	departments: any[] = [];
	isLoading = false;
	machines: any[] = [];
	machinesOriginal: any[] = [];
	opMachines: any[] = [];
	popupChangeWorkOrderStageOperator: ChangeWorkOrderStageOperator = new ChangeWorkOrderStageOperator();
	requiredAmount = 0;
	runningStages: WorkOrderStage[] = [];
	selectedUserProfile: UserProfile = new UserProfile;
	selectedUserProfileId = 0;
	showComplete = false;
	showInfo = false;
	showMimic = false;
	showMode = false;
	showOperatorChange = false;
	showPopup = false;
	showSuperLogin = false;
	showSupervisor = false;
	showUserStatus = false;
	siteId = 0;
	siteName = "";
	staffAttendance: any[] = [];
	stageId = 0;
	stageNumber = "";
	statusLookup: { id: number, name: string }[] = [];
	supervisorCode = "";
	userProfiles: UserProfile[] = [];
	workOrderId = 0;

	constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private authService: AuthService, private workOrderStageService: WorkOrderStageService, private machineService: MachineService, private appSettingsService: AppSettingsService, private userProfileService: UserProfileService, private workOrderService: WorkOrderService, private staffAttendanceService: StaffAttendanceService) {
		this.departmentLookup = GetPropertiesFromEnum(Department);
		this.statusLookup = GetPropertiesFromEnum(ProductionStatus);
		console.log(this.statusLookup);
		this.route.params
			.subscribe((params) => {
				this.siteId = Number.parseInt(params.id);
				this.isLoading = true;
				this.showPopup = true;
				this.getData();
				setTimeout(() => {
					this.isLoading = false;
					this.showPopup = false;
				}, 2000);
			});
	}

	changeMode(status: ProductionStatus) {
		this.submitProductionMode(status);
		this.toggleMode();
	}

	changeWorkOrderStageOperator() {
		const deptId = this.departmentLookup.find(x => x.name == this.currentMachine.department).id;
		this.popupChangeWorkOrderStageOperator.userProfileId = this.selectedUserProfile.id;
		this.popupChangeWorkOrderStageOperator.workOrderStageId = this.currentMachine.runningStages[0].id;
		this.workOrderStageService.changeOperator(this.popupChangeWorkOrderStageOperator)
			.subscribe(
				() => {
					notify("Work Order Stage Operator Updated", "success", 5000);
					this.toggleOperatorChange();
					console.log(deptId);
					this.getUserProfiles();
					this.selectedUserProfileId = 0;
					this.selectedUserProfile = new UserProfile();
					this.reloadPage();

				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	changeWorkOrderStageOperatorPopup(stageId: number) {
		this.popupChangeWorkOrderStageOperator = new ChangeWorkOrderStageOperator();
		this.popupChangeWorkOrderStageOperator.workOrderStageId = stageId;
		this.showOperatorChange = true;
		return false;
	}

	formatDate(dateComplete: Date) {
		const date = new Date(dateComplete);
		if (date.getFullYear() != 1) {
			const dateFormat = `${date.getDay()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
			return dateFormat;
		}
		return "";
	}

	getData() {
		this.machineService.appOpsPlannerForSite(this.siteId)
			.subscribe(
				(res: any) => {

					const machinesTemp = res.response;
					this.siteName = res.response[0].siteName;
					this.machines = machinesTemp.filter((x: { cellNumber: null; }) => x.cellNumber != null);
					this.machinesOriginal = machinesTemp.filter((x: { cellNumber: null; }) => x.cellNumber != null);
					const unique = [...new Set(res.response.map((item: { department: any; }) => item.department))]; // [ 'A', 'B']
					this.departments = unique.filter(x => x != "Back Office");
					if (this.departmentName == "") {
						this.departmentName = this.departments[0];
					}
					this.machines = this.machinesOriginal.filter(x => x.department == this.departmentName);
					if (this.currentMachine.machineId > 0) {
						const machineId = this.currentMachine.machineId;
						this.currentMachine = this.machinesOriginal.find(x => x.machineId == machineId);
					}
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment["build"][property];
	}

	getUserProfiles() {
		const deptId = this.departmentLookup.find(x => x.name == this.currentMachine.department);
		this.userProfileService.byCurrentDepartment(deptId.id)
			.subscribe(
				(res: any) => this.userProfiles = res.response.filter((x: { id: any; }) => x.id != this.currentMachine.runningStages[0].assignedUserProfileId),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	mimicUrl() {
		const totalUrl = "https://bplflow.com/admin/operator/" + this.currentMachine.interfaceId;
		const sanitized = this.sanitizer.sanitize(SecurityContext.URL, totalUrl);
		return this.sanitizer.bypassSecurityTrustResourceUrl(totalUrl);
		return sanitized;
	}

	reloadPage() {
		const popupStart = this.showPopup;
		this.isLoading = true;
		this.showPopup = true;

		this.getData();

		setTimeout(() => {
			this.isLoading = false;
			this.showPopup = false;
			this.showPopup = popupStart;
		}, 2000);
	}

	renderStatusColor(status: ProductionStatus) {
		return GetProductionStatusColor(status);
	}

	renderStatusName(status: ProductionStatus) {
		return this.statusLookup.filter(x => x.id == status)[0].name.toUpperCase();
	}

	renderUserAssigned() {
		if (this.currentMachine.runningStages.length > 0) {
			if (this.currentMachine.runningStages[0].assignedUserProfileId > 0) {
				return "Operator: " + this.currentMachine.runningStages[0].assignedUserProfileFullName;
			} else {
				return "No User Assigned to Machine!";
			}
		} else {
			return "No User Assigned to Machine!";
		}
	}

	renderWorkOrderHidden() {
		const displayed = this.currentMachine.availableStages.slice(0, 3).length;
		const totalCount = this.currentMachine.availableStages.length;
		if (totalCount != displayed) {
			return "Total of " + (totalCount - displayed) + " work orders hidden";
		} else {
			return "";
		}
	}

	selectName() {
		this.selectedUserProfile = this.userProfiles.find(x => x.id == this.selectedUserProfileId)!;
	}

	selectSiteForScreen(data: any) {
		this.departmentName = data.itemData;
		this.machines = this.machinesOriginal.filter(x => x.department == data.itemData);
	}

	splitWorkOrder() {
		if (confirm("Are you sure you wish to split this work order? This cannot be undone.") == false) {
			return;
		}
		const currentStage = this.currentMachine.runningStages[0];
		let workOrder = new WorkOrder();
		this.workOrderService.getSingleById(currentStage.workOrderId)
			.subscribe(
				(res: any) => workOrder = res.response,
				(err) => console.log(err)
			);

		if ((workOrder.parentWorkOrderId !== undefined && workOrder.parentWorkOrderId > 0)
			|| currentStage === undefined || currentStage === null || currentStage.length === 0) {
			alert("This Work Order cannot be split.");
		}

		if (currentStage.quantityCompleted > 0 && currentStage.quantityComplete < currentStage.quantityRequired) {
			this.workOrderService.split(currentStage.workOrderId)
				.subscribe(
					() => this.reloadPage(),
					(err) => console.log(err)
				);
		}
	}

	submitProductionMode(prodStatus: ProductionStatus) {
		if (prodStatus == this.currentMachine.productionStatus) {
			alert("Machine is already in selected mode.");
		} else {
			this.machineService.updateProductionStatusSupervisor(this.currentMachine.machineId, prodStatus)
				.subscribe(
					() => {
						notify("Production Mode Changed Successfully!", "success", 5000);
						this.reloadPage();
					},
					(err) => {
						notify("Something went wrong!", "error", 5000);
						console.log(err);
					}
				);
		}
	}

	submitRequiredQty() {
		this.currentMachine.runningStages.forEach((stage: any) => {
			this.workOrderStageService.requiredAmountAdjustment(stage.id, this.requiredAmount)
				.subscribe(
					() => {
						notify("Required Amount Submitted Successfully!", "success", 10000);
					},
					(err) => {
						notify("Something went wrong!", "error", 5000);
						console.log(err);
					}
				);
		});
		this.getData();
	}

	submitStillageRequest() {
		this.machineService.requestStillageSupervisor(this.currentMachine.runningStages[0].id)
			.subscribe(
				() => {
					notify("Request Submitted Successfully!", "success", 10000);
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
		this.getData();
	}

	submitSupervisorLogin() {
		this.authService.loginEmployeeCode(this.supervisorCode)
			.subscribe(
				() => {
					this.authService.checkSupervisor(this.supervisorCode)
						.subscribe(
							(res: any) => {
								if (res.response) {
									this.toggleSupervisor(this.currentMachine);
									this.toggleInfo(this.currentMachine);
								}
							},
							(err) => {
								notify("Something went wrong!", "error", 5000);
								console.log(err);
							}
						);
					notify("Login Successful!", "success", 5000);
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
		this.getData();
	}

	/* Toggles */
	toggleComplete() {
		this.showComplete = !this.showComplete;
	}

	toggleInfo(machine: any) {
		if (machine != null) {
			this.currentMachine = machine;
			this.getUserProfiles();
		}
		this.showInfo = !this.showInfo;
		this.showPopup = !this.showPopup;
	}

	toggleMimic() {
		this.showMimic = !this.showMimic;
	}

	toggleMode() {
		this.showMode = !this.showMode;
	}

	toggleOperatorChange() {
		this.showOperatorChange = !this.showOperatorChange;
	}

	toggleSupervisor(machine: any) {
		this.currentMachine = machine;
		this.showSuperLogin = !this.showSuperLogin;
		this.showPopup = !this.showPopup;
	}

	toggleUserStatus() {
		if (!this.showUserStatus) {
			this.isLoading = true;
			this.staffAttendanceService.app_currentStatusForSite(this.siteId)
				.subscribe(
					(res: any) => {
						if (res.response) {
							console.log(res.response);
							this.staffAttendance = res.response;
						}
						this.isLoading = false;
					},
					(err) => {
						notify("Something went wrong!", "error", 5000);
						console.log(err);
						this.isLoading = false;
					}
				);
		}
		this.showUserStatus = !this.showUserStatus;
		this.showPopup = !this.showPopup;
	}

	windowWidthCheck() {
		return window.innerWidth >= 768;
	}

}