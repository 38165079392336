<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details">
				<dxi-item dataField="name"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="dateCommissioned" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="deviceType" editorType="dxSelectBox" [editorOptions]="{ items: deviceTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>