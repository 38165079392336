import { Model } from "@interfaces/model.interface";

export interface Invoice extends Model {
	clientBillingLocationId: number;
	clientDeliveryLocationId: number;
	clientId: number;
	completed: boolean;
	dateSent?: Date;
	deliveryNoteId?: number;
	generatedById: number;
	includeDeliveryCost: boolean;
	internalNotes: string;
	lineItems: string;
	salesOrderId: number;
	shipVia: string;
}

export class Invoice implements Invoice {	
	constructor(data?: Partial<Invoice>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}