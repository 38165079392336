import { Model } from "@interfaces/model.interface";

export interface User extends Model {
	accessToken: string;
	accessTokenExpiresOn?: Date;
	active: boolean;
	email: string;
	failedLoginAttempts: number;
	failedLoginTime?: Date;
	firstName: string;
	lastName: string;
	notes: string;
	oldAccessToken: string;
	oldAccessTokenExpiresOn?: Date;
	passwordExpiresOn: Date;
	passwordNeverExpires: boolean;
	passwordResetToken: string;
	passwordResetTokenExpiresAt?: Date;
	userName: string;
}

export class User implements User {
	accessToken = "";
	accessTokenExpiresOn?: Date;
	active = false;
	email = "";
	failedLoginAttempts = 0;
	failedLoginTime?: Date;
	firstName = "";
	lastName = "";
	notes = "";
	oldAccessToken = "";
	oldAccessTokenExpiresOn?: Date;
	passwordExpiresOn = new Date();
	passwordNeverExpires = false;
	passwordResetToken = "";
	passwordResetTokenExpiresAt?: Date;
	userName = "";

	constructor(data?: Partial<User>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}