import { Department } from "@enums/department";
import { Model } from "@interfaces/model.interface";

export interface Process extends Model {
	department: Department;
	description: string;
	hourlyLabourRate: number;
	hourlyMachineRate: number;
	isSampleBlank: boolean;
	isSingleQuantityStage: boolean;
	name: string;
	templateId: number;
	totalHourlyRate: number;
}

export class Process implements Process {	
	department = Department.BackOffice;
	description = "";
	hourlyLabourRate = 0;
	hourlyMachineRate = 0;
	name = "";
	templateId = 0;
	totalHourlyRate = 0;

	constructor(data?: Partial<Process>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}