<div class="limiter">
	<div class="container-login100">
		<div class="wrap-login100">
			<div class="login100-pic " data-tilt="">
				<img src="../../../assets/Logo.png">
			</div>
			<form class="login100-form validate-form">
				<span class="login100-form-title">
					Password Reset
				</span>
				<div class="wrap-input100 validate-input" data-validate="Password is required">
					<input class="input100" type="password" name="password"  [(ngModel)]="resetPasswordDetails.password" placeholder="Password" autocomplete="off"
						style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;">
					<span class="focus-input100"></span>
					<span class="symbol-input100">
						<i class="fa fa-lock" aria-hidden="true"></i>
					</span>
					<span *ngIf="validationErrors.password !== ''" class="text-danger text-nowrap">{{validationErrors.password}}</span>
				</div>
				<div class="wrap-input100 validate-input" data-validate="Password is required">
					<input class="input100" type="password" name="confirmPassword" placeholder="Confirm Password"  [(ngModel)]="resetPasswordDetails.confirmPassword" autocomplete="off"
						style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;">
					<span class="focus-input100"></span>
					<span class="symbol-input100">
						<i class="fa fa-lock" aria-hidden="true"></i>
					</span>
					<span *ngIf="validationErrors.confirmPassword !== ''" class="text-danger">{{validationErrors.confirmPassword}}</span>
				</div>
				<div class="container-login100-form-btn">
					<button class="login100-form-btn" (click)="submitData()">
						Submit Details
					</button>
				</div>
				<div class="text-center p-t-12" style="margin-top:20px; ">
					<span class="txt1">
						{{passwordComplexityRules}}
					</span>
				</div>
			</form>
		</div>
	</div>
</div>
