import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EmailEditorComponent } from "angular-email-editor";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { EmailTemplate } from "@interfaces/emailTemplate.interface";
import { EmailTemplateService } from "@services/emailTemplate.service";

@Component({
	moduleId: module.id,
	selector: "emailTemplates-id",
	styleUrls: ["emailTemplates-id.css"],
	templateUrl: "emailTemplates-id.html"
})

export class EmailTemplateIdAdminComponent {
	createEnabled = true;
	editEnabled = true;
	@ViewChild("editor")
	private emailEditor!: EmailEditorComponent;
	headerPrimaryButtons: AdminHeaderButton[] =[];
	item: EmailTemplate = new EmailTemplate();
	itemId = 0;
	itemType = "Email Template";
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	statuses: any;
	title: string = "View " + this.itemType;

	constructor(private router:Router, private route: ActivatedRoute,private itemService: EmailTemplateService, ) {
		this.buildButtons("edit");
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {						
						this.getItem(params.id);
					}
				} else {
					this.create();
				}
			});
	}

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "duplicate", text: "Duplicate"},
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.emailEditor.editor.exportHtml((data:any) => {
			this.item.html = data.html;
			this.item.design = data.design;
					
			this.itemService.createSingle(this.item)
				.subscribe(
					() => {
						notify("Successfully Updated " + this.itemType, "success", 5000);
						this.item = new EmailTemplate();
						this.close();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					}
				);
		});
	}

	duplicate() {
		const newItem = new EmailTemplate();
		newItem.subject = this.item.subject;
		newItem.templateName = this.item.templateName + " - Copy";
		newItem.design = this.item.design;
		newItem.html = this.item.html;
		newItem.notes = this.item.notes;
		newItem.emailPriority = this.item.emailPriority;
		newItem.senderEmailAddress = this.item.senderEmailAddress;
		newItem.senderName = this.item.senderName;

		this.item = newItem;
		this.createItem();
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	editorLoaded(loaded: any) {
		// load the design json here
		const lintIsAnnoying = loaded;
		loaded = lintIsAnnoying;
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => this.item = res.response,
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
				() => this.emailEditor.editor.loadDesign(this.item.design)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateItem() {
		this.emailEditor.editor.exportHtml((data:any) =>
		{
			this.item.html = data.html;
			this.item.design = data.design;
			this.itemService.updateSingleById(this.item.id, this.item)
				.subscribe(
					() => {
						notify("Successfully Updated " + this.itemType, "success", 5000);
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					}
				);
		});
	}

	valueChange(value: any) {
		this.item.html = value;
	}
}