<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="clientMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button *ngIf="editEnabled" hint="Edit" icon="fal fa-pencil" [onClick]="editRow"></dxi-button>
		<dxi-button *ngIf="deleteEnabled" icon="fal fa-trash" [onClick]="deleteRow"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" dataType="number" alignment="left"></dxi-column>
    <dxi-column dataField="name" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="supplierCode" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="town" dataType="string" alignment="left"></dxi-column>
    <dxi-column dataField="postcode" dataType="string" alignment="left"></dxi-column>
</dx-data-grid>