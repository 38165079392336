import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { LogExport } from "@interfaces/logExport.interface";
import { Service } from "@services/service";

@Injectable()
export class ExportLogService extends Service {
	constructor(injector: Injector) {
		super("ExportLog", injector);
	}

	logExport(data: LogExport) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/LogExport`, data);
	}
}