<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="6">
				<dxi-item dataField="name" [colSpan]="3"></dxi-item>
				<dxi-item [colSpan]="3" dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>	
				<dxi-item dataField="description" [colSpan]="6"></dxi-item>		
			</dxi-tab>
			<dxi-tab title="Stats" *ngIf="this.readOnly">
				<dxi-item itemType="group" caption="Project Potential" [colCount]="3">
					<dxi-item dataField="potentialTotalEnq" [editorOptions]="{ readOnly: true}" [label]="{ text: 'Potential Sales' }"></dxi-item>
					<dxi-item dataField="potentialTotalVal" [label]="{ text: 'Potential Sales Value' }" [editorOptions]="{ readOnly: true, format: {type:'currency',currency:'GBP', precision: 2} }"></dxi-item>
					<dxi-item dataField="potentialQty" [label]="{ text: 'Potential Sales Quantity' }" [editorOptions]="{ readOnly: true}"></dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="Project Actual Performance" [colCount]="3">
					<dxi-item [label]="{ text: 'Sales' }" dataField="potentialTotalEnq.thing" [editorOptions]="{ readOnly: true}"></dxi-item>
					<dxi-item [label]="{ text: 'Sales Value' }" dataField="potentialTotalVal.thing" [editorOptions]="{ readOnly: true, format: {type:'currency',currency:'GBP', precision: 2} }"></dxi-item>
					<dxi-item dataField="totalSpend" [editorOptions]="{readOnly: true, format: {type:'currency',currency:'GBP', precision: 2} }"></dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Enquiries" *ngIf="this.readOnly">
				<dx-data-grid class="data-grid" [dataSource]="enquiries" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="projectEnquiries"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewEnquiry"></dxi-button>
					</dxi-column>
					<dxi-column dataField="quoteNumber" alignment="left"></dxi-column>
					<dxi-column dataField="assignedToId">
						<dxo-lookup
							[dataSource]="users"
							valueExpr="id"
							displayExpr="fullName">
					</dxo-lookup>
					</dxi-column>
					<dxi-column dataField="client.name" caption="Customer Name"></dxi-column>
					<dxi-column dataField="enquiryValue" alignment="right" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 3 }"></dxi-column>
					<dxi-column dataField="enquiryQuantity" alignment="right"></dxi-column>
					<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="approved" alignment="left" [customizeText]="boolYesNo"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="quoted" alignment="left" [customizeText]="boolYesNo"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Purchase Orders" *ngIf="this.readOnly">
				<dx-data-grid class="data-grid" [dataSource]="purchases" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="projectPurchase"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewPo"></dxi-button>
					</dxi-column>
					<dxi-column dataField="id" alignment="left"></dxi-column>
					<dxi-column dataField="email"></dxi-column>
					<dxi-column dataField="supplier.name" caption="Supplier Name"></dxi-column>
					<dxi-column dataField="totalCost" alignment="right" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
					<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>