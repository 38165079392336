import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class OpsActionAuditService extends Service {
	constructor(injector: Injector) {
		super("OpsActionAudit", injector);
	}

	// API
	getByMachineId(id?: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByMachineId/${id}`);
	}

	getByOperatorId(id?: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByOperatorId/${id}`);
	}

	getByPartId(id?: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByPartId/${id}`);
	}

	getByUserProfileId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByUserProfileId/${id}`);
	}

	getByWorkOrderId(id?: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByWorkOrderId/${id}`);
	}

	getByWorkOrderStageId(id?: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByWorkOrderStageId/${id}`);
	}
}