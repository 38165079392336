import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { SystemParamValue } from "@interfaces/systemParamValue.interface";
import { Service } from "@services/service";

@Injectable()
export class SystemParameterService extends Service {
	constructor(injector: Injector) {
		super("SystemParams", injector);
	}

	// API
	getSystemParamValueById(id: number, vid: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/${id}/Values/${vid}`);
	}

	getSystemParamValuesById(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/${id}/Values`);
	}

	updateSystemParamValueById(id: number, vid: number, data: SystemParamValue) {
		return this.http.patch(`${environment.server.apiURL}/${this.endpoint}/${id}/Values/${vid}`, data);
	}
}