import { Invoice } from "./invoice.interface";

export interface CreateInvoiceFromShippingNote extends Invoice {
	alsoCreateDeliveryNote: boolean;
}

export class CreateInvoiceFromShippingNote implements CreateInvoiceFromShippingNote {	
	constructor(data?: Partial<CreateInvoiceFromShippingNote>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}