import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { PFMEA } from "@interfaces/pfmea.interface";
import { PFMEAActionTaken } from "@interfaces/pfmeaActionTaken.interface";
import { PFMEACPCDetails } from "@interfaces/pfmeaCPCDetails.interface";
import { MachineService } from "@services/machine.service";
import { PFMEAService } from "@services/pfmea.service";

@Component({
	moduleId: module.id,
	selector: "pfmeas-id",
	styleUrls: ["pfmeas-id.css"],
	templateUrl: "pfmeas-id.html"
})
export class PFMEASIdAdminComponent {
	@ViewChild("actionsTakenGridContainer", { static: false }) actionsTakenDataGrid!: DxDataGridComponent;
	@ViewChild("cpcGridContainer", { static: false }) cpcDataGrid!: DxDataGridComponent;
	actionsTaken: PFMEAActionTaken[] = [];
	allowedNumbers: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	changes: any = [];
	cpcDetails: PFMEACPCDetails[] = [];
	createEnabled = true;
	editEnabled = true;
	editRowKey: any = null;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	item: PFMEA = new PFMEA();
	itemType = "PFMEA";
	machineId = 0;
	machineName = "";
	purchases: any[] = [];
	readOnly = true;
	title: string = "View " + this.itemType;

	constructor(private router:Router, private route: ActivatedRoute, private machineService: MachineService, private itemService: PFMEAService) {
		this.buildButtons("view");
		this.route.params
			.subscribe((params) => {
				this.machineId = params.machineId;
				this.getMachine(this.machineId);

				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {
						this.getItem(params.id);
					}
				} else {
					this.create();
				}
			});

		this.onInitNewActionTakenRow = this.onInitNewActionTakenRow.bind(this);
		this.saveActionsTakenChanges = this.saveActionsTakenChanges.bind(this);
	}

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];	
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType + (this.machineName !== "" ? ` - ${this.machineName}` : "");
	}

	close() {
		this.router.navigate([`admin/machines/${this.machineId}/pfmeas`]);
	}

	create() {
		this.item.machineId = this.machineId;
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType + (this.machineName !== "" ? ` - ${this.machineName}` : "");
	}

	createItem() {
		this.prepareItemForSaving();
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new PFMEA();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType + (this.machineName !== "" ? ` - ${this.machineName}` : "");
		notify("You are now editing...", "warning", 5000);
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "detection":
			case "occurance":
			case "severity":
				if (this.item.detection === undefined || this.item.detection === null) {
					this.item.detection = 0;
				}
				if (this.item.occurance === undefined || this.item.occurance === null) {
					this.item.occurance = 0;
				}
				if (this.item.severity === undefined || this.item.severity === null) {
					this.item.severity = 0;
				}
				this.item.rpn = this.item.detection * this.item.occurance * this.item.severity;
				break;
		}
	}
	
	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.actionsTaken = JSON.parse(this.item.actionsTaken);
					this.cpcDetails = JSON.parse(this.item.cpcDetails);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getMachine(id: number) {
		this.machineService.getSingleById(id)
			.subscribe(
				(res: any) => { this.machineName = res.response.name; this.title = this.title + ` - ${this.machineName}`;},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}
 
	onInitNewActionTakenRow(row: any) {
		row.data.detection = 0;
		row.data.occurance = 0;
		row.data.severity = 0;
	}

	prepareItemForSaving() {
		this.item.actionsTaken = JSON.stringify(this.actionsTaken);
		this.item.cpcDetails = JSON.stringify(this.cpcDetails);
	}

	saveActionsTakenChanges() {
		this.actionsTakenDataGrid.instance.saveEditData();

		this.actionsTaken.find((action: any) => {
			if (action.detection === undefined || action.detection === null) {
				action.detection = 0;
			}
			if (action.occurance === undefined || action.occurance === null) {
				action.occurance = 0;
			}
			if (action.severity === undefined || action.severity === null) {
				action.severity = 0;
			}
			action.rpn = action.detection * action.occurance * action.severity;
		});
	}

	updateItem() {
		this.prepareItemForSaving();
		this.itemService.updateSingleById(this.item.id,this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}
