<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="row">
	<div class="col-12 p-3">
		<div id="opfloorplan"></div>
	</div>
</div>
<dx-popup [width]="750" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *ngIf="popupMachine !== null" class="machineInfo">
		<p>{{popupMachine.cellNumber}} - {{popupMachine.machineName}}</p>
		<table>
			<tbody>
				<tr>
					<th class="running" colspan="4">Running</th>
				</tr>
				<tr>
					<th scope="col">W/O</th>
					<th scope="col">Part</th>
					<th scope="col">Qty Completed</th>
					<th scope="col">Qty Required</th>
					<th scope="col">Operator</th>
				</tr>
				<tr *ngFor="let workOrderStage of popupMachine.runningStages">
					<th scope="row" [class]="workOrderStage.isPaused ? 'paused' : ''"><i *ngIf="workOrderStage.isPaused" class="fa-pause-circle fal me-2"></i>{{workOrderStage.workOrderId}}</th>
					<td>{{workOrderStage.partNumber}}</td>
					<td>{{workOrderStage.quantityCompleted}}</td>
					<td>{{workOrderStage.quantityRequired}}</td>
					<td>{{workOrderStage.assignedUserProfileFullName}}</td>
				</tr>
				<tr>
					<th class="available" colspan="4">Available</th>
				</tr>
				<tr>
					<th scope="col">W/O</th>
					<th scope="col">Part</th>
					<th scope="col">Qty</th>
					<th scope="col">Operator</th>
				</tr>					
				<tr *ngFor="let workOrderStage of popupMachine.availableStages">
					<th scope="row" [class]="workOrderStage.isPaused ? 'paused' : ''"><i *ngIf="workOrderStage.isPaused" class="fa-pause-circle fal me-2"></i>{{workOrderStage.workOrderId}}</th>
					<td>{{workOrderStage.partNumber}}</td>
					<td>{{workOrderStage.quantityRequired}}</td>
					<td>{{workOrderStage.assignedUserProfileFullName}}</td>
				</tr>
				<tr>
					<th class="pending" colspan="4">Pending</th>
				</tr>
				<tr>
					<th scope="col">W/O</th>
					<th scope="col">Part</th>
					<th scope="col">Qty</th>
					<th scope="col">Operator</th>
				</tr>
				<tr *ngFor="let workOrderStage of popupMachine.pendingStages">
					<th scope="row" [class]="workOrderStage.isPaused ? 'paused' : ''"><i *ngIf="workOrderStage.isPaused" class="fa-pause-circle fal me-2"></i>{{workOrderStage.workOrderId}}</th>
					<td>{{workOrderStage.partNumber}}</td>
					<td>{{workOrderStage.quantityRequired}}</td>
					<td>{{workOrderStage.assignedUserProfileFullName}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</dx-popup>