import { AfterViewInit, Component, Input } from "@angular/core";
import { InvoiceGridLineItem } from "@interfaces/invoiceGridLineItem.interface";

@Component({
	moduleId: module.id,
	selector: "invoiceGridDetails",
	styleUrls: ["invoiceGridDetails.css"],
	templateUrl: "invoiceGridDetails.html"
})
export class InvoiceGridDetailsComponent implements AfterViewInit {
	@Input() lineItemData = "";
	lineItems: InvoiceGridLineItem[] = [];
  
	ngAfterViewInit() {
		this.lineItems = JSON.parse(this.lineItemData);
	}
}