import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class MenuService extends Service {
	constructor(injector: Injector) {
		super("Menu", injector);
	}

	// API
	getMenu(menuType: string) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetMenu/${menuType}`);
	}

	getPublicMenu(menuType: string) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetPublicMenu/${menuType}`);
	}

	getUserMainMenu() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetUserMainMenu`);
	}
}