<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="2">
				<dxi-item dataField="id" [colSpan]="1" [editorOptions]="{ readOnly: true }"></dxi-item>
				<dxi-item dataField="workReferenceNo" [colSpan]="1" [label]="{ text: 'BPL Reference' }"></dxi-item>
				<dxi-item dataField="supplierId" editorType="dxSelectBox" [editorOptions]="{ items: suppliers, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Supplier' }"></dxi-item>	
				<dxi-item dataField="supplierContactId" editorType="dxSelectBox" [editorOptions]="{ items: contacts, searchEnabled: true, displayExpr:'contactName', valueExpr:'id' }" [label]="{ text: 'Contact' }"></dxi-item>	
				<dxi-item dataField="purchaseType" editorType="dxSelectBox" [editorOptions]="{ items: purchaseTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true, value: '', readOnly: readOnly || screenMode !== 'create' }"></dxi-item>
				<dxi-item dataField="dueDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"></dxi-item>
				<dxi-item dataField="contactEmail" [editorOptions]="{ readOnly: 'true' || screenMode !== 'create' }"></dxi-item>
				<dxi-item dataField="contactName" [editorOptions]="{ readOnly: 'true' || screenMode !== 'create' }"></dxi-item>
				<dxi-item dataField="freightAmount" editorType="dxNumberBox" [editorOptions]="{ format: { type:'currency', currency:'GBP', precision: 4 } }"></dxi-item>
				<dxi-item dataField="totalCost" editorType="dxNumberBox" [label]="{ text: 'Sub Total' }" [editorOptions]="{ readOnly: 'true', format: { type:'currency', currency:'GBP', precision: 4 } }"></dxi-item>
				<dxi-item dataField="projectId" editorType="dxSelectBox" [editorOptions]="{ items: projects, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Project' }"></dxi-item>	
				<dxi-item dataField="siteId" editorType="dxSelectBox" [editorOptions]="{ items: sites, searchEnabled: true, displayExpr:'name', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Delivery Location' }"></dxi-item>	
				<dxi-item dataField="weight" [colSpan]="1" [label]="{ text: 'Weight' }"></dxi-item>
				<dxi-item dataField="packedIn" [colSpan]="1" [label]="{ text: 'Packed In' }"></dxi-item>
				<dxi-item dataField="specialInstructions" [colSpan]="2" [label]="{ text: 'Special Instructions' }"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Line Items">
				<dx-data-grid #gridContainer class="data-grid" [dataSource]="lineItems" [remoteOperations]="false" [allowColumnReordering]="false" (onInitNewRow)="onInitNewLineItemRow($event)" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="purchaseLines"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxo-editing mode="row" [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true" [confirmDelete]="false" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey"></dxo-editing>
					<dxo-toolbar>
						<dxi-item name="addRowButton" showText="always"></dxi-item>
					</dxo-toolbar>
					<dxi-column dataField="partId" dataType="number" alignment="left">
						<dxo-lookup [dataSource]="filteredParts" valueExpr="id" displayExpr="partNumber"></dxo-lookup>					
					</dxi-column>
					<dxi-column *ngIf="item.purchaseType < 90 && item.purchaseType != 40 && item.purchaseType != 50" dataField="lineDescription" dataType="string" alignment="left" width="500" [setCellValue]="onSetLineItemDescriptionCellValue">
						<dxo-lookup [dataSource]="filteredPurchasableItems" displayExpr="name" valueExpr="name"></dxo-lookup>
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column *ngIf="item.purchaseType == 50" dataField="lineDescription" dataType="string" alignment="left" width="500">
						<dxo-lookup [dataSource]="purchasableSpecs"></dxo-lookup>
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column *ngIf="item.purchaseType >= 90 || item.purchaseType == 40 " dataField="lineDescription" dataType="string" alignment="left"></dxi-column>
					<dxi-column *ngIf="item.purchaseType == 70" dataField="length" dataType="number" alignment="left"></dxi-column>
					<dxi-column *ngIf="item.purchaseType == 70" dataField="width" dataType="number" alignment="left"></dxi-column>
					<dxi-column *ngIf="item.purchaseType == 70" dataField="height" dataType="number" alignment="left"></dxi-column>
					<dxi-column *ngIf="item.purchaseType == 30" dataField="materialShape" dataType="number" alignment="left">
						<dxo-lookup [dataSource]="materialShapes" valueExpr="id" displayExpr="name"></dxo-lookup>					
					</dxi-column>
					<dxi-column *ngIf="item.purchaseType == 30" dataField="width" dataType="number" alignment="left"></dxi-column>
					<dxi-column *ngIf="item.purchaseType == 30" dataField="length" dataType="number" alignment="left"></dxi-column>
					<dxi-column *ngIf="item.purchaseType == 30" dataField="thickness" dataType="number" alignment="left" caption="Gauge">
						<dxo-lookup [dataSource]="thicknesses"></dxo-lookup>					
					</dxi-column>
					<dxi-column *ngIf="item.purchaseType == 30" dataField="height" dataType="number" alignment="left"></dxi-column>
					<dxi-column *ngIf="item.purchaseType == 30" dataField="weight" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="cost" dataType="number" alignment="left" caption="Unit Cost" [format]="{ type: 'currency', currency: 'GBP', precision: 4, useGrouping: true }">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column dataField="lineCost" dataType="number" alignment="left" [allowEditing]="false" [format]="{ type: 'currency', currency: 'GBP', precision: 4, useGrouping: true }"></dxi-column>
					<dxi-column type="buttons">
						<dxi-button name="edit"></dxi-button>
						<dxi-button name="delete"></dxi-button>
						<dxi-button name="save" [onClick]="saveLineItemChanges"></dxi-button>
						<dxi-button hint="Download Price" icon="money"[onClick]="downloadPrice"></dxi-button>
						<dxi-button name="cancel"></dxi-button>
						<dxi-button hint="Clone" icon="copy" [onClick]="cloneIconClick"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Supporting Files">
				<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
					<dx-data-grid class="data-grid" [dataSource]="supportingDocuments" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="purchaseDocs"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column dataField="description" dataType="text" alignment="left" caption="Description">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column type="buttons">
							<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
							<dxi-button hint="Delete" icon="fal fa-trash" [onClick]="deleteSupportingDocumentLink"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
				<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
					<dxi-item [visible]="screenMode != 'view'" itemType="button" [buttonOptions]="supportingDocumentButtonOptions"></dxi-item>
				</dxi-item>
			</dxi-tab>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Add New Purchasable Item'">
				<dx-form class="m-3" [(formData)]="popupPurchasableItem" [showColonAfterLabel]="true" labelLocation="top" (onFieldDataChanged)="popupPurchasableItemFormDataChanged($event)">
					<dxi-item dataField="name" [label]="{ text: 'Name / Part Number' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="description"></dxi-item>
					<dxi-item dataField="purchaseType" editorType="dxSelectBox" [editorOptions]="{ items: purchaseTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="materialType" editorType="dxSelectBox" [editorOptions]="{ items: materialTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true, readOnly: (popupPurchasableItem.purchaseType != 30 && popupPurchasableItem.purchaseType != 70) }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="minimumBuyableQuantity" editorType="dxNumberBox" [label]="{ text: 'MBQ' }"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Supplier'">
				<dx-form class="m-3" [(formData)]="popupSupplier" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="name" [label]="{ text: 'Supplier Name' }">
						<dxi-validation-rule type="required" message="Supplier Name is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="telephoneNumber" [label]="{ text: 'Contact Phone Number' }"></dxi-item>
					<dxi-item dataField="address1" [label]="{ text: 'Address Line 1' }"></dxi-item>
					<dxi-item dataField="address2" [label]="{ text: 'Address Line 2' }"></dxi-item>
					<dxi-item dataField="town"></dxi-item>
					<dxi-item dataField="postcode"></dxi-item>
					<dxi-item dataField="excludeFromRFQs" editorType="dxCheckBox" [colSpan]="2"></dxi-item>
					<dxi-item [colSpan]="2">
						<p class="red">Please Note: Further details will need configuring (e.g. contacts, locations, purchasable items) through the admin page once this supplier is created.</p>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Contact'">
				<dx-form class="m-3" [(formData)]="popupContact" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="contactName">
						<dxi-validation-rule type="required" message="Contact Name is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item dataField="contactEmailAddress">
						<dxi-validation-rule type="required" message="Contact Email is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="jobTitle"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Supporting Document'">
				<dx-form class="m-3" [(formData)]="popupSupportingDocument" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="description">
						<dxi-validation-rule type="required" message="Description is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item>
						<dx-file-uploader [maxFileSize]="2097152" [uploadFile]="uploadSupportingDocument"></dx-file-uploader>
					</dxi-item>
					<dxi-item>
						<p>Adding this document will save all outstanding changes on the Purchase Log. Please ensure all details are correct before uploading.</p>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Project'">
				<dx-form class="m-3" [(formData)]="popupProject" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>	
					<dxi-item dataField="name">
						<dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item dataField="description"></dxi-item>
				</dx-form>
			</div>
		</div>
	</dx-popup>
</div>