import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class DashboardService extends Service {
	constructor(injector: Injector) {
		super("Dashboard", injector);
	}

	// API
	getForCurrentUser() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForCurrentUser`);
	}
}