export enum Status {    
	Active = 10, 
	Cancelled = 20,
	Suspended = 30,
	Deleted = 40,
	Waitlist = 50,
	Published = 60,
	Draft = 70,
	Unpublished = 80,
	Expired = 90
}