import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { Guid } from "guid-typescript";
import { FirewallLogFailureReason } from "@enums/firewallLogFailureReason.enum";
import { environment } from "@environments/environment";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { FirewallLog } from "@interfaces/firewallLog.interface";
import { Part } from "@interfaces/part.interface";
import { Project } from "@interfaces/project.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { WorkOrder } from "@interfaces/workOrder.interface";
import { ClientService } from "@services/client.service";
import { FileService } from "@services/file.service";
import { FirewallLogService } from "@services/firewallLog.service";
import { NonConformanceReportService } from "@services/nonConformanceReport.service";
import { PartService } from "@services/part.service";
import { ProjectService } from "@services/project.service";
import { UserProfileService } from "@services/userProfile.service";
import { WorkOrderService } from "@services/workOrder.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "firewallLogs-id",
	styleUrls: ["firewallLogs-id.css"],
	templateUrl: "firewallLogs-id.html"
})
export class FirewallLogsIdAdminComponent {
	areasOfIssue: string[] = [
		"CAD Issue",
		"Health and Safety",
		"Issue Found at Customer",
		"Maintenance Issue",
		"Person / Training Issue",
		"Process wrong",
		"Process - Assembly",
		"Process - 5 Axis Issue",
		"Process - Flat Laser issue",
		"Process - Goods-In",
		"Process - Press Issue",
		"Process - Press Brake issue",
		"Process - Weld Issue",
		"Process - Spot Weld Issue",
		"Process - Kingsland Issue",
		"Process - Fab/Deburr Issue",
		"Process - Firewall Issue",
		"Process - Logistics issue",
		"Process - Quality Issue",
		"Process - Toolroom",
		"Supplier - Material Issue",
		"Supplier - Outsource Issue",
	];
	clients: Client[] = [];
	failureReasons: any;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	imageStyle = "";
	item: FirewallLog = new FirewallLog();
	itemId = 0;
	itemType = "Firewall Log";
	mode = "";
	parts: Part[] = [];
	projects: Project[] = [];
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	title: string = "View " + this.itemType;
	userProfiles: UserProfile[] = [];
	workOrders: WorkOrder[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private clientService: ClientService, private fileService: FileService, private itemService: FirewallLogService, private nonConformanceReport: NonConformanceReportService, private partService: PartService, private projectService: ProjectService, private userProfileService: UserProfileService, private workOrderService: WorkOrderService) {
		this.getClients();
		this.getParts();
		this.getPeople();
		this.getProjects();
		this.getWorkOrders();

		this.failureReasons = GetPropertiesFromEnum(FirewallLogFailureReason);

		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.getItem(params.id.split("_")[0], "edit");
					this.edit();
				} else {
					this.getItem(params.id, "view");
				}
			} else {
				this.create();
			}
		});

		this.uploadImage = this.uploadImage.bind(this);
		this.viewNonConformanceReport = this.viewNonConformanceReport.bind(this);
	}

	buildButtons(mode: string) {
		this.mode = mode;
		this.headerPrimaryButtons = [];
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		if (mode == "edit") {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "Cancel" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create") {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			if (this.item.nonConformanceReportId != undefined && this.item.nonConformanceReportId > 0) {
				this.headerSecondaryButtons = [
					{ method: "viewNonConformanceReport", text: "View NCR" }
				];
			} else {
				this.headerSecondaryButtons = [
					{ method: "createNonConformanceReport", text: "Create NCR" }
				];
			}
		}
	}

	cancelEditing() {
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		if (this.mode === "view" || confirm("Are you sure you wish to leave the page? Unsaved changes will be discarded.")) {
			this.router.navigate([`/${this.returnUrl}/`]);
		}
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Added New " + this.itemType, "success", 5000);
					this.item = new FirewallLog();
					this.mode = "view";
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	createNonConformanceReport() {
		if (this.item.nonConformanceReportId != undefined && this.item.nonConformanceReportId > 0) {
			return;
		}
		if (confirm("Are you sure you wish to create an NCR for this log?") == false) {
			return;
		}
		this.itemService.createNonConformanceReport(this.item.id)
			.subscribe(
				() => {
					notify("Successfully Created NCR", "success", 5000);
					this.getItem(this.item.lookupId, this.mode);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "workOrderId":
				{
					const workOrder = this.workOrders.filter((w: WorkOrder) => w.id == args.value);
					this.item.partId = workOrder[0].partId;
					
					const part = this.parts.filter((p: Part) => p.id == this.item.partId);
					this.item.clientId = part[0].clientId;
					this.item.projectId = part[0].projectId;
				}
				break;
		}
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => {
					this.clients = res.response.items;
					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getItem(lookupId: Guid, mode: string) {
		this.itemService.getSingleByLookupId(lookupId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.setImageStyle(this.item.imageUrl);
					this.buildButtons(mode);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}
	
	getParts() {
		this.partService.getAll()
			.subscribe(
				(res: any) =>  {
					this.parts = res.response.items;
					this.parts.sort((a,b) => a.partNumber.localeCompare(b.partNumber));
				},
				(err) => console.log(err)
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.userProfiles = res.response;
					this.userProfiles.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}
	
	getProjects() {
		this.projectService.getAll()
			.subscribe(
				(res: any) =>  {
					this.projects = res.response.items;
					this.projects.sort((a,b) => a.displayName.localeCompare(b.displayName));
				},
				(err) => console.log(err)
			);
	}
	
	getWorkOrders() {
		this.workOrderService.getAll()
			.subscribe(
				(res: any) =>  {
					this.workOrders = res.response.items;
				},
				(err) => console.log(err)
			);
	}
	
	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	setImageStyle(imageUrl: string) {
		this.imageStyle = `background-image: url("${imageUrl}")`;
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadImage(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		uploadData.append("mimeTypeKey", "ImageMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.item.imageUrl = `${environment.server.storageUrl}/${containerName}/${res.response[0].location}`;
					this.setImageStyle(this.item.imageUrl);
					notify("Image uploaded - Click Save to confirm change", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Image Upload Failed - Please ensure you are uploading a valid image file", "error", 5000);
				}
			);
	}

	viewNonConformanceReport() {
		if (this.item.nonConformanceReportId == undefined) {
			return;
		}
		this.nonConformanceReport.getSingleById(this.item.nonConformanceReportId)
			.subscribe(
				(res: any) => {
					const ncr = res.response;
					this.router.navigate([`admin/nonConformanceReports/${ncr.lookupId}`]);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}