<div class="limiter">
	<div class="container-login100">
		<div class="wrap-login100">
			<div class="login100-pic " data-tilt="">
				<img src="../../../assets/Logo.png">
			</div>
			<form class="login100-form validate-form">
				<div *ngIf="requestSuccess">
					<span class="login100-form-title">Request Sent</span>
					<p>Your request has been processed. Please check your emails for further instructions.</p>
				</div>
				<div *ngIf="!requestSuccess">
					<span class="login100-form-title">Forgotten your password?</span>
					<p>Enter the email address associated with your account. You will receive further instructions via email.</p>
					<div class="wrap-input100 validate-input" data-validate="Valid email address is required: ex@abc.xyz">
						<input class="input100" type="text" name="email"  [(ngModel)]="requestDetails.emailAddress" placeholder="Email Address" style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;">
						<span *ngIf="validationErrors.emailAddress !== ''" class="text-danger text-nowrap">{{validationErrors.emailAddress}}</span>
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-envelope" aria-hidden="true"></i>
						</span>
					</div>
					<div class="container-login100-form-btn">
						<button class="login100-form-btn" (click)="submitData()">
							Request Password Reset
						</button>
					</div>
				</div>
				<div class="text-center p-t-12" style="margin-top:20px; ">
					<a class="txt2" href="/admin">Return to Login Page</a>
				</div>
			</form>
		</div>
	</div>
</div>
