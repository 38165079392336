<div class="operator-bg" style="overflow-x:auto; padding:20px">
	<div class="row">

	</div>
	<div class="row">
		<div class="col-4">
			<h1 style="color:white;padding-left:15px;">Production Overview</h1>
			<span style="color:#77787F; padding-left:15px;">Last Refresh: {{this.lastRefresh}} <span style="color:#DAFDCB">(Auto Refresh On)</span></span>
		</div>
		<div class="col-2">
			
		</div>
		<div class="col-2" style="padding-left:10px; padding-right:10px;">
			<div
				style="width:100%; height:50px; background-color:#1DFF00; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px;">
				<h3>{{this.prodAct}}</h3>
			</div>
		</div>
		<div class="col-2" style="padding-left:10px; padding-right:10px;">
			<div
				style="width:100%; height:50px; background-color:#FFA500; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px;">
				<h3>{{this.prodAmb}}</h3>
			</div>
		</div>
		<div class="col-2" style="padding-left:10px; padding-right:10px;">
			<div
				style="width:100%; height:50px; background-color:red; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px;">
				<h3>{{this.prodRed}}</h3>
			</div>
		</div>
	</div>
	
	<!-- <div class="row" style="border: solid 2px #838088; border-radius:25px;  background-color:#1E1828; margin-top:20px; margin-left:2.5%; width:95%">
		<h4 style="color:#838088; padding-left:15px; padding-top:10px">Machine Statuses</h4>
		<div class="col-4" style="padding:10px;">
			<div
				style="width:100%; height:100px; background-color:#DAFDCB; border-radius:25px; text-align: center; margin-top:auto; padding-top:35px;">
				<h3>5</h3>
			</div>
		</div>
		<div class="col-4" style="padding:10px;">
			<div
				style="width:100%; height:100px; background-color:#F1F962; border-radius:25px; text-align: center; padding-top:35px;">
				<h3>7</h3>
			</div>
		</div>
		<div class="col-4" style="padding:10px;">
			<div
				style="width:100%; height:100px; background-color:#F9988A; border-radius:25px; text-align: center; vertical-align: middle; padding-top:35px">
				<h3>3</h3>
			</div>
		</div>
	</div> -->
	<div class="row">
		<div *ngFor="let machine of machinesRunning" class="col-3 g-10">
			<div class="activeContainer" [class.breakdown]="machine.productionStatus == 120">
				<h3 style="color:white;">{{machine.cellNumber}} - <span
						[style.color]="renderStatusColor(machine.productionStatus)" [class.blink_me]="machine.productionStatus == 100 || machine.productionStatus == 120">{{renderStatusName(machine.productionStatus)}}</span>
				</h3>
				<div class="row">
					<div class="col-9">
						<div *ngIf="machine.runningStages.length > 0" class="row">
							<div class="col-4" style="padding:5px; color:#77787F;">Part <br /> <span
									style="color:white;">{{machine.runningStages[0].partNumber.slice(0,20)}}</span></div>
							<div class="col-4" style="padding:5px; color:#77787F;">Work Order <br /> <span
									style="color:white;">{{machine.runningStages[0].workOrderId}}</span></div>
							<div class="col-4" style="padding:5px; color:#77787F;">Operation <br /> <span
									style="color:white;">{{machine.runningStages[0].operationNumber}}</span>
							</div>
						</div>
						<div *ngIf="machine.runningStages.length > 0" class="row">
							<div class="col-4" style="padding:5px; color:#77787F;">Client <br /> <span
									style="color:white;">{{machine.runningStages[0].clientName.slice(0,20)}}</span></div>
							<div *ngIf="machine.runningStages[0].assignedUserProfileId > 0" class="col-4" style="padding:5px; color:#77787F;">Operator <br /> <span
									style="color:white;">{{machine.runningStages[0].assignedUserProfileFullName}}</span></div>
									<div *ngIf="machine.runningStages[0].assignedUserProfileId == null" class="col-4" style="padding:5px; color:#77787F;">Operator <br /> <span
										style="color:red;">No Operator</span></div>
							<div class="col-4" style="padding:5px; color:#77787F;">Quantity <br /> <span
									style="color:white;">{{machine.runningStages[0].quantityCompleted}}/{{machine.runningStages[0].quantityRequired}}</span>
							</div>
						</div>
					</div>
					<div class="col-3">
						<img style="height:115px; max-width:100%; max-height:215px !important;" [src]="machine.runningStages[0].partImage">
					</div>
				</div>
				
				<div class="row">
					<div class="col-12" style="padding:5px; color:#77787F;">
						<div style="width:100%; height:25px; background-color: #2B2B38; border-radius:25px">
							<div [class.blink_me_progress]="machine.productionStatus == 100" [class.blink_me_breakdown]="machine.productionStatus == 120" style="width:{{machine.runningStages[0].quantityCompleted/machine.runningStages[0].quantityRequired * 100}}%; background-color:#1DFF00; padding-left:20px; border-radius:25px">
								{{(machine.runningStages[0].quantityCompleted/machine.runningStages[0].quantityRequired * 100).toFixed(0)}}%
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
	<h4 style="color:white;padding-left:15px; padding: 15px;">No Work Orders Assigned</h4>
	<div class="row">
		<div *ngFor="let machine of machinesNoJob" class="col-2 g-10">
			<div
				style="border: solid 2px #838088; border-radius:25px;  background-color:#1E1828; margin-top:20px; padding:15px; width:90%; margin-left:5%; margin-right:5%;">
				<h3 style="color:white;">{{machine.cellNumber}} - <span [class.blink_me]="machine.productionStatus == 100 || machine.productionStatus == 120"
						[style.color]="renderStatusColor(machine.productionStatus)">{{renderStatusName(machine.productionStatus)}}</span>
				</h3>
			</div>
		</div>
	</div>
</div>