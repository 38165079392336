import { Injectable } from "@angular/core";

@Injectable()
export class AppSettingsService {
	settings: any = {};

	get(property: string, defaultReturn?: any) {
		const value = this.settings[property];
		if (value) return this.settings[property];
		else if (defaultReturn) return defaultReturn;
		else return undefined;
	}

	getFromLocalStorage(property: string) {
		const value = localStorage.getItem(property);
		if (value) {
			this.set(property, value);
			return value;
		}
		return undefined;
	}

	reset() {
		this.settings = {};
	}

	resetLocalStorage() {
		localStorage.clear();
	}

	set(property: string, value: any) {
		this.settings[property] = value;
		localStorage.setItem(property, value);
	}
}