import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class LogService extends Service {
	constructor(injector: Injector) {
		super("Logs", injector);
	}

	// API
	getLogById(logId: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/${logId}`);
	}
}