import { Model } from "@interfaces/model.interface";

export interface Email extends Model {
	body: string;
}

export class Email implements Email {
	body = "";
	
	constructor(data?: Partial<Email>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}