export interface ReduceArrearsOnParts {
	parts: string; // JSON List of ReduceArrearsOnPartsLineItem lines
}

export class ReduceArrearsOnParts implements ReduceArrearsOnParts {
	constructor(data?: Partial<ReduceArrearsOnParts>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}