<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="group" caption="Details" [colCount]="7">
			<dxi-item [colSpan]="2" dataField="requestedById" editorType="dxSelectBox" [editorOptions]="{ readOnly: true, items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Employee' }"></dxi-item>	
			<dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'Start Date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="startTime" editorType="dxSelectBox" [editorOptions]="{ items: holidayTimesStart, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Start Time' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
			<dxi-item dataField="endDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'End Date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="endTime" editorType="dxSelectBox" [editorOptions]="{ items: holidayTimesEnd, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'End Time' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
			<dxi-item dataField="requestStatus" editorType="dxSelectBox" [editorOptions]="{ readOnly: true, items: holidayRequestStatuses, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Status' }"></dxi-item>	
		</dxi-item>
		<dxi-item itemType="group" caption="Overlapping Requests (same shift)">
			<dx-data-grid class="data-grid" [dataSource]="overlappingRequests" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="holreqOverlap"></dxo-state-storing>
				<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="true"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="true"></dxo-export>
				<dxi-column dataField="requestedById" caption="Employee"><dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup></dxi-column>
				<dxi-column dataField="startDate" dataType="date" alignment="left" caption="Start Date" format="dd/MM/yyyy" sortOrder="asc"></dxi-column>
				<dxi-column dataField="startTime" dataType="number" alignment="left" caption="Start Time"><dxo-lookup [dataSource]="holidayTimes" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
				<dxi-column dataField="endDate" dataType="date" alignment="left" caption="End Date" format="dd/MM/yyyy"></dxi-column>
				<dxi-column dataField="endTime" dataType="number" alignment="left" caption="End Time"><dxo-lookup [dataSource]="holidayTimes" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
				<dxi-column dataField="holidayLength" dataType="number" alignment="left" caption="Length (days)"></dxi-column>
				<dxi-column dataField="requestStatus" dataType="number" alignment="left" caption="Status"><dxo-lookup [dataSource]="holidayRequestStatuses" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
				<dxi-column dataField="reviewedById" caption="Reviewer"><dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup></dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<dx-form class="m-3" [(formData)]="popupChangeHolidayRequestStatus" [showColonAfterLabel]="true" labelLocation="top" [colCount]="1">
				<dxi-item dataField="holidayRequestStatus" editorType="dxSelectBox" [editorOptions]="{ items: popupHolidayRequestStatuses, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'New Status' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item>When Declining or Revoking a holiday request, the Reason is required. The Employee will be notified of this reason by email.</dxi-item>
				<dxi-item dataField="reason" editorType="dxTextBox" [label]="{ text: 'Reason for change' }"></dxi-item>	
			</dx-form>
		</div>
	</dx-popup>
</div>