export enum QualityControlCheckType {
	ManualMeasurement = 0,
	CheckingFixture = 10,
	TemplateComparison = 20,
	GoGauge = 30,
	TorqueTest = 40,
	PushOutTest = 50,
	SquashTest = 60,
	DestructionTest = 70,
	Visual = 80
}