import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { ImportEdiOrdersCSV } from "@interfaces/importEdiOrdersCSV.interface";
import { Service } from "@services/service";

@Injectable()
export class EdiMessageService extends Service {
	constructor(injector: Injector) {
		super("EdiMessage", injector);
	}

	// API
	getGrid() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetGrid`);
	}

	getLatest() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/Latest/`);
	}
	
	importOrdersCSV(data: ImportEdiOrdersCSV) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ImportOrdersCSV`, data);
	}
	
	reprocessEdiMessage(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ReprocessMessage/${id}`, {});
	}
}