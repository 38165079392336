import { Model } from "@interfaces/model.interface";
import { Client } from "./client.interface";

export interface SalesOrder extends Model {
	client: Client;
	clientContactId: number;
	clientId: number;
	createdById: number;
	mrdDate: Date;
	poDocumentId?: number;
	poNumber: string;
	projectId: number;
	projectManagerId: number;
	quantity: number;
	rfQsCreated: boolean;
	salesEnquiryId: number;
	totalValue: number;
	vatValue: number;
}

export class SalesOrder implements SalesOrder {
	constructor(data?: Partial<SalesOrder>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}