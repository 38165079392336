export enum ProductionStatus {
	Active = 10,
	Idle = 20,
	Maintenance = 30,
	Inactive = 40,
	Changeover = 50,
	ToiletBreak = 60,
	Break = 70,
	QLoop = 80,
	QualityCheck = 90,
	AssistanceRequired = 100,
	InitialCheckFailed = 110,
	Breakdown = 120
}