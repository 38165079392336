<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [colCount]="2" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="group" caption="Issue Details" [colSpan]="2" [colCount]="6">
			<dxi-item [colSpan]="2" dataField="areaOfIssue" editorType="dxSelectBox" [editorOptions]="{ items: areasOfIssue, searchEnabled: true }" [label]="{ text: 'Area Of Issue' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item [colSpan]="2" dataField="dateReported" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'Report Date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item [colSpan]="2" dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="partId" editorType="dxSelectBox" [editorOptions]="{ items: parts, searchEnabled: true, displayExpr:'partNumber', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Part' }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="machineId" editorType="dxSelectBox" [editorOptions]="{ items: machines, searchEnabled: true, displayExpr: 'name', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Machine' }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="projectId" editorType="dxSelectBox" [editorOptions]="{ items: projects, searchEnabled: true, displayExpr:'displayName', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Project' }"></dxi-item>	
			<dxi-item [colSpan]="2" dataField="personResponsibleId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Person Responsible' }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="concernOrigin" editorType="dxSelectBox" [editorOptions]="{ items: concernOrigins, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Origin of Concern' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item [colSpan]="2" dataField="supplierId" editorType="dxSelectBox" [editorOptions]="{ items: suppliers, searchEnabled: true, displayExpr: 'name', readOnly: (readOnly || item.concernOrigin !== 20), showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Supplier' }"></dxi-item>
			<dxi-item [colSpan]="1" dataField="priority" editorType="dxSelectBox" [editorOptions]="{ items: priorities, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Priority' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item [colSpan]="1" dataField="customerConcernNumber"></dxi-item>
			<dxi-item [colSpan]="1" dataField="numberOfPartsAffected"></dxi-item>
			<dxi-item [colSpan]="1" dataField="quantitySuspected"></dxi-item>
			<dxi-item [colSpan]="1" dataField="invoiceNumber"></dxi-item>
			<dxi-item [colSpan]="1" dataField="costing" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
			<dxi-item [colSpan]="6" dataField="concern" editorType="dxTextArea" [editorOptions]="{ height: 150 }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="rootCause" editorType="dxTextArea" [editorOptions]="{ height: 150 }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="containment" editorType="dxTextArea" [editorOptions]="{ height: 150 }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="correctiveAction" editorType="dxTextArea" [editorOptions]="{ height: 150 }" [label]="{ text: 'Corrective Action & Recommendations' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="notes" editorType="dxTextArea" [editorOptions]="{ height: 150 }"></dxi-item>
			<dxi-item [colSpan]="1" dataField="automotive" editorType="dxCheckBox"></dxi-item>
			<dxi-item [colSpan]="1" dataField="previousOccurances" editorType="dxCheckBox"></dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="Care Point Info" [colSpan]="2" [colCount]="6">
			<dxi-item [colSpan]="1" dataField="carePoint" editorType="dxCheckBox"></dxi-item>
			<dxi-item [colSpan]="2" dataField="partRouterStageNumber" editorType="dxSelectBox" [editorOptions]="{ items: dropDownProcessRouters, searchEnabled: true, displayExpr:'name', showClearButton: true, valueExpr:'id', readOnly: (readOnly || item.partId === undefined || item.partId === null || item.carePoint !== true) }" [label]="{ text: 'Router Stage' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="carePointDetails" editorType="dxTextArea" [editorOptions]="{ height: 150, readOnly: (readOnly || item.carePoint !== true) }"></dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="Images" [colSpan]="1" [colCount]="1">
			<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
			<dx-data-grid class="data-grid" [dataSource]="images" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File"></dxi-column>
				<dxi-column dataField="description" dataType="text" alignment="left" caption="Description"></dxi-column>
				<dxi-column type="buttons">
					<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewImage"></dxi-button>
					<dxi-button [visible]="screenMode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deleteImageLink"></dxi-button>
				</dxi-column>
			</dx-data-grid></dxi-item>
			<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
				<dxi-item [visible]="screenMode != 'view'" itemType="button" [buttonOptions]="imageButtonOptions"></dxi-item>
			</dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="Supporting Documents" [colSpan]="1" [colCount]="1">
			<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
			<dx-data-grid class="data-grid" [dataSource]="supportingDocuments" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="description" dataType="text" alignment="left" caption="Description">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column type="buttons">
					<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
					<dxi-button [visible]="screenMode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deleteSupportingDocumentLink"></dxi-button>
				</dxi-column>
			</dx-data-grid></dxi-item>
			<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
				<dxi-item [visible]="screenMode != 'view'" itemType="button" [buttonOptions]="supportingDocumentButtonOptions"></dxi-item>
			</dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="People Responsible for Closure (min. 1)" [colSpan]="1" [colCount]="1">
			<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
			<dx-data-grid class="data-grid" [dataSource]="peopleResponsibleForClosure" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxi-column dataField="userProfileId" dataType="text" alignment="left" caption="Employee"><dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup></dxi-column>
				<dxi-column type="buttons">
					<dxi-button [visible]="screenMode != 'view'" hint="Remove" icon="fal fa-trash" [onClick]="deleteUserProfileLink"></dxi-button>
				</dxi-column>
			</dx-data-grid></dxi-item>
			<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
				<dxi-item [visible]="screenMode != 'view'" itemType="button" [buttonOptions]="peopleResponsibleForClosureButtonOptions"></dxi-item>
			</dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="Closing Details" [colSpan]="1" [colCount]="4">
			<dxi-item [colSpan]="1" dataField="deadline" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', readOnly: true, type: 'date' }" [label]="{ text: 'Deadline' }"></dxi-item>
			<dxi-item [colSpan]="1" dataField="dateForClosure" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'Date For Closure' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item [colSpan]="1" dataField="dateClosed" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', readOnly: true, type: 'date' }" [label]="{ text: 'Date Closed' }"></dxi-item>
			<dxi-item [colSpan]="1" dataField="closedById" editorType="dxSelectBox" [editorOptions]="{ items: people, readOnly: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Closed By' }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="totalTime" [editorOptions]="{ readOnly: true }" editorType="dxNumberBox" [label]="{ text: 'Total Time' }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="totalCost" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2}, readOnly: true }" editorType="dxNumberBox" [label]="{ text: 'Total Cost' }"></dxi-item>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="750" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Add New Supporting Document'">
				<dx-form class="m-3" [(formData)]="popupSupportingDocument" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="description">
						<dxi-validation-rule type="required" message="Description is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item>
						<dx-file-uploader [maxFileSize]="4194304" [uploadFile]="uploadSupportingDocument"></dx-file-uploader>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Image'">
				<dx-form class="m-3" [(formData)]="popupImage" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="description">
						<dxi-validation-rule type="required" message="Description is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item>
						<dx-file-uploader [maxFileSize]="2097152" [uploadFile]="uploadImage"></dx-file-uploader>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Responsible Employee'">
				<dx-form class="m-3" [(formData)]="popupUserProfile" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="userProfileId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Employee' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Timesheet'">
				<dx-data-grid class="data-grid" [dataSource]="timesheet" [remoteOperations]="false" [allowColumnReordering]="false" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" height="250px" (onInitNewRow)="onInitNewTimesheetRow($event)">
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-editing mode="popup" [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
						<dxo-popup title="Add Time" [showTitle]="true" [width]="'55%'" [height]="'auto'"></dxo-popup>
						<dxo-form [colCount]="2">
							<dxi-item [colSpan]="2" dataField="userId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
							<dxi-item dataField="date" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
							<dxi-item dataField="time" [label]="{ text: 'Time (Hours)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
						</dxo-form>
					</dxo-editing>
					<dxo-toolbar>
						<dxi-item name="addRowButton" showText="always"></dxi-item>
					</dxo-toolbar>
					<dxi-column type="buttons">
						<dxi-button name="edit"></dxi-button>
						<dxi-button name="delete"></dxi-button>
					</dxi-column>
					<dxi-column dataField="userId" dataType="number" alignment="left" caption="User">
						<dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup>
					</dxi-column>
					<dxi-column dataField="date" dataType="date" alignment="left" caption="Date" format="dd/MM/yyy"></dxi-column>
					<dxi-column dataField="time" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="cost" dataType="number" alignment="left" [allowEditing]="false" [calculateCellValue]="calculateTimesheetCostCellValue" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, precision: 2, type: 'currency' }"></dxi-column>
				</dx-data-grid>
			</div>
		</div>
	</dx-popup>
</div>