<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [colCount]="1" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="6">
				<dxi-item dataField="supplierId" [colSpan]="3" editorType="dxSelectBox" [editorOptions]="{ items: suppliers, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Supplier' }"></dxi-item>	
				<dxi-item dataField="cost" [colSpan]="3" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency:'GBP', precision: 2} }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="name" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="weight" [colSpan]="3" [label]="{ text: 'Weight (kg)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="grossWeightLimit" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="maxLoad" [colSpan]="3" [editorOptions]="{readOnly: true }"></dxi-item>
				<dxi-item dataField="packagingWidth" [colSpan]="2"></dxi-item>
				<dxi-item dataField="packagingDepth" [colSpan]="2"></dxi-item>
				<dxi-item dataField="packagingHeight" [colSpan]="2"></dxi-item>
				<dxi-item dataField="labelSize" editorType="dxSelectBox" [editorOptions]="{ items: labelSizes, displayExpr:'name', valueExpr:'id' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="boxIsReturnable" editorType="dxCheckBox"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Layer Info" [colCount]="2">
				<dxi-item dataField="minPerLayer" editorType="dxNumberBox"></dxi-item>
				<dxi-item dataField="maxPerLayer" editorType="dxNumberBox"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Auxiliary Packaging" [colCount]="5">
				<dxi-item dataField="lidCode" [colSpan]="2"></dxi-item>
				<dxi-item dataField="minPerLid" editorType="dxNumberBox"></dxi-item>
				<dxi-item dataField="maxPerLid" editorType="dxNumberBox"></dxi-item>
				<dxi-item dataField="lidIsReturnable" editorType="dxCheckBox"></dxi-item>
				<dxi-item dataField="palletCode" [colSpan]="2"></dxi-item>
				<dxi-item dataField="minPerPallet" editorType="dxNumberBox"></dxi-item>
				<dxi-item dataField="maxPerPallet" editorType="dxNumberBox"></dxi-item>
				<dxi-item dataField="palletIsReturnable" editorType="dxCheckBox"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Image" [colCount]="2">
				<dxi-item>
					<div class="image" [style]="imageStyle"></div>
				</dxi-item>
				<dxi-item>
					<dx-file-uploader [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadImage" accept="image/*" [allowedFileExtensions]="['.gif', '.jpg', '.jpeg', '.png']"></dx-file-uploader>
				</dxi-item>
			</dxi-tab>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Add New Supplier'">
				<dx-form class="m-3" [(formData)]="popupSupplier" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="name" [label]="{ text: 'Supplier Name' }">
						<dxi-validation-rule type="required" message="Supplier Name is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="telephoneNumber" [label]="{ text: 'Contact Phone Number' }"></dxi-item>
					<dxi-item dataField="address1" [label]="{ text: 'Address Line 1' }"></dxi-item>
					<dxi-item dataField="address2" [label]="{ text: 'Address Line 2' }"></dxi-item>
					<dxi-item dataField="town"></dxi-item>
					<dxi-item dataField="postcode"></dxi-item>
					<dxi-item dataField="excludeFromRFQs" editorType="dxCheckBox" [colSpan]="2"></dxi-item>
					<dxi-item [colSpan]="2">
						<p class="red">Please Note: Further details will need configuring (e.g. contacts, locations, purchasable items) through the admin page once this supplier is created.</p>
					</dxi-item>
				</dx-form>
			</div>
		</div>
	</dx-popup>
</div>