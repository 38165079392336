import { PackagingLabelSize } from "@enums/packagingLabelSize.enum";
import { Model } from "@interfaces/model.interface";

export interface Packaging extends Model {
	boxIsReturnable: boolean;
	cost: number;
	grossWeightLimit: number;
	imageUrl: string;
	labelSize: PackagingLabelSize;
	lidCode: string;
	lidIsReturnable: boolean;
	maxLoad: number;
	maxPerLayer: number;
	maxPerLid: number;
	maxPerPallet: number;
	minPerLayer: number;
	minPerLid: number;
	minPerPallet: number;
	name: string;
	packagingDepth: number;
	packagingHeight: number;
	packagingWidth: number;
	palletCode: string;
	palletIsReturnable: boolean;
	supplierId: number;
	weight: number;
	width: number;
}

export class Packaging implements Packaging {	
	boxIsReturnable = true;
	grossWeightLimit = 0;
	labelSize = PackagingLabelSize.Standard;
	maxLoad = 0;
	maxPerLayer = 0;
	minPerLayer = 0;
	name = "";
	packagingDepth = 0;
	packagingHeight = 0;
	packagingWidth = 0;
	weight = 0;

	constructor(data?: Partial<Packaging>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}