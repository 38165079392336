import { Part } from "./part.interface";

export interface PartRelationship {
	childPart: Part;
	childPartId: number;
	masterPart: Part;
masterPartId: number;
quantityRequired: number;
}

export class PartRelationship implements PartRelationship {
	childPartId = 0
	masterPartId = 0
	quantityRequired = 1

	constructor(data?: Partial<PartRelationship>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}