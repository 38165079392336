import { Model } from "@interfaces/model.interface";

export interface RequestForQuote extends Model {
	createdById: number;
	dateSent: Date;
	lineItems: string;
	outsourcedLineItems: string;
	projectManagerId?: number;
	salesOrderId?: number;
	supplierId: number;
	won: boolean;
}

export class RequestForQuote implements RequestForQuote {
	constructor(data?: Partial<RequestForQuote>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}