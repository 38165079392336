import { Model } from "@interfaces/model.interface";

export interface QmsDocument extends Model {
	creatorId: number;
	documentName: string;
	documentOwnerId: number;
	nextReviewDate: Date;
	revisionDate: Date;
	revisionNumber: number;
}

export class QmsDocument implements QmsDocument {
	constructor(data?: Partial<QmsDocument>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}