export interface LoginDetails {
	login: string;
	password: string;
}

export class LoginDetails implements LoginDetails {
	login = "";
	password = "";

	constructor(login?: string, password?: string) {
		if (login) this.login = login;
		if (password) this.password = password;
	}
}