import { Model } from "@interfaces/model.interface";

export interface SupplierLocation extends Model {
	addressLine1: string;
	addressLine2: string;
	country: string;
	county: string;
	isBilling: boolean;
	isDelivery: boolean;
	isPrimaryBilling: boolean;
	isPrimaryDelivery: boolean;
	name: string;
	postcode: string;
	supplierId: number;
	town: string;
}

export class SupplierLocation implements SupplierLocation {
	name = "";

	constructor(data?: Partial<SupplierLocation>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}