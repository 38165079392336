export interface CreateManualShippingNote {
	clientLocationId: number;
	dateRequired: Date;
	dock: string;
	partsRequired: string;
}

export class CreateManualShippingNote implements CreateManualShippingNote {	
	dateRequired = new Date;
	constructor(data?: Partial<CreateManualShippingNote>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}