import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "@environments/environment";
import { FobScanned } from "@interfaces/fobScanned.interface";
import { StaffAttendanceService } from "@services/staffAttendance.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "clocking",
	styleUrls: ["clocking.css"],
	templateUrl: "clocking.html"
})
export class ClockingAdminComponent {
	companyName = "";
	copyright = "";
	fobScannedEntity = new FobScanned();
	siteId = 0;
	
	constructor(private route: ActivatedRoute, private staffAttendanceService: StaffAttendanceService) {
		this.companyName = this.getEnvironmentProperty("companyLongName");
		this.copyright = "© Copyright " + this.companyName + " " + new Date().getFullYear();
		this.route.params
			.subscribe((params) => {
				this.siteId = Number.parseInt(params.id);
			});
	}

	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment["build"][property];
	}

	submitData() {
		this.fobScannedEntity.siteId = this.siteId;
		this.staffAttendanceService.fobScanned(this.fobScannedEntity)
			.subscribe(
				() => {
					notify("Fob Scanned Successfully!", "success", 5000);
					this.fobScannedEntity.fobId = "";
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
					this.fobScannedEntity.fobId = "";
				}
			);
	}


	windowWidthCheck() {
		return window.innerWidth >= 768;
	}
}