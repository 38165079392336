import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { HolidaySettings } from "@interfaces/holidaySettings.interface";

@Injectable()
export class HolidaySettingsService {
	endpoint = "HolidaySettings";

	constructor(private http: HttpClient) {}

	// API
	canEditDefaultAllowance() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/CanEditDefaultAllowance`);
	}
	
	getSettings() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}`);
	}

	updateSettings(data: HolidaySettings) {
		return this.http.patch(`${environment.server.apiURL}/${this.endpoint}`, data);
	}
}