import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ProductionRequestStatus } from "@enums/productionRequestStatus.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { ExportLogService } from "@services/exportLog.service";
import { ProductionRequestService } from "@services/productionRequestService";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "productionRequests",
	styleUrls: ["productionRequests.css"],
	templateUrl: "productionRequests.html"
})
export class ProductionRequestsAdminComponent {
	headerPrimaryButtons: AdminHeaderButton[] = [{ method: "createProductionRequestsFromEdi", text: "Create Requests" }];
	itemType = "Production Request";
	items: any[] = [];
	statuses: any;
	title: string;
	
	constructor(private router: Router, private exportLogService: ExportLogService, private itemService: ProductionRequestService) {
		this.title = `${this.itemType}s`;
		this.statuses = GetPropertiesFromEnum(ProductionRequestStatus);
		this.getItems();
		this.approve = this.approve.bind(this);
		this.decline = this.decline.bind(this);
		this.viewSalesOrder = this.viewSalesOrder.bind(this);
	}
	
	approve(e: any) {
		if (confirm("Are you sure you wish to approve this Production Request and create a Sales Order? This cannot be undone.") == false) {
			return;
		}

		this.itemService.approve(e.row.data.id)
			.subscribe(
				(res: any) => {
					notify("Production Request Approved", "Success", 5000);
					this.router.navigate([`/admin/salesOrders/${res.response.lookupId}`]);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}
	
	createProductionRequestsFromEdi() {
		if (confirm("Are you sure you wish to enqueue this job? Production Requests will be created as a background task, you will receive an email notification when completed.") == false) {
			return;
		}

		this.itemService.createProductionRequestsFromEdi()
			.subscribe(
				() => {
					notify("Production Request Creation Enqueued", "Success", 5000);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}
	
	decline(e: any) {
		if (confirm("Are you sure you wish to decline this Production Request? This cannot be undone.") == false) {
			return;
		}

		this.itemService.decline(e.row.data.id)
			.subscribe(
				() => {
					notify("Production Request Declined", "Success", 5000);
					this.getItems();
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getItems() {
		this.itemService.getAllDetails()
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	isRowApproved(e: any) {
		return e.row.data.status === 20;
	}

	isRowPending(e: any) {
		return e.row.data.status === 0;
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Production Requests";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewSalesOrder(e: any) {
		this.router.navigate([`/admin/salesOrders/${e.row.data.salesOrder.lookupId}`]);
	}
}