import { Model } from "@interfaces/model.interface";

export interface CompanyHoliday extends Model {
	endDate: Date;
	length: number;
	name: string;
	startDate: Date;
}

export class CompanyHoliday implements CompanyHoliday {
	endDate = new Date;
	startDate = new Date;

	constructor(data?: Partial<CompanyHoliday>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}