import { Department } from "@enums/department";
import { ProfileAccountStatus } from "@enums/profileAccountStatus.enum";
import { Shift } from "@enums/shift.enum";
import { Model } from "@interfaces/model.interface";
import { User } from "@interfaces/user.interface";

export interface UserProfile extends Model {
	address1: string;
	address2: string;
	currentDepartment: Department;
	dateOfBirth: Date;
	defaultDepartment: Department;
	defaultHolidayAllowance: number;
	email: string;
	emailAddressVerified: boolean;
	firstName: string;
	fullName: string;
	gender: string;
	isConsultancyLead: boolean;
	isSupervisor: boolean;
	jobTitle: string;
	lastName: string;
	lastSeen: Date;
	markCompleted: boolean; // not a real field but used by super admins when creating new member details
	marketingConsent: boolean;
	postcode: string;
	shiftId: Shift;
	siteId: number;
	status: ProfileAccountStatus;
	supervisorId: number;
	telephoneNumber: string;
	title: string;
	town: string;
	user: User;
	userId: number;
}

export class UserProfile implements UserProfile {
	address1 = "";
	address2 = "";
	currentDepartment = Department.BackOffice;
	dateOfBirth = new Date();
	defaultDepartment = Department.BackOffice;
	email = "";
	emailAddressVerified = false;
	firstName = "";
	gender = "";
	isConsultancyLead = false;
	isSupervisor = false;
	jobTitle = "";
	lastActivityAt = new Date();
	lastName = "";
	lastSeen = new Date();
	markCompleted = false;
	marketingConsent = false;
	postcode = "";
	shiftId = Shift.Day;
	siteId = 1;
	status = ProfileAccountStatus.AwaitingApproval;
	telephoneNumber = "";
	title = "";
	town = "";
	user: User = new User();

	constructor(data?: Partial<UserProfile>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}