<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [colCount]="1">
		<dxi-item itemType="group" caption="Details" [colSpan]="1" [colCount]="2">
			<dxi-item dataField="defaultHolidayAllowance" [colSpan]="1" [label]="{ text: 'Default Holiday Allowance (days inc half day)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="shortHolidayMaximumDays" [colSpan]="1" [label]="{ text: 'Short Holiday Max Length (days inc half day)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="shortHolidayNoticeDays" [colSpan]="1" [label]="{ text: 'Notice Period for Short Holidays (days)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="longHolidayNoticeDays" [colSpan]="1" [label]="{ text: 'Notice Period for Long Holidays (days)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
		</dxi-item>
	</dx-form>
</div>