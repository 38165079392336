import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Audit } from "@interfaces/audit.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { AuditService } from "@services/audit.service";
import { ExportLogService } from "@services/exportLog.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "audits",
	styleUrls: ["audits.css"],
	templateUrl: "audits.html"
})
export class AuditAdminComponent {
	audits: Audit[] = [];
	title = "Audits";

	constructor(private router: Router, private auditsService: AuditService, private exportLogService: ExportLogService) {
		this.getAudits();
		this.viewRow = this.viewRow.bind(this);
	}

	getAudits() {
		this.auditsService.getAll()
			.subscribe(
				(res: any) => {
					this.audits = res.response.items;
					console.log(this.audits[0].id);
				},
				(err) => console.log(err),
			);
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Audits";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`admin/audits/${e.row.data.id}`]);
	}
}