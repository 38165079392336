<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2"  [readOnly]="readOnly">
		<dxi-item dataField="recipients.To" [colSpan]="2"></dxi-item>
		<dxi-item dataField="recipients.CC"></dxi-item>
		<dxi-item dataField="recipients.BCC"></dxi-item>
		<dxi-item dataField="subject" [colSpan]="2"></dxi-item>
		<dxi-item dataField="createdAt"></dxi-item>
			<dxi-item dataField="sentDate"></dxi-item>	
			<dxi-item dataField="senderName"></dxi-item>
			<dxi-item dataField="senderEmail"></dxi-item>
	</dx-form>
	<br/>
	<div class="email" [innerHtml]="this.html"></div>
	<br/>
</div>