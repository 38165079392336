import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItemService } from "@services/menuItem.service";
import { AdminMenuItem } from "@interfaces/adminMenuItem.interface";

@Component({
	moduleId: module.id,
	selector: "menu",
	styleUrls: ["menu.css"],
	templateUrl: "menu.html"
})
export class MenuAdminComponent {
	tiles: AdminMenuItem[] = [];

	constructor(private route: ActivatedRoute, private router: Router, private menuItemService: MenuItemService) {
		this.route.params
			.subscribe((params) => {
				this.getTiles(params.id);
			});
	}

	getTiles(slug: string) {
		this.menuItemService.getUserMenuItems(slug)
			.subscribe(
				(res: any) => {
					this.tiles = res.response;
					this.tiles.sort((a,b) => a.text.localeCompare(b.text));
				},
				(err) => console.log(err)					
			);
	}

	navigate(route: string) {
		this.router.navigate([`admin/${route}`]);
	}
}