<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="2">
				<dxi-item dataField="id" [editorOptions]="{ readOnly:true }"></dxi-item>
				<dxi-item dataField="documentName"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="creatorId" editorType="dxSelectBox" [editorOptions]="{ readOnly:true, items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Creator' }"></dxi-item>	
				<dxi-item dataField="createdAt" [editorOptions]="{ readOnly:true }"></dxi-item>
				<dxi-item dataField="revisionDate" [editorOptions]="{ readOnly:true }"></dxi-item>
				<dxi-item dataField="revisionNumber" [editorOptions]="{ readOnly:true }"></dxi-item>
				<dxi-item dataField="documentOwnerId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Owner' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item dataField="nextReviewDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			</dxi-tab>
			<dxi-tab *ngIf="screenMode !== 'create'" title="Version History">
				<dx-data-grid class="data-grid" [dataSource]="versions" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="400px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column dataField="createdAt" dataType="date" alignment="left" caption="Created"></dxi-column>
					<dxi-column dataField="revisionNumber" dataType="text" alignment="left" caption="Revision"></dxi-column>
					<dxi-column dataField="changeDescription" dataType="text" alignment="left" caption="Change"></dxi-column>
					<dxi-column dataField="uploaderId" caption="Uploaded By">
						<dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup>
					</dxi-column>
					<dxi-column dataField="approverId" caption="Approved By">
						<dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup>
					</dxi-column>
					<dxi-column type="buttons">
						<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadVersionFileLink" [visible]="isVersionDownloadButtonAvailable"></dxi-button>
						<dxi-button hint="Approve" icon="fal fa-check" [onClick]="approveVersion" [visible]="isVersionApproveButtonAvailable"></dxi-button>
						<dxi-button hint="Delete" icon="fal fa-trash" [onClick]="deleteVersion" [visible]="isVersionDeleteButtonAvailable"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Add New Version'">
				<dx-form class="m-3" [(formData)]="popupVersion" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="changeDescription"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item>
						<dx-file-uploader [maxFileSize]="20971520" [uploadFile]="uploadVersionFile">
						<dxi-validation-rule type="required"></dxi-validation-rule></dx-file-uploader>
					</dxi-item>
				</dx-form>
			</div>
		</div>
	</dx-popup>
</div>