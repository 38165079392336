import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { DeliveryNote } from "@interfaces/deliveryNote.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { SalesOrder } from "@interfaces/salesOrder.interface";
import { DeliveryNoteService } from "@services/deliveryNote.service";
import { ExportLogService } from "@services/exportLog.service";
import { SalesOrderService } from "@services/salesOrder.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "salesOrders-id-deliveryNotes",
	styleUrls: ["salesOrders-id-deliveryNotes.css"],
	templateUrl: "salesOrders-id-deliveryNotes.html"
})
export class SalesOrdersIdDeliveryNotesAdminComponent {
	headerPrimaryButtons: AdminHeaderButton[] = [
		{ method: "viewSalesOrder", text: "Go to Sales Order" }
	];
	itemId = 0;
	items: DeliveryNote[] = [];
	salesOrder: SalesOrder = new SalesOrder();
	title = "";

	constructor(private router: Router, private exportLogService: ExportLogService, private route: ActivatedRoute, private deliveryNoteService: DeliveryNoteService, private salesOrderService: SalesOrderService) {
		this.route.params.subscribe((params) => {
			this.itemId = params.id;
			this.getItems(params.id);
			this.getSalesOrder();
			this.title = `Delivery Notes for Sales Order #${params.id}`;
		});
		
		this.viewRow = this.viewRow.bind(this);
	}

	boolYesNo(cellInfo: any){		
		if (cellInfo.value === true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	}

	getItems(id: number) {
		this.deliveryNoteService.getBySalesOrderId(id)
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getSalesOrder() {
		this.salesOrderService.getSingleById(this.itemId)
			.subscribe(
				(res: any) => this.salesOrder = res.response,
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Delivery Notes for Sales Order " + this.itemId;
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`admin/deliveryNotes/${e.row.data.lookupId}`]);
	}

	viewSalesOrder() {		
		this.router.navigate([`admin/salesOrders/${this.salesOrder.lookupId}`]);
	}
}