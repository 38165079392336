import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { EdiMessage } from "@interfaces/ediMessage.interface";
import { EdiMessageService } from "@services/ediMessage.service";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { EdiMessageType } from "@enums/ediMessageType.enum";
import { EdiMessageProcessingStatus } from "@enums/ediMessageProcessingStatus.enum";
import { EdiMessageDirection } from "@enums/ediMessageDirection.enum";

@Component({
	moduleId: module.id,
	selector: "ediMessages-id",
	styleUrls: ["ediMessages-id.css"],
	templateUrl: "ediMessages-id.html"
})
export class EdiMessagesIdAdminComponent {
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: EdiMessage = new EdiMessage();
	itemId = 0;
	itemType = "Edi Message";
	messageDirections: any;
	messageTypes: any;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	statuses: any;
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private itemService: EdiMessageService) {
		this.messageDirections = GetPropertiesFromEnum(EdiMessageDirection);
		this.messageTypes = GetPropertiesFromEnum(EdiMessageType);
		this.statuses = GetPropertiesFromEnum(EdiMessageProcessingStatus);
		this.route.params.subscribe((params) => {
			this.itemId = params.id;
			this.getItem(params.id);
		});
	}

	buildButtons() {
		this.headerPrimaryButtons = [
			{ method: "close", text: "Close" }
		];

		if (this.item.status === EdiMessageProcessingStatus.Deserialized) {
			this.headerTertiaryButtons = [
				{ method: "reprocessMessage", text: "Reprocess" }
			];
		} else {
			this.headerTertiaryButtons = [];
		}
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.buildButtons();
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	reprocessMessage() {
		if (this.item.status !== EdiMessageProcessingStatus.Deserialized) {
			notify("Cannot reprocess this message", "error", 5000);
			return;
		}

		if (confirm("Attempt to reprocess message?") == false) {
			return;
		}

		this.itemService.reprocessEdiMessage(this.item.id)
			.subscribe(
				() => {
					notify("Successfully submitted message for reprocessing", "success", 5000);
					this.getItem(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}