import { Model } from "@interfaces/model.interface";

export interface Client extends Model {
	accountingNumber: string;
	address1: string;
	address2: string;
	applyVAT: boolean;
	bankDetailsId: number;
	ediImportDaysBeforeForecasts?: number;
	ediSelfBilling: boolean;
	name: string;
	paymentTerms: number;
	postcode: string;
	productionRequestForecastDays: number;
	productionRequestOrderDays: number;
	sendASNsViaEDI: boolean;
	showOnEdi: boolean;
	supplierCode: string;
	telephoneNumber: string;
	town: string;
}

export class Client implements Client {
	accountingNumber = "";
	address1 = "";
	address2 = "";
	applyVAT = true;
	bankDetailsId = 1;
	ediSelfBilling = false;
	name = "";
	paymentTerms = 0;
	postcode = "";
	productionRequestForecastDays = 0;
	productionRequestOrderDays = 0;
	sendASNsViaEDI = false;
	supplierCode = "";
	telephoneNumber = "";
	town = "";

	constructor(data?: Partial<Client>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}