<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button *ngIf="editEnabled" hint="Edit" icon="fal fa-pencil" [onClick]="editRow" [visible]="isEditEnabledForRow"></dxi-button>
		<dxi-button *ngIf="deleteEnabled" icon="fal fa-trash" [onClick]="deleteRow"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" dataType="number" alignment="left" sortOrder="desc"></dxi-column>
	<dxi-column dataField="reporterId" caption="Reported By"><dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup></dxi-column>
    <dxi-column dataField="areaOfIssue" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="status" caption="Status"><dxo-lookup [dataSource]="statuses" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
	<dxi-column dataField="clientId" caption="Client"><dxo-lookup [dataSource]="clients" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
	<dxi-column dataField="partId" caption="Part"><dxo-lookup [dataSource]="parts" valueExpr="id" displayExpr="partNumber"></dxo-lookup></dxi-column>
    <dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy" caption="Date Reported"></dxi-column>
    <dxi-column dataField="deadline" dataType="date" format="dd/MM/yyyy" caption="Deadline"></dxi-column>
    <dxi-column dataField="dateForClosure" dataType="date" format="dd/MM/yyyy" caption="Date for Closure"></dxi-column>
    <dxi-column dataField="dateClosed" dataType="date" format="dd/MM/yyyy" caption="Closure Date"></dxi-column>
    <dxi-column dataField="primaryResponsible" dataType="string" caption="Resp. for Closure"></dxi-column>
	<dxi-column dataField="concernOrigin" caption="Origin"><dxo-lookup [dataSource]="concernOrigins" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
    <dxi-column dataField="costing" dataType="number" alignment="left" caption="Costing" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 2 }"></dxi-column>
</dx-data-grid>