import { EdiStyle } from "@enums/ediStyle.enum";
import { Model } from "@interfaces/model.interface";

export interface ClientLocation extends Model {
	addressLine1: string;
	addressLine2: string;
	clientId: number;
	colour: string;
	country: string;
	county: string;
	ediReference: string;
	ediStyle: EdiStyle;
	isBilling: boolean;
	isDelivery: boolean;
	isPrimaryBilling: boolean;
	isPrimaryDelivery: boolean;
	name: string;
	postcode: string;
	town: string;
}

export class ClientLocation implements ClientLocation {
	colour = "";
	ediReference = "";
	name = "";

	constructor(data?: Partial<ClientLocation>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}