<admin-header [primaryButtons]="headerPrimaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="6">
				<dxi-item dataField="name" [colSpan]="3"></dxi-item>
				<dxi-item dataField="telephoneNumber" [colSpan]="3"></dxi-item>
				<dxi-item dataField="address1" [colSpan]="3"></dxi-item>
				<dxi-item dataField="address2" [colSpan]="3"></dxi-item>
				<dxi-item dataField="town" [colSpan]="3"></dxi-item>
				<dxi-item dataField="postcode" [colSpan]="3"></dxi-item>
				<dxi-item dataField="excludeFromRFQs" [colSpan]="3" editorType="dxCheckBox" caption="Exclude from RFQ Generation"></dxi-item>	
				<dxi-item dataField="rfqContactId" [colSpan]="3" editorType="dxSelectBox" [editorOptions]="{ items: contacts, searchEnabled: true, displayExpr:'contactName', valueExpr:'id' }" [label]="{ text: 'RFQ Contact' }"></dxi-item>	
			</dxi-tab>
			<dxi-tab title="Contacts">
				<dx-data-grid class="data-grid" [dataSource]="contacts" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="suppliersContacts"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editContactPopup"></dxi-button>
						<dxi-button icon="fal fa-trash" [onClick]="deleteContact"></dxi-button>
					</dxi-column>
					<dxi-column dataField="contactName" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="contactEmailAddress" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="jobTitle" dataType="string" alignment="left"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Documents">
				<dx-data-grid class="data-grid" id="gridContainer" [dataSource]="documents" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="suppliersDocuments"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadDocument"></dxi-button>
					</dxi-column>
					<dxi-column dataField="description" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="fileName" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyy" alignment="left"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Purchase Types" *ngIf="selectablePurchaseTypes.length > 0">
				<dxi-item>Changes saved immediately</dxi-item>
				<dx-data-grid class="data-grid" id="gridContainer" [dataSource]="selectablePurchaseTypes" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="suppliersType"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="Add Purchase Type" icon="fal fa-toggle-off" [onClick]="togglePurchaseType" [visible]="isTogglePurchaseTypeOnVisible"></dxi-button>
						<dxi-button hint="Remove Purchase Type" icon="fal fa-toggle-on" [onClick]="togglePurchaseType" [visible]="isTogglePurchaseTypeOffVisible"></dxi-button>
					</dxi-column>
					<dxi-column dataField="name" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="isSelected" dataType="string" alignment="left" [customizeText]="boolYesNo"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Locations">
				<dx-data-grid class="data-grid" id="locationGridContainer" [dataSource]="locations" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="supplierLocations"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="Set Primary Delivery" icon="fal fa-truck" [onClick]="setPrimaryDelivery"></dxi-button>
						<dxi-button hint="Set Primary Billing" icon="fal fa-money-bill-wave" [onClick]="setPrimaryBilling"></dxi-button>
						<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editLocationPopup"></dxi-button>
						<dxi-button hint="Delete" icon="fal fa-trash" [onClick]="deleteLocation"></dxi-button>
					</dxi-column>
					<dxi-column dataField="name" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="postcode" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="town" dataType="string" alignment="left"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="isDelivery" dataType="string" alignment="left" [customizeText]="boolYesNo" caption="Delivery"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="isBilling" dataType="string" alignment="left" [customizeText]="boolYesNo" caption="Billing"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="isPrimaryDelivery" dataType="string" alignment="left" [customizeText]="boolYesNo" caption="Primary Delivery"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="isPrimaryBilling" dataType="string" alignment="left" [customizeText]="boolYesNo" caption="Primary Billing"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<dx-form *ngIf="popupType == 'Contact'" class="m-3" [(formData)]="popupContact" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
		<dxi-item dataField="contactName">
			<dxi-validation-rule type="required" message="Contact Name is required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item dataField="contactEmailAddress">
			<dxi-validation-rule type="required" message="Contact Email is required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item dataField="jobTitle"></dxi-item>
	</dx-form>
	<dx-form *ngIf="popupType == 'Location'" class="m-3" [(formData)]="popupLocation" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
		<dxi-item dataField="name"><dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule></dxi-item>
		<dxi-item dataField="addressLine1"></dxi-item>
		<dxi-item dataField="addressLine2"></dxi-item>
		<dxi-item dataField="town"></dxi-item>
		<dxi-item dataField="county"></dxi-item>
		<dxi-item dataField="postcode"></dxi-item>
		<dxi-item dataField="country"></dxi-item>
		<dxi-item dataField="isDelivery" editorType="dxCheckBox"></dxi-item>
		<dxi-item dataField="isBilling" editorType="dxCheckBox"></dxi-item>
	</dx-form>
</dx-popup>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="addDocumentPopupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="addDocumentPopupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="addDocumentPopupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="addDocumentPopupCloseButtonOptions"></dxi-toolbar-item>
	<div class="col-6">
		<dx-file-uploader [disabled]="!uploadEnabled" class="mb-3 mx-2" [chunkSize]="200000" [maxFileSize]="10485760" [uploadChunk]="uploadChunk"></dx-file-uploader>
	</div>
	<div class="col-6">
		<input class="form-control shadow-none" type="text" placeholder="Enter Description" name="description" [(ngModel)]="newSupplierDocument.description">
	</div>
</dx-popup>