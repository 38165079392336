<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="mainFormDataChanged($event)">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Main Details" [colCount]="2">
				<dxi-item dataField="manufacturingVendorCode" [editorOptions]="{ readOnly: true }" [label]="{ text: 'Manufacturing Vendor Code' }"></dxi-item>
				<dxi-item dataField="shippingGSDBCode" [editorOptions]="{ readOnly: true }" [label]="{ text: 'Shipping GSDB Code' }"></dxi-item>
				<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Client' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="clientContactId" editorType="dxSelectBox" [editorOptions]="{ items: clientContacts, searchEnabled: true, displayExpr: 'displayName', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Contact' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="projectId" editorType="dxSelectBox" [editorOptions]="{ items: projects, searchEnabled: true, displayExpr:'name', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Project' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			</dxi-tab>
			<dxi-tab title="Part Details" [colCount]="2">
				<dxi-item dataField="partNumber" [label]="{ text: 'Part Number' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="supersededPartNumber" [label]="{ text: 'Superseded Part Number' }"></dxi-item>
				<dxi-item dataField="revisionLevel" [label]="{ text: 'Revision Level' }"></dxi-item>
				<dxi-item dataField="partDescription" [label]="{ text: 'Part Description' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="destinationName" [label]="{ text: 'Destination Name' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="annualVolume" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Annual Volume' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="batchSize" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Batch Size' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="weeklyMaxCapacity" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Weekly Max Capacity' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="numberOfShifts" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Number of Shifts' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="currentEngineeringLevel" [label]="{ text: 'Current Engineering Level' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="oldEngineeringLevel" [label]="{ text: 'Old Engineering Level' }"></dxi-item>
				<dxi-item dataField="clientLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, searchEnabled: true, displayExpr: 'name', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Plant' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			</dxi-tab>
			<dxi-tab title="Raw Materials">
				<dxi-item itemType="group">
					<dx-data-grid #rawMaterialsGridContainer class="data-grid" [dataSource]="rawMaterials" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onSaved)="calculateRawMaterialLineData()">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
							<dxo-popup title="Raw Material Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
							<dxo-form [colCount]="2">
								<dxi-item dataField="purchasableItemId" editorType="dxSelectBox" [editorOptions]="{ items: materialPurchasableItems, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Grade' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="gauge" editorType="dxSelectBox" [editorOptions]="{ items: thicknesses, searchEnabled: false }" [label]="{ text: 'Gauge (mm)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
								<dxi-item dataField="width" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 1, min: 0}}" [label]="{ text: 'Width (mm)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="pitch" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 1, min: 0}}" [label]="{ text: 'Pitch (mm)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="partsPerBlow" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Parts per Blow' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="grossWeight" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 5, min: 0}, readOnly: true}" [label]="{ text: 'Gross Weight (kg)' }"></dxi-item>
								<dxi-item dataField="rawMaterialPricePerKg" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Price per tonne' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="netWeight" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 5, min: 0}}" [label]="{ text: 'Net Weight (kg)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="scrapWeight" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 5, min: 0}, readOnly: true}" [label]="{ text: 'Scrap Weight (kg)' }"></dxi-item>
								<dxi-item dataField="scrapCostPerKg" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Scrap Cost per kg' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="scrapValue" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Scrap Value' }"></dxi-item>
								<dxi-item dataField="grossMaterialCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Gross Cost' }"></dxi-item>
								<dxi-item dataField="netMaterialCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Net Cost' }"></dxi-item>
							</dxo-form>
						</dxo-editing>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="purchasableItemId" dataType="number" alignment="left" caption="Grade">
							<dxo-lookup [dataSource]="materialPurchasableItems" displayExpr="name" valueExpr="id"></dxo-lookup>
						</dxi-column>
						<dxi-column dataField="gauge" dataType="number" alignment="left" caption="Gauge (mm)"></dxi-column>
						<dxi-column dataField="width" dataType="number" alignment="left" caption="Width (mm)"></dxi-column>
						<dxi-column dataField="pitch" dataType="number" alignment="left" caption="Pitch (mm)"></dxi-column>
						<dxi-column dataField="partsPerBlow" dataType="number" alignment="left" caption="Parts/Blow"></dxi-column>
						<dxi-column dataField="grossWeight" dataType="number" alignment="left" caption="Gross Weight (kg)"></dxi-column>
						<dxi-column dataField="rawMaterialPricePerKg" dataType="number" alignment="right" caption="Raw Material Price /tonne" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="netWeight" dataType="number" alignment="left" caption="Net Weight (kg)"></dxi-column>
						<dxi-column dataField="scrapWeight" dataType="number" alignment="left" caption="Scrap Weight (kg)"></dxi-column>
						<dxi-column dataField="scrapCostPerKg" dataType="number" alignment="right" caption="Scrap Cost /kg" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="scrapValue" dataType="number" alignment="right" caption="Scrap Value" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="grossMaterialCost" dataType="number" alignment="right" caption="Gross Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="netMaterialCost" dataType="number" alignment="right" caption="Net Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column type="buttons">
							<dxi-button name="edit"></dxi-button>
							<dxi-button name="delete"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
				<dxi-item itemType="group">
					<dxi-item dataField="rawMaterialSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Net Material Costs' }"></dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Router">
				<dxi-item itemType="group">
					<dx-data-grid #pressProcessesGridContainer class="data-grid" [dataSource]="pressProcesses" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onInitNewRow)="onInitNewPressProcessRow($event)" (onSaved)="calculatePressProcessLineData()">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
							<dxo-popup title="Press Process Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
							<dxo-form [colCount]="2">
								<dxi-item dataField="processId" editorType="dxSelectBox" [editorOptions]="{ items: processes, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Process' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="machineId" editorType="dxSelectBox" [editorOptions]="{ items: processes, searchEnabled: true, displayExpr: displayMachineInDropdown, valueExpr:'id'}" [label]="{ text: 'Machine' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="machineTonnage" [editorOptions]="{readOnly: true }" [label]="{ text: 'Tonnage' }"></dxi-item>
								<dxi-item dataField="partsPerHour" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Parts per Hour' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="partsPerBlow" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Parts per Blow' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="men" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Men' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item [colSpan]="2">Leave hourly running costs empty to take them from the process.</dxi-item>
								<dxi-item dataField="hourlyRunningCostLabour" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Hourly Cost: Labour' }"></dxi-item>
								<dxi-item dataField="hourlyRunningCostMachine" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Hourly Cost: Machine' }"></dxi-item>
								<dxi-item dataField="hourlyRunningCostTotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Hourly Cost: Total' }"></dxi-item>
								<dxi-item [colSpan]="2">Leave setting cost empty to take it from the machine.</dxi-item>
								<dxi-item dataField="settingCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Setting Cost' }"></dxi-item>
								<dxi-item dataField="processCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Process Cost' }"></dxi-item>
							</dxo-form>
						</dxo-editing>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="processId" dataType="number" alignment="left" caption="Process">
							<dxo-lookup [dataSource]="processes" displayExpr="name" valueExpr="id"></dxo-lookup>
						</dxi-column>
						<dxi-column dataField="machineId" dataType="number" alignment="left" caption="Machine">
							<dxo-lookup [dataSource]="machines" displayExpr="name" valueExpr="id" [displayExpr]="displayMachineInDropdown"></dxo-lookup>
						</dxi-column>
						<dxi-column dataField="tonnage" dataType="text" alignment="left" caption="Tonnage"></dxi-column>
						<dxi-column dataField="partsPerHour" dataType="number" alignment="left" caption="Parts/Hour"></dxi-column>
						<dxi-column dataField="partsPerBlow" dataType="number" alignment="left" caption="Parts/Blow"></dxi-column>
						<dxi-column dataField="men" dataType="number" alignment="left" caption="Men"></dxi-column>
						<dxi-column dataField="hourlyRunningCostLabour" dataType="number" alignment="right" caption="Hourly Labour" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="hourlyRunningCostMachine" dataType="number" alignment="right" caption="Hourly Machine" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="hourlyRunningCostTotal" dataType="number" alignment="right" caption="Hourly Total" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="settingCost" dataType="number" alignment="right" caption="Setting Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="processCost" dataType="number" alignment="right" caption="Process Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column type="buttons">
							<dxi-button name="edit"></dxi-button>
							<dxi-button name="delete"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
				<dxi-item itemType="group">
					<dxi-item dataField="pressProcessSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Press Process Costs' }"></dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Part Image">
				<dxi-item>
					<div class="image" [style]="imageStyle"></div>
				</dxi-item>
				<dxi-item>
					<dx-file-uploader [visible]="mode != 'view'" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadImage" accept="image/*" [allowedFileExtensions]="['.gif', '.jpg', '.jpeg', '.png']"></dx-file-uploader>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Bought Out Materials">
				<dxi-item itemType="group">
					<dx-data-grid #boughtOutMaterialsGridContainer class="data-grid" [dataSource]="boughtOutMaterials" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onSaved)="calculateBoughtOutMaterialsLineData()">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
							<dxo-popup title="Bought Out Item Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
							<dxo-form [colCount]="2">
								<dxi-item dataField="supplierId" editorType="dxSelectBox" [editorOptions]="{ items: suppliers, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Supplier' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="purchasableItemId" editorType="dxSelectBox" [editorOptions]="{ items: purchasableItems, searchEnabled: true, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Item' }"></dxi-item>	
								<dxi-item dataField="itemName" [label]="{ text: 'Item Name' }"></dxi-item>
								<dxi-item dataField="costPerPart" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Cost per Part' }"></dxi-item>
								<dxi-item dataField="quantity" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Quantity' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="total" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Total' }"></dxi-item>
							</dxo-form>
						</dxo-editing>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="supplierId" dataType="number" alignment="left" caption="Supplier">
							<dxo-lookup [dataSource]="suppliers" displayExpr="name" valueExpr="id"></dxo-lookup>
						</dxi-column>
						<dxi-column dataField="purchasableItemId" dataType="number" alignment="left" caption="Item">
							<dxo-lookup [dataSource]="purchasableItems" displayExpr="name" valueExpr="id"></dxo-lookup>
						</dxi-column>
						<dxi-column dataField="itemName" dataType="text" alignment="left" caption="Item name"></dxi-column>
						<dxi-column dataField="costPerPart" dataType="number" alignment="right" caption="Cost / Part" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="quantity" dataType="number" alignment="right" caption="Quantity"></dxi-column>
						<dxi-column dataField="total" dataType="number" alignment="right" caption="Total" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column type="buttons">
							<dxi-button name="edit"></dxi-button>
							<dxi-button name="delete"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
				<dxi-item itemType="group">
					<dxi-item dataField="boughtOutMaterialsSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Bought Out Materials' }"></dxi-item>
				</dxi-item>
			</dxi-tab>			
			<!--<dxi-tab title="Weld & Assembly">
				<dxi-item itemType="group">
					<dx-data-grid #weldAndAssemblyGridContainer class="data-grid" [dataSource]="weldAndAssembly" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onSaved)="calculateWeldAndAssemblyLineData()">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
							<dxo-popup title="Weld & Assembly Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
							<dxo-form [colCount]="2">
								<dxi-item dataField="processDescription" [label]="{ text: 'Process Description' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="partsPerHour" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Parts per Hour' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="men" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Men' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="hourlyRunningCostLabour" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Hourly Cost: Labour' }"></dxi-item>
								<dxi-item dataField="hourlyRunningCostMachine" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Hourly Cost: Machine' }"></dxi-item>
								<dxi-item dataField="hourlyRunningCostTotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Hourly Cost: Total' }"></dxi-item>
								<dxi-item dataField="processCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Process Cost' }"></dxi-item>
							</dxo-form>
						</dxo-editing>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="processDescription" dataType="text" alignment="left" caption="Process Description"></dxi-column>
						<dxi-column dataField="partsPerHour" dataType="number" alignment="left" caption="Parts/Hour"></dxi-column>
						<dxi-column dataField="men" dataType="number" alignment="left" caption="Men"></dxi-column>
						<dxi-column dataField="hourlyRunningCostLabour" dataType="number" alignment="right" caption="Hourly Labour" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="hourlyRunningCostMachine" dataType="number" alignment="right" caption="Hourly Machine" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="hourlyRunningCostTotal" dataType="number" alignment="right" caption="Hourly Total" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="processCost" dataType="number" alignment="right" caption="Process Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column type="buttons">
							<dxi-button name="edit"></dxi-button>
							<dxi-button name="delete"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
				<dxi-item itemType="group">
					<dxi-item dataField="weldAndAssemblySubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Weld & Assembly Costs' }"></dxi-item>
				</dxi-item>
			</dxi-tab>-->
			<dxi-tab title="Packaging & Lead Times" [colCount]="2">
				<dxi-item dataField="packagingTerms" editorType="dxSelectBox" [editorOptions]="{ items: packagingTerms, searchEnabled: true, displayExpr:'name', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Packaging Terms' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="packagingId" editorType="dxSelectBox" [editorOptions]="{ items: packaging, searchEnabled: true, displayExpr:'name', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Packaging' }"></dxi-item>
				<dxi-item dataField="deliveryTerms" editorType="dxSelectBox" [editorOptions]="{ items: incoterms, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Delivery Terms' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item dataField="leadTimeFirstOff" [label]="{ text: 'Production Tooling Lead Time to First Off Parts (weeks)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="leadTimePhaseThree" [label]="{ text: 'Production Tooling Lead Time to Phase 3 PSW (weeks)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			</dxi-tab>
			<dxi-tab title="Tooling & Fixtures">
				<dxi-item itemType="group">
					<dx-data-grid #toolingFixturesGridContainer class="data-grid" [dataSource]="toolingFixtures" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onInitNewRow)="onInitNewToolingFixtureRow($event)" (onSaved)="calculateToolingFixturesLineData()">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
							<dxo-popup title="Tooling / Fixture Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
							<dxo-form [colCount]="2">
								<dxi-item dataField="description" editorType="dxSelectBox" [editorOptions]="{ items: toolingFixtureDescriptions, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Description' }"></dxi-item>	
								<dxi-item dataField="superseded" editorType="dxCheckBox" [label]="{ text: 'Superseded' }"></dxi-item>
								<dxi-item dataField="change" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Change' }"></dxi-item>
								<dxi-item dataField="total" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Total' }"></dxi-item>
							</dxo-form>
						</dxo-editing>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="description" dataType="number" alignment="left" caption="Item">
							<dxo-lookup [dataSource]="toolingFixtureDescriptions" displayExpr="name" valueExpr="id"></dxo-lookup>
						</dxi-column>
						<dxi-column dataField="superseded" alignment="left" caption="Superseded"></dxi-column>
						<dxi-column dataField="change" dataType="number" alignment="right" caption="Change" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="total" dataType="number" alignment="right" caption="Total" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column type="buttons">
							<dxi-button name="edit"></dxi-button>
							<dxi-button name="delete"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
				<dxi-item itemType="group">
					<dxi-item dataField="toolingFixtureSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Totals' }"></dxi-item>
					<dxi-item dataField="toolingFixtureTotalChanges" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Changes' }"></dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Additional Costs" [colCount]="2">
				<dxi-item dataField="materialOverhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Material Overhead' }"></dxi-item>
				<dxi-item dataField="materialOverheadDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
				<dxi-item dataField="generalOverhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'General Overhead' }"></dxi-item>
				<dxi-item dataField="generalOverheadDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
				<dxi-item dataField="profit" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Profit' }"></dxi-item>
				<dxi-item dataField="profitDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
				<dxi-item dataField="additionalCostsSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Subtotal' }"></dxi-item>
				<dxi-item dataField="additionalCostsSubtotalDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Finishing Details" [colCount]="2">
				<dxi-item dataField="totalPartCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Parts Total' }"></dxi-item>
				<dxi-item dataField="packagingAndLogistics" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Packaging & Logistics' }"></dxi-item>
				<dxi-item dataField="containerCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Container Cost / Rent' }"></dxi-item>
				<dxi-item dataField="grandTotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Total' }"></dxi-item>
				<dxi-item dataField="unitOfMeasure" [label]="{ text: 'Unit of Measure' }"></dxi-item>
				<dxi-item dataField="currency" editorType="dxSelectBox" [editorOptions]="{ items: currencies, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Currency' }"></dxi-item>	
				<dxi-item dataField="additionalNotes" editorType="dxTextArea" [colSpan]="2" [editorOptions]="{ height: 150 }" [label]="{ text: 'Additional Notes' }"></dxi-item>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>