import { Model } from "@interfaces/model.interface";

export interface LinkedPartGroup extends Model {
	linkedRouterDetails: string;
	name: string;
}

export class LinkedPartGroup implements LinkedPartGroup {

	constructor(data?: Partial<LinkedPartGroup>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}