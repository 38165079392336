import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { ProcessTemplate } from "@enums/processTemplate.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Machine } from "@interfaces/machine.interface";
import { Process } from "@interfaces/process.interface";
import { ProcessMachine } from "@interfaces/processMachine.interface";
import { MachineService } from "@services/machine.service";
import { ProcessService } from "@services/processService";
import { ProcessMachineService } from "@services/processMachine.service";
import { Department } from "@enums/department";

@Component({
	moduleId: module.id,
	selector: "processes-id",
	styleUrls: ["processes-id.css"],
	templateUrl: "processes-id.html"
})
export class ProcessesIdAdminComponent {
	activeMachineIds: number[] = [];
	createEnabled = true;
	departments: any;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	item: Process = new Process();
	itemId = "";
	itemType = "Process";
	machines: Machine[] = [];
	processMachines: ProcessMachine[] = [];
	processTemplates: any = [];
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	statuses: any;
	title: string = "View " + this.itemType;

	constructor(private router:Router, private route: ActivatedRoute, private itemService: ProcessService, private machineService: MachineService, private processMachineService: ProcessMachineService) {
		this.buildButtons("view");
		this.departments = GetPropertiesFromEnum(Department);
		this.processTemplates = GetPropertiesFromEnum(ProcessTemplate);
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {
						this.getItem(params.id);
					}
				} else {
					this.create();
				}
			});
	}

	buildButtons(mode: string) {
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];	
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		// need to rework this as it won't have the id for the machine assignment
		this.item.totalHourlyRate = this.item.hourlyLabourRate + this.item.hourlyMachineRate;
		this.itemService.createSingle(this.item)
			.subscribe(
				(res: any) => {
					const filteredMachines = this.machines.filter((f) => this.activeMachineIds.indexOf(f.id) !== -1);
					this.processMachineService.updateMachinesForProcess(res.response.lookupId, filteredMachines)
						.subscribe(
							(res) => console.log(res),
							(err) => {
								console.log(err);
								notify("Something went wrong!", "error", 5000);
							},
							() => notify("Successfully Updated", "success", 5000)
						);
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new Process();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "hourlyLabourRate":
			case "hourlyMachineRate":
				this.item.totalHourlyRate = this.item.hourlyLabourRate + this.item.hourlyMachineRate;
				break;
			case "department":
				this.getMachines();
				break;
		}
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getProcessLinks();
					this.getMachines();
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getMachines() {
		this.machineService.getByDepartment(this.item.department)
			.subscribe(
				(res: any) => this.machines = res.response,
				(err) => console.log(err)
			);
	}

	getProcessLinks() {
		this.processMachineService.forProcess(this.item.id)
			.subscribe(
				(res: any) => this.processMachines = res.response,
				(err) => console.log(err),
				() => this.processMachines.forEach((w) => {
					this.activeMachineIds.push(w.machineId);
				})
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	toggleMachine(machine: Machine) {
		const index = this.activeMachineIds.indexOf(machine.id);
		if (index === -1) this.activeMachineIds.push(machine.id);
		else this.activeMachineIds.splice(index, 1);
	}

	updateItem() {
		this.item.totalHourlyRate = this.item.hourlyLabourRate + this.item.hourlyMachineRate;
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					const filteredMachines = this.machines.filter((f) => this.activeMachineIds.indexOf(f.id) !== -1);
					this.processMachineService.updateMachinesForProcess(this.item.lookupId, filteredMachines)
						.subscribe(
							(res) => console.log(res),
							(err) => {
								console.log(err);
								notify("Something went wrong!", "error", 5000);
							},
							() => notify("Successfully Updated", "success", 5000)
						);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}