import { Component } from "@angular/core";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Group } from "@interfaces/group.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { ExportLogService } from "@services/exportLog.service";
import { GroupService } from "@services/group.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "groups",
	styleUrls: ["groups.css"],
	templateUrl: "groups.html"
})
export class GroupAdminComponent {
	groups: Group[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [
		{ icon: "fal fa-plus", method: "addNewItem", text: "New Group" }
	];
	title = "Groups";

	constructor(private exportLogService: ExportLogService, private groupService: GroupService) {
		this.getGroups();
	}

	addNewItem() {
		// TODO: Add new
	}

	getGroups() {
		this.groupService.getAll()
			.subscribe(
				(res: any) => this.groups = res.response.items,
				(err) => console.log(err),
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Groups";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}
}