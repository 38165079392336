export interface WorkOrderStageIncrementQuantity {
	id: number;
	maximumQuantity: number;
	quantity: number;
}

export class WorkOrderStageIncrementQuantity implements WorkOrderStageIncrementQuantity {
	constructor(data?: Partial<WorkOrderStageIncrementQuantity>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}