import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { CreateQLoopDto } from "@interfaces/createQLoopDto.interface";
import { GenerateOpsPlanningWorkOrdersPdf } from "@interfaces/generateOpsPlanningWorkOrdersPdf.interface";
import { Service } from "@services/service";
import { Guid } from "guid-typescript";

@Injectable()
export class WorkOrderService extends Service {
	constructor(injector: Injector) {
		super("WorkOrder", injector);
	}

	// API	
	appGetAllDetails(id:Guid){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/AppByLookup/${id}`);
	}

	changeQuantity(id: number, quantity: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ChangeQuantity/${id}/${quantity}`, {});
	}

	complete(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Complete/${id}`, {});
	}

	createQLoop(data: CreateQLoopDto){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateQLoop`, data);
	}
	
	generateAndDownloadMasterLabelPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndDownloadMasterLabelPdf/${id}`, { responseType: "blob" as "json"} );
	}

	generateAndDownloadPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndDownloadPdf/${id}`, { responseType: "blob" as "json"} );
	}

	getAll(){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetAll`);
	}

	getAllComplete(){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetAllComplete`);
	}

	getAllIncomplete(){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetAllIncomplete`);
	}

	getForOriginal(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForOriginal/${id}`);
	}

	getForParent(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForParent/${id}`);
	}

	getForPart(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForPart/${id}`);
	}

	getForSalesOrder(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForSalesOrder/${id}`);
	}

	getStageSummaryForPart(partId: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/StageSummaryForPart/${partId}`);
	}
	
	opsPlanningWorkOrderSummaryPdf(parameters: GenerateOpsPlanningWorkOrdersPdf) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/OpsPlanningWorkOrderSummaryPdf`, parameters, { responseType: "blob" as "json"} );
	}
	
	split(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Split/${id}`, {});
	}
}