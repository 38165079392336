import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class SupplierLocationService extends Service {
	constructor(injector: Injector) {
		super("SupplierLocation", injector);
	}

	// API
	getLocationsBySupplierId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetLocationsBySupplierId/${id}`);
	}

	getPrimaryBillingForSupplierId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetPrimaryBillingForSupplierId/${id}`);
	}

	getPrimaryDeliveryForSupplierId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetPrimaryDeliveryForSupplierId/${id}`);
	}

	setPrimaryBilling(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/SetPrimaryBilling/${id}`, {});
	}

	setPrimaryDelivery(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/SetPrimaryDelivery/${id}`, {});
	}
}