import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class SupplierDocumentService extends Service {
	constructor(injector: Injector) {
		super("SupplierDocument", injector);
	}

	// API
	getDocumentsForSupplierId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/DocumentsFor/${id}`);
	}
}