import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Audit } from "@interfaces/audit.interface";
import { AuditService } from "@services/audit.service";

@Component({
	moduleId: module.id,
	selector: "audits-id",
	styleUrls: ["audits-id.css"],
	templateUrl: "audits-id.html"
})
export class AuditIdAdminComponent {
	audit: Audit = new Audit();
	auditId = 0;

	constructor(private route: ActivatedRoute,private auditService: AuditService) {
		this.route.params
			.subscribe((params) => {
				this.auditId = params.id;
				this.getAudit(this.auditId);
			});
	}

	getAudit(auditId: number) {
		this.auditService.getSingleById(auditId)
			.subscribe(
				(res: any) => this.audit = res.response,
				(err) => console.log(err)
			);
	}
}