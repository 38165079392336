import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import { Guid } from "guid-typescript";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import { PurchaseType } from "@enums/purchaseType.enum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { OutsourcedSpecification } from "@interfaces/outsourcedSpecification.interface";
import { PurchasableItem } from "@interfaces/purchasableItem.interface";
import { RequestForQuote } from "@interfaces/requestForQuote.interface";
import { RequestForQuoteLineItem } from "@interfaces/requestForQuoteLineItem.interface";
import { RequestForQuoteOutsourcedLineItem } from "@interfaces/requestForQuoteOutsourcedLineItem.interface";
import { Supplier } from "@interfaces/supplier.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AuthService } from "@services/auth.service";
import { RequestForQuoteService } from "@services/requestForQuote.service";
import { OutsourcedSpecificationService } from "@services/outsourcedSpecification.service";
import { PurchasableItemService } from "@services/purchasableItem.service";
import { SupplierService } from "@services/supplier.service";
import { UserProfileService } from "@services/userProfile.service";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "requestForQuotes-id",
	styleUrls: ["requestForQuotes-id.css"],
	templateUrl: "requestForQuotes-id.html"
})
export class RequestForQuotesIdAdminComponent {
	@ViewChild("gridContainer", { static: false }) dataGrid!: DxDataGridComponent;
	changes: any = [];
	createEnabled = true;
	editEnabled = true;
	editRowKey: any = null;
	groupedPurchasableItems: DataSource | undefined;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: RequestForQuote = new RequestForQuote();
	itemType = "RFQ";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	lineItemPurchasableItem: PurchasableItem = new PurchasableItem();
	lineItems: RequestForQuoteLineItem[] = [];	
	outsourcedLineItems: RequestForQuoteOutsourcedLineItem[] = [];	
	outsourcedSpecifications: OutsourcedSpecification[] = [];
	people: UserProfile[] = [];
	purchasableItems: PurchasableItem[] = [];
	readOnly = true;
	readOnlyAlways = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "";
	suppliers: Supplier[] = [];
	thicknesses: number[] = [0, 0.2, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.5, 1.6, 1.8, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 10, 12, 15, 20, 25];
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private authServices: AuthService, private itemService: RequestForQuoteService, private outsourcedSpecificationService: OutsourcedSpecificationService, private purchasableItemService: PurchasableItemService, private supplierService: SupplierService, private userProfileService: UserProfileService) {
		this.getPeople();
		this.getOutsourcedSpecifications();
		this.getSuppliers();
		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.getItem(params.id.split("_")[0], "edit");
					this.edit();
				} else {
					this.getItem(params.id, "view");
				}
			} else {
				this.create();
				this.buildButtons("create");
			}
		});
	}

	buildButtons(mode: string) {
		this.screenMode = mode;
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ icon: "fal fa-mailbox", method: "sendEmail", text: "Send" }
			];
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.title = "Create New " + this.itemType;
	}

	createItem() {		
		this.authServices.getCurrentUser()
			.subscribe(
				(res: any) => { 
					const user = res.response;
					this.item.createdById = user.id;
					this.item.lineItems = JSON.stringify(this.lineItems);
					
					this.itemService.createSingle(this.item)
						.subscribe(
							() => {
								notify("Successfully Created " + this.itemType, "success", 5000);
								this.close();
							},
							(err) => {
								console.log(err);
								notify("Something went wrong!", "error", 5000);
							}
						);
				},
				(err) => console.log(err)
			);
	}

	edit() {
		this.readOnly = false;
		this.title = "Edit " + this.itemType;
		this.buildButtons("edit");
		notify("You are now editing...", "warning", 5000);
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "supplierId":
				this.getPurchasableItems();
				break;
		}
	}

	getItem(lookupId: Guid, buttonMode: string) {
		this.itemService.getSingleByLookupId(lookupId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.lineItems = JSON.parse(this.item.lineItems);
					this.outsourcedLineItems = JSON.parse(this.item.outsourcedLineItems);
					this.getPurchasableItems();
					this.buildButtons(buttonMode);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
			);
	}

	getOutsourcedSpecifications()	{
		this.outsourcedSpecificationService.getAll()
			.subscribe(
				(res: any) => {
					this.outsourcedSpecifications = res.response.items;

					this.outsourcedSpecifications.sort((a,b) => a.specification.localeCompare(b.specification));
				},
				(err) => console.log(err)
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}
	
	getPurchasableItems() {
		this.purchasableItemService.getForSupplier(this.item.supplierId)
			.subscribe(
				(res: any) =>  {
					this.purchasableItems = res.response;
					const transformedPurchasableItems: any = [];

					this.purchasableItems.forEach(purchasableItem => {
						transformedPurchasableItems.push({ category: `${PurchaseType[purchasableItem.purchaseType]}`, id: purchasableItem.id, name: purchasableItem.name });
					});

					this.groupedPurchasableItems = new DataSource({
						group: "category",
						store: new ArrayStore({
							data: transformedPurchasableItems,
							key: "id",
						}),
					});
				},
				(err) => console.log(err)
			);
	}
	
	getSuppliers() {
		this.supplierService.getAll()
			.subscribe(
				(res: any) =>  {
					this.suppliers = res.response.items;
					this.suppliers.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	sendEmail() {
		if (confirm("Are you sure you wish to send this RFQ? Please ensure the supplier RFQ contact and the details below are correct.") == false) {
			return;
		}

		this.itemService.send(this.item.id)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					this.getItem(this.item.lookupId, this.screenMode);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateItem() {
		this.item.lineItems = JSON.stringify(this.lineItems);
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					// this.cancelEditing();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}