import { Component } from "@angular/core";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Feature } from "@interfaces/feature.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { ExportLogService } from "@services/exportLog.service";
import { FeatureService } from "@services/feature.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "features",
	styleUrls: ["features.css"],
	templateUrl: "features.html"
})
export class FeatureAdminComponent {
	features: Feature[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [
		{ icon: "fal fa-plus", method: "addNewItem", text: "New Feature" }
	];
	title = "Features";

	constructor(private featureService: FeatureService, private exportLogService: ExportLogService) {
		this.getFeatures();
	}

	addNewItem() {
		// TODO: Add new
	}

	getFeatures() {
		this.featureService.getAll()
			.subscribe(
				(res: any) => this.features = res.response.items,
				(err) => console.log(err),
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Features";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}
}