import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "@app/app.module";
import { environment } from "@environments/environment";
import config from "devextreme/core/config";

if (environment.build.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.error(err));

config({  
	editorStylingMode: "filled"
}); 