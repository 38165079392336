import { AfterViewInit, Component, Input } from "@angular/core";
import { SalesOrderLineItem } from "@interfaces/salesOrderLineItem.interface";

@Component({
	moduleId: module.id,
	selector: "salesOrderGridDetails",
	styleUrls: ["salesOrderGridDetails.css"],
	templateUrl: "salesOrderGridDetails.html"
})
export class SalesOrderGridDetailsComponent implements AfterViewInit {
	@Input() lineItemData = "";
	lineItems: SalesOrderLineItem[] = [];
  
	ngAfterViewInit() {
		this.lineItems = JSON.parse(this.lineItemData);
	}
}