<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-tabs #apiTabs [dataSource]="tabs" [selectedIndex]="0" (onItemClick)="selectTab($event)"></dx-tabs>
	<dx-scheduler *ngIf="selectedTabId === 0" id="dayShiftScheduler" [dataSource]="requestsData" [views]="['timelineMonth']" currentView="timelineMonth" [firstDayOfWeek]="0" [startDayHour]="8" [endDayHour]="20" [cellDuration]="60" [groups]="['ownerId']" [currentDate]="currentDate" height="auto" appointmentTemplate="appointmentTemplate" appointmentTooltipTemplate="tooltipTemplate" (onAppointmentClick)="blockPopup($event)" (onAppointmentDblClick)="blockPopup($event)">
		<dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowDragging]="false" [allowUpdating]="false"></dxo-editing>
		<div *dxTemplate="let model of 'appointmentTemplate'"></div>
		<div *dxTemplate="let model of 'tooltipTemplate'">
			{{model.appointmentData.details}}
		</div>
		<dxi-resource fieldExpr="ownerId" [allowMultiple]="false" [dataSource]="dayShiftPeopleData" label="Employee"></dxi-resource>
		<dxi-resource fieldExpr="requestStatus" [allowMultiple]="false" [dataSource]="statusesData" label="Status" [useColorAsDefault]="true"></dxi-resource>
	</dx-scheduler>
	<dx-scheduler *ngIf="selectedTabId === 1" id="nightShiftScheduler" [dataSource]="requestsData" [views]="['timelineMonth']" currentView="timelineMonth" [firstDayOfWeek]="0" [startDayHour]="8" [endDayHour]="20" [cellDuration]="60" [groups]="['ownerId']" [currentDate]="currentDate" height="auto" appointmentTemplate="appointmentTemplate" appointmentTooltipTemplate="tooltipTemplate" (onAppointmentClick)="blockPopup($event)" (onAppointmentDblClick)="blockPopup($event)">
		<dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowDragging]="false" [allowUpdating]="false"></dxo-editing>
		<div *dxTemplate="let model of 'appointmentTemplate'"></div>
		<div *dxTemplate="let model of 'tooltipTemplate'">
			{{model.appointmentData.details}}
		</div>
		<dxi-resource fieldExpr="ownerId" [allowMultiple]="false" [dataSource]="nightShiftPeopleData" label="Employee"></dxi-resource>
		<dxi-resource fieldExpr="requestStatus" [allowMultiple]="false" [dataSource]="statusesData" label="Status" [useColorAsDefault]="true"></dxi-resource>
	</dx-scheduler>
	<dx-scheduler *ngIf="selectedTabId === 2" id="bothShiftsScheduler" [dataSource]="requestsData" [views]="['timelineMonth']" currentView="timelineMonth" [firstDayOfWeek]="0" [startDayHour]="8" [endDayHour]="20" [cellDuration]="60" [groups]="['ownerId']" [currentDate]="currentDate" height="auto" appointmentTemplate="appointmentTemplate" appointmentTooltipTemplate="tooltipTemplate" (onAppointmentClick)="blockPopup($event)" (onAppointmentDblClick)="blockPopup($event)">
		<dxo-editing [allowAdding]="false" [allowDeleting]="false" [allowDragging]="false" [allowUpdating]="false"></dxo-editing>
		<div *dxTemplate="let model of 'appointmentTemplate'"></div>
		<div *dxTemplate="let model of 'tooltipTemplate'">
			{{model.appointmentData.details}}
		</div>
		<dxi-resource fieldExpr="ownerId" [allowMultiple]="false" [dataSource]="peopleData" label="Employee"></dxi-resource>
		<dxi-resource fieldExpr="requestStatus" [allowMultiple]="false" [dataSource]="statusesData" label="Status" [useColorAsDefault]="true"></dxi-resource>
	</dx-scheduler>
</div>