<div class="container footer-background">
	<div class="row justify-content-center"></div>
	<div class="row justify-content-center translucent-background">
		<div class="col-12 col-lg-11 col-xxl-10">
			<div class="row my-3">
				<div class="col-12 col-md-4">
				</div>
				<div class="col-12 col-md-4">
				</div>
				<div class="col-12 col-md-4">
				</div>
			</div>
		</div>
	</div>
	<div class="row"></div>
</div>